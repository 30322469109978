import React, { useEffect, useState } from "react";
import { Stack, Menu, MenuItem, Typography, IconButton } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import useLanguage from "../hooks/useLanguage";
import useData from "../hooks/useData";
import { Icon } from "@mui/material";

const Language = () =>{
    const { setLanguage } = useLanguage()
    const { languagesList } = useData()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => languagesList.length ? setAnchorEl(event.currentTarget) : {}
    const handleClose = () => setAnchorEl(null)
    const [colored, setColored] = useState(false)
    useEffect(()=> setColored(anchorEl != null) , [anchorEl])
    
    return(
        <Stack>
            <IconButton onMouseEnter={()=>setColored(true)} style={{color: colored ? '#FDC309': '#fff'}}
                onMouseLeave={()=> {if (!anchorEl) setColored(false)}}
             onClick={handleClick} variant="nav"><LanguageIcon/></IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}>
                {
                    languagesList.map( language =>
                        <MenuItem key={language.value} onClick={() => {setLanguage(language.value); handleClose()}}><Typography><Icon>{language.icon}</Icon> {language.innerHTML}</Typography></MenuItem>
                    )
                }
            </Menu>
        </Stack>
    )
}
export default Language