import React, { useContext, useState } from "react";
import { useForm } from 'react-hook-form'
import { Stack, Typography } from "@mui/material";
import axiosInstance from "../../axios";
import { useNavigate, Link } from "react-router-dom";
import { AuthStateContext } from "../state/AuthStateContext";
import useLanguage from '../hooks/useLanguage'
import RoutesList from "../../helper/routes";
import { roles } from "../../helper/roles";
import ImgInputLight from './ImgInputLight';
import PointedButton from './PointedButton'
import CustomSelect from "./select";
import useData from "../hooks/useData";
import Alert from '@mui/material/Alert';
import PasswordForgot from "../MemberArea/Auth/PasswordForgot";
import { format } from "date-fns";

const LoginForm = ({type, roleIndex=0}) => {
    const { control, handleSubmit, formState: {errors} } = useForm()
    const [passwordForgot, setPasswordForgot] = useState(false)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const { setAuthenticated } = useContext(AuthStateContext)
    const [loading, setLoading] = useState(false)
    const baseUrl = '/images/Member/'
    const { text } = useLanguage()
    const { rolesSelect } = useData()
    const [ datereactivate, setDateReactivate ] = useState('')

    const homeRoute = role =>{
        switch (role){
            case roles.blogger: return RoutesList.blogger
            case roles.admin: return RoutesList.adminhome
            case roles.teamleader: return RoutesList.teamleaderhome
            case roles.manager: return RoutesList.managerhome
            default: return RoutesList.landing
        }
    }

    const login = async data =>{
        const { mailpseudo, password, role } = data
        setLoading(true)
        setError('')
        try {
            const {status, data} = await axiosInstance.post('/login', { email: mailpseudo, user_name: mailpseudo, password: password, role: role  })
            if (status == 200){
                setError('')
                await setAuthenticated(true, role, data?.token)
                navigate(homeRoute(role))
            }
        } catch (error) {
            if (error.response.status == 401)
                switch (error.response.data.error){
                    case 'id': case 'role': setError(error.response.data.error); break
                    case 'mot de passe invalide': setError('mdp'); break
                    default: setError('smth')
                }         
            else if (error.response.status == 403){
                    if (error.response.data.message =='Votre compte a été suspendu pendant 15 jours vous ne pouvez pas accédez') 
                        {
                            setDateReactivate (error.response.data.date ? format(new Date(error.response.data.date), 'dd/MM/yyyy') : '')
                            setError('sspnd')
                        }
                    else if (error.response.data.error=='Utilisateur banni') {
                        setError('bn')
                    }
                }
            
                else setError('smth')
        }
        setLoading(false)
    } 

    return(
        <Stack className="height-max-adjust" alignItems="center" justifyContent='center'variant="primarybg">
            <Stack className="width-resize" alignItems="center">
                <Stack spacing={3} className="width-resize">
                    <Stack style={{flexDirection: 'row', alignItems:"center"}}>    
                        <Stack className="imginput-img" borderRadius="50%" style={{ backgroundColor: '#eaea53', transform: "translateX(30%)" , border: '5px solid #4B4998'}}><img src={baseUrl + 'role.png'} alt="" /></Stack>
                        <Stack style={{backgroundColor:"#eaea53", flexDirection:"row", width:"100%", alignItems: "center"}}>
                            <CustomSelect name="role" control={control}
                                options={rolesSelect} className="in-input-light" defaultValueIndex={roleIndex}
                            />
                        </Stack>
                        <Stack style={{  width: 0, height: 0, borderTop: '25px solid transparent', borderRight:'25px solid #4B4998', borderBottom: '25px solid transparent', transform: "translateX(-100%)"}}></Stack>
                    </Stack>
                    <ImgInputLight placeholder={text.yourmailpseudo} control={control} name="mailpseudo" type="text" error={errors.mailpseudo} imgSrc={baseUrl + 'signinuser.png'}/>
                    <ImgInputLight placeholder={text.yourpassword} control={control} name="password" type="password" error={errors.password} imgSrc={baseUrl + 'signinpassword.png'}/>
                    {error && <Alert style={{margin: "20px"}} severity="error">
                        {
                            (error == 'smth') ? <Typography variant="error">{text.smthngwntwrng}</Typography>
                            : (error == 'mdp') ? <Typography variant="error">{text.mdperr}</Typography> 
                            : (error == 'sspnd') ? <Typography variant="error">{text.susp}{datereactivate} {text.reak}</Typography> 
                            : (error == 'bn') ? <Typography variant="error">{text.bn}</Typography> 
                            :<Typography variant= "error" >{text.iderr}</Typography> 
                        }
                    </Alert>}
                    <Link className="link4" onClick={()=>setPasswordForgot(true)} style={{textAlign: "center", textDecoration: "none"}}><Typography>{text.idorpasswordforgot}</Typography></Link>
                    <Stack alignItems="center"><PointedButton loading={loading} width="calc(100% - 50px)" onClick={handleSubmit(login)}>{text.signin}</PointedButton></Stack>
                </Stack>
            </Stack>
            <PasswordForgot visible={passwordForgot} onClose={() => setPasswordForgot(false) }/>
        </Stack>
    )
}
export default LoginForm