import React, {useRef} from "react";
import { Stack, Typography} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import TestimonialCard from "../tools/TestimonialCard";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CustomDivider from "../tools/CustomDivider"
import useData from "../hooks/useData";

const Testimonial = () =>{
    const { text } = useLanguage()
    const autoplaySpeed = 8500
    
    const slider = useRef(null)
    const restart = () => { if (slider.current) slider.current.slickGoTo(0) }
    const { testimonialCards } = useData()

    return(
        <Stack  alignItems="center" variant='testimonial' spacing={2} padding= '30px 0'>
            <Stack alignItems='center' spacing={3} maxWidth="80%">
                <Stack className="title-typo"  ><Typography variant='primarytitle' className="title-typo-change-light">{text.testimonial}</Typography></Stack>
                <CustomDivider color="secondary" alignCenter width='22%'/>
                <Stack textAlign='center'><Typography >{text.dedicated} <Typography variant='bold' >{text.cn}</Typography>{text.sharing}</Typography></Stack>
            </Stack>
            <Stack className="carousel-container">
                <Slider ref={slider} dots={false} infinite={false} speed={2500} slidesToShow={1} slidesToScroll={1} autoplay={true} autoplaySpeed={autoplaySpeed}
                    afterChange={current => { if (current == testimonialCards.length -1) setTimeout(restart, autoplaySpeed/2) }} >
                    {
                        testimonialCards.map( (t, index) =>
                            <TestimonialCard key={"t" + index} texts={t.texts} imgFile={t.imgFile}/>
                        )
                    }
                </Slider>
            </Stack>
        </Stack>
    )
}
export default Testimonial