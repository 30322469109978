import { Stack, Typography, Button, Alert, CircularProgress, Box, Select, MenuItem } from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form'
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import axiosInstance from "../../axios";
import { roles } from "../../helper/roles";
import { format } from "date-fns";
import { AuthStateContext } from "../state/AuthStateContext";
import PdfDisplayer from "../tools/PdfDisplayer";
import { useLocation } from "react-router-dom";
import RoutesList from "../../helper/routes";
import countriesList from "../../helper/countries";
import generateFormData from "../../helper/formData";
import { baseURL } from "../../helper/conf";
import OpenableImage from "../tools/OpenableImage";
import Input from "../tools/input";
import CustomSelect from "../tools/select";
import { jobs } from "../../helper/jobs";

const NewEmpTraining = () =>{
    const { text } = useLanguage()
    const { jobName, languageName, filterLanguages, payment } = useData()
    const [ feedback, setFeedback ] = useState('')
    const [ loginAuto, setLoginAuto ] = useState('')
    const [filter1, setFilter1] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        jobTitle: '',
        language: 'all',
    })
    const [filter2, setFilter2] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        jobTitle: '',
        language: 'all',
        teamLeader: 'all',
    })
    const [filter3, setFilter3] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        jobTitle: '',
        language: 'all',
        teamLeader: 'all',
    })
    const { control: control1, handleSubmit: handleSubmit1, formState: {errors: errors1}, setValue } = useForm()
    const { control: control2, handleSubmit: handleSubmit2, formState: {errors: errors2}, reset: reset2 } = useForm()
    
    const [editing, setEditing] = useState({job: '', payment: -1})
    //const { control: tscontrol, handleSubmit: tshandleSubmit, formState: {errors: tserrors} } = useForm()
    const [teamLeaderList, setTeamLeaderList] = useState([])
    const [filterTL, setFilterTL] = useState([{value: 'all', innerHTML: text.all }])
    //const [jobList, setJobList] = useState([])

    const [ trSch, setTrSch ] = useState([])

    const [ trainingDocumentsSending, setTrainingDocumentsSending ] = useState({})

    const { adminId, userId, managerId } = useContext(AuthStateContext)
    const location = useLocation()
    const [awaitLoginList, setAwaitLoginList] = useState([])

    const [selectedPayment, setSelectedPayment] = useState('')
    const { control: editControl, handleSubmit: handleEditSubmit, formState: { errors: editErrors }, setValue: setValueEdit } = useForm()

    const getToken = () =>{
        switch (location.pathname.split('/')[1]){
            case RoutesList.teamleaderhome.split('/')[1]: return userId
            case RoutesList.adminhome.split('/')[1]: return adminId
            case RoutesList.managerhome.split('/')[1]: return managerId
            default: return ''
        }
    }
    const [ agents, setAgents ] = useState([])
    const getDataAgents = async () =>{
        try {
            const { data: { Lists } } = await axiosInstance.get(`/agent_login/lists`, { headers: { 'Authorization': `Bearer ${getToken()}`}})
            setAgents(Lists)
        } catch (error) {}
    }
    const getDataTS = async () =>{
        try {
            const { data: {integrations} } = await axiosInstance.get(`/hired_users/training`, { headers: { 'Authorization': `Bearer ${getToken()}`}})
            setTrSch(integrations)
        } catch (error) {}
    }
    const getDataLoginAwait = async () =>{
        try {
            const { data: { trainings } } = await axiosInstance.get(`/agent/login_await/lists`, { headers: { 'Authorization': `Bearer ${getToken()}`}})
            setAwaitLoginList(trainings)
        } catch (error) {}
    }
    useEffect(()=>{
        const func = async () =>{
            try {
                let finalList = []
                let filterList = [{value: 'all', innerHTML: text.all }]
                const { data } = await axiosInstance.get(`/users/${roles.teamleader}`, { headers: { 'Authorization': `Bearer ${getToken()}` }})
                data.forEach(d => {
                    finalList.push({ value: d.user_name , innerHTML: d.user_name })
                    filterList.push({ value: d.user_name , innerHTML: d.user_name })
                })
                setTeamLeaderList(finalList)
                setFilterTL(filterList)
            } catch (error) {}
            /*try {
                let finalList = []
                const { data:{jobs} } = await axiosInstance.get(`/job/lists/${currentLanguage}`, { headers: { 'Authorization': `Bearer ${getToken()}` }})
                jobs.forEach(j => finalList.push({ value: j.title, innerHTML: jobName(j.title) }))
                setJobList(finalList)
            } catch (error) {}*/
        }
        func()
        getDataTS()
        getDataAgents()
        getDataLoginAwait()
    }, [])
    const getPaymentMethod = arr =>{
        if (!arr) return [ '' , text.notprovided]
        if (arr.paypal?.length) return ['PayPal', arr.paypal]
        if (arr.wise?.length) return ['Wise', arr.wise]
        if (arr.your_safe?.length) return ['Yoursafe', arr.your_safe]
        if (arr.iban?.length) return [text.bank, arr.iban]
        return [ '' , text.notprovided]
    }
    const [loading, setLoading] = useState(-1)
    //const [errts, setErrts] = useState('')
    /*const sendMailConf = async data =>{
        const test_id = editing
        setLoading(test_id)
        setEditing(-1)
        setErrts('')
        try {
            const { status } = await axiosInstance.post(`/hired_users/training/${test_id}`, { leader: data.teamleader, training_schedule: data.date }, { headers: { 'Authorization': `Bearer ${getToken()}`} })
            if (status == 200) {
                setErrts(text.success)
                const newList = trSch.filter(t => t.id_test != test_id)
                setTrSch(newList)
            }
        } catch (error) {
            if(error.response.data.message == 'Document de formation non trouvé') setErrts(text.frmtdcnntrv)
            else setErrts(text.smthngwntwrng)
        }
        setLoading(-1)

    }*/
    const [ loginGiving, setLoginGiving ] = useState({})
    const [nlLoading, setNlLoading] = useState(false)
    const [nlFb, setNlFb] = useState('')
    const newLogin = async data =>{
        setNlLoading(true)
        if (loginGiving.first_name){
            const login = loginAuto
            const { password } = data
            try {
                const { status } = await axiosInstance.post(`/agent/login_attribution/${loginGiving.id_test}/${loginGiving.id_user}/create`, {
                    login, password
                }, { headers: { 'Authorization': `Bearer ${getToken()}`}})
                if (status == 200) {
                    setNlFb(text.success)
                    setLoginGiving({})
                    reset2()
                    await getDataAgents()
                    await getDataLoginAwait()
                }
            } catch (error) { setNlFb(text.smthngwntwrng) }
        }
        setNlLoading(false)
    }
    const getExtension = name =>{
        const parts = name.split('.')
        return parts.pop()
    }

    const [document1, setDocument1] = useState(null)
    const [document2, setDocument2] = useState(null)
    const [document3, setDocument3] = useState(null)
    const [document4, setDocument4] = useState(null)
    const [ntdLoading, setNtdLoading] = useState(false)
    const sendTrainingSchedule = async data =>{
        const { id_test } = trainingDocumentsSending
        const { teamleader, date, startinghour } = data
        console.log(data, startinghour, typeof(startinghour))
        setFeedback('')
        setNtdLoading(true)
        try {
            const { status } = await axiosInstance.post(`/hired_users/training/${id_test}`, generateFormData({
                document1, document2, document3, document4, leader: teamleader, training_schedule: format(date, 'yyyy-MM-dd'), time: startinghour
            }) ,{ headers: { 'Authorization': `Bearer ${getToken()}`, 'Content-Type': 'multipart/form-data'}, transformRequest: null})
            if (status == 200) { setFeedback(text.success); window.location.reload() }
        } catch (error) {
            console.log(error.response)
            setFeedback(text.smthngwntwrng)
        }
        setNtdLoading(false)
    }
    const markTrainingAsFinished = async ts =>{
        setLoading(ts.id_test)
        try {
            const { status } = await axiosInstance.post(`/finish_training/${ts.id_test}/${ts.id_user}`, {}, { headers: { 'Authorization': `Bearer ${getToken()}`}})
            const nl = trSch.filter(n => n.id_test != ts.id_test)
            setTrSch(nl)
            if (status == 200) {
                await getDataLoginAwait()
                await getDataTS()
            }
        } catch (error) {}
        setLoading(-1)
    }
    const [ trainingHr, setTrainingHr ] = useState('00')
    const pmvalues = {
        wise: 'wise',
        paypal: 'paypal',
        yoursafe: 'yoursafe',
        bank: 'bank'
    }
    const getPaymentMethodValue = arr =>{
        if (!arr) return null
        if (arr.paypal?.length) return pmvalues.paypal
        if (arr.wise?.length) return pmvalues.wise
        if (arr.your_safe?.length) return pmvalues.yoursafe
        if (arr.iban?.length) return pmvalues.bank
        return null
    }
    const showEditForm = item =>{
        setEditing({job: '', payment: -1})
        setEditing({job: item.job_title, payment: item.payements?.id})
        setValueEdit('password', item.access_training?.password)
        const paymentmethodvalue = getPaymentMethodValue(item.payements)
        if (paymentmethodvalue){
            setValueEdit('paymentmethod', paymentmethodvalue)
            setSelectedPayment(paymentmethodvalue)
            setValueEdit("mail", getPaymentMethod(item.payements)[1])
        }
        /*switch (paymentmethodvalue){
            case pmvalues.wise: setValueEdit('wiseemail', item.payements.wise); break;
            case pmvalues.paypal: setValueEdit('paypalemail', item.payements.paypal); break;
            case pmvalues.yoursafe: setValueEdit('bitesafeemail', item.payements.your_safe); break;
            case pmvalues.bank: setValueEdit('iban', item.payements.iban); break;
            default: break;
        }*/
    }
    const [ editFb, setEditFb ] = useState(null)
    const [editLoading, setEditLoading] = useState(-1)
    const getDataToSend = data =>{
        const { mail, password, paymentmethod } = data
        switch (paymentmethod){
            case pmvalues.wise: return { wise: mail, paypal: null, your_safe: null, iban: null, password }
            case pmvalues.paypal: return { wise: null, paypal: mail, your_safe: null, iban: null, password }
            case pmvalues.yoursafe: return {  wise: null, paypal: null, your_safe: mail, iban: null,password }
            case pmvalues.bank: return { wise: null, paypal: null, your_safe: null, iban: mail, password }
            default: return { wise: null, paypal: null, your_safe: null, iban: null, password }
        }
    }
    const editPayOrPass = async (data, i) =>{
        const dataToSend = getDataToSend(data)
        setEditLoading(-1)
        setEditFb(null)
        setEditLoading(i)
        try {
            const { status } = await axiosInstance.post(`/agent_login/edit/${editing.job}/${editing.payment}`, dataToSend, { headers: { 'Authorization': `Bearer ${getToken()}`}})
            if (status == 200){
                await getDataAgents()
                setEditFb('success')
            }
        } catch (error) { setEditFb('error') }
        setEditing({job: '', payment: -1})
        setEditLoading(-1)
    }
    const getPlaceholder = () =>{
        switch (selectedPayment){
            case pmvalues.wise: return text.yourwise
            case pmvalues.paypal: return text.yourpaypal
            case pmvalues.yoursafe: return text.yourbitesafe
            case pmvalues.bank: return text.iban
            default: return ''
        }
    }
    const [idC,setIdC] = useState('')

    const [ loginsAuto, setLoginsAuto ] = useState({})

    const getJobCode = job => {
        switch(job){
            case jobs.chatoperator: return 'OT'
            case jobs.supervisor: return 'SP'
            case jobs.teamleader: return 'TL'
            default: return ''
        }
    }

    const fourDigits = n => {
        if (n > 999) return n
        if (n > 99) return '0' + n
        if (n > 9) return '00' + n 
        return '000' + n
    }

    useEffect(() => {
        const { job_title: job, language_test: language } = loginGiving
        if (job){
            const code = getJobCode(job)
            const lang = language.toUpperCase()
            const lastAgent = loginsAuto[job + language]
            const id = fourDigits( (lastAgent || 0) + 1)
            setLoginAuto(code + lang + '_' + id)
        }
        else setLoginAuto('')

    }, [loginGiving, loginsAuto])

    useEffect(() => {
        setLoginsAuto({})
        agents.forEach(agent =>{
            const { job_title: job, lang_test: language } = agent
            const key = job + language
            setLoginsAuto(value => ({ ...value, [key]: value[key] ? value[key] + 1 : 1  }))
        })
        console.log(loginsAuto)
    }, [agents])
    return(
        <Stack variant="primarybg" spacing={3} onClick={e=>setIdC(e.target.id.toString())}>
            <Stack style={{display: 'flex', gap:'15px', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                <img className="card-img-resize-2" src='/images/Admin/new_employee_training.png' alt=""/>
                <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#555555' fontWeight='bold' 
                    style={{WebkitTextStroke: "1px #ccccc6"}}
                >{text.newemptraining.toUpperCase()  }</Typography>
            </Stack>
            <BigContainerAccordion 
                summaryBg="#8fa9b1"
                summary={text.anet1}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter1.date}
                            onDateChange={{
                                year: y => setFilter1({...filter1, year: y}),
                                month: m => setFilter1({...filter1, month: m}),
                                date: d => setFilter1({...filter1, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter1.startingDate, onChange: d => setFilter1({...filter1, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter1.closingDate, onChange: d => setFilter1({...filter1, closingDate: d})},
                                { label: text.jobtitle , comp: 'TextInput', onChange: e => setFilter1({...filter1, jobTitle: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter1({...filter1, language: l.target.value}) },
                            ]}
                        />
                        <Stack spacing={1}>
                            <Stack>
                                <TableRow header columns={[
                                    { xs: 1, text: text.date },
                                    { xs: 1, text: text.jobtitle },
                                    { xs: 1, text: text.language },
                                    { xs: 1, text: text.agentfirstname },
                                    { xs: 1, text: text.agentlastname },
                                    { xs: 1, text: text.agentcountry },
                                    { xs: 1.25, text: text.agentemail },
                                    { xs: 1.25, text: text.agentskypeid },
                                    { xs: 1, text: text.teamleader },
                                    { xs: 1.5, text: text.trainingschedule },
                                    { xs: 1, text: text.actions },
                                ]} />
                                {
                                    trSch
                                    .filter(ts => 
                                        // Starting date
                                        ( (!filter1.startingDate) || ( filter1.startingDate <= new Date(ts.review)) )
                                        // Closing date
                                        && ( (!filter1.closingDate) || (new Date(ts.review) <= filter1.closingDate) )
                                        // Text match
                                        && ((!Boolean(jobName(ts.job_title))) || (jobName(ts.job_title)?.toLowerCase().includes(filter2.jobTitle)))
                                        // Language
                                        && ( (filter1.language == 'all') || (ts.language == filter1.language)) 
                                    )
                                    .map((ts, i) =>
                                        <TableRow key={i} columns={[
                                            { xs: 1, text: ts.review ? format(ts.review, 'dd/MM/yyyy') : '' },
                                            { xs: 1, text: jobName(ts.job_title) },
                                            { xs: 1, text: languageName(ts.language) },
                                            { xs: 1, text: ts.first_name },
                                            { xs: 1, text: ts.last_name },
                                            { xs: 1, text: (countriesList.find(c => c.value === ts.country))?.innerHTML },
                                            { xs: 1.25, text: ts.email },
                                            { xs: 1.25, text: ts.skype },
                                            { xs: 1, text: ts.team_leader },
                                            { xs: 1.5, text: ts.date_schedule ? `${format( ts.date_schedule ,'dd/MM/yyyy')} ${ts.time}:00 - ${ts.time}:45` : ''},
                                            { xs: 1, customContent: true, content:                                     
                                                <Stack width='100%' alignItems='center'>
                                                    {
                                                        (loading == ts.id_test) ?
                                                        <CircularProgress size='24px'/>
                                                        :
                                                        <Stack>
                                                            <Button onClick={()=> setTrainingDocumentsSending(ts)} style={{ textTransform: 'capitalize', color: '#3c6dc6', width: '100%' }}><Typography variant="boldcenter">{text.cnfgtrnng}</Typography></Button>
                                                            { ts.date_schedule && <Button onClick={()=>{markTrainingAsFinished(ts)}} style={{ textTransform: 'capitalize', color: '#0b0', width: '100%' }}><Typography variant="boldcenter">{text.mrksfnshd}</Typography></Button>}
                                                        </Stack>
                                                    }
                                                </Stack>
                                            },
                                        ]} />
                                    )
                                }
                            </Stack>
                            {/* errts.length && <Alert severity={errts == text.success ? 'success' : 'error'}>{errts}</Alert>*/ }
                        </Stack>

                    </Stack>
                }
            />
            <BigContainerAccordion 
                dataToDisplay={trainingDocumentsSending}
                summaryBg="#d415ff"
                summary={text.anet2}
                details={
                    <Stack spacing={2}>
                        <FormRow comp="readOnly" label={text.agentlogin} value={trainingDocumentsSending.email} control={control1} name='jobtitle' error={errors1.jobtitle}/>
                        <FormRow comp="readOnly" label={text.jobtitle} value={jobName(trainingDocumentsSending.job_title)} control={control1} name='jobtitle' error={errors1.jobtitle}/>
                        <FormRow comp="readOnly" label={text.language} value={languageName(trainingDocumentsSending.language)} control={control1} name='language' error={errors1.language}/>
                        <FormRow comp="Select" options={teamLeaderList} label={text.teamleader} control={control1} name='teamleader' error={errors1.teamleader}/>
                        <FormRow comp="DatePicker" label={text.trainingschedule} minDate={new Date()} maxDate={addDays(new Date(), 730)} control={control1} name='date' error={errors1.date}/>
                        <FormRow comp="OClockTimePicker" onChange={setTrainingHr} label={text.strthr} control={control1} name="startinghour" error={errors1.startinghour}/>
                        <FormRow comp="readOnly" label={text.dnhr} value={`${trainingHr}:45`} control={control1} name='endinghour' error={errors1.endinghour}/>
                        <FormRow comp="FileName" envImg={setDocument1} label={text.document + ' 1'} control={control1} name='doc1' error={errors1.doc1} name2="doc1name" setValue={setValue}/>
                        <FormRow comp="FileName" envImg={setDocument2} unRequired label={text.document + ' 2'} control={control1} name='doc2' error={errors1.doc2} name2="doc2name" setValue={setValue}/>
                        <FormRow comp="FileName" envImg={setDocument3} unRequired label={text.document + ' 3'} control={control1} name='doc3' error={errors1.doc3} name2="doc3name" setValue={setValue}/>
                        <FormRow comp="FileName" envImg={setDocument4} unRequired label={text.document + ' 4'} control={control1} name='doc4' error={errors1.doc4} name2="doc4name" setValue={setValue}/>
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            { trainingDocumentsSending.job_title && <Button onClick={handleSubmit1(sendTrainingSchedule)} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    ntdLoading ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>
                                        {text.sndml}
                                    </Typography>
                                }
                            </Button>}
                        </Stack>
                        { (feedback.length>0) && <Alert severity={(feedback == text.success) ? 'success' : 'error'}>{feedback}</Alert> }
                    </Stack>
                }
            />
            <BigContainerAccordion 
                summaryBg="#f3a000"
                summary={text.anet3}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                                date: d => setFilter2({...filter2, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter2.startingDate, onChange: d => setFilter2({...filter2, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter2.closingDate, onChange: d => setFilter2({...filter2, closingDate: d})},
                                { label: text.jobtitle , comp: 'TextInput', onChange: e => setFilter2({...filter2, jobTitle: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter2({...filter2, language: l.target.value}) },
                                { label: text.teamleader , comp: 'Select', choices: filterTL ,onChange: tl => setFilter2({...filter2, teamLeader: tl.target.value}) },
                            ]}
                        />
                        <Stack spacing={1}>
                            <Stack>    
                                <TableRow header columns={[
                                    { xs: 1, text: text.date },
                                    { xs: 1, text: text.jobtitle },
                                    { xs: 1, text: text.language },
                                    { xs: 1, text: text.agentfirstname },
                                    { xs: 1, text: text.agentlastname },
                                    { xs: 1, text: text.agentcountry },
                                    { xs: 2, text: text.agentemail },
                                    { xs: 1, text: text.agentskypeid },
                                    { xs: 1, text: text.teamleader },
                                    { xs: 1, text: text.trainingschedule },
                                    { xs: 1, text: text.actions },
                                ]} />
                                {
                                    awaitLoginList
                                    .filter(a => 
                                        // Starting date
                                        ( (!filter2.startingDate) || ( filter2.startingDate <= new Date(a.date)) )
                                        // Closing date
                                        && ( (!filter2.closingDate) || (new Date(a.date) <= filter2.closingDate) )
                                        // Text match
                                        && ((!Boolean(jobName(a.job_title))) || (jobName(a.job_title)?.toLowerCase().includes(filter2.jobTitle)))
                                        // Language
                                        && ( (filter2.language == 'all') || (a.language_test == filter2.language)) 
                                        // Team leader (select)
                                        && ((filter2.teamLeader == 'all') || (a.user_name_team_leader == filter2.teamLeader)) 
                                    )
                                    .map((a, i) =>
                                        <TableRow key={i} columns={[
                                            { xs: 1, text: a.date ? format(a.date, 'dd/MM/yyyy') : '' },
                                            { xs: 1, text: jobName(a.job_title) },
                                            { xs: 1, text: languageName(a.language_test) },
                                            { xs: 1, text: a.first_name },
                                            { xs: 1, text: a.last_name },
                                            { xs: 1, text: countriesList.find(c => c.value === a.country)?.innerHTML },
                                            { xs: 2, text: a.email },
                                            { xs: 1, text: a.skype },
                                            { xs: 1, text: a.user_name_team_leader },
                                            { xs: 1, text: a.date_schedule ? format(a.date_schedule, 'dd/MM/yyyy') : ''},
                                            { xs: 1, customContent: true, content:
                                                <Stack width='100%'>
                                                    <Button onClick={() =>{ setLoginGiving(a) }} style={{ textTransform: 'capitalize', color: '#e37584', width: '100%' }}><Typography variant="boldcenter">{text.gvlgn}</Typography></Button>
                                                </Stack>
                                            },
                                        ]} />
                                    )
                                }
                            </Stack>
                            { (nlFb.length > 0) && <Alert severity={(nlFb == text.success) ? 'success' : 'error'}>{nlFb}</Alert> }
                        </Stack>

                    </Stack>
                }
            />
            <BigContainerAccordion 
                dataToDisplay={loginGiving}
                summaryBg="#7ed957"
                summary={text.anet4}
                details={
                    <Stack spacing={2}>
                        <FormRow label={text.jobtitle} comp="readOnly" value={jobName(loginGiving.job_title)}/>
                        <FormRow label={text.teamleader} comp="readOnly" value={loginGiving.user_name_team_leader}/>
                        <FormRow label={text.language} comp="readOnly" value={languageName(loginGiving.language_test)}/>
                        <FormRow label={text.firstname} comp="readOnly" value={loginGiving.first_name}/>
                        <FormRow label={text.lastname} comp="readOnly" value={loginGiving.last_name}/>
                        <FormRow label={text.country} comp="readOnly" value={countriesList.find(c => c.value === loginGiving.country)?.innerHTML}/>
                        <FormRow label={text.skypeid} comp="readOnly" value={loginGiving.skype}/>
                        <FormRow label={text.login} comp="readOnly" value={loginAuto} error={errors2.login}/>
                        <FormRow label={text.password} idC={idC} control={control2} name="password" error={errors2.password}/>
                        

                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit2(newLogin)} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    nlLoading ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>
                                        {text.createnewlogin}
                                    </Typography>
                                }
                            </Button>
                        </Stack>
                        { (nlFb.length>0) && <Alert severity={(nlFb == text.success) ? 'success' : 'error'}>{nlFb}</Alert> }
                    </Stack>
                }
            />
            <BigContainerAccordion
                summaryBg="#1e57be"
                summary={text.agntslst}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter3.date}
                            onDateChange={{
                                year: y => setFilter3({...filter3, year: y}),
                                month: m => setFilter3({...filter3, month: m}),
                                date: d => setFilter3({...filter3, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter3.startingDate, onChange: d => setFilter3({...filter3, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter3.closingDate, onChange: d => setFilter3({...filter3, closingDate: d})},
                                { label: text.jobtitle , comp: 'TextInput', onChange: e => setFilter3({...filter3, jobTitle: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter3({...filter3, language: l.target.value}) },
                            ]}
                        />
                        <Stack spacing={2}>
                            <Stack>
                                <TableRow header columns={[
                                    { xs: 1, text: text.date },
                                    { xs: 1, text: text.jobtitle },
                                    { xs: 1, text: text.language },
                                    { xs: 1, text: text.agntnm },
                                    { xs: 1, text: text.agentlogin },
                                    { xs: 1, text: text.agntpass },
                                    { xs: 0.9, text: text.rsm },
                                    { xs: 0.9, text: text.pi },
                                    { xs: 0.9, text: text.proofofaddress },
                                    { xs: 0.9, text: text.slfi },
                                    { xs: 0.9, text: text.freelancecontract },
                                    { xs: 1, text: text.paymentmethod },
                                    { xs: 0.5, text: text.actions }
                                ]} />
                                {
                                    agents
                                    .filter(a => 
                                        // Starting date
                                        ( (!filter3.startingDate) || ( filter3.startingDate <= new Date(a.access_training?.date_attrib_login)) )
                                        // Closing date
                                        && ( (!filter3.closingDate) || (new Date(a.access_training?.date_attrib_login) <= filter3.closingDate) )
                                        // Text matchox
                                        && ((!Boolean(jobName(a.job_title))) || (jobName(a.job_title)?.toLowerCase().includes(filter3.jobTitle)))
                                        // Language
                                        && ( (filter3.language == 'all') || (a.lang_test == filter3.language)) 
                                    )
                                    .map((a, i) =>
                                        <TableRow key={i} columns={[
                                            { xs: 1, text: a.access_training?.date_attrib_login ? format(a.access_training?.date_attrib_login, 'dd/MM/yyyy') : '' },
                                            { xs: 1, text: jobName(a.job_title) },
                                            { xs: 1, text: languageName(a.lang_test) },
                                            { xs: 1, customContent: true, content: 
                                                <Stack width='100%'>
                                                    <Typography textAlign='center'>{a.first_name}</Typography>
                                                    <Typography textAlign='center'>{a.last_name}</Typography>
                                                </Stack>
                                            },
                                            { xs: 1, text: a.access_training?.login },
                                            { xs: 1, text: a.access_training?.password, customContent: (editing.payment == a.payements?.id && editing.job == a.job_title),
                                                content: <Input max control={editControl} name='password' placeholder={text.password}/>
                                            },
                                            { xs: 0.9, customContent: true, content: <PdfDisplayer file={`${baseURL}/pdf/resume/${a.resume}`} innerTableCell openable/> },
                                            { xs: 0.9, customContent: true, content:
                                                ((getExtension(a.proof_id) == 'pdf') ?
                                                <PdfDisplayer file={`${baseURL}/images/identity/${a.proof_id}`} innerTableCell openable/>
                                                : 
                                                (['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp', 'tiff', 'raw', 'svg', 'heif', 'ico', 'jfif'].includes(getExtension(a.proof_id))) ?
                                                    <OpenableImage sx={{height: '75px', width: '75px'}} src={`${baseURL}/images/identity/${a.proof_id}`}/>
                                                :<Typography bgcolor="grey" borderRadius="10px" padding="10px 50px" alignSelf="center" style={{width: "fit-content"}}>{a.proof_id}</Typography>
                                                )
                                            },
                                            { xs: 0.9, customContent: true, content: 
                                                ((getExtension(a.proof_ad) == 'pdf') ?
                                                <PdfDisplayer file={`${baseURL}/images/address/${a.proof_ad}`} innerTableCell openable/>
                                                : 
                                                (['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp', 'tiff', 'raw', 'svg', 'heif', 'ico', 'jfif'].includes(getExtension(a.proof_ad))) ?
                                                    <OpenableImage sx={{height: '75px', width: '75px'}} src={`${baseURL}/images/address/${a.proof_ad}`} />
                                                :<Typography bgcolor="grey" borderRadius="10px" padding="10px 50px" alignSelf="center" style={{width: "fit-content"}}>{a.proof_ad}</Typography>
                                                )
                                            },
                                            { xs: 0.9, customContent: true, content:
                                                <OpenableImage sx={{height: '75px', width: '75px'}} src={`${baseURL}/images/selfie/${a.selfie}`} />
                                            },
                                            { xs: 0.9, customContent: true, content:
                                                ((getExtension(a.contract) == 'pdf') ?
                                                <PdfDisplayer file={`${baseURL}/files/contract/${a.contract}`} innerTableCell openable/>
                                                : (['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp', 'tiff', 'raw', 'svg', 'heif', 'ico', 'jfif'].includes(getExtension(a.contract))) ?
                                                    <OpenableImage sx={{height: '75px', width: '75px'}} src={`${baseURL}/files/contract/${a.contract}`} />
                                                :<Typography bgcolor="grey" borderRadius="10px" padding="10px 50px" alignSelf="center" style={{width: "fit-content"}}>{a.contract}</Typography>
                                                )
                                            },
                                            { xs: 1, customContent: true, content:
                                                ((editing.payment == a.payements?.id && editing.job == a.job_title)) ?
                                                <Stack>
                                                    <CustomSelect required={false} onExternalChange={setSelectedPayment} control={editControl} name="paymentmethod" options={payment}/>
                                                    <Stack>
                                                        <Input type="text" control={editControl} required={false} name="mail" placeholder={getPlaceholder()} />
                                                        {
                                                            selectedPayment === 'bank' &&
                                                            <>
                                                                <Input type="text" control={editControl} required={false} name="holdrename" placeholder={text.accountholder} />
                                                                <Input type="text" control={editControl} required={false} name="holderaddress" placeholder={text.holderaddress} />
                                                                <Input type="text" control={editControl} required={false} name="bankholder" placeholder={text.banksholder} />
                                                                <Input type="text" control={editControl} required={false} name="bankaddress" placeholder={text.bankaddress} />
                                                                <Input type="text" control={editControl} required={false} name="swiftcode" placeholder={text.swiftcode} />
                                                            </>
                                                        }
                                                    </Stack>
                                                </Stack>
                                                :
                                                <Stack width="100%">
                                                    <Typography textAlign='center'>{getPaymentMethod(a.payements)[0]}</Typography>
                                                    <Typography textAlign='center'>{getPaymentMethod(a.payements)[1]}</Typography>                                               
                                                </Stack>
                                            },
                                            {
                                                xs: 0.5, customContent: true,
                                                content:
                                                (editLoading == i)  ?
                                                <CircularProgress color="inherit" size="24px"/>
                                                :
                                                ((editing.payment == a.payements?.id && editing.job == a.job_title) ?
                                                <Stack width='100%'>
                                                    <Button onClick={handleEditSubmit(data =>  editPayOrPass(data, i )) } style={{ textTransform: 'capitalize', textAlign: 'center', color: '#0b0', width: '100%', height: '100%' }}>
                                                        <Typography variant="boldcenter">{text.save}</Typography>
                                                    </Button> 
                                                    <Button onClick={() =>{ setEditing({job: '', payment: -1}) }} style={{ textTransform: 'capitalize', textAlign: 'center', color: '#3c6dc6', width: '100%', height: '100%' }}>
                                                        <Typography variant="boldcenter">{text.back}</Typography>
                                                    </Button> 
                                                </Stack>
                                                :
                                                <Button onClick={() =>{ showEditForm(a) }} style={{ textTransform: 'capitalize', textAlign: 'center', color: '#3c6dc6', width: '100%', height: '100%' }}>
                                                    <Typography variant="boldcenter">{text.edit}</Typography>
                                                </Button> 
                                                )
                                            }
                                        ]} />
                                    )
                                }
                            </Stack>
                            {
                                editFb && <Alert severity={editFb}>
                                    { (editFb == 'success') ? text.success : text.smthngwntwrng }
                                </Alert>
                            }
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default NewEmpTraining