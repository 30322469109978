import React from "react";
import { Stack, Typography, Box} from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import TitleWithBg from '../../tools/TitleWithBg'
import FileInput from "../../tools/FileInput";
import PdfDisplayer from "../../tools/PdfDisplayer";
import { baseURL } from "../../../helper/conf";

const AppUpload = ({control, errors, setResume, defaultValues}) =>{
    const { text } = useLanguage()
    return(
        <Stack variant="rightcontent" >
            <Stack display="flex" justifyContent="center" alignItems="center" ><TitleWithBg iconSrc='/images/Member/dashboard/4.png' children={text.yourapplication} color="#1db744" title/></Stack>
            <Stack style={{alignItems: "center", justifyContent: "center"}}><Typography variant="secondaryitalic">{text.app11}</Typography></Stack>
            <FileInput label={text.app12} visiblePartVariant='Browser' required={false} onExportFile={setResume}
                control={control} name="resume" error={errors.resume} accept="application/pdf"/>
            <Typography variant="white" padding="0 5%">({text.pdf})</Typography>

            <Box className="wrap-content" gap="25px" padding="0px 20px" >
            {
                defaultValues.resume ?
                <Stack display="flex" alignItems='center' justifyContent="center" width='100%'>
                    <PdfDisplayer file={`${baseURL}/pdf/resume/${defaultValues.resume}`}/>
                </Stack>
                : <>
                    <Stack width="70%" alignSelf="center"><Typography variant="white">{text.nyrsm}</Typography></Stack>
                    <Stack width='20%'/>
                </>
            }
            </Box>
        </Stack>
    )
}

export default AppUpload