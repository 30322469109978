import { Stack, Typography, Button, Alert, CircularProgress } from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import FormRow from "../tools/FormRow";
import { useForm, Controller } from 'react-hook-form'
import generateFormData from "../../helper/formData";
import { AuthStateContext } from "../state/AuthStateContext";
import RoutesList from "../../helper/routes";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../axios";
import { addDays, format } from "date-fns";
import Input from "../tools/input";
import CustomSelect from "../tools/select";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const JobPosts = () =>{
    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        jobTitle: '',
        language: 'all',
        openingPosition: 'both',
    })
    const { control, handleSubmit, formState: {errors}, setValue: setValueCreate } = useForm()
    const { control: editControl, handleSubmit: handleEdit, setValue, formState: {errors: editErrors} } = useForm()
    const { adminId, userId, managerId } = useContext(AuthStateContext)
    const [ feedBack, setFeedBack ] = useState('')
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [loadingActions, setLoadingActions] = useState(0)
    const [editing, setEditing] = useState(0)
    const getToken = () =>{
        switch (location.pathname.split('/')[1]){
            case RoutesList.teamleaderhome.split('/')[1]: return userId
            case RoutesList.adminhome.split('/')[1]: return adminId
            case RoutesList.managerhome.split('/')[1]: return managerId
            default: return ''
        }
    }
    getToken()

    const [ill, setIll] = useState(null)
    const newJobPosts = async data =>{
        const { jobtitle, location: joblocation, language, description, jobrequirement, salaryscale,
            incentives, openingdate, closingdate } = data
        const od = Boolean(openingdate) ? format(openingdate, 'yyyy-MM-dd') : null
        const cd = Boolean(closingdate) ? format(closingdate, 'yyyy-MM-dd') : null
        const formData = generateFormData({
            title: jobtitle.toLowerCase().split(' ').join('_'),
            image_illustration: ill,
            location: joblocation,
            language, description, incentives,
            job_requirement: jobrequirement,
            salary_scale: salaryscale,
            opening_date: od,
            closing_date: cd
        })
        setLoading(true)
        setFeedBack('')
        try {
            const { status, data } = await axiosInstance.post(`/job/create/${language}`, formData, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data',
                },
                transformRequest: null
            })
            if (status == 201){
                setFeedBack(text.adddsccsflly)
                window.location.reload()
            }
        } catch (error) {
            switch (error.response.status){
                case 400: if (error.response.data.message == 'La date de clôture doit être postérieure à la date d\'ouverture.')
                    setFeedBack(text.opnngclsngdterr)
                    else setFeedBack(text.smthngwntwrng)
                    break
                default: setFeedBack(text.smthngwntwrng)
            }
        }
        setLoading(false)
    }
    const { text, currentLanguage } = useLanguage()
    const { testLanguages, filterLanguages, jobName } = useData()
    const [ jobPostsList, setJobPostsList ] = useState([])
    const deleteJob = async id =>{
        setLoadingActions(id)
        try {
            const {status}= await axiosInstance.delete(`/job/delete/${id}`, {headers:{'Authorization': `Bearer ${getToken()}`}})
            const newList = jobPostsList.filter(j => j.id !== id)
            setJobPostsList(newList)
            if (status == 200){
                setFeedBack(text.dltd)
            }
        } catch (error) {}
        setLoadingActions(0)
    }

    useEffect(() => {
        if (feedBack) {

          const timeout = setTimeout(() => {
            setFeedBack(null);
          }, 2000);
    
          return () => clearTimeout(timeout);
        }
      }, [feedBack]);

    
    const showEditForm = job =>{
        setEditing(0)
        setEditing(job.id)
        setValue('title', job.title)
        setValue('description', job.description)
        setValue('language', job.language)
        setValue('closingdate', job.closing_date ? new Date(job.closing_date) : ( job.opening_date ? new Date(job.opening_date) : new Date()))
    }
    const editJob = async data =>{
        const { /*title,*/ description, language, closingdate } = data
        setLoadingActions(editing)
        try {
            await axiosInstance.put(`/job/edit/${editing}`, { description, language, closing_date: format(closingdate, 'yyyy-MM-dd') }, {headers: { 'Authorization': `Bearer ${getToken()}` }})
            const newList = [...jobPostsList]
            const i = jobPostsList.findIndex(j => j.id == editing)
            newList[i].description = description
            newList[i].language = language
            newList[i].closing_date = new Date(closingdate)
            setJobPostsList(newList)
        } catch (error) {}
        setEditing(0)
        setLoadingActions(0)
    }
    useEffect(()=>{
        const func = async () =>{
            try {
                const { data: {jobs} } = await axiosInstance.get(`/job/lists/${currentLanguage}`, {
                    headers: { 'Authorization': `Bearer ${getToken()}`},
                }) 
                if (jobs) setJobPostsList(jobs)
            } catch (error) {}
        }
        func()
    }, [])
    return(
        <Stack variant="primarybg" spacing={3}>
            <Stack style={{display: 'flex', gap:'15px', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                <img className="card-img-resize-2" src='/images/Admin/job_posts.png' alt=""/>
                <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#1cb743' fontWeight='bold' 
                    style={{WebkitTextStroke: "1px #005d0f"}}
                >{text.jobposts.toUpperCase()  }</Typography>
            </Stack>
            <BigContainerAccordion 
                summaryBg="#8b86b0"
                summary={text.ajp1}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.jobtitle , comp: 'TextInput', onChange: e => setFilter({...filter, jobTitle: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter({...filter, language: l.target.value}) },
                                { label: text.openingposition, comp: 'Select', choices:[{value: 'both', innerHTML: text.all},{value: 'open', innerHTML: text.jobopen},{value: 'closed', innerHTML: text.jobclose}] ,onChange: o => setFilter({...filter, openingPosition: o.target.value}) }
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 1, text: text.date },
                                { xs: 2, text: text.jobtitle },
                                { xs: 3, text: text.jobdescription },
                                { xs: 2, text: text.language },
                                { xs: 1, text: text.openclose },
                                { xs: 2, text: text.closingdate },
                                { xs: 1, text: text.actions },
                            ]} />
                            {
                                jobPostsList
                                .filter(j => 
                                    // Starting date
                                    ( (!filter.startingDate) || ( filter.startingDate <= new Date(j.created_at)) )
                                    // Closing date
                                    && ( (!filter.closingDate) || (new Date(j.created_at) <= filter.closingDate) )
                                    // Text match
                                    && ((!Boolean(jobName(j.title))) || (jobName(j.title)?.toLowerCase().includes(filter.jobTitle)))
                                    // Language
                                    && ( (filter.language == 'all') || (j.language == filter.language))
                                    // Special : Opening position
                                    && ( (filter.openingPosition == 'both') || 
                                        (  (filter.openingPosition == 'open') 
                                            &&  (new Date(j.opening_date) <= new Date()) // par rapport opening date
                                            &&  ( (!Boolean(j.closing_date)) || (new Date(j.closing_date) >= new Date())) // par rapport closing date
                                        ) ||
                                        (  (filter.openingPosition == 'closed') 
                                            &&  ((new Date(j.opening_date) >= new Date()) // par rapport opening date
                                            ||  ( (Boolean(j.closing_date)) && (new Date(j.closing_date) <= new Date()))) // par rapport closing date
                                        )
                                    )    
                                )
                                .map(j => <Stack key={j.id}>
                                    <TableRow columns={[
                                           { xs: 1, text: j.created_at ? format(new Date(j.created_at), 'dd/MM/yyyy') : '' },
                                           { xs: 2, text: jobName(j.title)/*, customContent: (editing == j.id), content: <Input max control={editControl} name="title" type='text' error={editErrors.title}/> */},
                                           { xs: 3, text: j.description, customContent: (editing == j.id), content: <Input max control={editControl} name="description" type='text' error={editErrors.description}/>},
                                           { xs: 2, text: j.language ? testLanguages.find(l => l.value == j.language).innerHTML : '',
                                             customContent: (editing == j.id), content: <CustomSelect max control={editControl} name="language" error={editErrors.language} options={testLanguages }/> },
                                           {   xs: 1,
                                               color: (((new Date(j.closing_date) >= new Date()) && (new Date(j.opening_date) <= new Date()) || (!Boolean(j.opening_date) || (!Boolean(j.closing_date)))) ? '#0b0' : '#f00'),
                                               text: (((new Date(j.closing_date) >= new Date()) && (new Date(j.opening_date) <= new Date()) || (!Boolean(j.opening_date) || (!Boolean(j.closing_date)))) ? text.jobopen : text.jobclose)
                                               ,customContent: (editing == j.id)
                                            },
                                           { xs: 2, text: Boolean(j.closing_date) ? format(j.closing_date, 'dd/MM/yyyy') : text.indfndt, customContent: (editing == j.id),
                                            content: <Controller 
                                                name="closingdate" 
                                                control={editControl}
                                                rules={{required: text.required}}
                                                render={({ field }) => (
                                                    <DatePicker dateFormat='dd-MM-yyyy' showYearDropdown scrollableYearDropdown yearDropdownItemNumber={100} maxDate={addDays(new Date(), 730)} minDate={new Date(j.opening_date)} className="in-datepicker" selected={field.value} onChange={field.onChange}/>    
                                                )}
                                            />},
                                           { xs: 1, customContent: true, content:
                                                (loadingActions == j.id) ? <CircularProgress/>
                                                :
                                                (
                                                    (editing == j.id)
                                                    ?<Stack width='100%'>
                                                        <Button onClick={handleEdit(editJob)} style={{ textTransform: 'capitalize', color: '#0b0', width: '100%' }}><Typography variant="boldcenter">{text.save}</Typography></Button>
                                                        <Button onClick={()=> setEditing(0)} style={{ textTransform: 'capitalize', color: '#3c6dc6', width: '100%' }}><Typography variant="boldcenter">{text.back}</Typography></Button>
                                                    </Stack>
                                                    :<Stack width='100%'>
                                                    <Button onClick={()=> showEditForm(j)} style={{ textTransform: 'capitalize', color: '#3c6dc6', width: '100%' }}><Typography variant="boldcenter">{text.edit}</Typography></Button>
                                                    <Button onClick={()=> deleteJob(j.id)} style={{ textTransform: 'capitalize', color: '#f00', width: '100%' }}><Typography variant="boldcenter">{text.delete}</Typography></Button>
                                                </Stack>
                                                )
                                           },
                                       ]} />
                                </Stack>
                                )
                            }
                        </Stack>
                        {feedBack && <Alert severity="success">
                            <Typography>{feedBack}</Typography>                              
                        </Alert>}

                    </Stack>
                }
            />
            <BigContainerAccordion 
                summaryBg="#997d5b"
                summary={text.ajp2}
                details={
                    <Stack spacing={2}>
                        <FormRow label={text.jobtitle} control={control} name='jobtitle' error={errors.jobtitle}/>
                        <FormRow label={text.illustrationphoto} comp="Image" envImg={setIll} control={control} name='illustration' error={errors.illustration}/>
                        <FormRow label={text.location} control={control} name='location' error={errors.location}/>
                        <FormRow label={text.language} comp="Select" options={testLanguages} control={control} name='language' error={errors.language}/>
                        <FormRow label={text.description} control={control} name='description' error={errors.description}/>
                        <FormRow label={text.jobrequirement} control={control} name='jobrequirement' error={errors.jobrequirement}/>
                        <FormRow label={text.salaryscale} control={control} name='salaryscale' error={errors.salaryscale}/>
                        <FormRow label={text.incentives} control={control} name='incentives' error={errors.incentives}/>
                        <FormRow label={text.openingdate} comp="DatePicker" name="openingdate" maxDate={addDays(new Date(), 365)} minDate={new Date()} control={control} error={errors.openingdate}/>
                        <FormRow label={text.closingdate} comp="IndDate" setValue={setValueCreate} name="closingdate" maxDate={addDays(new Date(), 730)} minDate={new Date()} control={control} error={errors.closingdate}/>
                        {feedBack && <Alert severity={ (feedBack == text.adddsccsflly) ? "success" : "error"}>
                            <Typography>{feedBack}</Typography>                              
                        </Alert>}
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit(newJobPosts)} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    loading ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>{text.createnewjobposts}</Typography>      
                                }
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default JobPosts