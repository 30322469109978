import React from "react";
import { Typography, Stack, List, ListItem } from "@mui/material";
const IconTextCard = ({iconSrc, label, children, variant="card", bgColor="", whitetext=false}) =>{
    const getComp = () =>{
        switch (variant){
            case "strcontent": return <Stack style={{backgroundColor: bgColor, width: '30%', boxShadow: '0px 0px 7px 0px '+ whitetext ? '#333' : '#aaa', borderRadius: '10px', padding: '10px 0'}} spacing={2}>
                <img style={{alignSelf: "center"}} height="70px" width="70px" src={iconSrc} alt=""/>
                <Stack spacing={1}>
                    <Typography textAlign='center' fontWeight='bold' variant="h3title" color={whitetext ? '#fff' : '#000'}>{label}</Typography>
                    <Typography textAlign='center' color={whitetext ? '#fff' : '#000'}>{children}</Typography>
                </Stack>
            </Stack>
            case "tabcontent": return <Stack style={{ backgroundColor: bgColor, width: '30%', boxShadow: '0px 0px 8px 0px #aaa', borderRadius: '10px', alignItems: 'center', padding: '10px 0'}} spacing={2}>
                <img height="100px" src={iconSrc} alt=""/>
                <Typography textAlign='center' fontWeight='bold' variant="h3title">{label}</Typography>
                <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                    { children.map(c => <ListItem key={c} sx={{display: 'list-item'}}>{c}</ListItem>) }
                </List>
            </Stack>
            default: return <Stack variant={variant} spacing={2} className={variant + "-container"} style={(variant == "statcard") ? {} : {width: "30%"}} alignItems='center'>
                <img height={(variant == "statcard") ? "100px" :'70px'} src={iconSrc} alt="" style={{boxShadow: (variant == "statcard") ? '0px 0px 20px -5px black' : ''}}/>
                <Stack display='flex' justifyContent='space-between' alignItems='center' width='95%' spacing={2}>
                    <Typography textAlign='center' fontWeight='bold' variant={(variant == "statcard" ) ? "primarysubtitleline" : "h3title"}>{label}</Typography>
                    {
                        (typeof(children) == "string") ? 
                        <Typography textAlign='center' fontWeight={(variant == "statcard") ? 'bold' :''} fontSize={(variant == "statcard") ? '20px' :''}>{children}</Typography>
                        : <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                            { children.map(c => <ListItem key={c} sx={{display: 'list-item'}}>{c}</ListItem>) }
                        </List>
                    }
                </Stack>
            </Stack>
        }
    }
    return(getComp())
}
export default IconTextCard