import React from "react";
import { Stack, Typography, Divider, Box } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import RoutesList from "../../helper/routes";
import useLanguage from "../hooks/useLanguage";
import DateDisplay from "../tools/DateDisplay";
import { format } from "date-fns";

const ArticleItem = ({imgSrc, title, date, author, authorId, comments, category, content, categoryId, id}) => {
    const { lang } = useParams()
    const { text } = useLanguage()
    return(
        <Stack spacing={7} padding="30px">
            <Stack alignItems="center">
                <Stack style={{ maxHeight: "300px", maxWidth: "90%", overflow: "hidden", display:"flex", flexDirection:"row", alignItems:"center" }}>
                    <img src={imgSrc} alt="" width="100%" />
                </Stack>
            </Stack>
            <Stack spacing={3}>
                <Typography variant="h2" textAlign="left">{title}</Typography>
                <Box className="wrap-content-mobile" justifyContent="flex-start" style={{opacity: "0.5"}}>
                    <DateDisplay>{ date ? format(date , 'dd/MM/yyyy'): ''}</DateDisplay>
                    <Divider style={{ width:"10px", height:"7px", border:"none", borderBottom: "7px dotted black"}}/>                                                   
                    {/*<Typography textAlign="center">{text.by} <Link className="link2" to={{pathname: RoutesList.author + authorId + "/" + lang}} state={{name: author}}>{author}</Link>,{comments?.length || text.noc} {(comments?.length>1) ? text.comments : text.comment }</Typography>*/}
                    <Typography textAlign="center">{text.by} {author} , {comments?.length || text.noc} {(comments?.length>1) ? text.comments : text.comment }</Typography>
                    <Divider style={{ width:"10px", height:"7px", border:"none", borderBottom: "7px dotted black"}}/>                                                   
                    <Typography><Link className="link2" to={RoutesList.category + categoryId + "/" + lang} state={{name: category}}>{category}</Link></Typography>
                </Box>
                <Typography style={{opacity: "0.8"}}>{content}</Typography>
            </Stack>
        </Stack>
    )
}

export default ArticleItem