import { Stack, Typography, Box } from "@mui/material";
import React from "react";

const TestimonialCard = ({texts, imgFile}) =>{
    const baseUrl = "/images/testimonial/"
    return(
        <Stack width="100%" className="testimonial" variant="primarybg">
            <Box maxWidth="100%" height="100%" className="w-c-large" overflow="hidden" display="flex" alignItems="center">
                
                <Stack height="60%" display="flex" alignItems="center" justifyContent="space-between"
                padding="0 40px">
                    <Typography variant="white" zIndex="2">{texts[2]}</Typography>
                    <Typography variant="white" zIndex="2">____</Typography>
                    <Stack style={{width: "100%"}} zIndex="2">
                        <Typography color="#00ff80;" fontWeight="bold">{texts[0]}</Typography>
                        <Typography color="#F00">{texts[1]}</Typography>
                    </Stack>
                </Stack>
                <Stack alignItems="center"><img style={{zIndex:"2"}} src={baseUrl + imgFile} alt="" className="testimonial-img"/></Stack>
            </Box>
        </Stack>
    )
}

export default TestimonialCard