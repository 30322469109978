import React from "react";
import { Typography, Stack } from "@mui/material";
import { roles } from "../../helper/roles";
import useLanguage from "../hooks/useLanguage";

const UserTitle = ({variant, inverseDisplay=false}) =>{
    const { text } = useLanguage()
    const getContents = () => {
        switch (variant){
            case roles.admin: return { title: text.adboard, border: '3px solid #dd53ff', textColor: '#fff', textShadow: '3px 0px 2px #00e2e9', boxShadow: '0px 0px 20px 1px #de5bff, inset 0px 0px 20px 1px #de5bff', stroke: 'none' }
            case roles.blogger: return { title: text.bdboard, border: '3px solid #fff', textColor: '#4b4998', textShadow: '1px 1px 2px #02014c, 1px -1px 2px #02014c, -1px 1px 2px #02014c, -1px -1px 2px #02014c', boxShadow: '0px 2px 2px 2px #02014c, 0px 0px 10px 10px #2070d2, inset 0px 0px 100px 100px #2070d2', stroke: 'none' }
            case roles.teamleader: return { title: text.tldboard, border: '1px solid #fff', textColor: 'transparent', textShadow: '0px 0px 0px #7edb96', boxShadow: '0px 0px 1px 1px #2fff5c, inset 0px 0px 1px 1px #2fff5c, 0px 0px 20px 1px #2fff5c, inset 0px 0px 20px 1px #2fff5c', stroke: '2px green' }
            case roles.manager: return { title: text.mdboard, border: '1px solid #fff', textColor: 'transparent', textShadow: '0px 0px 0px #A0E3F3', boxShadow: '0px 0px 1px 1px #63D1EA, inset 0px 0px 1px 1px #63D1EA, 0px 0px 20px 1px #63D1EA, inset 0px 0px 20px 1px #63D1EA', stroke: '2px #63D1EA' }
            default: return { imgFolder: '', title: '' }
        }
    }
    const contents = getContents()
    return(
        <div style={inverseDisplay ? {alignSelf: 'center', transform: 'scale(.9)'} : {}} className={inverseDisplay ? 'cond-rendering-inv' : 'cond-rendering'}>
            <Stack style={{border: contents.border, padding: "10px 20px",  
                borderRadius: '50px', boxShadow: contents.boxShadow}}>
                <Typography variant="whitetitle" fontWeight='bold' style={{ textShadow: contents.textShadow, WebkitTextStroke: contents.stroke}} color={contents.textColor}>{(contents.title) ? contents.title.toUpperCase() : ''}</Typography>
            </Stack>
        </div>
    )
}

export default UserTitle