import { Stack, Typography, Divider, Checkbox, Button, CircularProgress, Alert } from "@mui/material";
import React, { useState } from "react";
import useLanguage from "../hooks/useLanguage";
import ShareIcon from '@mui/icons-material/Share';
import Input from "../tools/input";
import { useForm } from "react-hook-form";
import axiosInstance from "../../axios";
import { format } from "date-fns";

const commentorToken = 'COMMENTATOR-CN'

const ArticleComment = ({postid, comments}) =>{
    const [commentor, setCommentor] = useState(localStorage.getItem(commentorToken))

    const { text } = useLanguage()
    const { control, handleSubmit, formState: {errors} } = useForm()
    const [ saveName, setSaveName ] = useState(false)
    const [loading, setLoading] = useState(false)
    const [ error, setError ] = useState('')

    const saveCommentor = mailusername => {
        setCommentor(mailusername)
        localStorage.setItem(commentorToken, mailusername)
    }
    const deleteCommentor = () => {
        setCommentor(null)
        localStorage.removeItem(commentorToken)
    }

    const submitCom = async datas => {
        const { mailusername, password, comment, websitecom } = datas
        setLoading(true)
        setError('')
        try {
            const res = await axiosInstance.post(`/post/${postid}/comment`, {email: mailusername, user_name: mailusername, password, comment, webiste: websitecom})
            const { status } = res
            console.log(res)
            if (status == 200){
                window.location.reload()
                if (saveName) saveCommentor(mailusername)
            }
        } catch (error) {
            switch (error.response.status){
                case 401: 
                    if (error.response.data.error =="id") setError(text.iderr)
                    else setError(text.mdperr)
                    break
                default: setError(text.smthngwntwrng)
            }
        }
        setLoading(false)
    }

    return(
        <Stack padding="30px" spacing={3} >
            <Stack spacing={5}>
                <Divider></Divider>
                <Stack display="flex" flexDirection="row" justifyContent="space-between" >
                    <Typography style={{opacity: "0.5", fontStyle: "italic"}} >{text.tags}</Typography>
                    <Stack><ShareIcon /></Stack>
                </Stack>
                <Divider></Divider>
            </Stack>
            <Stack spacing={4}>
                {
                    comments
                    ?.sort((c1, c2) => {return new Date(c2.created_at) - new Date(c1.created_at)})
                    .map((c, i) => <Stack key={`coms${i}`}>
                        <Typography variant="h6">{c.content}</Typography>
                        <Typography color='#4b4998'>{c.created_at ? format(c.created_at, 'dd/MM/yyyy HH:mm') : ''}</Typography>
                    </Stack>)
                }
            </Stack>
            <Stack paddingTop="100px" spacing={3}>
                <Typography variant="h2">{text.comment1}</Typography>
                <Typography style={{opacity: "0.6"}}>{text.comment2}</Typography>
                {/*<Stack variant="inputcomment"><Input white type='text' name='namecom' control={control} error={errors.namecom} placeholder={text.comment3}/></Stack>*/}
                {
                    commentor ?
                    <>
                        <Typography>{commentor}</Typography>
                        <Button variant="submitCom" onClick={deleteCommentor}>{text.delete}</Button>
                    </>
                    :
                    <>
                        <Stack variant="inputcomment"><Input white type='mail' name='mailusername' control={control} error={errors.emailcom} placeholder={text.comment4}/></Stack>
                        <Stack variant="inputcomment"><Input white type='password' name='password' control={control} error={errors.password} placeholder={text.password}/></Stack>
                        <Stack variant="inputcomment"><Input white type='text' name='websitecom' control={control} error={errors.websitecom} placeholder={text.comment5} required={false}/></Stack>
                        {/*<Stack display='flex' flexDirection='row' justifyContent='flex-start' gap='2px' alignItems="center">
                            <Checkbox onChange={()=> setSaveName(!saveName)}  size="small" />
                            <Typography style={{opacity: "0.6"}}>{text.comment6}</Typography>
                </Stack>*/}
                    </>
                }
                <Stack><Input white high type='text' name='comment' control={control} error={errors.comment} placeholder={text.comment7}/></Stack>
                { error.length ? <Alert severity="error">{error}</Alert> : <></> }
                <Button variant="submitCom" onClick={handleSubmit(submitCom)}>
                    { loading ? <CircularProgress color="inherit" size="24px"/> : <Typography>{text.submitapp}</Typography>}
                </Button>

            </Stack>
        </Stack>
    )
}

export default ArticleComment