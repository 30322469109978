import React, { useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AuthStateContext } from "../../state/AuthStateContext";
import ApplyFormTools from "../../tools/ApplyFormTools";
import ApplyFormSignup from "../../tools/ApplyFormSignup";

const ApplyForm = () => {

    const { candidateId} = useContext(AuthStateContext)

    if (candidateId!==null) { return ( <ApplyFormTools supervisor />) }
    else { return( <ApplyFormSignup supervisor/>) }

} 

export default ApplyForm;