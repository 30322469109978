import React from "react"
import { Typography } from "@mui/material";
import { useLocation, useParams, Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import RoutesList from "../../helper/routes";
import useLanguage from "../hooks/useLanguage";

const TextDisplay = ({children, menuItem}) =>{
    return(
        <Typography variant={menuItem ? "menu-item-nav" : "nav"}>{children}</Typography>
    )
}

const NavItem = ({to, children, menuItem=false}) =>{
    const { currentLanguage } = useLanguage()
    const location = useLocation()
    
    return (location.pathname == RoutesList.landing + currentLanguage)
    ? <ScrollLink style={ menuItem ? {width: "100%", height: "100%"} : { height: "100%" }} to={to} spy={true} smooth={true} offset={-175} duration={500}>
        <TextDisplay menuItem={menuItem}>{children}</TextDisplay>
    </ScrollLink>
    : <Link to={{pathname: RoutesList.landing + currentLanguage }} state={{link: to}} style={{textDecoration: 'none', height:"100%"}}>
        <TextDisplay menuItem={menuItem}>{children}</TextDisplay>
    </Link>
}

export default NavItem