import { Stack, Typography, Button, CircularProgress } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import useData from '../hooks/useData'
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form'
import SlidingTypography from "../tools/SlidingTypography";
import { AuthStateContext } from "../state/AuthStateContext";
import axiosInstance from "../../axios";
import { format, isValid } from "date-fns";
import generateFormData from "../../helper/formData";
import Alert from "@mui/material/Alert";

import { roles } from "../../helper/roles";
import countriesList from "../../helper/countries";
import { addDays } from "date-fns";

const AdminStaff = () =>{
    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        adminStaff: '',
        language: 'all'
    })

    const [filter2, setFilter2] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        teamLeader: '',
        language: 'all'
    })

    const [filter3, setFilter3] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        manager: '',
        language: 'all'
    })

    const { control, handleSubmit, formState: {errors} } = useForm()
    const { control: control1, handleSubmit: handleSubmit1, formState: {errors: errors1} } = useForm()
    const { text } = useLanguage()
    const { testLanguages, adminList, languageName, filterLanguages } = useData()
    
    
    const [usersRole, setUsersRole] = useState([])
    const [teamLeader, setTeamLeader] = useState([])
    const [manager, setManager] = useState([])
    const { adminId } = useContext(AuthStateContext)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    
    useEffect(()=>{
        const funct = async () =>{try {
            const { data } = await axiosInstance.get(`/users/${roles.admin}`, { headers: {'Authorization': `Bearer ${adminId} `} })
            setUsersRole(data)
        } catch (err){}
        try {
            const { data } = await axiosInstance.get(`/users/${roles.teamleader}`, { headers: {'Authorization': `Bearer ${adminId} `} })
            setTeamLeader(data)
        } catch (err){}
        try {
            const { data } = await axiosInstance.get(`/users/${roles.manager}`, { headers: {'Authorization': `Bearer ${adminId} `} })
            setManager(data)
        } catch (err){}
        }
        funct()  
    },[]) 
    
    const newStaff = async (datas, role) =>{
        const login = datas.login
        setLoading(true)
        setError('')
        try {
            const resp = await axiosInstance.post(`/update_role/${login}`, generateFormData({role}) , {
                headers:{
                    'Authorization': `Bearer ${adminId}`,
                    'Content-Type': 'multipart/form-data'
                },
                transformRequest: null
            })
            if (resp.status == 200){
                setError('succ')
                window.location.reload()
            }
        } catch (error) {
            if (error.response.status === 400) { (error.response.data.error === "Rôle non autorisé") ? setError('rolenonaut'): setError('djadmin')};
            if (error.response.status === 403) setError('accref');
            if (error.response.status === 404) setError('usrntfnd') ;
            if (error.response.status !== 400 && error.response.status !== 403 && error.response.status !== 404) setError('smth') ;
        }
        setLoading(false)
    }

    const [loadingActions, setLoadingActions] = useState(0)

    const deleteStaff = async (id, role=roles.admin) =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.delete(`/delete_user/${id}`, {headers: { 'Authorization': `Bearer ${adminId}`}})
            if (role == roles.admin){
                const newList = usersRole.filter(u => u.id !== id)
                setUsersRole(newList)
            }
            if (role == roles.teamleader) {
                const newListOfTl = teamLeader.filter(u => u.id !== id)
                setTeamLeader(newListOfTl)
            }
            if (role == roles.manager) {
                const newListOfMn = manager.filter(u => u.id !== id)
                setManager(newListOfMn)
            }
            if (status === 200) {
                setError('suppr')
            }
        } catch (error) {
            if (error.response.status === 401) {setError('dcnnctd')}
            if (error.response.status === 403) {setError('accref')}
            if (error.response.status === 404) {setError('usrntfnd')}
        }
        setLoadingActions(0)
    }

    const [login, setLogin] = useState('')
    const [loginTl, setLoginTl] = useState('')
    const [loginMn, setLoginMn] = useState('')
    const [newLists, setNewLists] = useState([])
    const [allButTl, setAllButTl] = useState([])
    const [allButMn, setAllButMn] = useState([])
    const [user, setUser] = useState([])
    const [tl, setTl] = useState([])
    const [mn, setMn] = useState([])

    useEffect(() => {
        const funct = async () =>{
            try {
                const {data}  = await axiosInstance.get(`/users_all`, { headers: {'Authorization': `Bearer ${adminId} `} })
                const lists = data.filter(r => r.role?.name !== roles.admin)
                setNewLists(lists)
            } catch (error) {}
            try {
                const {data}  = await axiosInstance.get(`/users_all`, { headers: {'Authorization': `Bearer ${adminId} `} })
                const notTl = data.filter(r => r.role?.name !== roles.teamleader)
                setAllButTl(notTl)
            } catch (error) {}
            try {
                const {data}  = await axiosInstance.get(`/users_all`, { headers: {'Authorization': `Bearer ${adminId} `} })
                const notMn = data.filter(r => r.role?.name !== roles.manager)
                setAllButMn(notMn)
            } catch (error) {}
        }
        funct()
    }, [])

    useEffect(() => {
        const users = newLists.find(u => u.mail.includes(login))
        if (login.length) setUser( users || [])
        else setUser([])
    }, [login])

    useEffect(() => {
        const teaml = allButTl.find(u => u.mail.includes(loginTl))
        if (loginTl.length) setTl( teaml || [])
        else setTl([])
    }, [loginTl])

    useEffect(() => {
        const manag = allButMn.find(u => u.mail.includes(loginMn))
        if (loginMn.length) setMn( manag || [])
        else setMn([])
    }, [loginMn])

    const suspend = async (id, role=roles.admin) =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.post(`/suspend/${id}`, {}, {headers: { 'Authorization': `Bearer ${adminId}`}})
            if (role==roles.admin) {
                const newList = [...usersRole]
                const i = usersRole.findIndex(c => c.id == id)
                newList[i].suspend = addDays(new Date(), 15)
                setUsersRole(newList)
            }
            if (role==roles.teamleader) {
                const newListTl = [...teamLeader]
                const i = teamLeader.findIndex(c => c.id == id)
                newListTl[i].suspend = addDays(new Date(), 15)
                setTeamLeader(newListTl)
            }
            if (role==roles.manager) {
                const newListMn = [...manager]
                const i = manager.findIndex(c => c.id == id)
                newListMn[i].suspend = addDays(new Date(), 15)
                setManager(newListMn)
            }
            
            if (status === 200) {
                setError('susp')
            }
        } catch (error) {
            if (error.response.status === 401) {setError('dcnnctd')}
            if (error.response.status === 403) {setError('accref')}
            if (error.response.status === 404) {setError('usrntfnd')}
        }
        setLoadingActions(0)
        
    }
   
    const getError = error =>{
        
        switch (error){
            case 'succ': return <Typography variant= "success" >{text.success}</Typography>
            case 'suppr': return <Typography variant= "success" >{text.dltd}</Typography>
            case 'rolenonaut': return <Typography variant="error">{text.rolenonaut}</Typography>
            case 'accref': return <Typography variant="error">{text.accref}</Typography>
            case 'djadmin' : return <Typography variant="error">{text.djadmin}</Typography>
            case 'usrntfnd' : return <Typography variant="error">{text.usrntfnd}</Typography>
            case 'dcnnctd' : return <Typography variant="error">{text.dcnnctd}</Typography>
            case 'susp': return <Typography variant= "success" >{text.sspndd}</Typography>
            case 'smth': return <Typography variant="error">{text.smthngwntwrng}</Typography>
            default: return <Typography variant="error">{text.smthngwntwrng}</Typography>;
        }
    }

    useEffect(() => {
        if (error) {
          const timeout = setTimeout(() => {
            setError(null);
          }, 4000);
    
          return () => clearTimeout(timeout);
        }
      }, [error]);

    {/*const [userInfo, setUserInfo] = useState([])
    const [login, setLogin] = useState([])
    const handleShowInfo = async datas => {
        const mail = datas.login
        setLogin(mail);
        try {
          const data = await axiosInstance.get(`/user_by/${mail}`);
          setUserInfo(data);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };
    */}

    

    return(
        <Stack variant="primarybg" spacing={3}>
            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                <img className="card-img-resize-2" src='/images/Admin/admin_staff.png' alt=""/>
                <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#ffc50f' fontWeight='bold' 
                    style={{WebkitTextStroke: "1px #7f6002"}}
                >{text.adminstaff.toUpperCase()  }</Typography>
            </Stack>
            <BigContainerAccordion 
                summaryBg="#0cc0df"
                summary={text.aas1}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.adminstaff , comp: 'TextInput', onChange: e => setFilter({...filter, adminStaff: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter({...filter, language: l.target.value}) }
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 2,  text: text.date},
                                { xs: 2,  text: text.adminstaff},
                                { xs: 1,  text: text.language},
                                { xs: 2,  text: text.adminstafflogin},
                                { xs: 2,  text: text.skypeid},
                                { xs: 1,  text: text.sspndd},
                                { xs: 2,  text: text.actions},
                            ]}/>
                            {
                                usersRole
                                .filter(a => 
                                    // Starting date
                                    ( (!filter.startingDate) || ( filter.startingDate <= new Date(a.created_at)) )
                                    // Closing date
                                    && ( (!filter.closingDate) || (new Date(a.created_at) <= filter.closingDate) )
                                    // Text match
                                    && a.first_name?.toLowerCase().includes(filter.adminStaff)
                                    // Language
                                    && ( (filter.language == 'all') || (a.language?.name == filter.language))  
                                )
                                .map( a =>
                                    <TableRow key={a.id} columns={[
                                        { xs: 2,  text: a.created_at ? format(new Date(a.created_at), 'dd/MM/yyyy') : ''},
                                        { xs: 2,  text: a.first_name},
                                        { xs: 1,  text: languageName(a.language?.name)},
                                        { xs: 2,  text: a.mail},
                                        { xs: 2,  text: a.skype},
                                        {   xs: 1,
                                            color: '#f00' ,
                                            text: (a.suspend ? (new Date() < new Date(a.suspend)) : "") ? text.sspndd : ""
                                        },
                                        //{ xs: 2,  customContent: true, content:
                                        //    <Button style={{textTransform: 'capitalize', color: '#000', width: '100%', height: '100%'}}><Typography variant="boldcenter">{text.edit}</Typography></Button>
                                        //},
                                        { xs: 2,  customContent: true, content: 
                                            (loadingActions == a.id) ? <CircularProgress/>
                                            :
                                                ((a.suspend ? (new Date() < new Date(a.suspend)) : "") ?
                                                    <Stack width='100%'>
                                                        <Button onClick={() => deleteStaff(a.id)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                    </Stack>
                                                :
                                                    <Stack width='100%'>
                                                        <Button onClick={() => suspend(a.id)} style={{textTransform: 'capitalize', color: '#e37584', width: '100%'}}><Typography variant="boldcenter">{text.suspend}</Typography></Button>
                                                        <Button onClick={() => deleteStaff(a.id)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                    </Stack>
                                                )
                                                
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error=='suppr' || error=='succ' || error=='susp') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>}
                    </Stack>
                }
            />
            <BigContainerAccordion 
                summaryBg="#f4a100"
                summary={text.aas2}
                details={
                    <Stack spacing={2}>
                        {/*<FormRow label={text.candidateid} control={control} name='id' error={errors.id}/>*/}
                        <FormRow label={text.email} name="login" control={control} error={errors.login} onChange={a=> setLogin(a)}/>
                        <FormRow label={text.firstname} comp="readOnly" value={user?.first_name} name="firstname" control={control} error={errors.firstname} />
                        <FormRow label={text.lastname} comp="readOnly" value={user?.last_name} name="lastname" control={control} error={errors.lastname}/>
                        {/*<FormRow label={text.password} comp="readOnly"/>*/}
                        <FormRow label={text.country} comp="readOnly" value={ user ? ((countriesList.find(c => c.value === user.country))?.innerHTML) : ''}/>
                        <FormRow label={text.language} comp="readOnly" value={ user ? languageName(user.language?.name) : ''}/>
                        <FormRow label={text.skypeid} comp="readOnly" value={user?.skype}/>
                        <Stack gap={3} style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit( data => newStaff(data, roles.admin))} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    loading ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>{text.createnewadminstaff}</Typography>      
                                }
                                
                            </Button>

                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>} 
                    </Stack>
                }
            />
            {/*
                <BigContainerAccordion 
                    summaryBg="#1cb743"
                    summary={text.aas3}
                    details={
                        <Stack spacing={2}>
                            <SearchFilter
                                selectedDate={filter2.date}
                                onDateChange={{
                                    year: y => setFilter2({...filter2, year: y}),
                                    month: m => setFilter2({...filter2, month: m}),
                                    date: d => setFilter2({...filter2, date: d}),
                                }}
                                items= {[
                                    { label: text.startingdate , comp: 'DatePicker', selected: filter2.startingDate, onChange: d => setFilter2({...filter2, startingDate: d})},
                                    { label: text.closingdate , comp: 'DatePicker', selected: filter2.closingDate, onChange: d => setFilter2({...filter2, closingDate: d})},
                                    { label: text.teamleader , comp: 'TextInput', onChange: e => setFilter2({...filter2, teamLeader: e.target.value}) },
                                    { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter2({...filter2, language: l.target.value}) }
                                ]}
                            />
                            <Stack>
                                <TableRow header columns={[
                                    { xs: 2,  text: text.date},
                                    { xs: 2,  text: text.teamleader},
                                    { xs: 1,  text: text.language},
                                    { xs: 2,  text: text.tllgn},
                                    { xs: 2,  text: text.skypeid},
                                    { xs: 1,  text: text.sspndd},
                                    { xs: 2,  text: text.actions},
                                ]}/>
                                {
                                    teamLeader
                                    .filter(a => 
                                        // Starting date
                                        ( (!filter2.startingDate) || ( filter2.startingDate <= new Date(a.created_at)) )
                                        // Closing date
                                        && ( (!filter2.closingDate) || (new Date(a.created_at) <= filter2.closingDate) )
                                        // Text match
                                        && a.first_name?.toLowerCase().includes(filter2.teamLeader)
                                        // Language
                                        && ( (filter2.language == 'all') || (a.language?.name == filter2.language))  
                                    )
                                    .map( a =>
                                        <TableRow key={a.id} columns={[
                                            { xs: 2,  text: a.created_at ? format(new Date(a.created_at), 'dd/MM/yyyy') : ''},
                                            { xs: 2,  text: a.first_name},
                                            { xs: 1,  text: languageName(a.language?.name)},
                                            { xs: 2,  text: a.mail},
                                            { xs: 2,  text: a.skype},
                                            {   xs: 1,
                                                color: '#f00' ,
                                                text: (a.suspend ? (new Date() < new Date(a.suspend)) : "") ? text.sspndd : ""
                                            },
                                            //{ xs: 2,  customContent: true, content:
                                            //    <Button style={{textTransform: 'capitalize', color: '#000', width: '100%', height: '100%'}}><Typography variant="boldcenter">{text.edit}</Typography></Button>
                                            //},
                                            { xs: 2,  customContent: true, content: 
                                                (loadingActions == a.id) ? <CircularProgress/>
                                                :
                                                    ((a.suspend ? (new Date() < new Date(a.suspend)) : "") ?
                                                        <Stack width='100%'>
                                                            <Button onClick={() => deleteStaff(a.id, roles.teamleader)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                        </Stack>
                                                    :
                                                        <Stack width='100%'>
                                                            <Button onClick={() => suspend(a.id, roles.teamleader)} style={{textTransform: 'capitalize', color: '#e37584', width: '100%'}}><Typography variant="boldcenter">{text.suspend}</Typography></Button>
                                                            <Button onClick={() => deleteStaff(a.id, roles.teamleader)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                        </Stack>
                                                    )
                                                    
                                            },
                                        ]}/>
                                    )
                                }
                            </Stack>
                            {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error=='suppr' || error=='succ' || error=='susp') ? "success" : "error"}>
                                {getError(error)}
                            </Alert>}
                        </Stack>
                    }
                />
                
                <BigContainerAccordion 
                    summaryBg="#f45a52"
                    summary={text.aas4}
                    details={
                        <Stack spacing={2}>
                            <FormRow label={text.email} name="logintl" control={control} error={errors.logintl} onChange={v=> setLoginTl(v)}/>
                            <FormRow label={text.firstname} comp="readOnly" value={tl?.first_name} name="firstname" control={control} error={errors.firstname} />
                            <FormRow label={text.lastname} comp="readOnly" value={tl?.last_name} name="lastname" control={control} error={errors.lastname}/>
                            <FormRow label={text.country} comp="readOnly" value={ tl ? ((countriesList.find(c => c.value === tl.country))?.innerHTML) : ''}/>
                            <FormRow label={text.language} comp="readOnly" value={ tl ? languageName(tl.language?.name) : ''}/>
                            <FormRow label={text.skypeid} comp="readOnly" value={tl?.skype}/>
                            <Stack gap={3} style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <Button onClick={handleSubmit( data => newStaff(data, roles.teamleader))} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                    {
                                        loading ? <CircularProgress color="inherit" size="24px"/>
                                        : <Typography>{text.createnewtl}</Typography>      
                                    }
                                    
                                </Button>

                            </Stack>
                            {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ') ? "success" : "error"}>
                                {getError(error)}
                            </Alert>} 
                        </Stack>
                    }
                />
            */}
            <BigContainerAccordion 
                summaryBg="#43a1b7"
                summary={text.aas5}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter3.date}
                            onDateChange={{
                                year: y => setFilter3({...filter3, year: y}),
                                month: m => setFilter3({...filter3, month: m}),
                                date: d => setFilter3({...filter3, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter3.startingDate, onChange: d => setFilter3({...filter3, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter3.closingDate, onChange: d => setFilter3({...filter3, closingDate: d})},
                                { label: text.teamleader , comp: 'TextInput', onChange: e => setFilter3({...filter3, manager: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter3({...filter3, language: l.target.value}) }
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 2,  text: text.date},
                                { xs: 2,  text: text.manager},
                                { xs: 1,  text: text.language},
                                { xs: 2,  text: text.email},
                                { xs: 2,  text: text.skypeid},
                                { xs: 1,  text: text.sspndd},
                                { xs: 2,  text: text.actions},
                            ]}/>
                            {
                                manager
                                .filter(a => 
                                    // Starting date
                                    ( (!filter3.startingDate) || ( filter3.startingDate <= new Date(a.created_at)) )
                                    // Closing date
                                    && ( (!filter3.closingDate) || (new Date(a.created_at) <= filter3.closingDate) )
                                    // Text match
                                    && a.first_name?.toLowerCase().includes(filter3.manager)
                                    // Language
                                    && ( (filter3.language == 'all') || (a.language?.name == filter3.language))  
                                )
                                .map( a =>
                                    <TableRow key={a.id} columns={[
                                        { xs: 2,  text: a.created_at ? format(new Date(a.created_at), 'dd/MM/yyyy') : ''},
                                        { xs: 2,  text: a.first_name},
                                        { xs: 1,  text: languageName(a.language?.name)},
                                        { xs: 2,  text: a.mail},
                                        { xs: 2,  text: a.skype},
                                        {   xs: 1,
                                            color: '#f00' ,
                                            text: (a.suspend ? (new Date() < new Date(a.suspend)) : "") ? text.sspndd : ""
                                        },
                                        //{ xs: 2,  customContent: true, content:
                                        //    <Button style={{textTransform: 'capitalize', color: '#000', width: '100%', height: '100%'}}><Typography variant="boldcenter">{text.edit}</Typography></Button>
                                        //},
                                        { xs: 2,  customContent: true, content: 
                                            (loadingActions == a.id) ? <CircularProgress/>
                                            :
                                                ((a.suspend ? (new Date() < new Date(a.suspend)) : "") ?
                                                    <Stack width='100%'>
                                                        <Button onClick={() => deleteStaff(a.id, roles.manager)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                    </Stack>
                                                :
                                                    <Stack width='100%'>
                                                        <Button onClick={() => suspend(a.id, roles.manager)} style={{textTransform: 'capitalize', color: '#e37584', width: '100%'}}><Typography variant="boldcenter">{text.suspend}</Typography></Button>
                                                        <Button onClick={() => deleteStaff(a.id, roles.manager)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                    </Stack>
                                                )
                                                
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error=='suppr' || error=='succ' || error=='susp') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>}
                    </Stack>
                }
            />
            <BigContainerAccordion 
                summaryBg="#af2b55"
                summary={text.aas6}
                details={
                    <Stack spacing={2}>
                        <FormRow label={text.email} name="login" control={control1} error={errors1.login} onChange={m=> setLoginMn(m)}/>
                        <FormRow label={text.firstname} comp="readOnly" value={mn?.first_name} />
                        <FormRow label={text.lastname} comp="readOnly" value={mn?.last_name} />
                        <FormRow label={text.country} comp="readOnly" value={ mn ? ((countriesList.find(c => c.value === mn.country))?.innerHTML) : ''}/>
                        <FormRow label={text.language} comp="readOnly" value={ mn ? languageName(mn.language?.name) : ''}/>
                        <FormRow label={text.skypeid} comp="readOnly" value={mn?.skype}/>
                        <Stack gap={3} style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit1( data => newStaff(data, roles.manager))} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    loading ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>{text.createnewmn}</Typography>      
                                }
                                
                            </Button>

                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>} 
                    </Stack>
                }
            />
        </Stack>
    )
}

export default AdminStaff