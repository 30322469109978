import React from "react";
import { Stack, Typography, Box} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import IconText from "../tools/IconText";
import AnimatedButton from "../tools/AnimatedButton";
import CustomDivider from "../tools/CustomDivider";
import { useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";

const Advantages = () =>{
    const { text } = useLanguage()
    const navigate = useNavigate()
    return(
        <Stack  >
            <Stack variant='waves1'><img src='/svg/waves3.svg'/></Stack>
            <Box className="wrap-content" display='flex' padding="100px 20px 20px 20px">
                <Stack display='flex' justifyContent='center' className="wrap-img-container" alignItems='center' style={{minWidth: "40%"}} ><img width="100%" src='/images/AdvantagesImage/4-our-advantages DEF.webp'/></Stack>
                <Stack>
                    <Stack spacing={3}>
                        <Stack spacing={3} id="advantages">
                            <Stack className="title-typo" style={{width: 'fit-content'}}><Typography variant='blacksubtitle'>{text.our} <Typography variant='primarysubtitle' className="title-typo-change-light">{text.advantages}</Typography></Typography></Stack>
                            <CustomDivider color="secondary" width='17%'/>
                            <Stack><IconText range='1' iconSrc='/icones/3- Our advantages/1. Competitive Compensation Packages.svg' children={text.advantage1} /></Stack>
                            <Stack><IconText range='2' iconSrc='/icones/3- Our advantages/2. Performance-Based Bonuses.svg' children={text.advantage2} /></Stack>
                            <Stack><IconText range='3' iconSrc='/icones/3- Our advantages/3. Customized Compensation Plans.svg' children={text.advantage3} /></Stack>
                            <Stack><IconText range='4' iconSrc='/icones/3- Our advantages/4. Career Growth Opportunities.svg' children={text.advantage4} /></Stack>
                            <Stack><IconText range='5' iconSrc='/icones/3- Our advantages/5. Employee Recognition Programs.svg' children={text.advantage5} /></Stack>
                            <Stack><IconText range='6' iconSrc='/icones/3- Our advantages/6. Flexible Work Arrangements.svg' children={text.advantage6} /></Stack>
                            <Stack><IconText range='7' iconSrc='/icones/3- Our advantages/7. Comprehensive Benefits Packages.svg' children={text.advantage7} /></Stack>
                            <Stack><IconText range='8' iconSrc='/icones/3- Our advantages/8. Investment in Training and Development.svg' children={text.advantage8} /></Stack>
                            <Stack><IconText range='9' iconSrc='/icones/3- Our advantages/9. Transparent Compensation Practices.svg' children={text.advantage9} /></Stack>
                            <Stack><IconText range='10' iconSrc='/icones/3- Our advantages/10. Employee Assistance Programs.svg' children={text.advantage10} /></Stack>
                            <Stack><IconText range='11' iconSrc='/icones/3- Our advantages/11. Performance Reviews and Feedback.svg' children={text.advantage11} /></Stack>
                            <Stack><IconText range='12' iconSrc='/icones/3- Our advantages/12. Market Analysis for Competitive Salaries.svg' children={text.advantage12} /></Stack>
                            <Stack><IconText range='13' iconSrc='/icones/3- Our advantages/13. Incentive Structures.svg' children={text.advantage13} /></Stack>

                        </Stack>
                        <Stack alignItems='center'><AnimatedButton onClick={()=> navigate(RoutesList.jobs)}>{text.check}</AnimatedButton></Stack>

                    </Stack>
                </Stack>
            </Box>
        </Stack>
    )
}
export default Advantages