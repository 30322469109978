import React, {useState, useEffect, useContext} from "react";
import { useForm } from "react-hook-form";
import { Stack, Typography, Box, OutlinedInput, Alert, Button } from "@mui/material";
import TimeRange from "./TimeRange";
import useLanguage from "../hooks/useLanguage";
import CustomSelect from "./select";
import AnimatedButton from "./AnimatedButton";
import CustomDivider from "./CustomDivider";
import useData from "../hooks/useData";
import axiosInstance from "../../axios";
import { jwtDecode } from "jwt-decode";
import { AuthStateContext } from "../state/AuthStateContext";
import Input from "./input";
import { format } from "date-fns";
import { jobs } from "../../helper/jobs";
import { useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";
import { DashboardStateContext } from "../state/DashboardStateContext";
import FileInput from "./FileInput";
import generateFormData from "../../helper/formData";

const ApplyFormTools = ({chatoperator=false, supervisor=false, teamleader=false}) =>{

    const { testLanguages, chatModExp, hearAboutUs } = useData()
    const { text, currentLanguage } = useLanguage()
    const { control, handleSubmit, formState: {errors} } = useForm()
    const [rangeDate, setRangeDate] = useState({})
    const [defaultValues, setDefaultValues] = useState({})
    const [ loading, setLoading ] = useState(false)
    const { candidateId } = useContext(AuthStateContext)
    const { id } = jwtDecode(candidateId)
    const [ error, setError ] = useState('')

    const getJobTitle = () =>{
        if (chatoperator) return jobs.chatoperator
        if (supervisor) return jobs.supervisor
        return jobs.teamleader
    }
    const navigate = useNavigate()
    const { setSelectedButton } = useContext(DashboardStateContext)
    const [resume, setResume] = useState(null)
    const onSubmit = async data =>{
        if (resume || defaultValues.resume){
            const { startTime:start_time, endTime:end_time } = rangeDate
            const { src:heard_about, motivation:tell_us, language } = data
            const experience = (data.experience == 'yes') ? 1 : 0
            const title = getJobTitle()
            setLoading(true)
            setError('')
            try {
                const { status } = await axiosInstance.post(`/jobs/${title}/add/${language}`, generateFormData({
                    start_time, end_time, heard_about, tell_us, experience, language, id, resume
                }), {headers: {'Authorization': `Bearer ${candidateId}`, 'Content-Type': 'multipart/form-data'}, transformRequest: null})
                if (status == 201){
                    setError(text.success)
                    setSelectedButton(4)
                    navigate(RoutesList.dashboard)
                }
            } catch (error) {
                const { status } = error.response
                switch (status){
                    case 400: setError(text.error); break
                    case 409: setError(text.tstalrddn); break
                    default: setError(text.smthngwntwrng)
                }           
            }
            setLoading(false)
        } else setError(text.umstupldyrcv)
    }

    useEffect(()=>{
        const userInfos = async () =>{try {
            const { data } = await axiosInstance.get(`/infos/${id}`, { headers: {'Authorization': `Bearer ${candidateId}`} })
            setDefaultValues(data)
        } catch (err){}}
        userInfos()  
    },[])

    return(
        <Stack id={chatoperator ? "apply-co" : (supervisor ? "apply-s" : "apply-tl")} style={{paddingTop: "100px"}}>
            <Stack variant="applyform" padding='2px'> 
                <Stack variant= "applyform" spacing={2} style={{padding: "75px 0 0 0"}}>
                    <Typography className="title-typo" variant="whitetitle" textAlign='center'>
                        {text.appformfor} <Typography variant="secondarytitle" className="title-typo-change">{chatoperator ? text.chatoperator : (supervisor ? text.supervisor : text.teamleader)}</Typography>
                    </Typography>
                    <CustomDivider color="secondary" alignCenter width='14%'/>
                    <Stack display={'flex'} alignItems="center" justifyContent={'center'} style={{width: "100%"}}>
                        <Stack spacing='10px' width="90%" padding="20px 0">
                            
                            <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                                <Stack width="50%"><Typography variant="primarybg">{text.firstname}</Typography><OutlinedInput readOnly placeholder={defaultValues.firstname}/></Stack>
                                <Stack width="50%"><Typography variant="primarybg">{text.lastname}</Typography><OutlinedInput readOnly placeholder={defaultValues.lastname}/></Stack>
                            </Box>
                        
                            <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                                <Stack width="50%"><Typography variant="primarybg">{text.email}</Typography><OutlinedInput readOnly placeholder={defaultValues.mail}/></Stack>
                                <Stack width="50%"><Typography variant="primarybg">{text.birthday}</Typography><OutlinedInput readOnly placeholder={defaultValues.birthdate ? format(defaultValues.birthdate, 'dd-MM-yyyy') : ''}/></Stack>
                                
                            </Box>
                            <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                                <Stack width="50%"><Typography variant="primarybg">{text.phone}</Typography><OutlinedInput readOnly placeholder={defaultValues.phone}/></Stack>
                                <Stack width="50%"><Typography variant="primarybg">{text.skypename}</Typography><OutlinedInput readOnly placeholder={defaultValues.pseudoskype}/></Stack>
                            </Box>
                            <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                                <Stack width="50%"><CustomSelect white name='language' label={text.whichchatlanguage} control={control} error={errors.language} options={testLanguages} defaultValueIndex={testLanguages.findIndex(l => l.value == currentLanguage)}/></Stack>
                                <Stack width="50%">
                                    <Typography variant="primarybg">{text.timesavailable}</Typography>
                                    <TimeRange onChange={setRangeDate}/>
                                </Stack>
                            </Box>
                            <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                                <Stack width="50%"><CustomSelect white name='experience' label={chatoperator ? text.expchatmod : (supervisor ? text.expsup : text.expteam)} control={control} error={errors.experience} options={chatModExp}/></Stack>
                                <Stack width="50%"><CustomSelect white name='src' label={text.hearabout} control={control} error={errors.src} options={hearAboutUs}/></Stack>
                            </Box>
                            <Stack><Input white high type='text' name='motivation' label={text.tellus} control={control} error={errors.motivation}/></Stack>
                            { !defaultValues.resume && <FileInput visiblePartVariant="DoubleTextPdf" label={`${text.bfrsbmttng},`} label2={text.upldrsmpdf} control={control} name="resume" error={errors.resume} onExportFile={setResume}/>}
                            { error.length ? <Alert severity={ (error == text.success) ? "success" : "error"}>{error}</Alert> : <></> }
                            <AnimatedButton loading={loading} onClick={handleSubmit(onSubmit)}>{text.submitapp}</AnimatedButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}
export default ApplyFormTools