import { useEffect, useState } from "react";
import RoutesList from "../../helper/routes"
import { useLocation } from 'react-router-dom';
import useLanguage from "./useLanguage";

const useTitle = ()=>{
    const location = useLocation()
    const { text } = useLanguage()
    const suffix = " - Creativ-Network"

    const getPartFromLast = rangeFromLast =>{
        const parts = decodeURIComponent(location.pathname).split("/")
        return parts[parts.length - rangeFromLast]
    }
    const lang = getPartFromLast(1)
    const id = getPartFromLast(2)
    const datepub = getPartFromLast(3)

    const refresh = () =>{
        switch (decodeURIComponent(location.pathname)){
            case RoutesList.landing + lang: setTitle("Creativ-Network - " + text.slog); break
            case RoutesList.test + lang : setTitle(text.test  + suffix); break
            case RoutesList.testteamleader + lang : setTitle(text.tltestform + suffix); break
            case RoutesList.chatoperator + lang : setTitle(text.titlechatoperator + suffix); break
            case RoutesList.jobs + lang: setTitle(text.titlejobs + suffix); break
            case RoutesList.pp + lang : setTitle(text.titlepp + suffix); break
            case RoutesList.terms + lang : setTitle(text.titleterms + suffix); break
            case RoutesList.blog + lang : setTitle(text.blog + suffix); break
            case RoutesList.memberarea + lang: setTitle(text.memberarea + suffix); break
            case RoutesList.dashboard + lang: setTitle(text.dashboard + suffix); break
            case RoutesList.adminhome + lang: setTitle(text.admin + suffix); break
            case RoutesList.supervisor + lang: setTitle(text.supervisor + suffix); break
            case RoutesList.testsupervisor + lang: setTitle(text.qstesttitle + suffix); break
            case RoutesList.blogger + lang: setTitle(text.blogger + suffix); break
            case RoutesList.teamleaderhome + lang:
            case RoutesList.jobsteamleader + lang:
                setTitle(text.teamleader + suffix); break
            case RoutesList.teamleaderhome + lang: setTitle(text.teamleader.toUpperCase() + suffix); break
            case RoutesList.teamleaderauth + lang:
            case RoutesList.admin + lang:
            case RoutesList.bloggerauth + lang:
                setTitle(text.login + suffix); break
            case RoutesList.category + id + "/" + lang :
            case RoutesList.author + id + "/" + lang :
            case "/" + datepub + "/" +  id + "/" + lang :
                setTitle(location.state.name + suffix); break
            default: setTitle('')
        }
    } 
    
    const [title, setTitle] = useState('')
    useEffect( () => refresh(), [location])
    
    return title
}
export default useTitle