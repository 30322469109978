import React from 'react';
import Typography from '@mui/material/Typography';

const ArcText = ({children}) => {
    const mult = 25
    
  return (
    <Typography variant='headerhello'>
        <svg width={children.length * mult} height={children.length * (mult/2)} >
            <path fill='transparent' id="curve" d={`M 0 10 C 0 10, ${children.length * (mult/2)} ${children.length * (mult/2)} ${children.length * mult} 10`}></path>
            <text fill='#fff' textAnchor="middle">
                <textPath className="style-font-5" href="#curve" startOffset="50%" >{children}</textPath>
            </text>
        </svg>
    </Typography>
        

    
    
  );
};

export default ArcText;