import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import useLanguage from "../../hooks/useLanguage";
import IconText from "../../tools/IconText";
import AnimatedButton from "../../tools/AnimatedButton"
import CustomDivider from "../../tools/CustomDivider"

const SalaryCompute = () =>{
    const { text } = useLanguage()
    const iconBaseUrl = "/images/chat-operator/3- How will the chat operator's salary be calculated/"
     
    return(
        <Stack>
            <Stack variant="waves2"><img src="/svg/waves2.svg"/></Stack>
            <Stack variant="curvy" display="flex">
                <Box className="wrap-content">
                    <Stack spacing={3} className="spacing-stack" style={{width: '65%'}} display="flex" alignItems="center" justifyContent="center">
                        <Typography className="title-typo text-resize-4" variant="whitetitle" textAlign='left'>
                            {text.coscint1} <Typography variant="secondarytitle" className="title-typo-change">{text.chatoperator}</Typography> {text.coscint2}
                        </Typography>
                        <CustomDivider color="secondary" width='14%'/>
                        <Stack spacing={1}>
                            <IconText white range={1} iconSrc={iconBaseUrl + "1. Base Pay per Message.svg"}>{text.cosc1}</IconText>
                            <IconText white range={2} iconSrc={iconBaseUrl + "2. Monthly Message Count.svg"}>{text.cosc2}</IconText>
                            <IconText white range={3} iconSrc={iconBaseUrl + "3. Payout Calculation.svg"}>{text.cosc3}</IconText>
                            <IconText white range={4} iconSrc={iconBaseUrl + "4. Performance Bonus.svg"}>{text.cosc4}</IconText>
                            <IconText white range={5} iconSrc={iconBaseUrl + "5. Christmas Bonus.svg"}>{text.cosc5}</IconText>
                        </Stack>
                        <AnimatedButton to="apply-co">{text.applynow}</AnimatedButton>
                    </Stack>

                    <Stack alignItems="center" justifyContent="flex-end" style={{width: '35%'}}>
                        <img width='100%' src="/images/chat-operator/3- e-chat-operator-salary-be-calculated DEF.webp" alt=""/>
                    </Stack>
                </Box>
            </Stack>
            <Stack variant="waves1"><img src="/svg/waves1.svg"/></Stack>
        </Stack>
    )
}

export default SalaryCompute