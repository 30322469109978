const allGroups = [
    ["Leadership","Communication","Résolution de problèmes","Assurance qualité","Formation","Innovation","Collaboration"],
    ["Leadership","Communication","Problem-solving","Quality Assurance","Training","Innovation","Collaboration"],
    ["Leadership","Comunicazione","Risoluzione dei problemi","Garanzia di qualità","Formazione","Innovazione","Collaborazione"],
    ["Leiderschap","Communicatie","Problemen oplossen","Kwaliteitsborging","Training","Innovatie","Samenwerking"],
    ["Liderazgo","Comunicación","Resolución de problemas","Garantía de calidad","Formación","Innovación","Colaboración"],
    ["Führung","Kommunikation","Problemlösung","Qualitätssicherung","Ausbildung","Innovation","Zusammenarbeit"],
]
const allTest = [
    [ // fr
        [
            "Approche du leadership en matière de qualité : Comment définiriez-vous votre approche de la direction d'une équipe soucieuse de maintenir des normes de haute qualité dans son travail ? ",
            "Motivation de l'équipe : Décrivez comment vous motivez votre équipe pour qu'elle fournisse constamment des services de qualité. Pouvez-vous donner un exemple de stratégie de motivation réussie ?",
            "Résolution des conflits en matière d'assurance qualité : Racontez une expérience au cours de laquelle vous avez dû résoudre un conflit lié au maintien de normes de qualité au sein de votre équipe. Comment l'avez-vous géré ?",
        ],
        [
            "Communication efficace en matière d'assurance qualité : Comment vous assurez-vous que les normes et les attentes en matière de qualité sont communiquées clairement à votre équipe ? Donnez un exemple de stratégie de communication réussie",
            "Retour d'information : Comment donnez-vous un retour d'information aux membres de votre équipe pour les aider à améliorer la qualité ? Pouvez-vous donner un exemple de situation où votre retour d'information a eu un impact positif ?",
            "Canaux de communication pour l'amélioration de la qualité : Expliquez les canaux de communication que vous mettez en place pour faciliter l'amélioration continue des processus d'assurance qualité au sein de votre équipe",
        ],
        [
            "Identification des problèmes en matière d'assurance qualité : Donnez un exemple de la manière dont vous avez identifié un problème lié à la qualité au sein de votre équipe. Comment l'avez-vous résolu ?",
            "Résolution innovante de problèmes : Décrivez une situation dans laquelle vous avez appliqué des techniques innovantes de résolution de problèmes pour améliorer la qualité des services fournis par votre équipe",
        ],[
            "Adhésion aux normes de qualité : Comment vous assurez-vous que votre équipe respecte systématiquement les normes de qualité établies ?",
            "Métriques et évaluation pour l'amélioration de la qualité : Comment utilisez-vous les indicateurs de performance pour évaluer et améliorer la qualité du travail de votre équipe ?",
        ],
        [
            "Programmes de formation continue : Expliquez votre approche des programmes de formation continue pour l'amélioration de la qualité. Comment maintenez-vous les compétences de votre équipe à jour ?",
            "Processus d'intégration pour l'assurance de la qualité : Décrivez votre processus d'intégration des nouveaux membres de l'équipe dans le contexte de l'assurance qualité. Comment vous assurez-vous qu'ils comprennent et respectent les normes de qualité ?"
        ],
        [
            "Stratégies innovantes pour l'amélioration de la qualité : Donnez un exemple de la manière dont vous avez introduit des stratégies innovantes pour améliorer la qualité des services fournis par votre équipe",
            "Adaptation à l'évolution des normes de qualité : Comment vous tenez-vous informé de l'évolution des normes de qualité du secteur et comment veillez-vous à ce que votre équipe s'adapte à ces changements ?"
        ],
        [
            "Collaboration pour l'assurance de la qualité : Décrivez comment vous favorisez la collaboration entre votre équipe et les autres services afin de garantir une approche globale de l'assurance de la qualité."
        ],
    ],
    
    [ // en
        [
            "Quality Leadership Approach: How would you define your approach to leading a team focused on maintaining high-quality standards in their work? ",
            "Team Motivation: Describe how you motivate your team to consistently deliver quality services. Can you provide an example of a successful motivation strategy?",
            "Conflict Resolution in Quality Assurance: Share an experience where you had to resolve a conflict related to maintaining quality standards within your team. How did you handle it?",
        ],
        [
            "Effective Communication in Quality Assurance: How do you ensure that quality standards and expectations are communicated clearly to your team? Provide an example of a successful communication strategy.",
            "Feedback Delivery: How do you deliver feedback to your team members to help them improve quality? Can you share an example of a situation where your feedback had a positive impact?",
            "Communication Channels for Quality Improvement: Explain the communication channels you establish to facilitate continuous improvement in quality assurance processes within your team.",
        ],
        [
            "Problem Identification in Quality Assurance: Share an example of how you identified a quality-related issue within your team. How did you approach solving it?",
            "Innovative Problem-Solving: Describe a situation where you applied innovative problem-solving techniques to enhance the quality of services provided by your team.",
        ],
        [
            "Quality Standards Adherence: How do you ensure that your team consistently adheres to established quality standards?",
            "Metrics and Evaluation for Quality Improvement: How do you use performance metrics to evaluate and improve the quality of your team's work?",
        ],
        [
            "Continuous Training Programs: Explain your approach to continuous training programs for quality improvement. How do you keep your team's skills up-to-date?",
            "Onboarding Process for Quality Assurance: Describe your onboarding process for new team members in the context of quality assurance. How do you ensure they understand and adhere to quality standards?"
        ],
        [
            "Innovative Strategies for Quality Enhancement: Share an example of how you introduced innovative strategies to enhance the quality of services provided by your team.",
            "Adaptation to Evolving Quality Standards: How do you stay informed about evolving industry quality standards, and how do you ensure your team adapts to these changes?"
        ],
        [
            "Collaboration for Quality Assurance: Describe how you foster collaboration between your team and other departments to ensure a holistic approach to quality assurance."
        ],
    ],
    
    [ // it
        [
            "Approccio alla leadership di qualità: Come definirebbe il suo approccio alla guida di un team focalizzato sul mantenimento di standard di alta qualità nel proprio lavoro? ",
            "Motivazione del team: Descriva come motiva il suo team a fornire costantemente servizi di qualità. Può fornire un esempio di strategia motivazionale di successo?",
            "Risoluzione dei conflitti nell'assicurazione qualità: Condividete un'esperienza in cui avete dovuto risolvere un conflitto relativo al mantenimento degli standard di qualità all'interno del vostro team. Come lo avete gestito?",
        ],
        [
            "Comunicazione efficace nell'assicurazione qualità: Come fate a garantire che gli standard e le aspettative di qualità siano comunicati chiaramente al vostro team? Fornite un esempio di strategia di comunicazione di successo",
            "Consegna del feedback: Come fornite il feedback ai membri del vostro team per aiutarli a migliorare la qualità? Potete condividere un esempio di situazione in cui il vostro feedback ha avuto un impatto positivo?",
            "Canali di comunicazione per il miglioramento della qualità: Spiegate i canali di comunicazione che stabilite per facilitare il miglioramento continuo dei processi di garanzia della qualità all'interno del vostro team",
        ],
        [
            "Identificazione dei problemi nell'assicurazione qualità: Condividete un esempio di come avete identificato un problema legato alla qualità all'interno del vostro team. Come avete affrontato la soluzione?",
            "Risoluzione innovativa dei problemi: Descrivete una situazione in cui avete applicato tecniche innovative di risoluzione dei problemi per migliorare la qualità dei servizi forniti dal vostro team",
        ],[
            "Aderenza agli standard di qualità: Come fate a garantire che il vostro team aderisca costantemente agli standard di qualità stabiliti?",
            "Metriche e valutazione per il miglioramento della qualità: Come utilizzate le metriche di performance per valutare e migliorare la qualità del lavoro del vostro team?",
        ],
        [
            "Programmi di formazione continua: Spiegate il vostro approccio ai programmi di formazione continua per il miglioramento della qualità. Come mantenete aggiornate le competenze del vostro team?",
            "Processo di inserimento per l'assicurazione della qualità: Descrivete il vostro processo di onboarding per i nuovi membri del team nel contesto dell'assicurazione qualità. Come vi assicurate che comprendano e aderiscano agli standard di qualità?",
        ],
        [
            "Strategie innovative per il miglioramento della qualità: Condividete un esempio di come avete introdotto strategie innovative per migliorare la qualità dei servizi forniti dal vostro team",
            "Adattamento agli standard di qualità in evoluzione: Come vi tenete informati sull'evoluzione degli standard di qualità del settore e come vi assicurate che il vostro team si adatti a questi cambiamenti?", "Adattamento agli standard di qualità in evoluzione",
        ],
        [
            "Collaborazione per l'assicurazione della qualità: Descrivete come promuovete la collaborazione tra il vostro team e gli altri reparti per garantire un approccio olistico alla garanzia di qualità."
        ],
    ],
    
    [ // nl
        [
            "Aanpak voor kwaliteitsleiderschap: Hoe zou u uw aanpak omschrijven voor het leiden van een team dat gericht is op het handhaven van hoge kwaliteitsnormen in hun werk? ",
            "Team Motivatie: Beschrijf hoe u uw team motiveert om consistent kwaliteitsdiensten te leveren. Kun je een voorbeeld geven van een succesvolle motivatiestrategie?",
            "Conflictoplossing in kwaliteitsborging: Deel een ervaring waarin je een conflict moest oplossen dat te maken had met het handhaven van kwaliteitsnormen binnen je team. Hoe heb je dat aangepakt?",
        ],
        [
            "Effectieve communicatie in kwaliteitsborging: Hoe zorg je ervoor dat de kwaliteitsnormen en verwachtingen duidelijk worden gecommuniceerd naar je team? Geef een voorbeeld van een succesvolle communicatiestrategie.",
            "Feedback geven: Hoe geef je feedback aan je teamleden om hen te helpen de kwaliteit te verbeteren? Kunt u een voorbeeld geven van een situatie waarin uw feedback een positieve impact had?",
            "Communicatiekanalen voor kwaliteitsverbetering: Leg uit welke communicatiekanalen je gebruikt om continue verbetering van de kwaliteitsborgingsprocessen binnen je team mogelijk te maken.","Communicatiekanalen voor kwaliteitsverbetering: Hoe geef je feedback aan je teamleden om hen te helpen de kwaliteit te verbeteren?",
        ],
        [
            "Probleemidentificatie in kwaliteitsborging: Geef een voorbeeld van hoe je een kwaliteitsgerelateerd probleem in je team hebt geïdentificeerd. Hoe heb je het opgelost?",
            "Innovatieve probleemoplossing: Beschrijf een situatie waarin je innovatieve probleemoplossingstechnieken hebt toegepast om de kwaliteit van de diensten van je team te verbeteren.",
        ],[
            "Naleving van kwaliteitsnormen: Hoe zorg je ervoor dat je team zich consequent houdt aan vastgestelde kwaliteitsnormen?",
            "Metriek en evaluatie voor kwaliteitsverbetering: Hoe gebruik je prestatiemetingen om de kwaliteit van het werk van je team te evalueren en te verbeteren?",
        ],
        [
            "Voortdurende trainingsprogramma's: Leg uit hoe je omgaat met continue trainingsprogramma's voor kwaliteitsverbetering. Hoe houd je de vaardigheden van je team up-to-date?",
            "Inwerkproces voor kwaliteitsborging: Beschrijf uw inwerkproces voor nieuwe teamleden in de context van kwaliteitsborging. Hoe zorg je ervoor dat ze de kwaliteitsnormen begrijpen en zich eraan houden?",
        ],
        [
            "Innovatieve strategieën voor kwaliteitsverbetering: Geef een voorbeeld van hoe je innovatieve strategieën hebt geïntroduceerd om de kwaliteit van de diensten van je team te verbeteren.",
            "Aanpassing aan veranderende kwaliteitsnormen: Hoe blijf je op de hoogte van de evoluerende kwaliteitsnormen in de sector en hoe zorg je ervoor dat je team zich aanpast aan deze veranderingen?",
        ],
        [
            "Samenwerking voor kwaliteitsborging: Beschrijf hoe je de samenwerking tussen je team en andere afdelingen bevordert om een holistische benadering van kwaliteitsborging te garanderen."
        ],
    ],
    
    [ // es
        [
            "Enfoque de liderazgo de calidad ¿Cómo definiría su enfoque para liderar un equipo centrado en mantener estándares de alta calidad en su trabajo? ",
            "Motivación del equipo: Describa cómo motiva a su equipo para que preste constantemente servicios de calidad. ¿Puede dar un ejemplo de una estrategia de motivación con éxito?",
            "Resolución de conflictos en el control de calidad: Comparta una experiencia en la que haya tenido que resolver un conflicto relacionado con el mantenimiento de los estándares de calidad dentro de su equipo. ¿Cómo lo resolvió?",
        ],
        [
            "Comunicación eficaz en el control de calidad: ¿Cómo se asegura de que las normas y expectativas de calidad se comunican claramente a su equipo? Proporcione un ejemplo de una estrategia de comunicación exitosa",
            "Entrega de retroalimentación: ¿Cómo proporciona retroalimentación a los miembros de su equipo para ayudarles a mejorar la calidad? ¿Puede compartir un ejemplo de una situación en la que su feedback haya tenido un impacto positivo?",
            "Canales de comunicación para la mejora de la calidad: Explique los canales de comunicación que establece para facilitar la mejora continua de los procesos de aseguramiento de la calidad dentro de su equipo",
        ],
        [
            "Identificación de problemas en la garantía de calidad: Comparta un ejemplo de cómo identificó un problema relacionado con la calidad dentro de su equipo. ¿Cómo lo resolvió?",
            "Resolución innovadora de problemas: Describa una situación en la que aplicó técnicas innovadoras de resolución de problemas para mejorar la calidad de los servicios prestados por su equipo",
        ],[
            "Cumplimiento de las normas de calidad: ¿Cómo se asegura de que su equipo cumple sistemáticamente las normas de calidad establecidas?",
            "Métricas y evaluación para la mejora de la calidad: ¿Cómo utiliza las métricas de rendimiento para evaluar y mejorar la calidad del trabajo de su equipo?",
        ],
        [
            "Programas de formación continua: Explique su enfoque de los programas de formación continua para la mejora de la calidad. ¿Cómo mantiene actualizadas las competencias de su equipo?",
            "Proceso de incorporación para la garantía de calidad: Describa su proceso de incorporación de nuevos miembros del equipo en el contexto de la garantía de calidad. ¿Cómo se asegura de que entienden y cumplen las normas de calidad?",
        ],
        [
            "Estrategias innovadoras para la mejora de la calidad: Comparta un ejemplo de cómo introdujo estrategias innovadoras para mejorar la calidad de los servicios prestados por su equipo",
            "Adaptación a la evolución de las normas de calidad: ¿Cómo se mantiene informado sobre la evolución de las normas de calidad del sector y cómo se asegura de que su equipo se adapta a estos cambios?",
        ],
        [
            "Colaboración para el aseguramiento de la calidad: Describa cómo fomenta la colaboración entre su equipo y otros departamentos para garantizar un enfoque holístico de la garantía de calidad."
        ],
    ],
    
    [ // de
        [
            "Ansatz zur Qualitätsführerschaft: Wie würden Sie Ihre Herangehensweise an die Führung eines Teams definieren, das sich auf die Einhaltung hoher Qualitätsstandards bei seiner Arbeit konzentriert? ",
            "Motivation des Teams: Beschreiben Sie, wie Sie Ihr Team motivieren, durchgängig hochwertige Leistungen zu erbringen. Können Sie ein Beispiel für eine erfolgreiche Motivationsstrategie nennen?",
            "Konfliktlösung in der Qualitätssicherung: Erzählen Sie von einem Erlebnis, bei dem Sie einen Konflikt im Zusammenhang mit der Einhaltung von Qualitätsstandards innerhalb Ihres Teams lösen mussten. Wie sind Sie damit umgegangen?",
        ],
        [
            "Effektive Kommunikation in der Qualitätssicherung: Wie stellen Sie sicher, dass Qualitätsstandards und -erwartungen klar an Ihr Team kommuniziert werden? Nennen Sie ein Beispiel für eine erfolgreiche Kommunikationsstrategie.",
            "Feedback Delivery: Wie geben Sie Ihren Teammitgliedern Feedback, um sie bei der Verbesserung der Qualität zu unterstützen? Können Sie ein Beispiel für eine Situation nennen, in der sich Ihr Feedback positiv ausgewirkt hat?",
            "Kommunikationskanäle zur Qualitätsverbesserung: Erläutern Sie die Kommunikationskanäle, die Sie einrichten, um eine kontinuierliche Verbesserung der Qualitätssicherungsprozesse in Ihrem Team zu ermöglichen.",
        ],
        [
            "Problemidentifizierung in der Qualitätssicherung: Nennen Sie ein Beispiel dafür, wie Sie ein qualitätsbezogenes Problem innerhalb Ihres Teams erkannt haben. Wie sind Sie bei der Lösung vorgegangen?",
            "Innovative Problemlösung: Beschreiben Sie eine Situation, in der Sie innovative Problemlösungstechniken angewandt haben, um die Qualität der von Ihrem Team erbrachten Dienstleistungen zu verbessern.",
        ],[
            "Einhaltung von Qualitätsstandards: Wie stellen Sie sicher, dass Ihr Team die festgelegten Qualitätsstandards konsequent einhält?",
            "Metriken und Bewertung zur Qualitätsverbesserung: Wie nutzen Sie Leistungskennzahlen zur Bewertung und Verbesserung der Qualität der Arbeit Ihres Teams?",
        ],
        [
            "Kontinuierliche Schulungsprogramme: Erläutern Sie Ihren Ansatz für kontinuierliche Schulungsprogramme zur Qualitätsverbesserung. Wie halten Sie die Fähigkeiten Ihres Teams auf dem neuesten Stand?",
            "Onboarding-Prozess für die Qualitätssicherung: Beschreiben Sie Ihren Onboarding-Prozess für neue Teammitglieder im Zusammenhang mit der Qualitätssicherung. Wie stellen Sie sicher, dass sie die Qualitätsstandards verstehen und einhalten?"
        ],
        [
            "Innovative Strategien zur Qualitätsverbesserung: Nennen Sie ein Beispiel dafür, wie Sie innovative Strategien eingeführt haben, um die Qualität der von Ihrem Team erbrachten Dienstleistungen zu verbessern.",
            "Anpassung an sich entwickelnde Qualitätsstandards: Wie halten Sie sich über die sich entwickelnden Qualitätsstandards der Branche auf dem Laufenden und wie stellen Sie sicher, dass sich Ihr Team an diese Veränderungen anpasst?"
        ],
        [
            "Zusammenarbeit für die Qualitätssicherung: Beschreiben Sie, wie Sie die Zusammenarbeit zwischen Ihrem Team und anderen Abteilungen fördern, um einen ganzheitlichen Ansatz zur Qualitätssicherung zu gewährleisten."
        ],
    ]
]

const getKey = lang =>{
    switch(lang){
        case 'fr': return 0
        case 'it': return 2
        case 'nl': return 3
        case 'es': return 4
        case 'de': return 5
        default: return 1
    }
}
const order = testlist => {
    let i = 1
    let toReturn = []
    testlist.forEach(testgroup =>{
        let thisGroupVal = []
        testgroup.forEach(t => thisGroupVal.push(`${i++}. ${t}`))
        toReturn.push(thisGroupVal)
    })
    return toReturn
}

export const groupsup = lang => { return allGroups[getKey(lang)] }
export const testsup = (lang, unOrdered=false) => {
    const list = allTest[getKey(lang)]
    return unOrdered ? list : order(list)
}