import React from "react";
import LoginForm from "../tools/LoginForm";
import { roles } from "../../helper/roles";

const ManagerLogin = () => {
    return (
        <LoginForm type={roles.manager} roleIndex={1}/>
    )
} 

export default ManagerLogin;