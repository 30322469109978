import React, { useEffect, useState } from "react";
import { Stack, Typography, Box  } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import TitleWithBg from '../../tools/TitleWithBg';
import format from "date-fns/format";
import Status from "../../tools/Status";
import { getTestRoute, getApplyFormRoute } from "../../../helper/jobs";
import { Link } from "react-router-dom";
import useData from '../../hooks/useData'

const AppCheck = ({control, errors, defaultValues}) =>{
    const { text, currentLanguage } = useLanguage()
    const [status, setStatus] = useState([])
    const [availableJobs, setAvailableJobs] = useState([])
    const { jobName } = useData()
    useEffect(()=>{
        const { available_jobs: dbAJ, test_status } = defaultValues
        if (test_status) {
            setStatus(test_status)
            setAvailableJobs(dbAJ.filter(n => !test_status.some(t => t.job_name === n)))
        }
    }, [defaultValues])
    return(
        <Stack variant="rightcontent" >
            <Stack display="flex" justifyContent="center" alignItems="center" ><TitleWithBg iconSrc='/images/Member/dashboard/4.png' children={text.yourapplication} color="#1db744" title/></Stack>
            <Stack style={{alignItems: "center", justifyContent: "center"}}><Typography variant="secondaryitalic">{text.application2}</Typography></Stack>
            <Stack gap={3}>
                <Box className="wrap-content" >
                    <Stack width="60%" justifyContent="center" alignItems="center"><TitleWithBg iconSrc="/images/Member/dashboard/application/29.png" children={text.appstatus1}/></Stack>
                    <Stack width="40%" spacing={1} style={{display: 'flex', justifyContent: 'center'}}>
                        {
                            availableJobs.map((j, i) => getApplyFormRoute(j) ? <Typography key={`avjobs${i}`} variant="white">{`${jobName(j)} : ${text.avlbl}. `}<Link to={getApplyFormRoute(j)} className="link">{ text.fllppfrm }</Link></Typography> : <Stack key={`avjobs${i}`}/>)
                        }{
                            status.map((s, i) => <Stack key={`job_app_form_${i}`} width="95%" alignSelf="center">
                                <Status success children={`${jobName(s.job_name)}${(currentLanguage == 'fr') ? ' ' : ''}: ${text.submittedon} ${format(s.status_form_date, 'dd/MM/yyyy')}`}/>
                            </Stack>)
                        }
                    </Stack>
                </Box>
                <Box className="wrap-content" >
                    <Stack width="60%" justifyContent="center" alignItems="center"><TitleWithBg iconSrc="/images/Member/dashboard/application/30.png" children={text.appstatus2}/></Stack>
                    <Stack width="40%" spacing={1} style={{display: 'flex', justifyContent: 'center'}}>{
                        status.map((s, i) => <Stack key={`job_test_${i}`} width="95%" alignSelf="center">
                            <Status success={Boolean(s.status_test_date)} testRoute={getTestRoute(s.job_name)} children={`${jobName(s.job_name)}${(currentLanguage == 'fr') ? ' ' : ''}: ${ Boolean(s.status_test_date) ? `${text.submittedon} ${format(s.status_test_date, 'dd/MM/yyyy')}` : text.notyet}`}/>
                            { /* Note: Si le lien de redirection vers le test ne s'affiche pas au cas !success, une raison 
                                probable est la valeur de testRoute qui serait null. Vérifiez l'écriture du s.job_name
                                et veillez à ce qu'elle corresponde à l'une des valeurs possibles dans getTestRoute() */ }
                        </Stack>)
                    }</Stack>
                    {/*<Stack width="40%" display="flex" flexDirection="column" gap={2} alignSelf="center">
                        <Status success children={text.reviewedon}/>
                </Stack>*/}
                </Box>
                <Box className="wrap-content" >
                    <Stack width="60%" justifyContent="center" alignItems="center"><TitleWithBg iconSrc="/images/Member/dashboard/application/31.png" children={text.appstatus3}/></Stack>
                    <Stack width="40%" alignSelf="center" spacing={1}>
                        {
                            status.map((s, i) => <Stack width="95%" key={`st${i}`} alignSelf="center">
                                { Boolean(s.review_test_date) && <Status success children={`${jobName(s.job_name)}${(currentLanguage == 'fr') ? ' ' : ''}: ${text.reviewedon} ${format(s.review_test_date, 'dd/MM/yyyy')}`}/> }
                            </Stack>)
                        }
                    </Stack>
                </Box>
                <Box className="wrap-content" >
                    <Stack width="60%" justifyContent="center" alignItems="center"><TitleWithBg iconSrc="/images/Member/dashboard/application/32.png" children={text.appstatus4}/></Stack>
                    <Stack width="40%" display="flex" flexDirection="column" gap={2} alignSelf="center">
                        {
                            status.map((s, i) => <Stack width="95%" key={`sta${i}`} alignSelf="center">
                                { (['Hired', 'Rejected'].includes(s.decision)) && <Status success={s.decision == 'Hired'} children={`${jobName(s.job_name)}${(currentLanguage == 'fr') ? ' ' : ''}: ${ (s.decision == 'Hired') ? text.hired : text.nothired }`}/> }
                            </Stack>)
                        }                    
                        {/*<Status children={text.nothired}/> */}                       
                    </Stack>
                </Box>
            </Stack>
            
        </Stack>
    )
}

export default AppCheck