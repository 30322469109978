import React, {useState, useEffect} from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import useData from "../hooks/useData";
import useLanguage from "../hooks/useLanguage";
import ArticleItem from "./ArticleItem";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import axiosInstance from "../../axios";
import { format } from "date-fns";

const Category = () => {
    const { id, lang } = useParams()
    const { dataBlog } = useData()
    const { text } = useLanguage()
    const navigate = useNavigate()
    const [posts, setPosts] = useState([])

    useEffect(()=>{
        const funct = async () =>{try {
            const { data } = await axiosInstance.get(`/posts_all`)
            console.log(data)
            setPosts(data)
        } catch (err){}}
        funct()  
    },[])

    return(
        <Stack onLoad={() => window.scrollTo(0, 0)}>
            <Box className="wrap-content" display="flex" padding="40px 0px">
                <Stack width="75%" display="flex" gap="75px" flexDirection="column-reverse" >
                    {
                        posts
                        .filter(p => p.category.id == id)
                        .map(
                            (d, index) => 
                            <Stack key={"d"+ index} spacing={7}>
                                <ArticleItem id={d.id} imgSrc={d.image_illustration} title={d.title_Post} date={d.created_at} 
                                    author={d.author.user_name} authorId={d.author.id} comments={d.post_comment}
                                    category={d.category.name} content={d.content} categoryId={d.category.id}/>
                                <Button onClick={() => navigate( "/" + format(d.created_at, 'yyyy-MM-dd') + "/" +  d.id + "/" + lang, { state: { name: d.title_Post } })} variant="neutral" style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "4px", marginLeft: "30px"}}>
                                {/*</Button><Button onClick={() => navigate( "/" + format(d.created_at, 'yyyy-MM-dd') + "/" +  d.id + "/" + lang)}  variant="neutral" style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "4px", marginLeft: "30px"}}>*/}
                                    <Typography variant="tiny">{text.readmore}</Typography>
                                    <TrendingFlatIcon/>
                                </Button>
                            </Stack>
                        )
                    }
                </Stack>
                <Stack></Stack> {/* droite */}
            </Box>
        </Stack>
    )
}

export default Category