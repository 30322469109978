import RoutesList from "./routes"

export const jobs = {
    chatoperator: 'chat_operator',
    webdev: 'web_developer',
    webdes: 'web_designer',
    teamleader: 'team_leader',
    digitalmarketer: 'digital_marketer',
    supervisor: 'supervisor',
}

export const getApplyFormRoute = jobName =>{
    switch(jobName){
        case jobs.chatoperator: return RoutesList.chatoperator
        case jobs.supervisor: return RoutesList.supervisor
        case jobs.teamleader: return RoutesList.jobsteamleader
        default: return null
    }
}

export const getTestRoute = jobName =>{
    switch (jobName){
        case jobs.chatoperator: return RoutesList.test
        case jobs.supervisor: return RoutesList.testsupervisor
        case jobs.teamleader: return RoutesList.testteamleader
        default: return null
    }
}