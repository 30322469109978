import React from "react";
import { Stack, Typography, Button, Box } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import TitleWithBg from '../../tools/TitleWithBg';
import TextWithBg from "../../tools/TextWithBg";
import Camera from "../../tools/Camera";
import FileInput from "../../tools/FileInput";
import DownloadIcon from '@mui/icons-material/Download';
import { baseURL } from "../../../helper/conf";
import PdfDisplayer from "../../tools/PdfDisplayer";

const IntegUploadDoc = ({control, errors, defaultValues, setProofIdentity, setProofAddress, setSelfie, setContract}) =>{
    const { text } = useLanguage()

    const download = (file, fileName) =>{
        fetch(file)
        .then(res => res.blob())
        .then(blob =>{
            const url = window.URL.createObjectURL(new Blob([blob]))
            const a = document.createElement('a')
            a.href = url
            const parts = file.split('.')
            a.download = `${fileName}.${parts[parts.length - 1]}`
            a.click()
            document.removeChild(a)
        })
        .catch(error => console.log(error))
    }
    const getExtension = name =>{
        const parts = name.split('.')
        return parts.pop()
    }
    
    return(
        <Stack variant="rightcontent" >
            <Stack display="flex" justifyContent="center" alignItems="center" ><TitleWithBg iconSrc='/images/Member/dashboard/7.png' children={text.integration} color="#cb6ce5" title/></Stack>
            <Stack style={{alignItems: "center", justifyContent: "center"}}><Typography variant="secondaryitalic">{text.integration1}</Typography></Stack>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Stack ><TextWithBg imgSrc="/images/Member/dashboard/integration/33.png" children={text.passportcopy}/></Stack>
                    <FileInput label={text.integ1up1} visiblePartVariant='Browser' required={false}
                            control={control} name="identityproof" error={errors.identityproof} onExportFile={setProofIdentity}/>
                    {
                        defaultValues.proof_identity ?
                            ((getExtension(defaultValues.proof_identity) == 'pdf') ?
                            <PdfDisplayer file={`${baseURL}/images/identity/${defaultValues.proof_identity}`}/>
                            : 
                            (['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp', 'tiff', 'raw', 'svg', 'heif', 'ico', 'jfif'].includes(getExtension(defaultValues.proof_identity))) ?
                                <Box component='img' alignSelf='center' sx={{height: '200px', width: '200px'}} src={`${baseURL}/images/identity/${defaultValues.proof_identity}`}/>
                            :<Typography bgcolor="grey" borderRadius="10px" padding="10px 50px" alignSelf="center" style={{width: "fit-content"}}>{defaultValues.proof_identity}</Typography>
                            )
                        : <>
                            <Stack width="70%" alignSelf="center"><Typography variant="white">{text.nypi}</Typography></Stack>
                            <Stack width='20%'/>
                        </>
                    }
                </Stack>
                <Stack gap={1}>
                    <Stack  ><TextWithBg imgSrc="/images/Member/dashboard/integration/34.png" children={text.proofofaddress}/></Stack>
                    <FileInput label={text.integ1up2} visiblePartVariant='Browser' required={false}
                            control={control} name="addressproof" error={errors.addressproof} onExportFile={setProofAddress}/>
                    {/*
                        defaultValues.proof_address ?
                        <Box className="wrap-content"  gap="25px" padding="0px 20px" >
                            <Stack width="70%" alignSelf="center"><Typography variant="white">{text.chckpa}</Typography></Stack>
                            <Stack width='20%'>
                                <Button variant="browser"  className="browser-button" onClick={() => download(`${baseURL}/images/address/${defaultValues.proof_address}`, text.pa)}>
                                    <Stack display="flex" flexDirection="row" alignItems='center' gap={1}>
                                        <Typography>{text.see}</Typography>
                                        <DownloadIcon fontSize="small"/>
                                    </Stack>
                                </Button>
                            </Stack>
                        </Box>
                        : <>
                            <Stack width="70%" alignSelf="center"><Typography variant="white">{text.nypa}</Typography></Stack>
                            <Stack width='20%'/>
                        </>
                    */}
                    {
                        defaultValues.proof_address ?
                            ((getExtension(defaultValues.proof_address) == 'pdf') ?
                            <PdfDisplayer file={`${baseURL}/images/address/${defaultValues.proof_address}`}/>
                            : 
                            (['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp', 'tiff', 'raw', 'svg', 'heif', 'ico', 'jfif'].includes(getExtension(defaultValues.proof_address))) ?
                            <Box component='img' alignSelf='center' sx={{height: '200px', width: '200px'}} src={`${baseURL}/images/address/${defaultValues.proof_address}`}/>
                            :<Typography bgcolor="grey" borderRadius="10px" padding="10px 50px" alignSelf="center" style={{width: "fit-content"}}>{defaultValues.proof_address}</Typography>
                            )
                        : <>
                            <Stack width="70%" alignSelf="center"><Typography variant="white">{text.a}</Typography></Stack>
                            <Stack width='20%'/>
                        </>
                    }
                </Stack>
                <Stack gap={1}>
                    <Stack  ><TextWithBg imgSrc="/images/Member/dashboard/integration/36.png" children={text.yourselfie}/></Stack>
                    <FileInput label={text.integ1up3} visiblePartVariant='Browser' required={false} accept="image/*"
                            control={control} name="selfie" error={errors.selfie} onExportFile={setSelfie}/>
                    <Camera camera label={text.integ1up4}/>
                    {
                        defaultValues.selfie ?
                        <Box component='img' 
                            sx={{height: '200px', width: '200px', alignSelf: 'center'}} 
                            src={`${baseURL}/images/selfie/${defaultValues.selfie}`}       
                        /> 
                        : <>
                            <Stack width="70%" alignSelf="center"><Typography variant="white">{text.nysl}</Typography></Stack>
                            <Stack width='20%'/>
                        </>
                    }
                </Stack>
                <Stack gap={1}>
                    <Stack ><TextWithBg imgSrc="/images/Member/dashboard/integration/35.png" children={text.freelance}/></Stack>
                    <Camera  label={text.integ1download}/>
                    <FileInput label={text.integ1up5} visiblePartVariant='Browser' required={false} onExportFile={setContract}
                            control={control} name="signedfreelancecontract" error={errors.signedfreelancecontract}/>
                    {/*
                        defaultValues.contract ?
                        <Box className="wrap-content"  gap="25px" padding="0px 20px" >
                            <Stack width="70%" alignSelf="center"><Typography variant="white">{text.chckc}</Typography></Stack>
                            <Stack width='20%'>
                                <Button variant="browser"  className="browser-button" onClick={() => download(`${baseURL}/files/contract/${defaultValues.contract}`, text.freelance)}>
                                    <Stack display="flex" flexDirection="row" alignItems='center' gap={1}>
                                        <Typography>{text.see}</Typography>
                                        <DownloadIcon fontSize="small"/>
                                    </Stack>
                                </Button>
                            </Stack>
                        </Box>
                        : <>
                            <Stack width="70%" alignSelf="center"><Typography variant="white">{text.nyc}</Typography></Stack>
                            <Stack width='20%'/>
                        </>
                    */}
                    {
                        defaultValues.contract ?
                            ((getExtension(defaultValues.contract) == 'pdf') ?
                            <PdfDisplayer file={`${baseURL}/files/contract/${defaultValues.contract}`}/>
                            : (['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp', 'tiff', 'raw', 'svg', 'heif', 'ico', 'jfif'].includes(getExtension(defaultValues.contract))) ?
                                <Box component='img' alignSelf='center' sx={{height: '200px', width: '200px'}} src={`${baseURL}/files/contract/${defaultValues.contract}`}/>
                            :<Typography bgcolor="grey" borderRadius="10px" padding="10px 50px" alignSelf="center" style={{width: "fit-content"}}>{defaultValues.contract}</Typography>
                            )
                        : <>
                            <Stack width="70%" alignSelf="center"><Typography variant="white">{text.nyc}</Typography></Stack>
                            <Stack width='20%'/>
                        </>
                    }

                </Stack>
            </Stack>
        </Stack>
    )
}

export default IntegUploadDoc