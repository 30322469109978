import { Stack, Typography, Button, CircularProgress } from "@mui/material";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import React, {useState, useEffect, useContext} from "react";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import {useForm} from 'react-hook-form'
import FormRow from "../tools/FormRow";
import countriesList from "../../helper/countries"
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { format } from "date-fns";
import Alert from "@mui/material/Alert";
import { addDays } from "date-fns";
import MailValidate from "../MemberArea/Auth/MailValidate";
import { mdpminlength } from "../../helper/conf";

const CnMembers = () =>{
    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        language: 'all',
    })
    const { control, handleSubmit, formState: {errors} } = useForm()
    const [error, setError] = useState('')
    const newMember = data =>{
        console.log(data)
    }

    const { text } = useLanguage()
    const { testLanguages, genders, languageName, filterLanguages } = useData()

    const [allUsers, setAllUsers] = useState([])
    const { adminId } = useContext(AuthStateContext)

    useEffect(()=>{
        const funct = async () =>{try {
            const { data } = await axiosInstance.get(`/users/candidate`, { headers: {'Authorization': `Bearer ${adminId} `} })
            setAllUsers(data)
        } catch (err){}}
        funct()  
    },[])

    const [loadingActions, setLoadingActions] = useState(0)

    const deleteMember = async id =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.delete(`/delete_user/${id}`, {headers: { 'Authorization': `Bearer ${adminId}`}})
            const newList = allUsers.filter(u => u.id !== id)
            setAllUsers(newList)
            if (status === 200) {
                setError('suppr')
            }
        } catch (error) {
            if (error.response.status === 401) {setError('dcnnctd')}
            if (error.response.status === 403) {setError('accref')}
            if (error.response.status === 404) {setError('usrntfnd')}
        }
        setLoadingActions(0)
    }

    const suspend = async id =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.post(`/suspend/${id}`, {}, {headers: { 'Authorization': `Bearer ${adminId}`}})
            const newList = [...allUsers]
            const i = allUsers.findIndex(c => c.id == id)
            newList[i].suspend = addDays(new Date(), 15)
            setAllUsers(newList)
            if (status === 200) {
                setError('susp')
            }
        } catch (error) {
            if (error.response.status === 401) {setError('dcnnctd')}
            if (error.response.status === 403) {setError('accref')}
            if (error.response.status === 404) {setError('usrntfnd')}
        }
        setLoadingActions(0)
        
    }

    const { errorsList } = useData()
    const [signUpData, setSignUpData] = useState(null)
    const [signUpLoading, setSignUpLoading] = useState(false)
    const [signUpError, setSignUpError] = useState('')
    const [phonePrefix, setPhonePrefix] = useState(`+${countriesList[0].phone}`)
    const [mailValidation, setMailValidation] = useState('')


    const verify = async data =>{
        const { language, firstname, lastname, username, mail, password, skypeid, birthdate, gender,
                 country, phone, appartroad, citydep, pobox } = data

        setSignUpLoading(true)
        setSignUpError('')

        if (password.length < mdpminlength ) {setError('mdp')} 
        else {
            
            try {
                const { status } = await axiosInstance.post(`/send_mail_code/${language}`, {
                    language_name: language,
                    first_name: firstname,
                    last_name: lastname,
                    user_name: username,
                    skype: skypeid,
                    birth_date: format(birthdate, 'yyyy-MM-dd'),
                    gender, country, pobox, password,
                    phone: phonePrefix + phone,
                    address: appartroad,
                    email: mail,
                    city: citydep,
                    role: 'candidate'
            })
            if (status == 200){
                setMailValidation(data.mail)
                setSignUpData(data)
                
            }
            } catch (error) {
                
                console.log(error)
                if (error.response.status == 400){
                    const errorName = Object.values(error.response.data.errors)[0]
                    switch (errorName){
                        case errorsList.usernamePattern.message: setSignUpError(errorsList.usernamePattern.errorLabel); break
                        case errorsList.poboxPattern.message: setSignUpError(errorsList.poboxPattern.errorLabel); break
                        case errorsList.phonePattern.message: setSignUpError(errorsList.phonePattern.errorLabel); break
                        case errorsList.skypeIdPattern.message: setSignUpError(errorsList.skypeIdPattern.errorLabel); break
                        case errorsList.usernameLength.message: setSignUpError(errorsList.usernameLength.errorLabel); break
                        case errorsList.mailUnique.message: setSignUpError(errorsList.mailUnique.errorLabel); break
                        case errorsList.usernameUnique.message: setSignUpError(errorsList.usernameUnique.errorLabel); break
                        default: setSignUpError(text.smthngwntwrng)
                    }
                }
                else setSignUpError(text.smthngwntwrng)
            }
        }
        setSignUpLoading(false)
    }

    const signUp = async () =>{
        const { language, firstname, lastname, username, mail, password, skypeid, birthdate, gender, country, phone, appartroad, citydep, pobox } = signUpData
        
        try {
            const resp = await axiosInstance.post(`/register/${language}`, {
                language_name: language,
                first_name: firstname,
                last_name: lastname,
                user_name: username,
                skype: skypeid,
                birth_date: format(birthdate, 'yyyy-MM-dd'),
                gender, country, pobox, password,
                phone: phonePrefix + phone,
                address: appartroad,
                email: mail,
                city: citydep,
                role: 'candidate'
            }, {})
            if (resp.status == 201){
                setError('usersucc')
                window.location.reload()
            }
        } catch (error) { console.log(error.response) }
        
    }

    const getError = error =>{
        
        switch (error){
            case 'succ': return <Typography variant= "success" >{text.adminsuccess}</Typography>
            case 'suppr': return <Typography variant= "success" >{text.dltd}</Typography>
            case 'accref': return <Typography variant="error">{text.accref}</Typography>
            case 'usrntfnd' : return <Typography variant="error">{text.usrntfnd}</Typography>
            case 'dcnnctd' : return <Typography variant="error">{text.dcnnctd}</Typography>
            case 'susp': return <Typography variant= "success" >{text.sspndd}</Typography>
            case 'usersucc': return <Typography variant="success">{text.success}</Typography>
            case 'mdp': return <Typography variant="error">{text.passwordlength}</Typography>
            case 'smth': return <Typography variant="error">{text.smthngwntwrng}</Typography>
            default: return <Typography variant="error">{text.smthngwntwrng}</Typography>;
        }
    }

    useEffect(() => {
        if (error) {
          const timeout = setTimeout(() => {
            setError(null);
          }, 4000);
    
          return () => clearTimeout(timeout);
        }
      }, [error]);

    return(
        <Stack variant="primarybg" spacing={3}>
            <Stack style={{display: 'flex', gap:'15px', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                <img className="card-img-resize-2" src='/images/Admin/cnmembers.png' alt=""/>
                <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#8a85af' fontWeight='bold' 
                    style={{WebkitTextStroke: "1px #2e2954"}}
                >{text.cnmembers.toUpperCase()  }</Typography>
            </Stack>
            <BigContainerAccordion 
                summaryBg="#1cb743"
                summary={text.acnm1}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter({...filter, language: l.target.value}) },
                                //{ label: text.newmembers , comp: 'TextInput', onChange: e => setFilter({...filter, newmembers: e.target.value}) },
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 1,  text: text.inscriptdate},
                                { xs: 2,  text: text.memberfirstname},
                                { xs: 2,  text: text.memberlastname},
                                { xs: 2,  text: text.email},
                                { xs: 2,  text: text.country},
                                { xs: 1,  text: text.language},
                                { xs: 1,  text: text.sspndd},
                                { xs: 1,  text: text.actions},
                            ]}/>
                            {
                                allUsers
                                .filter(m => 
                                    // Starting date
                                    ( (!filter.startingDate) || ( filter.startingDate <= new Date(m.created_at)) )
                                    // Closing date
                                    && ( (!filter.closingDate) || (new Date(m.created_at) <= filter.closingDate) )
                                    // Language
                                    && ( (filter.language == 'all') || (m.language?.name == filter.language))  
                                )
                                .map( m =>
                                    <TableRow key={m.id} columns={[
                                        { xs: 1,  text: m.created_at ? format(new Date(m.created_at), 'dd/MM/yyyy') : ''},
                                        { xs: 2,  text: m.first_name},
                                        { xs: 2,  text: m.last_name},
                                        { xs: 2,  text: m.mail},
                                        { xs: 2,  text: (countriesList.find(c => c.value === m.country)).innerHTML },
                                        { xs: 1,  text: languageName(m.language?.name)},
                                        {   xs: 1,
                                            color: '#f00' ,
                                            text: (m.suspend ? (new Date() < new Date(m.suspend)) : "") ? text.sspndd : ""
                                        },
                                        { xs: 1,  customContent: true, content: 
                                            (loadingActions == m.id) ? <CircularProgress/>
                                            :
                                                ((m.suspend ? (new Date() < new Date(m.suspend)) : "") ?
                                                    <Stack width='100%'>
                                                        <Button onClick={() => deleteMember(m.id)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                    </Stack>
                                                :
                                                    <Stack width='100%'>
                                                        <Button onClick={() => suspend(m.id)} style={{textTransform: 'capitalize', color: '#e37584', width: '100%'}}><Typography variant="boldcenter">{text.suspend}</Typography></Button>
                                                        <Button onClick={() => deleteMember(m.id)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                    </Stack>
                                                )
                                                
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error=='suppr' || error=='succ' || error=='susp') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>}
                    </Stack>
                }
            />
            <BigContainerAccordion 
                summaryBg="#01e1e9"
                summary={text.acnm2}
                details={
                    <Stack spacing={2}>
                        {/*<FormRow label={text.memberid} comp="readOnly"/>*/}
                        <FormRow label={text.language} comp="Select" options={testLanguages} control={control} name='language' error={errors.language}/>
                        <FormRow label={text.firstname} control={control} name="firstname" error={errors.firstname}/>
                        <FormRow label={text.lastname} control={control} name="lastname" error={errors.lastname}/>
                        <FormRow label={text.username} control={control} name="username" error={errors.username}/>
                        <FormRow label={text.email2} comp="Mail" control={control} name="mail" error={errors.mail}/>
                        <FormRow label={text.password} comp="Password" control={control} name="password" error={errors.password}/>
                        <FormRow label={text.skypeid} control={control} name="skypeid" error={errors.skypeid}/>
                        <FormRow label={text.birthday} control={control} name="birthdate" error={errors.birthdate} comp="DatePicker" maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))} />
                        <FormRow label={text.gndr} comp="Select" options={genders} control={control} name='gender' error={errors.gender}/>
                        <FormRow label={text.country} comp="Select" options={countriesList} control={control} name="country" error={errors.country} onChange={val => setPhonePrefix(`+${countriesList.find(c => c.value == val).phone}`)}/>
                        <FormRow label={text.phone} control={control} name="phone" error={errors.phone} prefix={phonePrefix}/>
                        <FormRow label={text.appartroad} control={control} name="appartroad" error={errors.appartroad}/>
                        <FormRow label={text.citydep} control={control} name="citydep" error={errors.citydep}/>
                        <FormRow label={text.pobox} control={control} name="pobox" error={errors.pobox}/>
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit(verify)} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    signUpLoading ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>{text.addnewmemberandsendnotification}</Typography>      
                                }
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
            {signUpError && <Alert style={{width: "fit-content", alignSelf: "center"}} severity= "error">
                {signUpError}
            </Alert>}
            {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ' || error=='newbloggersucc' || error=='usersucc') ? "success" : "error"}>
                {getError(error)}
            </Alert>}
            <MailValidate onValidated={signUp} mail={mailValidation} onClose={()=> setMailValidation("")}/>
        </Stack>
    )
}

export default CnMembers