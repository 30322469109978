import react, { useState, useEffect,useRef } from "react";
import { Controller } from "react-hook-form"
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { OutlinedInput, Stack, Typography, InputAdornment, IconButton} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import { mdpminlength } from "../../helper/conf";

const Input = ({readonly=false, type, control, idC='', name, label, error, prefix="", startAdornment=undefined, required=true, white=false, high=false, placeholder="", max=false, onChanges=()=>{}}) => {
    const { text } = useLanguage()
    const maildomain = undefined // exemple: 'gmail.com'
    const domain = (maildomain) ? maildomain.replace('.', '\\.'): undefined
    const isPassword = type === 'password'
    const [vis, setVis] = useState(!isPassword)
    const switchVis = () => setVis(vis => !vis)
    
    const [val,setVal] = useState('')
    const refP = useRef(null)
    const strGen = () => {
        let sug = ''
        const choices = 'ZQSDFGHJKWXCVBNzqdfghjkwxcvbn123456789$'
        for (let i = 1; i <= mdpminlength; i ++) sug += choices.charAt(Math.floor(Math.random() * choices.length))
        return sug
}
const [generatedStr,setGeneratedStr] = useState(strGen())
    useEffect(()=>{
        if(!val) setGeneratedStr(strGen())
        else if(setGeneratedStr) setGeneratedStr('')
    },[val])

    useEffect(()=>{
        if(idC === 'password'){
            if (!val)
                setGeneratedStr(strGen())
        } else if(generatedStr) setGeneratedStr('')
    },[idC])

    const clickSugg = (onChange) => {
        setVal(generatedStr)
        onChange(generatedStr)
        onChanges(generatedStr)
        setGeneratedStr('')
    }

    const changeV = (onChange,e) => {
        onChange(e)
        setVal(e.target.value)
        onChanges(e.target.value)
    }
    return(
        <Stack ref={refP} style={max ? {height: '100%', width: '100%', flexGrow: 1} : { height: '100%' }} position={'relative'}>
            <Typography variant={white ? "primarybg" : ""}>{label}</Typography>
            <Controller 
                control={control}
                name={name}
                defaultValue=''
                rules={ (type === 'email') ? {
                    required: "req",
                    pattern: {
                        value: new RegExp(`^[A-Z0-9._%+-]+@${domain || `[A-Z0-9.-]+\\.[A-Z]{2,}`}$`, 'i'),
                        message: "emailsyn"
                    }}
                    : {required: (required) ? "req" : false}
                }
                
                render={({ field:{onChange, ...rest} }) =>
                    <>
                        {name === 'password' && generatedStr && <Typography position={'absolute'} bottom={'-60%'} borderRadius={'5px'} style={{cursor:'pointer'}} backgroundColor={'rgb(240 240 240/.2)'}  border={'1px solid #fff'} color={'white'} minWidth={'5em'} textAlign={'center'} paddingX='4px' paddingY='2px' onClick={()=>{clickSugg(onChange)}}>{generatedStr}</Typography>}
                        <OutlinedInput id = {name} readOnly={readonly} placeholder={placeholder} multiline={high} variant={high ? 'high' : 'standard'} maxRows={3} type={(vis) ? 'text' : 'password'} {...rest} autoComplete="off"
                        onChange={e =>{
                            changeV(onChange,e)
                        }}
                        
                        value = {val}
                        startAdornment= {
                            startAdornment ? startAdornment :
                            <Typography>{prefix}</Typography>
                        }
                        endAdornment={(isPassword) ?
                                <InputAdornment position="end">
                                    <IconButton onClick={switchVis}>
                                        {(vis) ?  <VisibilityOff /> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>: <></>}
                        style={{ color: '#000', background: max ? 'transparent' : undefined, height: max ? '100%' : undefined }}/>
                     </>   
                    }
                    />
                    
            {error && <Typography variant="error" textAlign='left'>
                {
                    (error.message == "req") ? text.required : ((error.message == "emailsyn") ? text.invalidemail : text.error)
                }
                </Typography>}
        </Stack>
    )
}
export default Input
