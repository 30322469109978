import React, { useState } from "react";
import { SocialIcon } from "react-social-icons";

const SMButton = ({url="", network, inverse=false, onClick= ()=>{}}) =>{
    const [ hover, setHover ] = useState(inverse)
    const handleClick = () => (!url) ? onClick : window.open(url)
    return(
        <SocialIcon onMouseOver={()=> setHover(!inverse)} onMouseLeave={()=> setHover(inverse)}
            network={network} onClick={handleClick} bgColor={ hover ? "#4B4998" : "#FDC309"} 
            fgColor={ hover ? "#FDC309" : "#4B4998"}
            style={{width: "25px", height: "25px", padding: "3px", cursor: "pointer"}}/>
    )
}

export default SMButton