import { Stack, Typography, Grid, Button,CircularProgress } from "@mui/material";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import SnTable from "../tools/SnTable";
import SlidingTypography from "../tools/SlidingTypography";
import axiosInstance from '../../axios'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AuthStateContext } from '../state/AuthStateContext'
import { useForm } from "react-hook-form";
import FormRow from "../tools/FormRow";
import useData from "../hooks/useData";
import Alert from '@mui/material/Alert';

const Sn = () =>{
    const { text } = useLanguage()
    const [snData, setSnData] = useState([])
    const { adminId } = useContext(AuthStateContext)
    const [ editing, setEditing ] = useState(-1)
    const [ loading, setLoading ] = useState(-1)
    const [ loading1, setLoading1 ] = useState(0)
    const { testLanguages} = useData()
    const { control, handleSubmit, formState: { errors }, setValue } = useForm()
    const { control: control2, handleSubmit: handleSubmit2, formState: {errors: errors2} } = useForm()
    const [illPhoto, setIllPhoto] = useState(null)
    const [error, setError] = useState(0)
    
    const getLanguageData = lang =>{
        switch (lang){
            case "fr": return ['#004aad', text.french]
            case "en": return ['#ff3131', text.english]
            case "it": return ['#1cb743', text.italian]
            case "nl": return ['#de5b6d', text.dutch]
            case "es": return ['#f4a100', text.spanish]
            case "de": return ['#000000', text.german]
            default: return ['#000000', '']
        }
    }

    const restructure = datas =>{
        let toReturn = []
        let languages = []
        datas.forEach(sn => {
            const lang = sn.languages[0]?.name
            if (!languages.includes(lang)){
                languages.push(lang)
                const data = datas.filter(d => d.languages[0]?.name == lang)
                const [ bgcolor, label] = getLanguageData(lang)
                toReturn.push({ data, bgcolor, label })
            }
        })
        console.log(toReturn)
        return toReturn
    }
    
    const getData = async() =>{
        try {
            const { data } = await axiosInstance.get(`/social_networks`, { headers: {'Authorization': `Bearer ${adminId} `}})
            setSnData(restructure(data))
        } catch (error) {}
    }

    useEffect(()=>{getData()}, [])

    const handleEdit = data =>{
        setEditing(data.id)
        setValue("link", data.link)
        setValue("login", data.login)
        setValue("password", data.password)
    }

    const updateSn = async data =>{
        const { link, password, login } = data
        setLoading(editing)
        try {
            const { status } = await axiosInstance.post(`/social_network/update/${editing}`, { link, login, password }, { headers: {'Authorization': `Bearer ${adminId} `}})
            await getData()
        } catch (error) {}
        setEditing(-1)
        setLoading(-1)
    }

    const newSn = async data => {
        const { language, link, login, password, social_name } = data;
        setLoading1(true)
        setError('')
        const formData = new FormData();
        formData.append('language', language);
        formData.append('social_name', social_name);
        formData.append('link', link);
        formData.append('login', login);
        formData.append('password', password);
        formData.append('name', illPhoto);
        
        try {
            const { status } = await axiosInstance.post(`/social_network/add`, formData, {
                headers: {
                    'Authorization': `Bearer ${adminId}`,
                    'Content-Type': 'multipart/form-data',
                },
                transformRequest: null
            });
    
            if (status === 201) {
                setError('succ')
                window.location.reload()
            }
        } catch (error) {
            if (error.response.status === 401) setError('dcnnctd') ;
            if (error.response.status === 403) setError('accref');
            if (error.response.status !== 401 && error.response.status !== 403) setError('smth') ;
            console.log(error.response);
        }
        setLoading1(false)

    
    };

	const deleteSn = async id =>{
	        setLoading(id)
	        try {
	            await axiosInstance.delete(`/social_network/delete/${id}`, { headers: {'Authorization': `Bearer ${adminId} `}})
	            await getData()
	        } catch (error) {}
	        setLoading(-1)
	    }

    const getError = error =>{
        
        switch (error){
            case 'succ': return <Typography variant= "success" >{text.success}</Typography>
            case 'accref': return <Typography variant="error">{text.accref}</Typography>
            case 'smth': return <Typography variant="error">{text.smthngwntwrng}</Typography>
            case 'dcnnctd': return <Typography variant="error">{text.dcnnctd}</Typography>
            default: return <Typography variant="error">{text.smthngwntwrng}</Typography>;
        }
    }

    useEffect(() => {
        if (error) {
          const timeout = setTimeout(() => {
            setError(null);
          }, 4000);
    
          return () => clearTimeout(timeout);
        }
      }, [error]);

    return(
        <Stack variant="primarybg" spacing={3}>
            <Stack style={{display: 'flex', gap:'15px', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                <img className="card-img-resize-2" src='/images/Admin/sn.png'/>
                <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#90d3fb' fontWeight='bold' 
                    style={{WebkitTextStroke: "1px #3784d0"}}
                >{text.sn.toUpperCase()  }</Typography>
            </Stack>
            <BigContainerAccordion 
                summaryBg="#ff5757"
                summary={text.asn1}
                details={
                    snData.map((sn, i) => <BigContainerAccordion
                        key={`sn${i}`}
                        summaryBg={sn.bgcolor}
                        summary={sn.label}
                        details={
                            <Stack className="padding-resize-tlint" spacing={2} >
                        <Grid container style={{display: 'flex', justifyContent:"space-between"}}  >
                            <Grid item xs={2} style={{backgroundColor: '#ffebcd', minHeight: '100px',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <SlidingTypography textAlign="center" fast>
                                    <Typography className="style-font-4" variant="table-head-text" style={{height: '100%'}}>
                                        {text.sn}
                                    </Typography>
                                </SlidingTypography>
                            </Grid>
                            <Grid item xs={3.5} style={{backgroundColor: '#ffebcd', minHeight: '100px',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <SlidingTypography textAlign="center" fast>
                                    <Typography className="style-font-4" variant="table-head-text" style={{height: '100%'}}>
                                        {text.link}
                                    </Typography>
                                </SlidingTypography>
                            </Grid>
                            <Grid item xs={3} style={{backgroundColor: '#ffebcd', minHeight: '100px',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <SlidingTypography textAlign="center" fast>
                                    <Typography className="style-font-4" variant="table-head-text" style={{height: '100%'}}>
                                        {text.login}
                                    </Typography>
                                </SlidingTypography>
                            </Grid>
                            <Grid item xs={2} style={{backgroundColor: '#ffebcd', minHeight: '100px',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <SlidingTypography textAlign="center" fast>
                                    <Typography className="style-font-4" variant="table-head-text" style={{height: '100%'}}>
                                        {text.password}
                                    </Typography>
                                </SlidingTypography>
                            </Grid>
                            <Grid item xs={1} style={{backgroundColor: '#ffebcd', minHeight: '100px',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <SlidingTypography textAlign="center" fast>
                                    <Typography className="style-font-4" variant="table-head-text" style={{height: '100%'}}>
                                        {text.actions}
                                    </Typography>
                                </SlidingTypography>
                            </Grid>
                        </Grid>
                        {
                            sn.data?.map((d, i) =>
                            <SnTable key={`snitem${i}`} control={control} onSubmit={handleSubmit(updateSn)}
                                onEdit={handleEdit} onDelete={deleteSn} editing={editing} sn={d.social_name} errors={errors}
                                data={{id: d.id, link: d.link, login: d.login, password: d.password, name: d.name}}
                                loading={loading}/>)
                        }
                        {/*<IconButton variant="nav" style={{width: "fit-content", alignSelf: 'flex-end'}}><AddCircleIcon/></IconButton>*/}
                    </Stack>
                        }
                    />)                 
                }
            />
            <BigContainerAccordion 
                summaryBg="#f4a100"
                summary={text.asn2}
                details={
                    <Stack spacing={2}>
                        <FormRow comp="Select" options={testLanguages} label={text.language} control={control2} name='language' error={errors2.language} />
                        <FormRow label={text.name} control={control2} name='social_name' error={errors2.social_name}/>
                        <FormRow label={text.link} control={control2} name='link' error={errors2.link}/>
                        <FormRow label={text.login} control={control2} name='login' error={errors2.login}/>
                        <FormRow label={text.password} comp="Password" control={control2} name='password' error={errors2.password}/>
                        <FormRow comp="Image" label={text.sn} envImg={setIllPhoto} control={control2} name='sn' error={errors2.sn}/>
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit2(newSn)} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    loading1 ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>{text.asn2}</Typography>      
                                }
                            </Button>
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>} 
                    </Stack>
                }
            />
        </Stack>
    )
}

export default Sn