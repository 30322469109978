import React, { useState, useContext, useEffect, useMemo } from "react";
import { Typography, Stack, Divider, Button, TextField, CircularProgress, Alert } from "@mui/material";
import useLanguage from '../hooks/useLanguage'
import { testteamlead, groupteamlead } from "../../helper/testteamlead";
import useData from "../hooks/useData";
import { jwtDecode } from "jwt-decode";
import { AuthStateContext } from "../state/AuthStateContext";
import axiosInstance from "../../axios";
import { jobs } from "../../helper/jobs";
import { useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";
import { DashboardStateContext } from "../state/DashboardStateContext";

const TestFormTL = () => {
    const { text, currentLanguage } = useLanguage()

    const [group, setGroup] = useState(-1)
    const [response, setResponse] = useState([])
    const [lastInputCount, setLastInputCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const { testLanguages } = useData()
    const [ userInfo, setUserInfo] = useState({})
    const [ lang, setLang ] = useState('en')
    const { candidateId } = useContext(AuthStateContext)
    const { id } = jwtDecode(candidateId)
    const navigate = useNavigate()
    useEffect(()=>{
        const func = async () =>{
            try {
                const { status, data: {user_name, language} } = await axiosInstance.get(`/test_info/${id}/${jobs.teamleader}`, { headers: { 'Authorization' : `Bearer ${candidateId}` } })
                if (status == 200) setUserInfo({user_name, language})
                if (language) setLang(language)
            } catch (error) {}
        }
        func()
    }, [])

    const reinitInputs = count => {
        for (let i = 1; i <= count; i++){
            const input = document.querySelector(`#answer-input-${i}`)
            input.value = ""
        }
    }

    const reinitErrors = count =>{
        for (let i = 1; i <= count; i++){
            const errorTypography = document.querySelector(`#error-displayer-${i}`)
            errorTypography.setAttribute('class', 'display-none')
        }
    }

    const allQuestionsAnswered = count =>{
        let allAnswered = true
        for (let i = 1; i <= count; i++){
            const input = document.querySelector(`#answer-input-${i}`)
            if (!input.value.length){
                const errorTypography = document.querySelector(`#error-displayer-${i}`)
                errorTypography.setAttribute('class', 'display-block')
                allAnswered = false
            }
        }
        return allAnswered
    }

    const inputsCount = () =>{
        let i = 1
        let count = 0
        let input = document.querySelector(`#answer-input-${i++}`)
        while (input){
            count++
            input = document.querySelector(`#answer-input-${i++}`)
        }
        return count
    }
    
    const addValues = count =>{
        const newResponse = []
        for (let i = 1; i <= count; i++){
            const input = document.querySelector(`#answer-input-${i}`)
            newResponse.push(input.value)
        }
        setResponse(prevResponse => [...prevResponse, ...newResponse])
    }

    const removeValues = count =>{
        const updatedResponse = [...response]
        for (let i = 0; i<count; i++) updatedResponse.pop()
        setResponse(updatedResponse)
    }

    const back = () =>{
        const count = inputsCount()
        reinitErrors(count)
        reinitInputs(count)
        setGroup(g => g -1)
        removeValues(lastInputCount)
    }
    const next = () =>{
        const count = inputsCount()
        reinitErrors(count)
        if (allQuestionsAnswered(count)){
            addValues(count)
            reinitInputs(count)
            setLastInputCount(count)
            setGroup(g => g +1)
        } 
    }
    const { setSelectedButton } = useContext(DashboardStateContext)
    const sendTestResp = async () =>{
        setLoading(true)
        try {
            const { status } = await axiosInstance.post(`/job/${jobs.teamleader}/test/${id}/${currentLanguage}`, response, { headers: { 'Authorization' : `Bearer ${candidateId}` } })
            if (status == 200) {
                setSelectedButton(4)
                navigate(RoutesList.dashboard)
            }
        } catch (error) {setError(text.smthngwntwrng)}
        setLoading(false)
    }
    const respLength = useMemo(() =>{
        let count = 0
        testteamlead(lang).forEach(g => g.forEach(s => count++))
        return count
    }, [testteamlead(lang)])
    useEffect(()=>{if (response.length == respLength) sendTestResp() }, [response])

    return(
        <Stack onLoad={() => window.scrollTo(0, 0)}>
            <Stack style={{position: "relative"}}>
                <Stack alignItems='center' style={{overflow: 'hidden'}}>
                    <img src={ "/images/test/Test-Team-Leader.webp"} style={{width: '100%'}}/>
                </Stack>
                <Stack style={{ width:"70%", position: 'absolute', top: "40%", transform: "translateY(-50%)"}}>
                    <Typography className="text-resize" variant="primarytitle" fontWeight='bold'>{text.tltest}</Typography>
                </Stack>
            </Stack>
            <Stack alignItems='center' margin="20px 0">
                <Stack className="test-form" spacing={2}>
                    <Stack variant="bordered" style={{overflow: 'hidden'}} spacing={3}>
                        <Divider variant="primarybold"/>
                        {
                            loading ? 
                            <Stack alignItems='center' paddingBottom='15px'>
                                <Typography variant="h2" width='95%' fontWeight="bold">{text.thanktest1} {userInfo.user_name ? ', ' + (userInfo.user_name)[0].toUpperCase() + (userInfo.user_name).slice(1) : ''}</Typography>
                            </Stack>
                            : <Stack spacing={3} alignItems="center">
                                <Stack spacing={1} width='95%'>
                                    <Typography style={{width: '95%'}} variant="h2" textAlign="left" fontWeight="bold">{text.tltesttitle}</Typography>
                                    <Stack>
                                        <Typography fontWeight='bold' alignSelf='left'>{text.username} : {userInfo.user_name ? (userInfo.user_name)[0].toUpperCase() + (userInfo.user_name).slice(1) : ''}</Typography>
                                        <Typography fontWeight='bold' alignSelf='left'>{text.language} : {userInfo.language ? testLanguages.find(l => l.value == userInfo.language).innerHTML : ''}</Typography>
                                    </Stack>
                                </Stack>              
                                {
                                    (group == -1) ?
                                    <>
                                        <Typography style={{width: '95%'}}>"{text.tltestint1}"</Typography>
                                        <Typography style={{width: '95%'}}>{text.cotestint2}</Typography>
                                        <Typography style={{width: '95%'}}>{text.goodluck}</Typography>
                                    </>
                                    : <></>
                                }
                            </Stack>
                        }
                    </Stack>

                    {
                        ( [groupteamlead(lang).length, -1].includes(group)) ? <></>
                        : <Stack spacing={3}>{
                            testteamlead(lang)[group].map((v, index1) => 
                            <Stack variant="bordered" key={'k' + index1}>
                                {
                                    (index1 == 0) ?
                                    <Stack variant="primarybg" alignItems='center' borderRadius='10px 10px 0 0'>
                                        <Typography width='95%' variant="primarybg" fontWeight='bold' padding='10px 0'>
                                            {groupteamlead(lang)[group] || ""}
                                        </Typography>
                                    </Stack>
                                    : <></>
                                }
                                <Stack alignItems='center' padding="20px 0" spacing={2}>
                                    <div style={{width: '95%'}}>    
                                        { v.split(':').map((vv, index2) => 
                                            <span
                                                key={'v'+ index1 + index2}
                                                style={{fontWeight: (index2==0) ? 'bold' : 'regular'}}
                                            >
                                                {`${vv}${(index2==0) ? ': ' : ''}`}
                                            </span>
                                        )}
                                    </div>
                                    <TextField id={`answer-input-${index1 + 1}`} style={{ width: '95%' }} maxRows={3}
                                        placeholder={text.youranswer} multiline/>
                                    <Typography id={`error-displayer-${index1 + 1}`} className="display-none"
                                        textAlign="left" style={{ width: "95%", color: '#f00'}}>{text.required}</Typography>
                                </Stack>
                            </Stack>
                            )
                        }</Stack>
                    }
                    {error && <Alert severity={(error== 'succ') ? "success" : "error"}>
                            { error }
                        </Alert>}
                    {
                        (Boolean(userInfo.language)) ?
                        <Stack display='flex' flexDirection='row' gap='12px'>
                            {(group >= 0 ) ? <Button variant="testform" disabled={loading} onClick={back}>{text.back}</Button>: <></>}
                            <Button variant="testform" disabled={loading} onClick={next}>{ loading ? <CircularProgress color="inherit" size="24px"/> : ((group < groupteamlead(lang).length -1) ? text.next : text.finish)}</Button>
                        </Stack>
                        : <></>
                    }
                </Stack>
            </Stack>
        </Stack>

    )
} 

export default TestFormTL;