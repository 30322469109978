import { IconButton, MenuItem, Typography, Menu, Box } from "@mui/material";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useLanguage from "../hooks/useLanguage";
import RoutesList from "../../helper/routes";
import { useNavigate } from "react-router-dom";
import NavItem from "../tools/NavItem";

const DropDownHeader = () =>{
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const navigate = useNavigate()
    const { text } = useLanguage()
    return(
        <Box className="dropdown-menu">
            <IconButton style={{color: anchorEl ? '#FDC309': '#fff'}} onClick={event =>  setAnchorEl(anchorEl ? null : event.currentTarget) }>{anchorEl ? <CloseIcon/> : <MenuIcon/>}</IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}>
                <MenuItem variant="nav"><NavItem to="home" menuItem>{text.home}</NavItem></MenuItem>
                <MenuItem variant="nav"><NavItem to="missions" menuItem>{text.ourmissions}</NavItem></MenuItem>
                <MenuItem variant="nav"><NavItem to="compvalues" menuItem>{text.ourcompany}</NavItem></MenuItem>
                <MenuItem variant="nav"><NavItem to="advantages" menuItem>{text.ouradvantages}</NavItem></MenuItem>
                <MenuItem variant="nav" onClick={()=>navigate(RoutesList.blog)}><Typography style={{margin: "0 15px"}}>{text.blog}</Typography></MenuItem>
                <MenuItem variant="nav" onClick={() => navigate(RoutesList.jobs)}><Typography style={{margin: "0 15px"}}>{text.jobs}</Typography></MenuItem>
                
            </Menu>
        </Box>
    )
}

export default DropDownHeader