import { Stack, Typography, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useLanguage from "../hooks/useLanguage";

const preloadImages = urls =>{
    urls.forEach(url =>{
        const img = new Image()
        img.src = url
    })
}

const JobOfferCard = ({imgFileName, IconFileName, hiring, jobName, description, onEnter}) =>{
    const baseUrl = "/images/jobs/"
    const iconsBaseUrl = baseUrl + "icons/"
    const [hover, setHover] = useState(false)
    const { text } = useLanguage()

    const descriptionTypography = useRef(null)
    const [descHeight, setDescHeight] = useState(0)
    const [imageSrc, setImageSrc] = useState('')

    const icon = iconsBaseUrl + IconFileName + ".svg"
    const hoverIcon = iconsBaseUrl + IconFileName + "_secondary.svg"

    useEffect(()=> {
        setDescHeight(hover ? 120  + descriptionTypography.current.offsetHeight : 80)
        setImageSrc(hover ? hoverIcon : icon)
    } , [hover])
    useEffect(()=> preloadImages([icon, hoverIcon]), [])

    return(
        <Stack style={{flexBasis: "28%"}} position='relative' alignItems="center" className="job-card-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <img src={baseUrl+imgFileName} alt="" style={{width: '100%'}}/>
            <Stack variant="jobcard" className="job-card" style={{'--shown-height': `${descHeight}px`}}>
                <Stack style={{flexDirection:'row', display:'flex', justifyContent:'center', alignItems:'center', minHeight: '80px', maxHeight: '80px'}}>
                    <img src={imageSrc} alt="" height='40px'/>
                    <Typography variant={hover ? "secondaryh3" : "primaryh3"}>{jobName}</Typography>
                </Stack>
                <Stack alignItems='center'>
                    <Typography textAlign="center" width="80%" ref={descriptionTypography}>{description}</Typography>
                </Stack>
                {
                    hover ?
                        <Button 
                                variant={hiring ? "hiring" : "appclosed"}
                                style={{textTransform: 'capitalize', position: 'absolute', top: "100%", left: "50%", transform: "translate(-50%, -50%)"}}
                                onClick={hiring ? onEnter : () =>{}}
                            >
                                {hiring ? text.detailsandapply : text.appclosed}
                        </Button>
                    : <></>
                }
            </Stack>
            <Typography variant={hiring ? "green" : "red"} className="slideXY2" style={{position: 'absolute', right: '1%', top: '6.5%'}}>
                {hiring ? text.hirenow : text.closed}
            </Typography>
        </Stack>
    )
}

export default JobOfferCard