import React, { useContext } from "react";
import { Stack } from "@mui/material";
import About from './TeamLeader/About2'
import ApplyForm from "./TeamLeader/ApplyForm7";
import ApplySteps from "./TeamLeader/ApplySteps6";
import IdentityCheck from "./TeamLeader/IdentityCheck5";
import Introduction from "./TeamLeader/Introduction1";
import LeadershipSkills from './TeamLeader/LeadershipSkills3'
import Requirements from "./TeamLeader/Requirements4";
import { AuthStateContext } from "../state/AuthStateContext";

const TeamLeader = () =>{
    const { candidateId } = useContext(AuthStateContext)
    if (candidateId){
        const dest = document.getElementById('apply-tl')
        if (dest) dest.scrollIntoView()
    }
    return(
        <Stack>
            <Introduction/>
            <About/>
            <LeadershipSkills/>
            <Requirements/>
            <IdentityCheck/>
            <ApplySteps/>
            <ApplyForm/>
        </Stack>
    )
}

export default TeamLeader