const allSituations = [
    [ // fr
        "Bonjour belle demoiselle, quand j’ai vu ta photo, j’ai tout de suite craqué sur toi. Je ne veux pas m’emballer, mais je ne dirai pas non à un brin de causette avec toi, histoire de voir si le courant passe entre nous. Qu’est-ce que t’en pense ? Moi, c’est Patrick, j’ai 40 ans et j’ai un gosse de 18 ans avec qui je m’entends très bien. Pour le moment, je ne cherche rien de particulier ici, mais je ne suis pas contre une relation sans prise de tête pour commencer. Tu es dispo ? C’est comment ton genre de mec ? Bisous" ,
        "Ce n’est pas que je m’ennuie avec toi, mais je n’ai presque plus de crédits et ça va être chaud de continuer à discuter ici. Tu t’imagines sûrement que c’est beaucoup trop tôt et je te comprends, mais je ne t’oblige à rien. Mais pour te prouver ma bonne foi et mon envie de te découvrir davantage, je te laisse mes contacts XXXXXXX tu peux m’appeler quand tu veux, je réponds toujours, même quand je suis au boulot. A plus ma belle, bisous !",
        "Ma chérie, rien qu’à l’idée de te serrer dans mes bras, je sens la chaleur qui monte tout doucement en moi. Ça me fait vibrer de partout et je bande comme un taureau en t’écrivant ces quelques mots. Tu ne sais pas à quel point, j’ai envie de te faire l’amour. Je veux me blottir contre toi, t’embrasser, sentir ton parfum, te caresser de partout avant de fouiller ton entre-jambe. Je lècherai ton minou pour que tu prennes ton pied et quand je vois que tu n’en peux plus, je dresserai ma queue, j’écarte tes jambes et je l’enfonce tout doucement, cm par cm afin que tu puisses goûter à ce plaisir qui nous envahit tous les deux. On fera l’amour tous les deux jusqu’au petit matin ma chérie, tu verras, ça va te plaire !",
        "C’est ça, prends moi pour un con ! Je te rappelle que ça fait déjà 4 mois que l’on discute ici sur ce site et tout ce que j’ai pour le moment, ce sont des messages pleins de promesses et vide d’action. Tu trouves toujours des excuses pour ne pas me voir et crois-moi, je n’en peux plus. Je ne suis pas là pour jouer avec les sentiments, je suis quelqu’un de bien posé dans ma tête et je veux refaire ma vie avec une femme qui sait aussi ce qu’elle veut. Pas avec une gamine qui refuse de franchir le pas, mais qui préfère rester cachée derrière son écran. Décide-toi à la fin. Je vais franchement finir par perdre patience. ",
        "Je te l’ai déjà dit, je travaille dans la manutention industrielle. Je m’occupe de l’entretien des grandes machines de production. Je veille à ce que tout se passe bien et grâce à mes connaissances pointues en mécanique, j’arrive à dénicher ce qui ne va pas afin de résoudre le souci le plus vite possible. En dehors de mes heures de boulot, j’aime aller à la campagne, histoire de me rafraîchir les idées. J’aime aussi passer du temps avec mes amis pour aller en boite, à la plage, au soleil et je fais aussi de temps en temps de l’escalade pour me maintenir en forme. Et toi alors ? On s’est déjà échangé 10 messages mais jusque-là, je n’ai pas la moindre information sur toi, ni ce que tu aimes et ce que tu détestes.",
        "La semaine prochaine, je vais partir en Écosse rejoindre ma sœur. Ça fait déjà pas mal de temps qu’on ne s’est pas vu tous les deux et je pense que ça nous ferait du bien à tous les deux de passer un peu de temps ensemble. Quand je reviendrai, j’espère que tu auras pris le temps de réfléchir à ma proposition et que tu ne me laisseras plus tomber comme la dernière fois. Et le pire dans tout cela, c’est que j’avais déjà réservé un bon restaurant pour toi et moi, mais comme tu ne m’as plus répondu, j’ai tout annulé à la dernière minute. Mais bon, je ne t’en veux pas. J’espère seulement que tu réalises à quel point tu comptes énormément pour moi",
        "Coucou chéri, ça me fait tellement plaisir de voir que tu penses beaucoup à moi. Ton message m’a vraiment plu tu sais et je ne peux qu’être enchanté d’un tel encouragement venant de toi. Je veux que tu saches mon cœur, que tu es le seul homme qui m’a fait ressentir que je suis quelqu’un à ses yeux. Tu occupes une place très importante dans ma vie et je peux t’assurer que pour rien au monde, je ne te laisserai tomber mon amour. Tu sais quoi ? J’ai rêvé de toi cette nuit J J’ai rêvé qu’on a fait une balade romantique au bord de la plage et on a fini ensuite, par faire l’amour comme des bêtes. Dommage que ce soit juste un rêve ! Tu penses que ça va se réaliser très bientôt ? En tout cas, je ne perds pas espoir et j’attends impatiemment que ce moment arrive mon chéri. Ce sera le plus beau jour de ma vie. Je t’embrasse et prends soin de toi mon cœur, je vais aller au boulot maintenant. Bisous. NB : c’est une conversation gay",
        "Isabelle, tu es une très belle femme, qui a surement aussi du caractère et j’aime ça. J’ai lu ta description et cela convient parfaitement à mes recherches. Tu cherches une aventure sexuelle pimentée avec un homme d’expérience ? Ce n’est pas pour me vanter mais du haut de mes 45 ans, j’ai connu pas mal de tigresses dans ma vie et crois-moi, je leur ai fait toutes découvrir ce qu’est le vrai plaisir. Ton cul est parfait sur la photo, tes gros seins inspirent le désir et je ne peux qu’être émerveillé par ton joli visage qui cache sûrement un caractère bien cochon.. humm Il me tarde de t’avoir dans mon lit. J’espère seulement que tu es assez coquine pour t’occuper de ma grosse bite qui ne cherche qu’à te combler par tous les trous, ma belle. Contacte-moi sur Whatsapp XXXXXXXXXX ce sera beaucoup plus simple. NB: Il ne faut jamais dire que les coordonnées (mail, numéro, fb,...etc) sont censurés par le site",
        "C’est bien joli tout ça, mais écoute, je ne suis pas là pour parler de ma vie et dépenser mes sous pour rien. Si tu veux vraiment que l’on fasse connaissance, je te propose un rendez-vous ce soir devant la gare à 19h. Je porterai une chemise blanche, tu me reconnaîtras facilement. On prendra un verre ou un resto peu importe dans un lieu qui te plait, ça te va ? Mais avant, j'aimerais que tu m’appelles sur mon numéro xxxxxx pour pas que je me déplace pour rien.",
        "Bon là, tu dépasses les bornes ma belle ! J’ai été très patient avec toi, mais là t’abuses et je crois que tu me prends vraiment pour un con ! Je ne sais pas qui est ce Luc dont tu me parles, mais t’es même pas foutu de te souvenir de mon prénom. Je t’ai dit aussi que mes parents sont morts et que je n’ai ni frères, ni sœur et tu oses encore me demander comment se porte ma famille ? Je m’en doutais, ce site pourri n’est bourré que de faux profils. Ça m’apprendra à ne pas écouter les conseils de mes collègues. J’espère que tu t’es bien rempli les poches durant tout ce temps qu’on a parlé tous les deux. Tu t’es bien foutu de moi salope, bravo ! Je vais porter plainte et crois-moi, tu entendras toujours parler de moi, jusqu’à ce que cette affaire soit réglée. J’espère te retrouver derrière les barreaux ! Salut et bon vent, qui que tu sois !...Ah oui, pour info, mon prénom est Kevin et tâche de noter cela quelque part pour que la relève n’oublie plus jamais le prénom des autres pigeons qui seront pris dans vos filets !",
        "Envoyez un 1er POKE au client qui vient tout juste de se connecter. Il n’a encore rien mis sur son profil, ni même une photo. A vous d’engager la conversation en inventant votre propre personnage !",
        "Ce même client vous a répondu avec un simple flirt. Réagissez et donnez le maximum d’intérêt à votre réponse",
        "Relance de niveau 1. Le dernier message du client était: \"D'accord chérie, je dois vraiment y aller maintenant et je ne serai pas de retour avant ce soir, passe une bonne journée, bisous xxxxx\” Il est déjà tard et le client n’est pas revenu. Répondez",
        "Relance de niveau 2. Toujours aucun signe de ce même client après 2 jours suite à votre relance de niveau 1. Répondez et usez de votre imagination pour enfin relancer cette conversation",
        "Relance de niveau 3. Visiblement, ce client a décidé de couper définitivement le pont avec le profil, mais rien n’est encore perdu. Il va falloir mettre les bouchées doubles ! Répondez et n’hésitez pas à être agressif(ve) dans vos propositions. C’est votre dernière chance !"
    
    ], [// en
        "Hello beautiful lady, when I saw your photo, I immediately fell for you. I don't want to get ahead of myself, but I wouldn't say no to a chat with you, just to see if we're on the same wavelength. What do you think? My name's Patrick, I'm 40 and I've got an 18-year-old kid with whom I get on really well. At the moment, I'm not looking for anything in particular here, but I'm not averse to a no-holds-barred relationship to start with. Are you available? What's your type like? Kisses",
        "It's not that I'm bored with you, but I'm almost out of credits and it's going to be hot to keep talking here. You're probably thinking it's much too soon, and I understand, but I'm not forcing you to do anything. But to prove my good faith and my desire to get to know you better, I'm leaving you my XXXXXXX contacts: you can call me whenever you like, I always answer, even when I'm at work. See you later, love!",
        "My darling, just the thought of holding you in my arms makes me feel the heat rising slowly inside me. It makes me tingle all over and I'm getting hard as a bull writing these words to you. You don't know how much I want to make love to you. I want to snuggle up to you, kiss you, smell your perfume, caress you all over before delving into your crotch. I'll lick your pussy so you can get off on it, and when I see you can't take it any more, I'll erect my cock, spread your legs and push it in ever so gently, cm by cm so you can taste the pleasure that's coming over us both. We'll make love together until dawn, my darling, you'll see, you'll love it!",
        "That's it, take me for a fool! Let me remind you that we've been chatting here on this site for 4 months already and all I've got so far are messages full of promises and empty of action. You're always finding excuses not to see me and believe me, I can't take it anymore. I'm not here to play with feelings, I'm a very settled person in my own mind and I want to rebuild my life with a woman who also knows what she wants. Not with some kid who refuses to take the plunge and prefers to hide behind her screen. Make up your mind eventually. Frankly, I'm running out of patience. ",
        "I've already told you, I work in industrial materials handling. I look after the maintenance of large production machines. I make sure that everything runs smoothly, and thanks to my in-depth knowledge of mechanics, I'm able to find out what's wrong and fix it as quickly as possible. When I'm not working, I like to go to the countryside to refresh my ideas. I also like to spend time with my friends at clubs, on the beach and in the sun, and I occasionally go rock-climbing to keep fit. What about you? We've already exchanged 10 messages, but so far I haven't got a single piece of information about you, your likes and dislikes.",
        "Next week, I'm going to Scotland to join my sister. It's been quite a while since we've seen each other and I think it would do us both good to spend some time together. When I come back, I hope you'll have taken the time to think about my proposal and won't let me down like you did last time. And the worst thing is, I'd already booked a nice restaurant for you and me, but when you didn't reply, I cancelled it at the last minute. But hey, I don't blame you. I just hope you realize how much you mean to me.",
        "Hello darling, it makes me so happy to see that you think so much of me. Your message really pleased me you know and I can only be delighted with such encouragement coming from you. I want you to know, sweetheart, that you're the only man who's ever made me feel like I'm somebody in his eyes. You occupy a very important place in my life and I can assure you that for nothing in the world will I let you down, my love. Do you know something? I dreamt about you last night J I dreamt we went for a romantic walk by the beach and then ended up making love like animals. Too bad it was just a dream! Do you think it'll come true any time soon? In any case, I'm not giving up hope and I can't wait for this moment to arrive, my darling. It's going to be the happiest day of my life. Kisses and take care sweetheart, I'm off to work now. Love you.  NB: this is a gay conversation",
        "Isabelle, you're a very beautiful woman, who certainly also has character and I like that. I've read your description and it suits my search perfectly. Are you looking for a spicy sexual adventure with an experienced man? I don't mean to brag, but in my 45 years I've had quite a few tigresses in my life, and believe me, I've introduced them all to real pleasure. Your ass looks perfect in the photo, your big breasts inspire desire and I can't help but marvel at your pretty face, which surely hides a very dirty temper... I can't wait to have you in my bed. I only hope you're naughty enough to take care of my big cock that's only looking to fill you up through every hole, my beautiful. Contact me on Whatsapp XXXXXXXXXX it'll be much easier. NB: Never say that your contact details (e-mail, number, fb, etc.) are censored by the site.",
        "That's all very well, but listen, I'm not here to talk about my life and spend my money for nothing. If you really want to get to know me, I suggest we meet tonight in front of the station at 7pm. I'll be wearing a white shirt, so you'll recognize me easily. We'll have a drink or a bite to eat somewhere you like, how's that? But first, I'd like you to call me on my xxxxxx number so that I don't have to go out of my way for nothing.",
        "Now you've gone too far, sweetheart! I've been very patient with you, but now you're really taking me for a fool! I don't know who this Luc is you're talking about, but you can't even remember my first name. I also told you that my parents are dead and I have no brothers or sisters, and you still dare to ask me how my family is doing? As I suspected, this rotten site is full of fake profiles. That'll teach me not to take advice from my colleagues. I hope you've been lining your pockets this whole time we've been talking. You've made a fool of me bitch, bravo! I'm going to press charges, and believe me, you'll be hearing from me again and again, until this matter is settled. I hope to see you behind bars! Goodbye and Godspeed, whoever you are!...Oh yes, just so you know, my first name is Kevin and make sure you write that down somewhere so the next generation never forgets the first names of the other suckers who get caught in your net!",
        "Send a 1st POKE to the customer who has just logged in. He hasn't put anything on his profile yet, not even a photo. It's up to you to start the conversation by inventing your own character!",
        "That same customer responded with a simple flirtation. React and make your response as interesting as possible",
        "Level 1 relaunch. The customer's last message was: \"Okay honey, I really have to go now and I won't be back until tonight, have a good day, kisses xxxxx\" It's already late and the customer hasn't returned. Answer",
        "Level 2 relaunch. Still no sign of the same customer after 2 days following your Level 1 follow-up. Respond and use your imagination to get the conversation going again.",
        "Level 3 relaunch. Clearly, this customer has decided to cut his ties with the profile once and for all, but nothing is lost yet. You'll have to double your efforts! Reply and don't hesitate to be aggressive in your proposals. This is your last chance!"

    ],[
        // it
        "Ciao bella signora, quando ho visto la tua foto mi sono subito innamorato di te. Non voglio lasciarmi trasportare, ma non direi di no a una breve chiacchierata con te, solo per vedere se c'è la giusta chimica. Allora, cosa ne pensi? Mi chiamo Patrick, ho 40 anni e ho un ragazzo di 18 anni con cui vado molto d'accordo. Al momento non sto cercando nulla di particolare, ma non sono contrario a una relazione senza esclusione di colpi per iniziare. Sei disponibile? Qual è il tuo tipo? Baci",
        "Non è che mi annoi con voi, ma ho quasi esaurito i crediti e mi scoccia continuare a chiacchierare qui. Probabilmente starai pensando che è troppo presto, e lo capisco, ma non ti sto obbligando a fare nulla. Ma per dimostrare la mia buona fede e il mio desiderio di conoscerti meglio, ti lascio i miei contatti XXXXXXX. Puoi chiamarmi quando vuoi, rispondo sempre, anche quando sono al lavoro. A dopo, amore!",
        "Tesoro mio, il solo pensiero di stringerti tra le mie braccia mi fa sentire il calore che sale lentamente dentro di me. Ho un formicolio dappertutto e sto diventando duro come un toro mentre ti scrivo queste parole. Non sai quanto desidero fare l'amore con te. Voglio accoccolarmi a te, baciarti, sentire il tuo profumo, accarezzarti dappertutto prima di scavare nel tuo inguine. Ti leccherò la figa in modo che tu possa eccitarti e quando vedrò che non ce la fai più, metterò il mio cazzo in alto, ti allargherò le gambe e lo spingerò dentro delicatamente, centimetro dopo centimetro, in modo che tu possa assaporare il piacere che sta venendo su entrambi. Faremo l'amore insieme fino alle prime ore del mattino, tesoro mio, vedrai, ti piacerà!",
        "Ecco, prendetemi per pazzo! Ti ricordo che sono 4 mesi che chattiamo su questo sito e tutto quello che ho ricevuto finora sono messaggi pieni di promesse e nessuna azione. Trovi sempre scuse per non vedermi e, credimi, non ne posso più. Non sono qui per giocare con i sentimenti, sono una persona lucida e voglio ricostruire la mia vita con una donna che sappia anche lei cosa vuole. Non con una ragazzina che si rifiuta di fare il grande passo e preferisce nascondersi dietro il suo schermo. Deciditi prima o poi. Sinceramente sto per perdere la pazienza. ",
        "Le ho già detto che lavoro nella movimentazione industriale. Mi occupo della manutenzione di grandi macchine di produzione. Mi assicuro che tutto funzioni senza intoppi e, grazie alle mie approfondite conoscenze di meccanica, sono in grado di scoprire cosa non va e di ripararlo il più rapidamente possibile. Al di fuori dell'orario di lavoro, mi piace andare in campagna per schiarirmi le idee. Mi piace anche passare il tempo con i miei amici in discoteca, in spiaggia e al sole, e ogni tanto vado a scalare per tenermi in forma. E tu? Ci siamo già scambiati 10 messaggi, ma finora non ho avuto la minima informazione su di te, su ciò che ti piace e su ciò che odi.",
        "La prossima settimana andrò in Scozia per raggiungere mia sorella. È passato un po' di tempo dall'ultima volta che ci siamo visti e penso che farebbe bene a entrambi passare un po' di tempo insieme. Quando tornerò, spero che tu abbia avuto il tempo di pensare alla mia proposta e che non mi deluderai come hai fatto l'ultima volta. E la cosa peggiore di tutto questo è che avevo già prenotato un bel ristorante per noi due, ma quando non mi hai richiamato, ho cancellato tutto all'ultimo minuto. Ma non ti biasimo. Spero solo che tu capisca quanto sei importante per me.",
        "Ciao cara, mi fa molto piacere vedere che pensi così tanto a me. Mi è piaciuto molto il tuo messaggio, sai, e sono felice di sentire un tale incoraggiamento da parte tua. Voglio che tu sappia, tesoro, che sei l'unico uomo che mi ha fatto sentire qualcuno ai suoi occhi. Sei una parte molto importante della mia vita e ti assicuro che non ti abbandonerei per nulla al mondo, amore mio. Sai una cosa? Stanotte ti ho sognato, ho sognato che facevamo una passeggiata romantica sulla spiaggia e poi finivamo per fare l'amore come animali. Peccato che fosse solo un sogno! Pensi che si avvererà molto presto? In ogni caso, non perdo la speranza e non vedo l'ora che arrivi questo momento, tesoro mio. Sarà il giorno più felice della mia vita. Baci e stammi bene, tesoro, ora vado a lavorare. Ti amo.  NB: questa è una conversazione gay",
        "Isabelle, sei una donna molto bella, che ha sicuramente anche carattere, e questo mi piace. Ho letto la tua descrizione e corrisponde perfettamente a quello che sto cercando. Stai cercando un'avventura sessuale piccante con un uomo esperto? Non voglio vantarmi, ma all'età di 45 anni ho avuto un bel po' di tigri nella mia vita e credimi, le ho introdotte tutte al vero piacere. Il tuo culo è perfetto nella foto, le tue grandi tette ispirano desiderio e non posso fare a meno di ammirare il tuo bel viso, che sicuramente nasconde un carattere molto sporco... Non vedo l'ora di averti nel mio letto. Spero solo che tu sia abbastanza maliziosa da prenderti cura del mio grosso cazzo che vuole solo riempirti da ogni buco, mia bella. Contattami su Whatsapp XXXXXXXXXX sarà molto più facile. NB: Non devi mai dire che i tuoi dati di contatto (email, numero, fb, ecc) sono censurati dal sito.",
        "Va bene, ma ascolta, non sono qui per parlare della mia vita e spendere i miei soldi per niente. Se vuole davvero conoscermi, ci vediamo stasera davanti alla stazione alle 19.00. Indosserò una camicia bianca, così mi riconoscerai facilmente. Prenderemo un drink o un boccone da mangiare in un posto che ti piace, che ne dici? Ma prima vorrei che mi chiamassi al mio numero xxxxxx, in modo da non dovermi scomodare per nulla.",
        "Ora hai esagerato, amore! Sono stata molto paziente con te, ma ora hai esagerato e credo che tu mi stia prendendo per matta! Non so chi sia questo Luc di cui parli, ma non riesci nemmeno a ricordare il mio nome di battesimo. Ti ho anche detto che i miei genitori sono morti e che non ho fratelli o sorelle e tu osi ancora chiedermi come sta la mia famiglia? Lo sapevo, questo sito di merda è pieno di profili falsi. Così imparo a non accettare i consigli dei miei colleghi. Spero che tu ti sia riempito le tasche in tutto questo tempo in cui abbiamo parlato. Mi hai preso in giro, stronza, brava! Sporgerò denuncia e, credetemi, mi sentirete ancora e ancora finché il caso non sarà risolto. Spero di vederti dietro le sbarre! Addio e buona fortuna, chiunque tu sia!... Oh sì, giusto perché tu lo sappia, il mio nome di battesimo è Kevin e assicurati di scriverlo da qualche parte, in modo che la prossima generazione non dimentichi mai i nomi di battesimo degli altri piccioni che rimangono impigliati nella tua rete!",
        "Inviate un 1° POKE al cliente che ha appena effettuato l'accesso. Non hanno ancora inserito nulla nel loro profilo, nemmeno una foto. Sta a voi iniziare la conversazione inventando il vostro personaggio!",
        "Questo stesso cliente ha risposto con un semplice flirt. Reagite e rendete la vostra risposta il più interessante possibile.",
        "Recupero di livello 1. L'ultimo messaggio del cliente è stato: \"Ok tesoro, ora devo proprio andare e non tornerò prima di stasera, ti auguro una buona giornata, con affetto xxxxx \" È già tardi e il cliente non è ancora tornato. Risposta",
        "Recupero di livello 2. Dopo 2 giorni dal vostro follow-up di livello 1 non c'è ancora traccia dello stesso cliente. Rispondete e usate la vostra immaginazione per far ripartire la conversazione.",
        "Recupero di livello 3. È chiaro che questo cliente ha deciso di tagliare i ponti con il profilo, ma nulla è ancora perduto. Dovrete raddoppiare gli sforzi! Rispondete e non esitate a essere aggressivi nelle vostre proposte. È la vostra ultima occasione!"

    ],[ // nl
        "Hallo mooie dame, toen ik je foto zag viel ik meteen voor je. Ik wil niet op de zaken vooruit lopen, maar ik zou geen nee zeggen tegen een snelle babbel met jou, gewoon om te zien of we de dingen onder de knie hebben. Wat denk je ervan? Mijn naam is Patrick, ik ben 40 en ik heb een kind van 18 waar ik het heel goed mee kan vinden. Ik ben op dit moment niet op zoek naar iets speciaals, maar ik ben niet tegen een relatie zonder regels om mee te beginnen. Ben je beschikbaar? Wat voor type ben je? Kusjes",
        "Het is niet dat ik je verveel, maar mijn credits zijn bijna op en het wordt heet om hier te blijven chatten. Je denkt waarschijnlijk dat het veel te vroeg is, en dat kan ik begrijpen, maar ik dwing je nergens toe. Maar om mijn goede vertrouwen te bewijzen en mijn verlangen om je beter te leren kennen, laat ik je mijn XXXXXXX contacten na. Je kunt me bellen wanneer je maar wilt, ik neem altijd op, zelfs als ik aan het werk ben. Tot later, liefje!",
        "Mijn liefste, alleen al de gedachte om jou in mijn armen te houden zorgt ervoor dat ik de hitte langzaam in me voel opkomen. Ik tintel helemaal en word keihard terwijl ik deze woorden aan je schrijf. Je weet niet hoe graag ik met je wil vrijen. Ik wil tegen je aan kruipen, je kussen, je parfum ruiken, je helemaal strelen voordat ik in je kruis graaf. Ik zal je kutje likken zodat je erop kunt klaarkomen, en als ik zie dat je het niet meer aankunt, zal ik mijn pik omhoog steken, je benen spreiden en hem zachtjes naar binnen duwen, centimeter voor centimeter, zodat je het genot kunt proeven dat ons beiden overvalt. We zullen samen vrijen tot in de kleine uurtjes, schat, je zult het zien, je zult het heerlijk vinden!",
        "Dat is het, je houdt me voor de gek! Laat me je eraan herinneren dat we hier nu al 4 maanden chatten op deze site en alles wat ik tot nu toe heb gekregen zijn berichten vol beloftes en geen actie. Je vindt altijd excuses om me niet te zien en geloof me, ik kan er niet meer tegen. Ik ben hier niet om met gevoelens te spelen, ik ben een nuchter persoon en ik wil mijn leven opnieuw opbouwen met een vrouw die ook weet wat ze wil. Niet met een kind dat weigert de sprong te wagen en zich liever achter haar scherm verstopt. Neem uiteindelijk een beslissing. Ik ga eerlijk gezegd mijn geduld verliezen. ",
        "Ik heb je al verteld dat ik in de industriële afhandeling werk. Ik zorg voor het onderhoud van grote productiemachines. Ik zorg ervoor dat alles op rolletjes loopt en dankzij mijn grondige kennis van mechanica kan ik uitzoeken wat er mis is en het zo snel mogelijk oplossen. Buiten werktijd ga ik graag de natuur in om mijn hoofd leeg te maken. Ik breng ook graag tijd door met mijn vrienden in clubs, op het strand en in de zon, en ik ga af en toe klimmen om fit te blijven. En hoe zit het met jou? We hebben al 10 berichten uitgewisseld, maar tot nu toe heb ik nog niet de minste informatie over jou gekregen, of over wat je leuk vindt en waar je een hekel aan hebt.",
        "Volgende week ga ik naar Schotland om naar mijn zus te gaan. Het is lang geleden dat we elkaar gezien hebben en ik denk dat het ons allebei goed zal doen om wat tijd samen door te brengen. Als ik terugkom, hoop ik dat je de tijd hebt genomen om over mijn voorstel na te denken en dat je me niet zult teleurstellen zoals de vorige keer. En het ergste van dit alles is dat ik al een leuk restaurant voor jou en mij had geboekt, maar toen je niet terugbelde, heb ik alles op het laatste moment afgezegd. Maar hé, ik neem het je niet kwalijk. Ik hoop alleen dat je beseft hoeveel je voor me betekent.",
        "Hoi lieverd, het maakt me zo blij om te zien dat je zoveel aan me denkt. Ik vond je bericht erg leuk, weet je, en ik ben blij om zo'n aanmoediging van je te horen. Ik wil dat je weet, lieverd, dat jij de enige man bent die me ooit het gevoel heeft gegeven dat ik iemand ben in zijn ogen. Je bent een heel belangrijk deel van mijn leven en ik kan je verzekeren dat ik je voor niets ter wereld zou teleurstellen, mijn liefste. Weet je wat? Ik heb vannacht over je gedroomd. Ik droomde dat we een romantische strandwandeling maakten en daarna als beesten de liefde bedreven. Jammer dat het maar een droom was! Denk je dat het snel zal uitkomen? In ieder geval geef ik de hoop niet op en kan ik niet wachten tot dit moment aanbreekt, mijn liefste. Het wordt de gelukkigste dag van mijn leven. Kusjes en pas goed op jezelf, lieverd, ik ga nu naar mijn werk. Ik hou van je.  NB: dit is een homogesprek",
        "Isabelle, je bent een hele mooie vrouw, die zeker ook karakter heeft en daar hou ik van. Ik heb je beschrijving gelezen en die sluit perfect aan bij wat ik zoek. Ben je op zoek naar een pikant seksueel avontuur met een ervaren man? Ik wil niet opscheppen, maar op 45 jarige leeftijd heb ik al heel wat tijgertjes in mijn leven gehad en geloof me, ik heb ze allemaal kennis laten maken met echt genot. Je kont ziet er perfect uit op de foto, je grote tieten wekken verlangen op en ik kan het niet helpen me te verbazen over je mooie gezicht, dat zeker een heel vies temperament verbergt... Ik kan niet wachten om je in mijn bed te hebben. Ik hoop alleen dat je stout genoeg bent om voor mijn grote lul te zorgen die er alleen maar op uit is om je uit elk gaatje te vullen, mijn schoonheid. Neem contact met me op Whatsapp XXXXXXXXXX het zal veel gemakkelijker zijn. NB: Je moet nooit zeggen dat je contactgegevens (e-mail, nummer, fb, enz.) worden gecensureerd door de site.",
        "Dat is allemaal goed en wel, maar luister, ik ben hier niet om over mijn leven te praten en mijn geld aan niets uit te geven. Als je me echt wilt leren kennen, ontmoet ik je vanavond om 19.00 uur voor het station. Ik draag een wit shirt, zodat je me gemakkelijk herkent. We gaan iets drinken of eten ergens waar jij dat leuk vindt, wat vind je daarvan? Maar eerst wil ik graag dat je me belt op mijn xxxxxx nummer zodat ik niet voor niets de deur uit hoef.",
        "Nu ben je te ver gegaan, liefje! Ik ben heel geduldig met je geweest, maar nu ben je te ver gegaan en ik denk dat je me echt voor de gek houdt! Ik weet niet wie die Luc is waar je het over hebt, maar je kunt niet eens mijn voornaam onthouden. Ik heb je ook verteld dat mijn ouders dood zijn en dat ik geen broers of zussen heb en je durft nog steeds te vragen hoe het met mijn familie gaat? Ik wist het, deze klote site zit vol met nepprofielen. Dat zal me leren geen advies aan te nemen van mijn collega's. Ik hoop dat je je zakken hebt gevuld in al die tijd dat we hebben gepraat. Je hebt me voor gek gezet, trut, bravo! Ik ga een aanklacht indienen en geloof me, je zult nog van me horen totdat deze zaak is opgelost. Ik hoop je achter de tralies te zien! Vaarwel en Godspeed, wie je ook bent!... Oh ja, het is maar dat je het weet, mijn voornaam is Kevin en zorg ervoor dat je die ergens opschrijft zodat de volgende generatie nooit de voornamen vergeet van de andere duiven die in je net verstrikt raken!",
        "Stuur een 1e POKE naar de klant die zich net heeft aangemeld. Ze hebben nog niets op hun profiel gezet, zelfs geen foto. Het is aan jou om het gesprek te beginnen door je eigen personage te verzinnen!",
        "Deze zelfde klant heeft gereageerd met een simpele flirt. Reageer en maak je antwoord zo interessant mogelijk",
        "Herinnering van niveau 1. Het laatste bericht van de klant was: \OK schat, ik moet nu echt gaan en ik kom vanavond pas terug, nog een fijne dag, liefs xxxxx \" Het is al laat en de klant is nog niet terug. Antwoord",
        "Herinnering van niveau 2. Nog steeds geen teken van dezelfde klant na 2 dagen na je opvolging van niveau 1. Reageer en gebruik je verbeelding om het gesprek weer op gang te krijgen.",
        "Herinnering van niveau 3. Deze klant heeft duidelijk besloten om alle banden met het profiel te verbreken, maar er is nog niets verloren. Je moet je inspanningen verdubbelen! Reageer en aarzel niet om agressief te zijn in je voorstellen. Dit is je laatste kans!"

    ],[ // es
        "Hola guapa, cuando vi tu foto, me enamoré inmediatamente de ti. No quiero adelantarme, pero no diría que no a una charla con usted, para ver si estamos en la misma onda. ¿Qué te parece? Me llamo Patrick, tengo 40 años y un hijo de 18 con el que me llevo muy bien. Por el momento, no busco nada en particular, pero no soy reacio a una relación sin compromiso para empezar. ¿Estás disponible? ¿Cómo es tu tipo? Besos",
        "No es que me aburra de ti, pero estoy casi sin créditos y va a hacer calor para seguir hablando aquí. Seguramente estarás pensando que es demasiado pronto, y lo entiendo, pero no te estoy obligando a nada. Pero para demostrarte mi buena fe y mis ganas de conocerte mejor, te dejo mis contactos XXXXXXX: puedes llamarme cuando quieras, siempre contesto, incluso cuando estoy en el trabajo. Hasta luego, amor",
        "Cariño mío, sólo de pensar en tenerte entre mis brazos siento que el calor sube lentamente en mi interior. Me cosquillea todo el cuerpo y se me pone dura como un toro al escribirte estas palabras. No sabes las ganas que tengo de hacerte el amor. Quiero acurrucarme a tu lado, besarte, oler tu perfume, acariciarte por todas partes antes de hurgar en tu entrepierna. Te lameré el coño para que te excites, y cuando vea que ya no puedes más, erectaré mi polla, te abriré las piernas y te la meteré suavemente, centímetro a centímetro, para que saborees el placer que nos invade a los dos. Haremos el amor juntos hasta el amanecer, cariño, ¡ya verás, te va a encantar!",
        "¡Eso es, tómame por tonto! Permíteme recordarte que ya llevamos 4 meses chateando en este sitio y todo lo que he recibido hasta ahora son mensajes llenos de promesas y vacíos de acción. Siempre estás buscando excusas para no verme y créeme, no puedo soportarlo más. No estoy aquí para jugar con los sentimientos, soy una persona muy asentada en mi propia mente y quiero rehacer mi vida con una mujer que también sepa lo que quiere. No con una niñata que se niega a dar el paso y prefiere esconderse detrás de su pantalla. Decídete de una vez. Francamente, se me está acabando la paciencia. ",
        "Ya se lo he dicho, trabajo en la manipulación de materiales industriales. Me ocupo del mantenimiento de grandes máquinas de producción. Me aseguro de que todo funcione bien y, gracias a mis profundos conocimientos de mecánica, soy capaz de averiguar qué falla y solucionarlo lo antes posible. Cuando no estoy trabajando, me gusta ir al campo para refrescar mis ideas. También me gusta pasar tiempo con mis amigos en los clubes, en la playa y al sol, y de vez en cuando hago escalada para mantenerme en forma. ¿Y usted? Ya hemos intercambiado 10 mensajes, pero hasta ahora no he obtenido ni un solo dato sobre ti, tus gustos y tus aversiones",
        "La semana que viene me voy a Escocia a reunirme con mi hermana. Hace bastante tiempo que no nos vemos y creo que nos vendría bien a las dos pasar algún tiempo juntas. Cuando vuelva, espero que te hayas tomado el tiempo de pensar en mi propuesta y no me defraudes como la última vez. Y lo peor es que ya había reservado un buen restaurante para ti y para mí, pero como no me respondiste, lo cancelé en el último momento. Pero bueno, no te culpo. Sólo espero que te des cuenta de lo mucho que significas para mí",
        "Hola cariño, me hace muy feliz ver que piensas tanto en mí. Tu mensaje me ha complacido de verdad, sabes, y sólo puedo estar encantada con tanto aliento viniendo de ti. Quiero que sepas, cariño, que eres el único hombre que me ha hecho sentir que soy alguien a sus ojos. Ocupas un lugar muy importante en mi vida y puedo asegurarte que por nada del mundo te defraudaré, mi amor. ¿Sabes una cosa? Anoche soñé contigo J Soñé que dábamos un romántico paseo por la playa y acabábamos haciendo el amor como animales. ¡Lástima que sólo fuera un sueño! ¿Crees que se hará realidad pronto? En cualquier caso, no pierdo la esperanza y estoy deseando que llegue ese momento, cariño. Va a ser el día más feliz de mi vida. Besos y cuídate cariño, ahora me voy a trabajar. Te quiero.  NB: esta es una conversación gay",
        "Isabelle, eres una mujer muy guapa, que sin duda también tiene carácter y eso me gusta. He leído tu descripción y se ajusta perfectamente a lo que busco. ¿Buscas una aventura sexual picante con un hombre experimentado? No quiero presumir, pero en mis 45 años he tenido bastantes tigresas en mi vida, y créeme, las he introducido a todas en el verdadero placer. Tu culo se ve perfecto en la foto, tus grandes pechos inspiran deseo y no puedo dejar de maravillarme con tu bonita cara, que seguramente esconde un carácter muy sucio... Estoy deseando tenerte en mi cama. Sólo espero que seas lo suficientemente traviesa como para ocuparte de mi gran polla que sólo busca llenarte por todos los agujeros, preciosa mía. Contacta conmigo por Whatsapp XXXXXXXXXX será mucho más fácil. NB: Nunca digas que tus datos de contacto (e-mail, número, fb, etc.) están censurados por la página",
        "Eso está muy bien, pero escucha, no estoy aquí para hablar de mi vida y gastar mi dinero para nada. Si de verdad quieres conocerme, te propongo que nos veamos esta noche delante de la estación a las 19.00 horas. Llevaré una camisa blanca, así que me reconocerás fácilmente. Tomaremos una copa o comeremos algo en algún sitio que te guste, ¿qué te parece? Pero antes, me gustaría que me llamaras a mi número xxxxxx para que no tenga que desplazarme para nada",
        "¡Ahora has ido demasiado lejos, cariño! He sido muy paciente contigo, ¡pero ahora sí que me tomas por tonta! No sé quién es ese Luc del que hablas, pero ni siquiera recuerdas mi nombre de pila. También te he dicho que mis padres han muerto y que no tengo hermanos, ¿y aún te atreves a preguntarme cómo está mi familia? Como sospechaba, este sitio podrido está lleno de perfiles falsos. Eso me enseñará a no aceptar consejos de mis colegas. Espero que te hayas estado llenando los bolsillos todo este tiempo que hemos estado hablando. Me has dejado en ridículo perra, ¡bravo! Voy a presentar cargos, y créeme, oirás de mí una y otra vez, hasta que este asunto se resuelva. ¡Espero verte entre rejas! Adiós y que Dios te acompañe, quienquiera que seas!... Ah, sí, para que lo sepas, mi nombre de pila es Kevin y asegúrate de escribirlo en algún sitio para que la próxima generación nunca olvide los nombres de pila de los demás pringados que caigan en tus redes!",
        "Envía un 1er POKE al cliente que acaba de entrar. Aún no ha puesto nada en su perfil, ni siquiera una foto. Te toca a ti iniciar la conversación inventándote tu propio personaje!",
        "Ese mismo cliente respondió con un simple flirteo. Reacciona y haz que tu respuesta sea lo más interesante posible",
        "Relanzamiento del nivel 1. El último mensaje del cliente fue: \"Vale cariño, ahora tengo que irme y no volveré hasta la noche, que tengas un buen día, besos xxxxx\" Ya es tarde y el cliente no ha vuelto. Contesta",
        "Relanzamiento del nivel 2. Todavía no hay rastro del mismo cliente después de 2 días tras tu seguimiento de Nivel 1. Responda y utilice su imaginación para retomar la conversación",
        "Relanzamiento de nivel 3. Está claro que este cliente ha decidido cortar sus lazos con el perfil de una vez por todas, pero aún no hay nada perdido. ¡Tendrás que redoblar tus esfuerzos! Responde y no dudes en ser agresivo en tus propuestas. Es tu última oportunidad",
    ],[ // de
        "Hallo schöne Frau, als ich Ihr Foto sah, habe ich mich sofort in Sie verliebt. Ich will nicht voreilig sein, aber ich würde nicht nein zu einem Gespräch mit Ihnen sagen, nur um zu sehen, ob wir auf der gleichen Wellenlänge sind. Was hältst du davon? Ich heiße Patrick, bin 40 und habe einen 18-jährigen Sohn, mit dem ich mich sehr gut verstehe. Im Moment bin ich nicht auf der Suche nach etwas Bestimmtem, aber ich bin nicht abgeneigt, eine Beziehung ohne Vorbehalte zu beginnen. Bist du verfügbar? Wie ist dein Typ? Küsse",
        "Es ist nicht so, dass ich mich mit dir langweile, aber ich habe fast keinen Kredit mehr und es wird zu heiß, um hier weiter zu reden. Du denkst wahrscheinlich, es ist viel zu früh, und ich verstehe das, aber ich zwinge dich zu nichts. Aber als Beweis meines guten Willens und meines Wunsches, dich besser kennen zu lernen, hinterlasse ich dir meine XXXXXXX-Kontakte: Du kannst mich anrufen, wann immer du willst, ich gehe immer ran, auch wenn ich auf der Arbeit bin. Bis später, meine Liebe!",
        "Mein Liebling, allein der Gedanke, dich in meinen Armen zu halten, lässt die Hitze in mir langsam aufsteigen. Es kribbelt mich am ganzen Körper und ich werde steif wie ein Stier, wenn ich dir diese Worte schreibe. Du weißt nicht, wie sehr ich mit dir Liebe machen möchte. Ich möchte mich an dich kuscheln, dich küssen, dein Parfüm riechen, dich am ganzen Körper streicheln und dann in deinen Schritt eindringen. Ich werde deine Muschi lecken, damit du davon erregt wirst, und wenn ich sehe, dass du es nicht mehr aushältst, werde ich meinen Schwanz aufrichten, deine Beine spreizen und ihn ganz sanft hineinschieben, Zentimeter für Zentimeter, damit du das Vergnügen schmecken kannst, das uns beide überkommt. Wir werden uns bis zum Morgengrauen lieben, mein Schatz, du wirst sehen, du wirst es lieben!",
        "So ist es gut, halte mich für dumm! Ich möchte dich daran erinnern, dass wir schon seit 4 Monaten hier auf dieser Seite chatten und alles, was ich bisher bekommen habe, sind Nachrichten voller Versprechen und ohne Taten. Du findest immer Ausreden, um dich nicht mit mir zu treffen, und glaub mir, ich kann das nicht mehr ertragen. Ich bin nicht hier, um mit Gefühlen zu spielen, ich bin ein sehr gefestigter Mensch und ich möchte mein Leben mit einer Frau neu aufbauen, die auch weiß, was sie will. Nicht mit einem Kind, das sich weigert, den Sprung zu wagen und sich lieber hinter ihrem Bildschirm versteckt. Entscheide dich endlich. Ehrlich gesagt bin ich mit meiner Geduld am Ende. ",
        "Ich habe Ihnen bereits gesagt, dass ich in der industriellen Fördertechnik arbeite. Ich kümmere mich um die Wartung von großen Produktionsmaschinen. Ich sorge dafür, dass alles reibungslos läuft, und dank meiner fundierten Kenntnisse der Mechanik kann ich herausfinden, was nicht stimmt, und es so schnell wie möglich beheben. Wenn ich nicht arbeite, gehe ich gerne in die Natur, um neue Ideen zu sammeln. Außerdem verbringe ich gerne Zeit mit meinen Freunden in Clubs, am Strand und in der Sonne, und gelegentlich gehe ich klettern, um mich fit zu halten. Und was ist mit dir? Wir haben bereits 10 Nachrichten ausgetauscht, aber bis jetzt habe ich noch keine einzige Information über dich, deine Vorlieben und Abneigungen erhalten",
        "Nächste Woche fahre ich nach Schottland zu meiner Schwester. Es ist schon eine ganze Weile her, dass wir uns gesehen haben, und ich denke, es würde uns beiden gut tun, etwas Zeit miteinander zu verbringen. Wenn ich zurückkomme, hoffe ich, dass du dir die Zeit genommen hast, über meinen Vorschlag nachzudenken und mich nicht wieder so im Stich lässt wie beim letzten Mal. Und das Schlimmste ist, dass ich bereits ein nettes Restaurant für dich und mich gebucht hatte, aber als du nicht geantwortet hast, habe ich es in letzter Minute abgesagt. Aber hey, ich mache dir keinen Vorwurf. Ich hoffe nur, du weißt, wie viel du mir bedeutest.",
        "Hallo Liebling, es macht mich so glücklich zu sehen, dass du so viel von mir hältst. Deine Nachricht hat mich sehr gefreut, und ich kann mich nur freuen, wenn ich so viel Zuspruch von dir bekomme. Du sollst wissen, mein Schatz, dass du der einzige Mann bist, der mir jemals das Gefühl gegeben hat, dass ich in seinen Augen jemand bin. Du nimmst einen sehr wichtigen Platz in meinem Leben ein, und ich kann dir versichern, dass ich dich um nichts in der Welt im Stich lassen werde, meine Liebe. Weißt du was? Ich habe letzte Nacht von dir geträumt J Ich habe geträumt, dass wir einen romantischen Spaziergang am Strand gemacht haben und uns dann wie Tiere geliebt haben. Schade, dass es nur ein Traum war! Glaubst du, er wird bald wahr? Auf jeden Fall gebe ich die Hoffnung nicht auf und kann diesen Moment kaum erwarten, mein Schatz. Es wird der glücklichste Tag meines Lebens sein. Küsse und pass auf dich auf, mein Schatz, ich gehe jetzt zur Arbeit. Ich liebe dich.  NB: dies ist ein schwules Gespräch",
        "Isabelle, du bist eine sehr schöne Frau, die sicherlich auch Charakter hat und das gefällt mir. Ich habe deine Beschreibung gelesen und sie passt perfekt zu meiner Suche. Bist du auf der Suche nach einem pikanten sexuellen Abenteuer mit einem erfahrenen Mann? Ich will nicht prahlen, aber in meinen 45 Jahren hatte ich schon einige Tigerinnen in meinem Leben, und glaube mir, ich habe sie alle in das wahre Vergnügen eingeführt. Dein Arsch sieht auf dem Foto perfekt aus, deine großen Brüste wecken Begehrlichkeiten und ich kann nicht anders, als dein hübsches Gesicht zu bewundern, hinter dem sich sicherlich ein sehr schmutziges Temperament verbirgt... Ich kann es kaum erwarten, dich in meinem Bett zu haben. Ich hoffe nur, du bist frech genug, dich um meinen großen Schwanz zu kümmern, der nur darauf wartet, dich durch jedes Loch auszufüllen, meine Schöne. Kontaktieren Sie mich auf Whatsapp XXXXXXXXXX es wird viel einfacher sein. NB: Sagen Sie nie, dass Ihre Kontaktdaten (E-Mail, Nummer, fb, etc.) von der Website zensiert werden.",
        "Das ist ja alles schön und gut, aber hören Sie, ich bin nicht hier, um über mein Leben zu reden und mein Geld umsonst auszugeben. Wenn Sie mich wirklich kennen lernen wollen, schlage ich vor, wir treffen uns heute Abend um 19 Uhr vor dem Bahnhof. Ich werde ein weißes Hemd tragen, damit du mich leicht erkennen kannst. Wir werden etwas trinken oder essen gehen, wo du willst, wie wäre das? Aber vorher möchte ich, dass Sie mich unter meiner Nummer xxxxxx anrufen, damit ich mich nicht umsonst bemühen muss.",
        "Jetzt bist du zu weit gegangen, mein Schatz! Ich war sehr geduldig mit dir, aber jetzt hältst du mich wirklich zum Narren! Ich weiß nicht, wer dieser Luc ist, von dem du sprichst, aber du kannst dich nicht einmal an meinen Vornamen erinnern. Ich habe dir auch gesagt, dass meine Eltern tot sind und ich keine Geschwister habe, und du wagst es trotzdem, mich zu fragen, wie es meiner Familie geht? Wie ich vermutet habe, ist diese miese Seite voller falscher Profile. Das wird mich lehren, keine Ratschläge von meinen Kollegen anzunehmen. Ich hoffe, Sie haben sich die ganze Zeit, in der wir uns unterhalten haben, die Taschen gefüllt. Du hast einen Narren aus mir gemacht, Schlampe, bravo! Ich werde Anzeige erstatten, und glauben Sie mir, Sie werden immer wieder von mir hören, bis diese Angelegenheit geklärt ist. Ich hoffe, ich sehe Sie hinter Gittern! Auf Wiedersehen und viel Glück, wer auch immer Sie sind!...Ach ja, nur damit Sie es wissen, mein Vorname ist Kevin und stellen Sie sicher, dass Sie das irgendwo aufschreiben, damit die nächste Generation niemals die Vornamen der anderen Trottel vergisst, die sich in Ihrem Netz verfangen!",
        "Schicken Sie einen 1. POKE an den Kunden, der sich gerade eingeloggt hat. Er hat noch nichts in sein Profil eingegeben, nicht einmal ein Foto. Es liegt an dir, das Gespräch zu beginnen, indem du deinen eigenen Charakter erfindest!",
        "Derselbe Kunde hat mit einem einfachen Flirt geantwortet. Reagieren Sie und machen Sie Ihre Antwort so interessant wie möglich",
        "Level 1 Relaunch. Die letzte Nachricht des Kunden war: \"Okay, Schatz, ich muss jetzt wirklich los und werde erst heute Abend zurück sein, schönen Tag noch, Küsse xxxxx\" Es ist bereits spät, und der Kunde ist nicht zurückgekommen. Antwort",
        "Level 2 Relaunch. Nach 2 Tagen nach Ihrer Stufe-1-Wiedervorlage gibt es immer noch kein Zeichen desselben Kunden. Reagieren Sie und nutzen Sie Ihre Fantasie, um das Gespräch wieder in Gang zu bringen.",
        "Wiedervorlage Stufe 3. Offensichtlich hat dieser Kunde beschlossen, seine Verbindung mit dem Profil ein für alle Mal zu beenden, aber noch ist nichts verloren. Sie müssen Ihre Bemühungen verdoppeln! Antworten Sie und zögern Sie nicht, in Ihren Vorschlägen aggressiv zu sein. Dies ist Ihre letzte Chance!"
    ]
];
const getKey = lang =>{
    switch(lang){
        case 'fr': return 0
        case 'it': return 2
        case 'nl': return 3
        case 'es': return 4
        case 'de': return 5
        default: return 1
    }
}

export const situations =  lang => { return allSituations[getKey(lang)] }