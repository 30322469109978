import React, { useContext, useState, useEffect, useRef } from "react";
import { Stack, Typography, Divider, Box, Button } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import TitleWithBg from '../../tools/TitleWithBg'
import InfoManage from "./InfoManage";
import InfoChange from "./InfoChange";
import PointedButton from "../../tools/PointedButton";
import AppUpload from "./AppUpload";
import AppCheck from "./AppCheck";
import IntegUploadDoc from "./IntegUploadDoc";
import IntegUploadPay from "./IntegUploadPay"
import { animateScroll } from 'react-scroll';
import { DashboardStateContext } from "../../state/DashboardStateContext";
import {useForm} from 'react-hook-form'
import HelloUser from "../../Header/HelloUser";
import axiosInstance from "../../../axios"
import { jwtDecode } from "jwt-decode";
import { AuthStateContext } from "../../state/AuthStateContext";
import Alert from '@mui/material/Alert';
import { mdpminlength, pusherKey, pusherOptions } from "../../../helper/conf";
import generateFormData from "../../../helper/formData";
import { baseURL } from "../../../helper/conf";
import countriesList from "../../../helper/countries";
import { YourIntegStateContext } from "../../state/YourIntegState";
import { NotifStateContext } from "../../state/NotifState";
import Pusher from "pusher-js"
import { Howl } from "howler";

const Dashboard = () =>{
    const { text, currentLanguage } = useLanguage()
    const [error, setError] = useState('')
    const audioRef = useRef(null)

    const { selectedButton, setSelectedButton, saveState } = useContext(DashboardStateContext)
    const { candidateId, setFirstName, setProfilePicture: setUserPP } = useContext(AuthStateContext)
    const { setShow } = useContext(YourIntegStateContext)
    const { setNotifs } = useContext(NotifStateContext)
    const [defaultValues, setDefaultValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [phonePrefix, setPhonePrefix] = useState('')

    const [profilePicture, setProfilePicture] = useState({name: null})
    const [resume, setResume] = useState(null)
    const [proofIdentity, setProofIdentity] = useState(null)
    const [proofAddress, setProofAddress] = useState(null)
    const [selfie, setSelfie] = useState(null)
    const [contract, setContract] = useState(null)

    const [ unreadNotif, setUnreadNotif ] = useState(0)
    const ringtone = new Howl({
        src: ['/ringtones/notification.mp3'],
        volume: 1
    })

    const { id } = jwtDecode(candidateId)

    const userInfos = async () =>{
        try {
            const { data } = await axiosInstance.get(`/infos/${id}`, { headers: {'Authorization': `Bearer ${candidateId}`} })
            const fn = data.firstname
            const pp = data.profilephoto
            setNotifs(data.notification)
            const unreads = data.notification?.filter(n => !n.isRead)
            if (unreadNotif < unreads.length) ringtone.play()
            setUnreadNotif(unreads.length)
            setPhonePrefix(`+${countriesList.find(c => c.value == data.country).phone}`)
            setShow(data.test_status?.some(e => e.decision == 'Hired'))
            setDefaultValues(data)
            setFirstName(fn[0].toUpperCase() + fn.slice(1))
            if (pp) setUserPP(`${baseURL}/images/users/${pp}`)
        } catch (err){}
    }
    useEffect(()=>{ userInfos() },[])

    useEffect(() => {
        const channelName = 'private-candidate-' + id
        const pusher = new Pusher(pusherKey, pusherOptions)
        const channel = pusher.subscribe(channelName)
        channel.bind('test_review', userInfos)
        channel.bind('test_answer', userInfos)

        return () => {
            channel.unbind()
            pusher.unsubscribe(channelName)
        }
    }, [])

    const handleButtonClick = (buttonId) => {
        setSelectedButton(buttonId);
        animateScroll.scrollTo(0, {
            duration:500,
            spy:true,
            smooth:true,
            offset:-175
        })
    };

    const { control, handleSubmit, setValue, formState: {errors} } = useForm()
    
    const saveChanges = async data => {
        const {lastname, firstname, gender, phone, appart, city, pobox, country, 
            actualpassword, newpassword, confirmnewpassword, paypalemail, wiseemail, bitesafeemail,
            holdrename, holderaddress, bankholder, bankaddress, iban, swiftcode } = data
        const ap = (actualpassword == '') ? undefined : actualpassword
        const np = (newpassword == '') ? undefined : newpassword
        const cnp = (confirmnewpassword == '') ? undefined : confirmnewpassword
        setLoading(true)
        setError('')
        if (np==cnp) {
            if (np && np.length<mdpminlength) setError('minlength')
            else try {
                const resp = await axiosInstance.post(`/update/${id}/${currentLanguage}`, generateFormData({last_name: lastname, first_name: firstname, 
                    gender: gender, phone: phonePrefix + phone, address: appart, city: city, 
                    pobox: pobox, country: country, currentPassword: ap, newPassword: np, confirmPassword: cnp,
                    paypal: paypalemail, wise: wiseemail, your_safe: bitesafeemail, holder_name: holdrename, 
                    holder_address: holderaddress, holder_bank_name: bankholder, holder_bank_address: bankaddress, 
                    iban_count: iban, bic_count: swiftcode, profile_picture: profilePicture,
                    resume: resume, proof_identity: proofIdentity, proof_Address: proofAddress,
                    selfie: selfie, contract: contract
                }) , {
                    headers:{
                        'Authorization': `Bearer ${candidateId}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    transformRequest: null
                })
                
                if (resp.status == 200){
                    setError('succ')
                    saveState()
                    window.location.reload()
                }
            } catch (error) {
                if (error.response.status == 400){
                    if (error.response.data.message =="Le mot de passe actuel est incorrect.") setError('mdpincorrect')
                    else setError('smth')
                }
                else setError('smth')
            }
        }
        else setError('mdpnonidentique')
        setLoading(false)
    }
    

    return(
        <Stack variant="dashboard" className="height-max-adjust">
            <Stack  display="flex" flexDirection="column" gap={3} padding="10px 20px"  >
                <div className="cond-rendering-inv" style={{alignSelf: 'center', transform: 'scale(.9)'}}>
                    <HelloUser />
                </div>
                <Typography variant="style-font-title" className="style-font-2">{text.dashboard.toUpperCase()}</Typography>
                <Typography variant="white">{text.dashboard1}</Typography>
                <Typography variant="style-font-secondary" className="style-font-3" style={{textAlign: "center"}}>{text.dashboard2}</Typography>
                <Stack style={{alignItems: "center"}}><Divider variant="dottedwhite" /></Stack>
                <Box className="wrap-content" sx={{ justifyContent: 'center'}}>
                    <div className="left-content" style={{maxWidth: '40%'}}>
                        <Stack  style={{padding: "10px 10px 10px 0px"}}  >
                            <Stack>
                                <Stack><TitleWithBg iconSrc='/images/Member/dashboard/1.png' children={text.information} color="#f61c02" title/></Stack>
                                <Stack display="flex" gap={2} style={{padding: "0px 0px 0px 20px"}}>
                                    <Box display="flex" flexDirection="row" gap={2} >
                                        <Stack><img height='50px' src="/images/Member/dashboard/2.png" alt=""/></Stack>
                                        <Typography variant="textbutton" onClick={() => handleButtonClick(1)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.information1}</Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="row" gap={2} >
                                        <Stack><img height='50px' src="/images/Member/dashboard/3.png" alt=""/></Stack>
                                        <Typography variant="textbutton" onClick={() => handleButtonClick(2)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.information2}</Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Stack><TitleWithBg iconSrc='/images/Member/dashboard/4.png' children={text.yourapplication} color="#1db744" title/></Stack>
                                <Stack display="flex" gap={2} style={{padding: "0px 0px 0px 20px"}}>
                                    <Box display="flex" flexDirection="row" gap={2} >
                                        <Stack><img height='50px' src="/images/Member/dashboard/5.png" alt=""/></Stack>
                                        <Typography variant="textbutton" onClick={() => handleButtonClick(3)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.application1}</Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="row" gap={2} >
                                        <Stack><img height='50px' src="/images/Member/dashboard/6.png" alt=""/></Stack>
                                        <Typography variant="textbutton" onClick={() => handleButtonClick(4)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.application2}</Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                            {
                                (defaultValues.test_status?.some(e => e.decision == 'Hired')) &&
                                <Stack>
                                    <Stack><TitleWithBg iconSrc='/images/Member/dashboard/7.png' children={text.integration} color="#cb6ce5" title/></Stack>
                                    <Stack display="flex" gap={2} style={{padding: "0px 0px 0px 20px"}}>
                                        <Box display="flex" flexDirection="row" gap={2} >
                                            <Stack><img height='50px' src="/images/Member/dashboard/8.png" alt=""/></Stack>
                                            <Typography variant="textbutton" onClick={() => handleButtonClick(5)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.integration1}</Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="row" gap={2} >
                                            <Stack><img height='50px' src="/images/Member/dashboard/9.png" alt=""/></Stack>
                                            <Typography variant="textbutton" onClick={() => handleButtonClick(6)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.integration2}</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                            }
                            
                        </Stack>
                    </div>
                    <Stack className="width-resize right-content" alignSelf='center'>
                        <Box sx={{display: (selectedButton === 1) ? 'block' : 'none'}}><InfoManage defaultValues={defaultValues} setValue={setValue} control={control} errors={errors} phonePrefix={phonePrefix} setPhonePrefix={setPhonePrefix} file={profilePicture} setFile={setProfilePicture}/></Box>
                        <Box sx={{display: (selectedButton === 2) ? 'block' : 'none'}}><InfoChange control={control} errors={errors}/></Box>
                        <Box sx={{display: (selectedButton === 3) ? 'block' : 'none'}}><AppUpload control={control} errors={errors} setResume={setResume} defaultValues={defaultValues}/></Box>
                        <Box sx={{display: (selectedButton === 4) ? 'block' : 'none'}}><AppCheck defaultValues={defaultValues} control={control} errors={errors}/></Box>
                        <Box sx={{display: (selectedButton === 5) ? 'block' : 'none'}}><IntegUploadDoc control={control} errors={errors} defaultValues={defaultValues} setProofIdentity={setProofIdentity} setProofAddress={setProofAddress} setSelfie={setSelfie} setContract={setContract}/></Box>
                        <Box sx={{display: (selectedButton === 6) ? 'block' : 'none'}}><IntegUploadPay control={control} errors={errors} defaultValues={defaultValues} setValue={setValue}/></Box>
                    </Stack>
                </Box>
                {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ') ? "success" : "error"}>
                    {
                        (error=='succ') ? <Typography variant= "success" >{text.svsucc}</Typography>: 
                        ((error=='mdpincorrect') ? <Typography variant="error">{text.curmdperr}</Typography>:
                            ((error=='mdpnonidentique') ? <Typography variant="error">{text.mdpnid}</Typography>: 
                                (error=='minlength') ? <Typography variant="error">{text.passwordlength}</Typography>:
                                <Typography variant="error">{text.smthngwntwrng}</Typography>
                            )
                        )
                    }

                    
                </Alert>}                
                <Stack width="20%" alignSelf="center" >
                    <PointedButton onClick={handleSubmit(saveChanges)} loading={loading}>{text.save}</PointedButton>
                </Stack>
                
            </Stack>
        </Stack>
    )
}

export default Dashboard
