import React, { useState } from "react";
import { Box, Dialog, Stack } from "@mui/material";

const OpenableImage = ({src, sx}) =>{
    const [open, setOpen] = useState(false)

    return <>
        <Stack onClick={()=> setOpen(true) } style={{cursor: 'pointer'}}>
            <Box component='img' alignSelf='center' sx={sx} src={src}/>          
        </Stack>
        <Dialog open={open} onClose={()=> setOpen(false)}>
            <Box component='img' alignSelf='center' sx={{ height: '50vh' }} src={src}/>
        </Dialog>
    </> 
}

export default OpenableImage