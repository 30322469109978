import React from "react";
import LoginForm from "../tools/LoginForm";
import { roles } from "../../helper/roles";

const TeamLogin = () => {
    return (
        <LoginForm type={roles.teamleader} roleIndex={1}/>
    )
} 

export default TeamLogin;