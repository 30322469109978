import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import IconTextCard from "../../tools/IconTextCard";
import AnimatedButton from "../../tools/AnimatedButton";
import CustomDivider from "../../tools/CustomDivider"

const ApplySteps = () => {
    const { text } = useLanguage()
    const iconBaseUrl = "/images/chat-operator/6- 3 easy steps to apply for the position/"
    return(
        <Stack alignItems='center' spacing={3} marginBottom='50px'>
            <Typography className="title-typo text-resize-4" variant="title">
                {text.coasint1} <Typography variant="primarytitle2" className="title-typo-change-light-2">{text.cn}</Typography> {text.coasint2}
            </Typography>
            <CustomDivider color="secondary" alignCenter width='23%'/>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="primarycard" label={text.coas11 + ":"} iconSrc={iconBaseUrl + "1. Easy application and online tests.svg"}>{ text.coas12 }</IconTextCard>
                <IconTextCard variant="primarycard" label={text.coas21 + ":"} iconSrc={iconBaseUrl + "2. ID verification.svg"}>{ text.coas22 }</IconTextCard>
                <IconTextCard variant="primarycard" label={text.coas31 + ":"} iconSrc={iconBaseUrl + "3. Online training.svg"}>{ text.coas32 }</IconTextCard>
            </Box>
            <AnimatedButton to="apply-co">{text.applynow}</AnimatedButton>
        </Stack>
    )
}

export default ApplySteps