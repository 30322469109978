import React, { useEffect, useState } from "react";
import { Stack, Typography, Button, OutlinedInput, Alert, CircularProgress } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import { Link } from "react-router-dom";
import RoutesList from "../../../helper/routes"
import axiosInstance from "../../../axios";

const MailValidate = ({mail, onClose, onValidated}) =>{
    const [ visible, setVisible ] = useState(mail != "")
    const inputIds = ['code0', 'code1', 'code2', 'code3', 'code4', 'code5']
    const [ code, setCode ] = useState([])

    const [error, setError] = useState('')
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(()=> setVisible(mail != ""), [mail])
    useEffect(()=> { {
        const input0 = document.querySelector(`#${inputIds[0]}`)
        if (visible && input0) input0.focus()
    } },[visible])
    
    const { text } = useLanguage()
    
    useEffect(()=> setSubmitDisabled(code.length != inputIds.length), [code])
    const codeEnter = (index, value) =>{
        if (['0','1','2','3','4','5','6','7', '8','9'].includes(value)){
            setCode([...code, value])
            if (index < inputIds.length -1) document.querySelector(`#code${index +1}`).focus()
        } else document.querySelector(`#code${index}`).value = ""
}

    const handleRemove = (index, key) =>{
        if (key === 'Backspace'){
            const newcode = [...code]
            newcode.pop()
            setCode(newcode)
            if ((index == inputIds.length - 1) && (code.length == inputIds.length) ) 
                document.querySelector(`#code${index}`).value = ""
            else if (index > 0) {
                document.querySelector(`#code${index -1}`).focus()
                document.querySelector(`#code${index -1}`).value = ""
            } else document.querySelector(`#code0`).value = ""
        }
    }

    const verify = async () => {
        setLoading(true)
        setError(false)
        try {
            const { status } = await axiosInstance.post('/confirm_code_validation', { email: mail, validation_code: code.join('') })
            if (status == 200){
                setError('')
                close()
                onValidated()
            }
        } catch (error) {
            switch (error.response.status){
                case 401: setError(text.codevalidationerror); break
                default: setError(text.smthngwntwrng)
            }
        }
        setLoading(false)
        setCode([])
        inputIds.forEach(id => document.querySelector(`#${id}`).value = "")
    }

    const close = () =>{
        setVisible("")
        onClose()
        setSubmitDisabled(true)
        inputIds.forEach(id => {
            const input = document.querySelector(`#${id}`)
            if (input) input.value = ""
        })
        setCode([])
        setError('')
    }

    return(
        <Stack spacing={3} className={visible ? "popup mail-validate" : "popup close-popup"} style={{ transform: "translate(-50%, 50%)"}}>
            <Stack>
                <Typography variant="h2" textAlign="center">{text.creatememberspace}</Typography>
            </Stack>
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <Typography>
                        { text.createmsint11 } <Typography variant="bold">{ mail }</Typography> { text.createmsint12 }
                    </Typography>
                    <Typography>{text.createmsint2}</Typography>
                    <Stack display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                        {
                            inputIds.map((value, index) =>
                                <OutlinedInput readOnly={ index != ((code.length == inputIds.length) ? inputIds.length - 1 : code.length) }
                                    inputProps={{ maxLength: 1, style: {textAlign: "center"} }}
                                    style={{width: "40px", padding:"0"}} key={"inputcode" + index} id={value}
                                    onChange={e => codeEnter(index, e.target.value, e.key)}
                                    onKeyDown={e => {if (!e.target.readOnly) handleRemove(index, e.key)}}/>
                            )
                        }
                    </Stack>
                    <Typography textAlign="center">{text.createmsconsent} <Link to={RoutesList.pp} target="blank" className="link">{ text.termsAgreeLink1 }</Link></Typography>
                </Stack>
                {
                    error.length ? <Alert severity="error">
                        <Typography variant="error">{error}</Typography>
                    </Alert>
                    : null
                }
                <Stack display="flex" alignItems="center" justifyContent="space-evenly" flexDirection="row">
                    <Button disabled={submitDisabled} variant="hiring" onClick={verify} style={{textTransform: "capitalize"}}>{ loading ? <CircularProgress color="inherit" size="24px"/> : text.confirm}</Button>
                    <Button variant="outlined2" onClick={close}>{text.back}</Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default MailValidate