import { Typography, Stack, Box } from "@mui/material";
import React from "react";
import useLanguage from "../../hooks/useLanguage";
import IconTextCard from "../../tools/IconTextCard";
import AnimatedButton from "../../tools/AnimatedButton";
import CustomDivider from "../../tools/CustomDivider"
import { useParams } from "react-router-dom";

const Requirements = () =>{
    const { lang } = useParams()
    const { text } = useLanguage()
    const iconBaseUrl = "/images/chat-operator/4- What are the requirements to be a chat operator/"
    return(
        <Stack>
            <Stack alignItems='center' spacing={5} margin='35px 0'>
                <Typography className="title-typo text-resize-4" variant="title">
                    {text.corint1} <Typography variant="primarytitle2" className="title-typo-change-light-2">{text.cn}</Typography> {text.corint2}
                </Typography>
                <CustomDivider color="secondary" alignCenter width='22%'/>
                <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='80%'>
                    <IconTextCard iconSrc={iconBaseUrl+ "1_Proficiency_"+ lang + ".png"} label={text.cor11}>{text.cor12}</IconTextCard>
                    <IconTextCard iconSrc={iconBaseUrl+ "2. Age Limit.svg"} label={text.cor21}>{text.cor22}</IconTextCard>
                    <IconTextCard iconSrc={iconBaseUrl+ "3. Bank account.svg"} label={text.cor31}>{text.cor32}</IconTextCard>
                </Box>
                <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='80%'>
                    <IconTextCard iconSrc={iconBaseUrl+ "4. Terms and conditions.svg"} label={text.cor41}>{text.cor42}</IconTextCard>
                    <IconTextCard iconSrc={iconBaseUrl+ "5. Equipment.svg"} label={text.cor51}>{text.cor52}</IconTextCard>
                    <IconTextCard iconSrc={iconBaseUrl+ "6. Minimum message requirement.svg"} label={text.cor61}>{text.cor62}</IconTextCard>    
                </Box>
                <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='80%'>
                    <IconTextCard iconSrc={iconBaseUrl+ "7. Minimum weekly hours.svg"} label={text.cor71}>{text.cor72}</IconTextCard>
                    <IconTextCard iconSrc={iconBaseUrl+ "8. Taxes.svg"} label={text.cor81}>{text.cor82}</IconTextCard>
                    <IconTextCard iconSrc={iconBaseUrl+ "9. Guidlines.svg"} label={text.cor91}>{text.cor92}</IconTextCard>        
                </Box>
                <AnimatedButton to="apply-co">{text.applynow}</AnimatedButton>
            </Stack>
        </Stack>
    )
}

export default Requirements