import { Stack, Button, Typography, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Waypoint } from "react-waypoint";

const AnimatedButton = ({children, to=undefined, onClick= ()=>{}, loading=false}) => {
    const [animate, setAnimate] = useState(false)
    const scroll = () =>{
        const dest = document.getElementById(to);
        if (dest) dest.scrollIntoView({ behavior: 'smooth' });
            
    }
    return(
        <Stack alignItems='center' className={ animate ? "action-button-container" : "pre-action-button-container" }>
            <Button onClick={ to ? scroll : onClick} variant="action" className="action-button">
                {
                    loading ? <CircularProgress color="inherit" size="24px"/>
                    : <Typography variant="innerbutton">
                        {children}
                    </Typography>
                }
            </Button>
            <Waypoint onEnter={() => setAnimate(true)}/>
        </Stack>
    )
}

export default AnimatedButton