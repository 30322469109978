import React, { useContext } from "react";
import { MenuItem, Typography, Box } from "@mui/material";
import { AuthStateContext } from "../state/AuthStateContext";
import useLanguage from "../hooks/useLanguage";

const Logout = ({type, transparent=false, imgSrc}) =>{
    const { setAuthenticated } = useContext(AuthStateContext)
    const { text } = useLanguage()

    return( 
        transparent ?
        <Box className="hoverprimary menubox-logout" display="flex" flexDirection="row" gap={2} style={{alignItems: "center", width: "100%" ,cursor: 'pointer'}} onClick={()=> setAuthenticated(false, type)}>
            <img className="menubox-img"  src= {imgSrc} alt=""/>
            <Typography variant="tiny">{text.logout}</Typography>
        </Box>
        :
            <MenuItem style={{backgroundColor: '#f61b01', justifyContent: 'center'}} onClick={()=> setAuthenticated(false, type)}>
                <Typography variant="white">{text.logout}</Typography>   
            </MenuItem>     
    )
}

export default Logout