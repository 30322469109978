import React, { createContext, useState } from 'react'
import RoutesList from '../../helper/routes'

const AuthRedirectStateContext = createContext(false)

const AuthRedirectStateProvider = ({children}) => {
    const [redirectRoute, setRedirectRoute] = useState(RoutesList.dashboard)
    return (
        <AuthRedirectStateContext.Provider value={{
            redirectRoute, setRedirectRoute
        }}>
            {children}
        </AuthRedirectStateContext.Provider>
    )
}
export {AuthRedirectStateContext, AuthRedirectStateProvider}