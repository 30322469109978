import React from "react";
import { Stack, Typography,  Box} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import IconText from "../tools/IconText";
import AnimatedButton from "../tools/AnimatedButton";
import CustomDivider from "../tools/CustomDivider"
import { useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";

const Missions = () =>{
    const { text } = useLanguage()
    const navigate = useNavigate()
    return(
        <Stack>
            <Box className="wrap-content" display='flex' padding="100px 20px 0px 0px" gap='30px'>
                <Stack maxWidth='100%' display='flex' justifyContent='center' alignItems='center' gap='30px'  >
                    
                    <Stack alignItems='center'><img alt="" src='/images/MissionImage/2-our-missions-2e-ligne DEF.webp' width='100%'/></Stack>
                    <Stack alignItems='center'><AnimatedButton onClick={()=> navigate(RoutesList.jobs)}>{text.check}</AnimatedButton></Stack>
                    
                </Stack>
                <Stack spacing={2} minWidth="53%" id="missions" >
                    
                    <Stack className="title-typo" style={{width: 'fit-content'}}><Typography variant='blacksubtitle'>{text.our} <Typography variant='primarysubtitle' className="title-typo-change-light">{text.missions}</Typography></Typography></Stack>
                    <CustomDivider color="secondary" width='17%'/>
                    <Stack><IconText range='1' iconSrc='/icones/1- Our missions/1. Talent Identification and Acquisition.svg' children={text.mission1} /></Stack>
                    <Stack><IconText range='2' iconSrc='/icones/1- Our missions/2. Skill Assessment and Screening.svg' children={text.mission2} /></Stack>
                    <Stack><IconText range='3' iconSrc='/icones/1- Our missions/3. Cultural Fit and Values Alignment.svg' children={text.mission3} /></Stack>
                    <Stack><IconText range='4' iconSrc='/icones/1- Our missions/4. Position Customization.svg' children={text.mission4} /></Stack>
                    <Stack><IconText range='5' iconSrc='/icones/1- Our missions/5. Talent Development and Retention.svg' children={text.mission5} /></Stack>
                    <Stack><IconText range='6' iconSrc='/icones/1- Our missions/6. Market Analysis and Strategy.svg' children={text.mission6} /></Stack>
                    <Stack><IconText range='7' iconSrc='/icones/1- Our missions/7. Diversity and Inclusion.svg' children={text.mission7} /></Stack>
                    <Stack><IconText range='8' iconSrc='/icones/1- Our missions/8. Collaboration and Cross-Training.svg' children={text.mission8} /></Stack>
                    <Stack><IconText range='9' iconSrc='/icones/1- Our missions/9. Adaptation to Evolving Needs.svg' children={text.mission9} /></Stack>
                    <Stack><IconText range='10' iconSrc='/icones/1- Our missions/10. Continual Improvement.svg' children={text.mission10} /></Stack>
                    <Stack><IconText range='11' iconSrc='/icones/1- Our missions/11. Employee Satisfaction and Well-being.svg' children={text.mission11} /></Stack>
 
                </Stack>
            </Box>
            
        </Stack>
        
    )
}
export default Missions