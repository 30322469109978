import React from "react";
import { Box, Typography, Stack } from "@mui/material";
const IconText = ({range=null, iconSrc, children, white=false, center=true, bold=false, height='80px'}) =>{
    return(
        <Box display="flex" flexDirection="row" gap="10px" alignItems='center'>
            <Stack display='flex' justifyContent={center ? 'center' :'flex-start'} alignItems='center'>
                <img height={height} src={iconSrc} alt=""/>
            </Stack>
            <Stack display='flex' alignItems='center'>
                <Typography variant={white ? "white" : ""} fontWeight={bold ? 'bold' : 'regular'} alignItems='center'>{range ? range + ". " : ""}{children}</Typography>
            </Stack>
        </Box>
    )
}
export default IconText