import React, { useEffect, useState } from "react";
import { Grid, Typography, Stack, Checkbox, FormControlLabel, Select } from "@mui/material";
import Input from './input'
import CustomSelect from './select'
import { Controller } from "react-hook-form";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import useLanguage from "../hooks/useLanguage";
import FileInput from "./FileInput";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PdfDisplayer from "./PdfDisplayer";
import { baseURL } from "../../helper/conf";

const FormRow = ({label, idC='', control, envImg= ()=>{}, name, error, comp='TextInput', options=[] ,name2, setValue = ()=>{}, maxDate=new Date(), minDate=undefined, onChange=()=>{}, value = "", unRequired=false, prefix=""}) =>{
    const {text} = useLanguage()
    const maildomain = undefined // exemple: 'gmail.com'
    const domain = (maildomain) ? maildomain.replace('.', '\\.'): undefined
    const [undefinedDate, setUndefinedDate] = useState(false)
    const twoChars = n =>{ return `${(n<10) ? `0` : ``}${n}`}
    const getHourList = (min, max, jump=1) => {
        let list = []
        for (let i = min; i <= max; i+=jump) list.push({value: i, innerHTML: twoChars(i) + ':00'})
        return list
    }
    const getInputComponent = () =>{
        switch (comp){
            case 'OClockTimePicker': return <CustomSelect options={getHourList(0, 23)} name={name} control={control} error={error} onExternalChange={v => onChange(twoChars(v))}/>
            case 'readOnlyPDF': return <Stack style={{height: '100%', width: '100%', backgroundColor: '#f8f8f8', paddingLeft: '20px', boxSizing: 'border-box', justifyContent: 'center', display: 'flex', borderRadius: '3px'}}>
                {value && <PdfDisplayer file={`${baseURL}/pdf/resume/${value}`}/>}
            </Stack>
            case 'readOnly': return <Stack style={{height: '100%', width: '100%', backgroundColor: '#f8f8f8', paddingLeft: '20px', boxSizing: 'border-box', justifyContent: 'center', display: 'flex', borderRadius: '3px'}}>{value}</Stack>
            case 'Select': return <CustomSelect options={options} name={name} control={control} error={error} onExternalChange={onChange}/>
            case 'IndDate': return <Stack style={{height: '100%', position: 'relative'}}>
                <Controller
                    name={name}
                    control={control}
                    rules={{required: undefinedDate ? false : text.required}}
                    render={({field}) =>(
                        <DatePicker dateFormat='dd-MM-yyyy'showYearDropdown disabled={undefinedDate}
                            scrollableYearDropdown yearDropdownItemNumber={100} maxDate={maxDate} minDate={minDate}
                            className="datepicker" selected={field.value} onChange={field.onChange}/> 
                    )}
                />
                <FormControlLabel sx={{position: 'absolute', right: 0}} label={undefinedDate ? text.indfndt : text.dfndt} style={{color: undefinedDate ? '#4b4998' : undefined}} labelPlacement="end" control={<Checkbox icon={<DateRangeIcon/>} onChange={()=> {setValue(name, null); setUndefinedDate(s => !s)}} checkedIcon={<AllInclusiveIcon/>}/>}/>
                {error && <Typography variant="error" textAlign='left'>{text.required}</Typography>}
            </Stack>
            case 'IndDate': return <Stack style={{height: '100%', position: 'relative'}}>
                <Controller
                    name={name}
                    control={control}
                    rules={{required: undefinedDate ? false : text.required}}
                    render={({field}) =>(
                        <DatePicker dateFormat='dd-MM-yyyy'showYearDropdown disabled={undefinedDate}
                            scrollableYearDropdown yearDropdownItemNumber={100} maxDate={maxDate} minDate={minDate}
                            className="datepicker" selected={field.value} onChange={field.onChange}/> 
                    )}
                />
                <FormControlLabel sx={{position: 'absolute', right: 0}} label={undefinedDate ? text.indfndt : text.dfndt} style={{color: undefinedDate ? '#4b4998' : undefined}} labelPlacement="end" control={<Checkbox icon={<DateRangeIcon/>} onChange={()=> {setValue(name, null); setUndefinedDate(s => !s)}} checkedIcon={<AllInclusiveIcon/>}/>}/>
                {error && <Typography variant="error" textAlign='left'>{text.required}</Typography>}
            </Stack>
            case 'DatePicker': return <Stack style={{height: "100%"}}>
                <Controller 
                    name={name} 
                    control={control}
                    rules={{required: text.required}}
                    render={({ field }) => (
                        <DatePicker dateFormat='dd-MM-yyyy'showYearDropdown
                            scrollableYearDropdown yearDropdownItemNumber={100} maxDate={maxDate} minDate={minDate}
                            className="datepicker" selected={field.value} onChange={field.onChange}/> 
                    )}
                />
                {error && <Typography variant="error" textAlign='left'>{text.required}</Typography>}
            </Stack>
            case 'Mail': return <Input type="email" control={control} name={name} error={error} onChanges={onChange} prefix={prefix}/>
                
            case 'Image': return <FileInput name={name} control={control} error={error} onExportFile={envImg}
                accept="image/jpg,image/jpeg,image/webp,image/png" required visiblePartVariant="TotalInput"/>
            case 'FileName': return <FileInput name={name} control={control} error={error} onExportFile={envImg}
                required={!unRequired} visiblePartVariant="FileName" name2={name2} setValue={setValue}/>
            case 'Password': return <Input type="password" control={control} name={name} error={error}/>
            case 'DocxAndText': return <FileInput name="filetexttext" name2={name} control={control} error={error} onExportFile={envImg} setValue={setValue}
            accept=".docx" required2 visiblePartVariant="DocxAndText"/>
            default: return <Input control={control} idC={idC} name={name} error={error} onChanges={onChange} prefix={prefix}/>

        }
    }
    const input = getInputComponent()
    return(
        <Stack>
            <Grid container gap='15px'>
                <Grid item xs={3.5} style={{backgroundColor: '#ffebcd', minHeight: '50px'}}>
                    <Typography className="style-font-4" variant="table-head-text" style={{height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={7}>{ input }</Grid>
            </Grid>
        </Stack>
    )
}

export default FormRow