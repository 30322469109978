import { Stack, Typography, Box, OutlinedInput, Checkbox, FormControlLabel, InputAdornment, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import useLanguage from "../hooks/useLanguage";
import JobOfferCard from "./JobOfferCard";
import RoutesList from "../../helper/routes";
import { useNavigate } from "react-router-dom";
import CustomDivider from "../tools/CustomDivider";
import SearchIcon from '@mui/icons-material/Search';
import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import CloseIcon from '@mui/icons-material/Close';

const Jobs = () => {
    const { text } = useLanguage()
    const navigate = useNavigate()

    const [search, setSearch] = useState('')
    const [hiringJobs, setHiringJobs] = useState(false)
    const jobsData= [
        {details: ()=> navigate(RoutesList.chatoperator),name: text.jobco[0],desc: text.jobco[1],hiring: true,img: "2_Chat_operator_job_photos_2_ligne_1.webp",icon: "1_Chat_operator_icon_jobs"},
        {details: ()=> {} ,name: text.jobwdev[0],desc: text.webdevtext,hiring: false,img: "3_Web_developer_2_ligne_2.webp",icon: "2_Web_developer"},
        {details: ()=> navigate(RoutesList.jobsteamleader),name: text.jobtl[0],desc: text.tltext,hiring: true,img: "4_Team_leader_2_ligne_3.webp",icon: "3_Team_leader"},
        {details: ()=> {},name: text.jobdm[0],desc: text.digmartext,hiring: false,img: "5_Digital_marketing_specialist_3_ligne_1.webp",icon: "4_Marketing_Digital_Specialist"},
        {details: ()=> navigate(RoutesList.supervisor),name: text.jobsup[0],desc: text.supervisortext,hiring: true,img: "6_Supervisor_3_ligne_2.webp",icon: "5_Supervisor"},
        {details: ()=> {},name: text.jobwdes[0],desc: text.webdestext,hiring: false,img: "7_Web_designer_3_ligne_3.webp",icon: "6_Web_designer"},
    ]

    return(
        <Stack>
            <Stack spacing={3}>
                <Stack style={{position: "relative"}}>
                    <Stack alignItems="center" overflow="hidden">
                        <img src={ "/images/jobs/1-jobs-photo-1ere-ligne DEF.webp"} style={{width: '120%'}}/>
                    </Stack>
                    <Stack className="text-replace" alignItems="center" style={{position: 'absolute', top: "50%"}} spacing={2}>
                        <Typography variant="whitetitle" textAlign='center' style={{fontWeight: 'bold'}}>{text.jobsint1}</Typography>
                        <CustomDivider color="white" alignCenter width='17%'/>
                        <Typography  variant="secondaryh2" textAlign='center' style={{fontWeight: 'bold'}}>{text.jobsint2}</Typography>
                    </Stack>
                </Stack>
                <Stack alignItems='center' spacing={3}>
                    <Box className="wrap-content" gap='20px'>
                        <Stack>
                            <Typography className="title-typo" variant="title">{text.jobsat} <Typography variant="primarytitle2" className="title-typo-change-light-2">{text.cn}</Typography></Typography>
                            <CustomDivider color="secondary" alignCenter width='17%'/>
                        </Stack>
                        <Stack flexDirection="row"alignItems='center'>                  
                            <OutlinedInput
                                id="search-input"
                                style={{borderRadius: '15px', backgroundColor: 'transparent'}}
                                endAdornment={<InputAdornment position="end"><IconButton sx={{'&:hover': (search.length>0) ? { background: 'transparent', color: '#fdc309' }: {background: 'transparent'}}} onClick={()=>{ if(search.length>0){ document.querySelector('#search-input').value = ""; setSearch("") }}}>{ (search.length>0) ? <CloseIcon/> : <SearchIcon/>}</IconButton></InputAdornment>}
                                onChange={e => setSearch(e.target.value)}
                            />
                            <FormControlLabel label={hiringJobs ? text.pnjbs : text.lljbs} labelPlacement="bottom" onClick={()=> setHiringJobs(s => !s)} control={<Checkbox className="cb" icon={<WorkOutlineIcon/>} checkedIcon={<WorkIcon/>} sx={{'&:hover': { background: 'transparent', color: '#fdc309' }}}/>}/>
                        </Stack>
                    </Box>
                    <Box className="wrap-content" flexWrap='wrap' justifyContent="space-evenly" style={{ width: '90%' }}>
                        {jobsData
                        .sort((a, b) => {
                            if (a.hiring && !b.hiring) return -1
                            if (!a.hiring && b.hiring) return 1
                            return 0
                        })
                        .filter(j => ((j.hiring) || (!hiringJobs)) && (j.name.toLowerCase().includes(search.toLowerCase())))
                        .map((j, i) => <JobOfferCard key={`job${i}`} onEnter={j.details} jobName={j.name} description={j.desc} hiring={j.hiring}imgFileName={j.img} IconFileName={j.icon}/>)}                       
                    </Box>
                </Stack>        
            </Stack>
        </Stack>
    )
}

export default Jobs