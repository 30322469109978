import { Stack, Typography, Button, CircularProgress, OutlinedInput, Alert } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import useLanguage from "../../hooks/useLanguage";
import { useForm } from 'react-hook-form'
import Input from "../../tools/input";
import axiosInstance from "../../../axios";
import { mdpminlength } from "../../../helper/conf";
import { AuthStateContext } from "../../state/AuthStateContext";
import { roles } from "../../../helper/roles";
import { useNavigate } from "react-router-dom";
import { AuthRedirectStateContext } from "../../state/AuthRedirectStateContext";

const PasswordForgot = ({visible, onClose}) =>{
    const [step, setStep] = useState(1)
    const { text, currentLanguage } = useLanguage()
    const [ loading, setLoading ] = useState(false)
    const { setAuthenticated } = useContext(AuthStateContext)
    const { redirectRoute } = useContext(AuthRedirectStateContext)

    const { control, handleSubmit, formState: {errors} } = useForm()
    const [ mailGet, setMailGet ] = useState('')
    const [error, setError] = useState('')
    
    const inputIds = ['code0', 'code1', 'code2', 'code3', 'code4', 'code5']
    const [ code, setCode ] = useState([])
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const navigate = useNavigate()
    
    const { control: control2, handleSubmit: handleSubmit2, formState: {errors: errors2} } = useForm()

    useEffect(()=> setSubmitDisabled(code.length != inputIds.length), [code])
    useEffect(()=> { {
        const input0 = document.querySelector(`#${inputIds[0]}`)
        if ((step==2) && input0) input0.focus()
    } },[step])

    const sendMail = async datas =>{
        const { mail } = datas
        setLoading(true)
        setError('')
        try {
            const { status } = await axiosInstance.post(`/send_mail_login_check/${currentLanguage}`, { email: mail })
            if (status == 200){
                setMailGet(mail)
                setStep(2)
            }
        } catch (error) {
            if (error.response.status == 400) setError(text.invalidemail)
            else setError(text.smthngwntwrng) 
        }
        setLoading(false)
    }

    const codeEnter = (index, value) =>{
        if (['0','1','2','3','4','5','6','7', '8','9'].includes(value)){
            setCode([...code, value])
            if (index < inputIds.length -1) document.querySelector(`#code${index +1}`).focus()
        } else document.querySelector(`#code${index}`).value = ""
    }

    const handleRemove = (index, key) =>{
        if (key === 'Backspace'){
            const newcode = [...code]
            newcode.pop()
            setCode(newcode)
            if ((index == inputIds.length - 1) && (code.length == inputIds.length) ) 
                document.querySelector(`#code${index}`).value = ""
            else if (index > 0) {
                document.querySelector(`#code${index -1}`).focus()
                document.querySelector(`#code${index -1}`).value = ""
            } else document.querySelector(`#code0`).value = ""
        }
    }

    const verify = async () =>{
        setLoading(true)
        setError('')
        try{
            const { status } = await axiosInstance.post('/check_code_login', { email: mailGet, validation_code: code.join('') })
            if (status == 200) setStep(3)
        } catch (error) {
            if (error.response.status == 404) setError(text.codevalidationerror)
            else setError(text.smthngwntwrng)
        }
        inputIds.forEach(id => document.querySelector(`#${id}`).value = "")
        setCode([])
        setLoading(false)
    }

    const backtoFirstStep = () =>{
        setSubmitDisabled(true)
        inputIds.forEach(id => {
            const input = document.querySelector(`#${id}`)
            if (input) input.value = ""
        })
        setCode([])
        setStep(1)
    }

    const updatePassword = async data =>{
        const { password } = data
        if (password.length < mdpminlength) setError(text.passwordlength)
        else{
            setLoading(true)
            setError('')
            try {
                const { status, data:{ token } } = await axiosInstance.post(`/change_password/${currentLanguage}`, { email: mailGet, new_password: password })
                if (status == 200) {
                    await setAuthenticated(true, roles.candidat, token)
                    navigate(redirectRoute)
                    onClose()
                }        
            } catch (error) {
                
            }
            setLoading(false)
        }
    }

    const getComp = () =>{
        switch (step){
            case 1: return <>
                <Typography>{text.entryrml}</Typography>
                <Input control={control} type='email' name='mail' error={errors.mail}/>
                { error.length ? <Alert style={{margin: "20px"}} severity="error">
                    <Typography>{error}</Typography>
                </Alert> : null }
                <Stack display="flex" alignItems="center" justifyContent="space-evenly" flexDirection="row">
                    <Button variant="hiring" onClick={handleSubmit(sendMail)} style={{textTransform: "capitalize"}}>{ loading ? <CircularProgress color="inherit" size="28px"/> : text.confirm}</Button>
                    <Button variant="outlined2" onClick={onClose}>{text.back}</Button>
                </Stack>
            </>
            case 2: return <>
                <Typography>
                    { text.createmsint11 } <Typography variant="bold">{ mailGet }</Typography> { text.createmsint12 }
                </Typography>
                <Stack display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                    {
                        inputIds.map((value, index) =>
                            <OutlinedInput readOnly={ index != ((code.length == inputIds.length) ? inputIds.length - 1 : code.length) }
                                inputProps={{ maxLength: 1, style: {textAlign: "center"} }}
                                style={{width: "40px", padding:"0"}} key={"inputcode" + index} id={value}
                                onChange={e => codeEnter(index, e.target.value, e.key)}
                                onKeyDown={e => {if (!e.target.readOnly) handleRemove(index, e.key)}}/>
                        )
                    }
                </Stack>
                { error.length ? <Alert style={{margin: "20px"}} severity="error">
                    <Typography>{error}</Typography>
                </Alert> : null }
                <Stack display="flex" alignItems="center" justifyContent="space-evenly" flexDirection="row">
                    <Button disabled={submitDisabled} variant="hiring" onClick={verify} style={{textTransform: "capitalize"}}>{ loading ? <CircularProgress color="inherit" size="28px"/> : text.confirm}</Button>
                    <Button variant="outlined2" onClick={backtoFirstStep}>{text.back}</Button>
                </Stack>
            </>
            case 3: return <>
                <Typography>{text.entrynwpss}</Typography>
                <Input control={control2} type='password' name='password' error={errors2.password}/>
                { error.length ? <Alert style={{margin: "20px"}} severity="error">
                    <Typography>{error}</Typography>
                </Alert> : null }
                <Stack alignItems='center'>
                    <Button variant="hiring" onClick={handleSubmit2(updatePassword)} style={{textTransform: "capitalize"}}>{ loading ? <CircularProgress color="inherit" size="28px"/> : text.confirm}</Button>
                </Stack>
            </>
            default: return <></>
        }
    }
    
    return(
        <Stack spacing={2} className={visible ? 'popup password-forgot' : 'popup close-popup'} style={{ transform: 'translateX(-50%)' }}>
            <Typography variant="h2" textAlign='center'>{text.rcpcpt}</Typography>
            <Stack spacing={2}>
                { getComp() }
            </Stack>
        </Stack>
    )
}

export default PasswordForgot