import React, { useState } from "react";
import { Stack, Button } from "@mui/material";
import { roles } from "../../helper/roles";
import UserTitle from "../tools/UserTitle";
import useLanguage from "../hooks/useLanguage";
import PositionOverview from "./PositionOverview";
import JobPosts from "../Admin/JobPosts2";
import JobApplication from "../Admin/JobApplication3";
import NewEmpTraining from "../Admin/NewEmpTraining4";
import EmpIntegration from "../Admin/EmpIntegration5";

const Manager = () =>{
    const [state, setState] = useState(0)
    const {text} = useLanguage()
    const currentComponent = () =>{
        switch (state){
            case 0: return <PositionOverview seeDetails={setState}/>
            case 1: return <JobPosts/>
            case 2: return <JobApplication/>
            case 3: return <NewEmpTraining/>
            case 4: return <EmpIntegration switchToTraining={()=> setState(3)}/>
            default: return <></>
        }
    }

    return(
        <Stack variant="primarybg" className="height-max-adjust" spacing={3}>
            <UserTitle variant={roles.manager} inverseDisplay/>
            { currentComponent() }
            { 
                (state != 0) ?
                    <Stack padding='3px' className="card-button-resize" 
                    style={{backgroundColor: '#ba80ed', borderRadius: '20px', alignSelf: 'center'}}>
                        <Button variant="rose" onClick={()=>setState(0)}>{text.back}</Button> 
                    </Stack>
                : <></>
            }
        </Stack>
    )
}
export default Manager