import { Stack, Typography, Button, CircularProgress } from "@mui/material";
import React, {useState, useContext, useEffect} from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import {useForm} from 'react-hook-form'
import FormRow from "../tools/FormRow";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { useLocation } from "react-router-dom";
import RoutesList from "../../helper/routes";
import Alert from '@mui/material/Alert';
import { format } from "date-fns";
import generateFormData from "../../helper/formData";
import countriesList from "../../helper/countries";
import { addDays } from "date-fns";
import Input from "../tools/input";
import CustomSelect from "../tools/select";
import { roles } from "../../helper/roles";

const BlogPosts = ({blogger=false}) =>{
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        language: 'all',
        activeblogger: ''
    })
    const [filter2, setFilter2] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        language: 'all',
        category: 'all',
        activeblogger: '',
        blogposttitle: ''
    })

    const { control: control1, handleSubmit: handleSubmit1, formState: {errors: errors1} } = useForm()
    const { control: control2, handleSubmit: handleSubmit2, formState: {errors: errors2}, setValue: setValue2 } = useForm()
    const { control: control3, handleSubmit: handleSubmit3, formState: {errors: errors3} } = useForm()
    const { control: editControl, handleSubmit: handleEdit, setValue, formState: {errors: editErrors} } = useForm()
    const [editing, setEditing] = useState(0)

    const [posts, setPosts] = useState([])
    const location = useLocation()
    const { bloggerId, adminId } = useContext(AuthStateContext)

    const { text } = useLanguage()
    
    const [filterCat, setFilterCat] = useState([{value: 'all', innerHTML: text.all }])
    
    const getToken = () =>{
        return (RoutesList.blogger.split('/')[1] == location.pathname.split('/')[1])
        ? bloggerId
        : adminId
    }
    getToken()

    const newBlogger = async datas =>{
        const login = datas.login
        setLoading(true)
        setError('')
        try {
            const resp = await axiosInstance.post(`/update_role/${login}`, generateFormData({role: "blogger"}) , {
                headers:{
                    'Authorization': `Bearer ${adminId}`,
                    'Content-Type': 'multipart/form-data'
                },
                transformRequest: null
            })
            if (resp.status == 200){
                setError('newbloggersucc')
                window.location.reload()
            }
        } catch (error) {
            if (error.response.status === 400) { (error.response.data.error === "Rôle non autorisé") ? setError('rolenonaut'): setError('djblog')};
            if (error.response.status === 403) setError('accref');
            if (error.response.status === 404) setError('usrntfnd') ;
            if (error.response.status !== 400 && error.response.status !== 403 && error.response.status !== 404) setError('smth') ;
        }
        setLoading(false)
    }
   


    useEffect(()=>{
        const posts = async () =>{try {
            const { data } = await axiosInstance.get(`/posts_all`, { headers: {'Authorization': `Bearer ${getToken()} `} })
            setPosts(data)
            console.log(data)
        } catch (err){}}
        posts()  
    },[]) 

    const [usersRole, setUsersRole] = useState([])

    useEffect(()=>{
        const funct = async () =>{try {
            const { data } = await axiosInstance.get(`/users/${roles.blogger}`, { headers: {'Authorization': `Bearer ${adminId} `} })
            setUsersRole(data)
        } catch (err){}}
        funct()  
    },[]) 

    const [category, setCategory] = (useState([]))
    const [selectedLanguage, setSelectedLanguage] = useState('fr')

    useEffect(()=>{
        const categoryFunct = async () =>{try {
            const { data } = await axiosInstance.get(`/category_all`, { headers: {'Authorization': `Bearer ${getToken()} `} })
            console.log(data)
            setCategory(data)
            let l = [{value: 'all', innerHTML: text.all }]
            data.forEach(d=> l.push({value: d.name, innerHTML: d.name }))
            setFilterCat(l)
        } catch (err){}}
        categoryFunct()  
    },[])


    const [illPhoto, setIllPhoto] = useState(null)
    const newBlogPost = async data => {
        const { language, blogpostcategory, posttitle, posttext } = data;
        setLoading(true)
        setError('')
        const formData = new FormData();
        formData.append('language', language);
        formData.append('title', posttitle);
        formData.append('content', posttext);
        formData.append('category', blogpostcategory);
        formData.append('image', illPhoto);
        
        try {
            const { status } = await axiosInstance.post(`/post/create/${language}`, formData, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data',
                },
                transformRequest: null
            });
    
            if (status === 201) {
                setError('succ')
                window.location.reload()
            }
        } catch (error) {
            if (error.response.status === 400) setError('cat') ;
            if (error.response.status === 403) setError('accref');
            if (error.response.status === 404){if (error.response.data.error =="Langue introuvable") {setError('lang')} else setError('smth')}
            if (error.response.status !== 400 && error.response.status !== 403 && error.response.status !== 404) setError('smth') ;
            console.log(error.response);
        }
        setLoading(false)

    
    };
    
    const [loadingActions, setLoadingActions] = useState(0)

    const deleteBlogger = async id =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.delete(`/delete_user/${id}`, {headers: { 'Authorization': `Bearer ${adminId}`}})
            const newList = usersRole.filter(u => u.id !== id)
            setUsersRole(newList)
            if (status === 200) {
                setError('suppr')
            }
        } catch (error) {
            if (error.response.status === 401) {setError('dcnnctd')}
            if (error.response.status === 403) {setError('accref')}
            if (error.response.status === 404) {setError('usrntfnd')}
        }
        setLoadingActions(0)
    }

    const deletePost = async id =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.delete(`/post/delete/${id}`, {headers: { 'Authorization': `Bearer ${getToken()}`}})
            const newList = posts.filter(p => p.id !== id)
            setPosts(newList)
            if (status === 200) {
                setError('suppr')
            }
        } catch (error) {
            if (error.response.status === 403) {
                if (error.response.data.message === 'Accès refusé') setError('accref') ;
                if (error.response.data.message === 'Vous n\'avez pas l\'autorisation de supprimer ce post.') setError('nonaut') ;
            }
        }
        setLoadingActions(0)
    }


    const newCategory = async data => {
        const { category, illustration, language } = data;
        setLoading(true)
        setError('')
        const formData = new FormData();
        formData.append('name', category);
        formData.append('image', illustration);
        formData.append('language', language);
        
        try {
            const {status} = await axiosInstance.post(`/category/create`, formData, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data',
                },
                transformRequest: null
            })
    
            if (status === 201) {
                setError('succ')
                window.location.reload()
            }
        } catch (error) {
            if (error.response.status === 400) setError('cat') ;
            if (error.response.status === 403) setError('accref');
            if (error.response.status !== 400 && error.response.status !== 403) setError('smth') ;
        }
        setLoading(false)
    
    };

    const { testLanguages, blogPostsList, bloggerList, categoryList, languageName , filterLanguages} = useData()
    

    const getLanguage = lang =>{
        
        switch (lang){
            case 'fr': return text.french
            case 'en': return text.english
            case 'it': return text.italian
            case 'nl': return text.dutch
            case 'es': return text.spanish
            case 'de': return text.german
            default: return text.english;
        }
    }

    const getCategory = cat =>{
        
        switch (cat){
            case 'uncategorized': return text.uncategorized; break
            default: return text.uncategorized;
        }
    }

    const [login, setLogin] = useState('')
    const [newLists, setNewLists] = useState([])
    const [user, setUser] = useState([])

    useEffect(() => {
        const funct = async () =>{
            try {
                const {data}  = await axiosInstance.get(`/users_all`, { headers: {'Authorization': `Bearer ${adminId} `} })
                const lists = data.filter(r => r.role?.name !== "blogger")
                setNewLists(lists)
            } catch (error) {}
        }
        funct()
    }, [])

    useEffect(() => {
        const users = newLists.find(u => u.mail.includes(login))
        setUser(login.length ? users : [])
    }, [login])

    const suspend = async id =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.post(`/suspend/${id}`, {}, {headers: { 'Authorization': `Bearer ${adminId}`}})
            const newList = [...usersRole]
            const i = usersRole.findIndex(c => c.id == id)
            newList[i].suspend = addDays(new Date(), 15)
            setUsersRole(newList)
            if (status === 200) {
                setError('susp')
            }
        } catch (error) {
            if (error.response.status === 401) {setError('dcnnctd')}
            if (error.response.status === 403) {setError('accref')}
            if (error.response.status === 404) {setError('usrntfnd')}
        }
        setLoadingActions(0)
        
    }

    const showEditForm = blog =>{
        setEditing(0)
        setEditing(blog.id)
        setValue('title', blog.title_Post)
        setValue('category', blog.category?.name)
    }

    const editBlog = async data =>{
        const { title, category } = data
        setLoadingActions(editing)
        try {
            const {status} = await axiosInstance.put(`/post/edit/${editing}`, { title, category}, {headers: { 'Authorization': `Bearer ${getToken()}` }})
            const newList = [...posts]
            const i = posts.findIndex(p => p.id == editing)
            newList[i].title_Post = title
            newList[i].category_id = category
            setPosts(newList)

            if (status === 200) {
                setError('save')
            }

        } catch (error) {

        }
        setEditing(0)
        setLoadingActions(0)
    }

    const getError = error =>{
        
        switch (error){
            case 'succ': return <Typography variant= "success" >{text.catsuccess}</Typography>
            case 'save': return <Typography variant= "success" >{text.svd}</Typography>
            case 'cat': return <Typography variant="error">{text.catexst}</Typography>
            case 'accref': return <Typography variant="error">{text.accref}</Typography>
            case 'nonaut' : return <Typography variant="error">{text.nonaut}</Typography>
            case 'smth': return <Typography variant="error">{text.smthngwntwrng}</Typography>
            case 'newbloggersucc': return <Typography variant= "success" >{text.newbloggersucc}</Typography>
            case 'rolenonaut': return <Typography variant="error">{text.rolenonaut}</Typography>
            case 'djblog' : return <Typography variant="error">{text.djblog}</Typography>
            case 'usrntfnd' : return <Typography variant="error">{text.usrntfnd}</Typography>
            case 'suppr': return <Typography variant="success">{text.dltd}</Typography>
            case 'dcnnctd': return <Typography variant="success">{text.dcnnctd}</Typography>
            case 'susp': return <Typography variant= "success" >{text.sspndd}</Typography>
            default: return <Typography variant="error">{text.smthngwntwrng}</Typography>;
        }
    }

    useEffect(() => {
        if (error) {
          const timeout = setTimeout(() => {
            setError(null);
          }, 4000);
    
          return () => clearTimeout(timeout);
        }
      }, [error]);


    return(
        <Stack variant="primarybg" spacing={3}>
            <Stack style={{display: 'flex', gap:'15px', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                <img className="card-img-resize-2" src='/images/Admin/blog_posts.png' alt=""/>
                <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#fe1622' fontWeight='bold' 
                    style={{WebkitTextStroke: "1px #7e0000"}}
                >{text.blogposts.toUpperCase()  }</Typography>
            </Stack>
            { blogger ? <></> : 
                <BigContainerAccordion 
                    summaryBg="#1cb743"
                    summary={text.abp1}
                    details={
                        <Stack spacing={2}>
                            <SearchFilter
                                selectedDate={filter.date}
                                onDateChange={{
                                    year: y => setFilter({...filter, year: y}),
                                    month: m => setFilter({...filter, month: m}),
                                    date: d => setFilter({...filter, date: d}),
                                }}
                                items= {[
                                    { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                    { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                    { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter({...filter, language: l.target.value})},
                                    { label: text.activeblogger , comp: 'TextInput', onChange: e => setFilter({...filter, activeblogger: e.target.value}) },
                                ]}
                            />
                            <Stack>
                                <TableRow header columns={[
                                    { xs: 2, text: text.date },
                                    { xs: 2, text: text.bloggerfirstname },
                                    { xs: 2, text: text.bloggerlastname },
                                    { xs: 1, text: text.language },
                                    { xs: 3, text: text.bloggerloginid },
                                    { xs: 1, text: text.sspndd },
                                    //{ xs: 1, text: text.bloggerloginpassword },
                                    { xs: 1, text: text.actions },
                                ]} />
                                {
                                    usersRole
                                    .filter(a => 
                                        // Starting date
                                        ( (!filter.startingDate) || ( filter.startingDate <= new Date(a.created_at)) )
                                        // Closing date
                                        && ( (!filter.closingDate) || (new Date(a.created_at) <= filter.closingDate) )
                                        // Text match
                                        && a.mail?.toLowerCase().includes(filter.activeblogger)
                                        // Language
                                        && ( (filter.language == 'all') || (a.language?.name == filter.language))  
                                    )
                                    .map(b =>
                                        <TableRow key={b.id} columns={[
                                            { xs: 2,  text: b.created_at ? format(new Date(b.created_at), 'dd/MM/yyyy') : ''},
                                            { xs: 2,  text: b.first_name},
                                            { xs: 2,  text: b.last_name},
                                            { xs: 1,  text: languageName(b.language?.name)},
                                            { xs: 3,  text: b.mail},
                                            //{ xs: 1, customContent: true, content:
                                            //  <Stack width='100%'>
                                            //    <Button style={{ textTransform: 'capitalize', color: '#0b0', width: '100%' }}><Typography variant="boldcenter">{text.edit}</Typography></Button>
                                            //   </Stack>
                                            //},
                                            {   xs: 1,
                                                color: '#f00' ,
                                                text: (b.suspend ? (new Date() < new Date(b.suspend)) : "") ? text.sspndd : ""
                                            },
                                            { xs: 1,  customContent: true, content: 
                                                (loadingActions == b.id) ? <CircularProgress/>
                                                :
                                                    ((b.suspend ? (new Date() < new Date(b.suspend)) : "") ?
                                                        <Stack width='100%'>
                                                            <Button onClick={() => deleteBlogger(b.id)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                        </Stack>
                                                    :
                                                        <Stack width='100%'>
                                                            <Button onClick={() => suspend(b.id)} style={{textTransform: 'capitalize', color: '#e37584', width: '100%'}}><Typography variant="boldcenter">{text.suspend}</Typography></Button>
                                                            <Button onClick={() => deleteBlogger(b.id)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                        </Stack>
                                                    )
                                                    
                                            },
                                        ]} />
                                    )
                                }
                            </Stack>
                            {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error=='suppr' || error=='succ' || error=='susp') ? "success" : "error"}>
                                {getError(error)}
                            </Alert>}
                        </Stack>
                    }
                />
            }
            
            { blogger ? <></> :
                <BigContainerAccordion 
                    summaryBg="#01e1e9"
                    summary={text.abp2}
                    details={
                        <Stack spacing={2}>
                            {/*<FormRow label={text.jobtitle} control={control1} name='jobtitle' error={errors1.jobtitle}/>
                            <FormRow comp="Select" label={text.candidateid} control={control1} name='candidateid' error={errors1.candidateid}/>
                            <FormRow comp="Select" options={testLanguages} label={text.language} control={control1} name='language' error={errors1.language}/>*/}
                            <FormRow label={text.email} name="login" control={control1} error={errors1.login} onChange={v=> setLogin(v)}/>
                            <FormRow label={text.firstname} comp="readOnly" value={user?.first_name}/>
                            <FormRow label={text.lastname} comp="readOnly" value={user?.last_name}/>
                            <FormRow label={text.language} comp="readOnly" value={ user ? languageName(user.language?.name) : ''}/>
                            <FormRow label={text.country} comp="readOnly" value={ user ? ((countriesList.find(c => c.value === user.country))?.innerHTML) : ''}/>
                            <FormRow label={text.skypeid} comp="readOnly" value={user?.skype}/>
                            {/*<FormRow label={text.password} comp="readOnly"/>*/}
                            <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <Button onClick={handleSubmit1(newBlogger)} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                    {
                                        loading ? <CircularProgress color="inherit" size="24px"/>
                                        : <Typography>{text.createnewblogger}</Typography>      
                                    }
                                </Button>
                            </Stack>
                            {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ' || error=='newbloggersucc') ? "success" : "error"}>
                                {getError(error)}
                            </Alert>}
                        </Stack>
                    }
                />
            }
            
            <BigContainerAccordion 
                summaryBg="#d415ff"
                summary={text.abp3}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                                date: d => setFilter2({...filter2, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter2.startingDate, onChange: d => setFilter2({...filter2, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter2.closingDate, onChange: d => setFilter2({...filter2, closingDate: d})},
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter2({...filter2, language: l.target.value}) },
                                { label: text.activeblogger , comp: 'TextInput', onChange: e => setFilter2({...filter2, activeblogger: e.target.value}) },
                                { label: text.category , comp: 'Select', choices: filterCat ,onChange: l => setFilter2({...filter2, category: l.target.value}) },
                                { label: text.blogposttile , comp: 'TextInput', onChange: e => setFilter2({...filter2, blogposttitle: e.target.value}) },
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 2, text: text.date },
                                { xs: 2, text: text.bloggerloginid },
                                { xs: 2, text: text.language },
                                { xs: 2, text: text.blogposttile },
                                { xs: 2, text: text.blogpostcategory },
                                //{ xs: 1, text: text.blogpostlink },
                                { xs: 2, text: text.actions },
                            ]} />
                            {
                                posts
                                .filter(a => 
                                    // Starting date
                                    ( (!filter2.startingDate) || ( filter2.startingDate <= new Date(a.created_at)) )
                                    // Closing date
                                    && ( (!filter2.closingDate) || (new Date(a.created_at) <= filter2.closingDate) )
                                    // Text match
                                    && a.author.mail?.toLowerCase().includes(filter2.activeblogger)
                                    // Language
                                    && ( (filter2.language == 'all') || (a.post_language?.name == filter2.language)) 
                                    // Category (Select) 
                                    && ( (filter2.category == 'all') || (a.category?.name == filter2.category))
                                    // Text match 
                                    && a.title_Post?.toLowerCase().includes(filter2.blogposttitle)
                                )
                                .map(b =>
                                    <TableRow key={b.id} columns={[
                                        { xs: 2, text: b.created_at ? format(new Date(b.created_at), 'dd/MM/yyyy') : '' },
                                        { xs: 2, text: b.author.mail },
                                        { xs: 2, text: b.post_language.map(p => <Typography key={p.id}>{getLanguage(p.name)}</Typography>)},
                                        //{ xs: 2, text: b.title_Post },
                                        { xs: 2, text: b.title_Post, customContent: (editing == b.id), content: <Input max control={editControl} name="title" type='text' error={editErrors.title}/>},
                                        //{ xs: 2, text: b.category.name},
                                        { xs: 2, text: b.category.name,
                                             customContent: (editing == b.id), content: <CustomSelect max control={editControl} name="category" error={editErrors.category} options={category.filter(c => c.language?.name == b.post_language[0]?.name).map(c=> ({value: c.name, innerHTML:c.name}))} /> },
                                           
                                        //{ xs: 2, text: getCategory(b.category.name)},
                                        //{ xs: 1, customContent: true, content:
                                        //    <Stack width='100%'>
                                        //        <Button style={{ textTransform: 'capitalize', color: '#0b0', width: '100%' }}><Typography variant="boldcenter">{text.edit}</Typography></Button>
                                        //    </Stack>
                                        //},
                                        { xs: 2, customContent: true, content:
                                            (loadingActions == b.id) ? <CircularProgress/>
                                            :
                                            (
                                                (editing == b.id)
                                                ?<Stack width='100%'>
                                                    <Button onClick={handleEdit(editBlog)} style={{ textTransform: 'capitalize', color: '#0b0', width: '100%' }}><Typography variant="boldcenter">{text.save}</Typography></Button>
                                                    <Button onClick={()=> setEditing(0)} style={{ textTransform: 'capitalize', color: '#3c6dc6', width: '100%' }}><Typography variant="boldcenter">{text.back}</Typography></Button>
                                                </Stack>
                                                :<Stack width='100%'>
                                                <Button onClick={()=> showEditForm(b)} style={{ textTransform: 'capitalize', color: '#3c6dc6', width: '100%' }}><Typography variant="boldcenter">{text.edit}</Typography></Button>
                                                <Button onClick={()=> deletePost(b.id)} style={{ textTransform: 'capitalize', color: '#f00', width: '100%' }}><Typography variant="boldcenter">{text.delete}</Typography></Button>
                                            </Stack>
                                            )
                                       },
                                    ]} />
                                )
                            }
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ' || error == 'suppr' || error == 'save') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>}
                    </Stack>
                }
            />

            <BigContainerAccordion 
                summaryBg="#f45a52"
                summary={text.addblogpostcategory}
                details={
                    <Stack spacing={2}>
                        {/*<FormRow label={text.blogpostcategoryid} comp="readOnly"/>*/}
                        <FormRow label={text.blogpostcategory} control={control3} name='category' error={errors3.category}/>
                        <FormRow comp="Select" options={testLanguages} label={text.language} control={control3} name='language' error={errors3.language}/>
                        <FormRow comp="Image" label={text.illustrationphoto} control={control3} name='illustration' error={errors3.illustration}/>
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit3(newCategory)} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    loading ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>{text.addblogpostcategory}</Typography>      
                                }
                            </Button>
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>}
                    </Stack>
                }
            />

            <BigContainerAccordion 
                summaryBg="#f4a100"
                summary={text.abp4}
                details={
                    <Stack spacing={2}>
                        {/*
                            <FormRow label={text.blogpostid} comp="readOnly"/>
                            <FormRow comp="Select" label={text.bloggerid} control={control2} name='bloggerid' error={errors2.bloggerid}/>
                        */}
                        <FormRow comp="Select" options={testLanguages} label={text.language} control={control2} name='language' error={errors2.language} onChange={setSelectedLanguage}/>
                        <FormRow comp="Select" options={category.filter(c => c.language?.name === selectedLanguage).map(c=> ({value: c.name, innerHTML:c.name}))} label={text.blogpostcategory} control={control2} name='blogpostcategory' error={errors2.blogpostcategory}/>
                        <FormRow label={text.blogposttitle} control={control2} name='posttitle' error={errors2.posttitle}/>
                        <FormRow label={text.blogposttext} comp="DocxAndText" setValue={setValue2} control={control2} name='posttext' error={errors2.posttext}/>
                        <FormRow comp="Image" label={text.illustrationphoto} envImg={setIllPhoto} control={control2} name='illustration' error={errors2.illustration}/>
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit2(newBlogPost)} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    loading ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>{text.publishnewblogpost}</Typography>      
                                }
                            </Button>
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'succ') ? "success" : "error"}>
                            {
                                (error=='succ') ? <Typography variant= "success" >{text.succ}</Typography>: 
                                ((error=='cat') ? <Typography variant="error">{text.cat}</Typography>:
                                    ((error=='accref') ? <Typography variant="error">{text.accref}</Typography>: 
                                        (error=='lang') ? <Typography variant="error">{text.lang}</Typography>:
                                        <Typography variant="error">{text.smthngwntwrng}</Typography>
                                    )
                                )
                            }
                        </Alert>} 
                    </Stack>
                }
            />
        </Stack>
    )
}

export default BlogPosts