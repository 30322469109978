import { MenuItem, Box, Stack, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";

const TimeRange = (props, white=false) =>{
    const { text } = useLanguage()
    
    const twoChars = n =>{ return `${(n<10) ? `0` : ``}${n}`}
    const getList = (min, max, jump=1) => {
        let list = []
        for (let i = min; i <= max; i+=jump) list.push(i)
        return list
    }
    const [ startHList, setStartHList ] = useState(getList(0, 23))
    const [ endHList, setEndHList ] = useState(getList(0, 23))

    const [sh, setSH] = useState(0)
    const [sm, setSM] = useState(0)
    const [eh, setEH] = useState(23)
    const [em, setEM] = useState(59)

    useEffect(()=> props.onChange({
        startTime: `${twoChars(sh)}:${twoChars(sm)}`,
        endTime: `${twoChars(eh)}:${twoChars(em)}`
    }), [sh, sm, eh, em])

    const minutesChange = (start, value) => start ? setSM(value) : setEM(value)
    const hoursChange = async (start, value) =>{
        if (start){
            setSH(value)   
            setEndHList((value == 23) ? [] : getList(value + 1, 23))
        }
        else{
            setEH(value)
            setStartHList((value == 0) ? [] : getList(0, value -1))
        }
    }

    return(
        <Box className="wrap-content" alignItems='center' textAlign='center' gap="15px">
            <Stack>
                <Stack flexDirection='row' gap='5px' alignItems="center" justifyContent="center">
                    <Stack><Typography variant="primarybg">{text.from}</Typography></Stack>  
                    <Select defaultValue='0' onChange={event => hoursChange(true, event.target.value)}>{
                        startHList.map(
                            h => <MenuItem style={{fontSize: '12px', height:'15px'}} key={'h'+h} value={h}>{twoChars(h)}</MenuItem>
                        )
                    }</Select>
                    <Typography variant={white ? "primarybg" : ""}>:</Typography>
                    <Select defaultValue='0' onChange={event => minutesChange(true, event.target.value)}>{
                        getList(0, 55, 5).map(
                            m => <MenuItem style={{fontSize: '12px', height:'15px'}} key={'m'+m} value={m}>{twoChars(m)}</MenuItem>
                        )
                    }</Select>
                </Stack>
            </Stack>
            <Stack>
                <Stack flexDirection='row' gap='5px' alignItems="center" justifyContent="center">
                    <Stack><Typography variant="primarybg">{text.to}</Typography></Stack>
                    <Select defaultValue='23' onChange={event => hoursChange(false, event.target.value)}>{
                        endHList.map(
                            h => <MenuItem style={{fontSize: '12px', height:'15px'}} key={'h'+h} value={h}>{twoChars(h)}</MenuItem>
                        )
                    }</Select>
                    <Typography variant={white ? "primarybg" : ""}>:</Typography>
                    <Select defaultValue='55' onChange={event => minutesChange(false, event.target.value)}>{
                        getList(0, 55, 5).map(
                            m => <MenuItem style={{fontSize: '12px', height:'15px'}} key={'m'+m} value={m}>{twoChars(m)}</MenuItem>
                        )
                    }</Select>
                </Stack>
            </Stack>
        </Box>
    )
}

export default TimeRange