import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutesList from '../../helper/routes';
import { roles } from '../../helper/roles';

const teamLoginToken = 'creativ-network-team-auth'
const bloggerLoginToken = "creativ-network-blogger-auth"
const candidateLoginToken = "creativ-network-candidate-auth"
const adminLoginToken = "creativ-network-admin-auth"
const managerLoginToken = "creativ-network-manager-auth"
const AuthStateContext = createContext(false);

const AuthStateProvider = ({children}) => {
    const [teamLeaderState, setTeamLeaderState] = useState(localStorage.getItem(teamLoginToken))
    const [bloggerState, setBloggerState] = useState(localStorage.getItem(bloggerLoginToken))
    const [candidateState, setCandidateState] = useState(localStorage.getItem(candidateLoginToken))
    const [adminState, setAdminState] = useState(localStorage.getItem(adminLoginToken))
    const [managerState, setManagerState] = useState(localStorage.getItem(managerLoginToken))
    const [firstName, setFirstName] = useState('')
    const [profilePicture, setProfilePicture] = useState(`/images/Member/first_name.png`)

    const navigate = useNavigate()

    const setAuthenticated = (login, type, token=undefined) =>{
        switch (type){
            case roles.teamleader:
                if (login){
                    setTeamLeaderState(token)
                    localStorage.setItem(teamLoginToken, token)
                }
                else{
                    setTeamLeaderState(null)
                    localStorage.removeItem(teamLoginToken)
                    navigate(RoutesList.teamleaderauth)
                }
                break;
            case roles.blogger:
                if (login){
                    setBloggerState(token)
                    localStorage.setItem(bloggerLoginToken, token)                
                }
                else{
                    setBloggerState(null)
                    localStorage.removeItem(bloggerLoginToken)    
                    navigate(RoutesList.bloggerauth)                
                }
                break;
            case roles.candidat:
                if (login){
                    setCandidateState(token)
                    localStorage.setItem(candidateLoginToken, token)                
                }
                else{
                    setCandidateState(null)
                    localStorage.removeItem(candidateLoginToken)    
                    navigate(RoutesList.memberarea)             
                }
                break;
            case roles.admin:
                if (login){
                    setAdminState(token)
                    localStorage.setItem(adminLoginToken, token)                
                }
                else{
                    setAdminState(null)
                    localStorage.removeItem(adminLoginToken)
                    navigate(RoutesList.admin)          
                }
                break;
            case roles.manager:
                if (login){
                    setManagerState(token)
                    localStorage.setItem(managerLoginToken, token)
                }
                else{
                    setManagerState(null)
                    localStorage.removeItem(managerLoginToken)
                    
                }
            default: 
        }

    }
    return (
        <AuthStateContext.Provider value={{
            userId: teamLeaderState,
            bloggerId: bloggerState,
            candidateId: candidateState,
            adminId: adminState,
            managerId: managerState,
            firstName,
            setFirstName,
            profilePicture,
            setProfilePicture,
            setAuthenticated
        }}>
            {children}
        </AuthStateContext.Provider>
    )
}
export {AuthStateContext, AuthStateProvider}