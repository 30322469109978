import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom';
import WithThemeProvider from './helper/theme';
import useLanguage from './components/hooks/useLanguage';
import Team from './components/Team/Team';
import TeamLogin from './components/Team/TeamLogin';
import Home from './components/Home/Home'
import { Routes, Route, Navigate} from 'react-router-dom';
import RoutesList from './helper/routes'
import { AuthStateProvider } from './components/state/AuthStateContext';
import TestForm from './components/Test/TestForm';
import { AuthStateContext } from './components/state/AuthStateContext';
import ChatOperator from './components/Jobs/ChatOperator'
import PrivacyPolicy from './components/Footer/PrivacyPolicy';
import Terms from './components/Footer/Terms';
import Jobs from './components/Jobs/Jobs';
import './App.css'
import useTitle from './components/hooks/useTitle';
import BloggerLogin from './components/Blog/BloggerLogin';
import Blogger from './components/Blog/Blogger';
import Category from './components/Blog/Category';
import Author from './components/Blog/Author';
import Blog from './components/Blog/Blog';
import Cookies from './components/cookies/Cookies';
import Article from './components/Blog/Article'
import MemberAuth from './components/MemberArea/Auth/MemberAuth';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import Header2 from './components/Header/Header2';
import Dashboard from './components/MemberArea/Dashboard/Dashboard'
import Header3 from './components/Header/Header3';
import { roles } from './helper/roles';
import AdminHome from './components/Admin/AdminHome';
import AdminLogin from './components/Admin/AdminLogin';
import { DashboardStateProvider } from './components/state/DashboardStateContext';
import TeamLeader from './components/Jobs/TeamLeader';
import Supervisor from './components/Jobs/Supervisor';
import TestFormSup from './components/Test/TestFormSup';
import TestFormTL from './components/Test/TestFormTL';
import { AuthRedirectStateProvider } from './components/state/AuthRedirectStateContext';
import { YourIntegStateProvider } from './components/state/YourIntegState';
import { NotifStateProvider } from './components/state/NotifState';
import { jwtDecode } from 'jwt-decode';
import Manager from './components/Manager/Manager';
import ManagerLogin from './components/Manager/ManagerLogin';

const Redirect =  ({to: destination}) =>{
  const { currentLanguage } = useLanguage()
  return <Navigate to={ destination + currentLanguage}/>
}

const ProtectedRoute = ({isAuthRoute=false, to: destination, authType}) =>{
  const { userId, bloggerId, candidateId, adminId, managerId } = useContext(AuthStateContext)

  const getParams = () =>{
    switch (authType){
      case roles.manager: return {idToCheck: managerId, authRoute: RoutesList.managerauth, homeRoute: RoutesList.managerhome}
      case roles.teamleader: return {idToCheck: userId, authRoute: RoutesList.teamleaderauth, homeRoute: RoutesList.teamleaderhome}
      case roles.blogger: return {idToCheck: bloggerId, authRoute: RoutesList.bloggerauth, homeRoute: RoutesList.blogger}
      case roles.candidat: return { idToCheck: candidateId, authRoute: RoutesList.memberarea, homeRoute: RoutesList.dashboard }
      case roles.admin: return { idToCheck: adminId, authRoute: RoutesList.admin, homeRoute: RoutesList.adminhome }
      default: return { idToCheck: null, authRoute: RoutesList.landing, homeRoute: RoutesList.landing }
    }
  }
  const {idToCheck, authRoute, homeRoute} = getParams()

  const { exp } = idToCheck ? jwtDecode(idToCheck) : { exp: 0 }
  const timestamp = Math.floor(new Date().getTime() / 1000)

  if (isAuthRoute) return (exp < timestamp) ? destination : <Navigate to={homeRoute}/>
  return (exp < timestamp) ? <Navigate to={authRoute}/> : destination
}

const TestRoute = ({to: destination, redirectRoute}) =>{
  const { candidateId } = useContext(AuthStateContext)
  return candidateId ? destination : <Navigate to={redirectRoute}/>
}

export const Routing = () =>{
  const location = useLocation()
  const [ hf, setHf ] = useState({header: <></>, footer: <></>})

  const getRoute = () =>{
    let parts = location.pathname.split('/')
    parts.pop()
    return parts.join('/') + '/'
  }
  
  useEffect(()=>{
    const route = getRoute()
    switch (route){
      case RoutesList.dashboard:
        setHf({header: <Header2/>, footer: <></>}); break;
      case RoutesList.admin:
      case RoutesList.bloggerauth:
      case RoutesList.teamleaderauth:
        setHf({header: <Header3/>, footer: <></>}); break
      case RoutesList.managerauth:
        setHf({header: <Header3/>, footer: <></>}); break
      case RoutesList.adminhome:
        setHf({header: <Header3 variant={roles.admin}/>, footer: <></>}); break
      case RoutesList.blogger:
        setHf({header: <Header3 variant={roles.blogger}/>, footer: <></>}); break
      case RoutesList.teamleaderhome:
        setHf({header: <Header3 variant={roles.teamleader}/>, footer: <></>}); break
      case RoutesList.managerhome:
        setHf({header: <Header3 variant={roles.manager}/>, footer: <></>}); break
      default: setHf({header: <Header/>, footer: <Footer/>})
    }
    
  }, [location])
  document.title = useTitle()
  return(
    <>
        { hf.header }
        <Routes>              
            <Route path={RoutesList.landing} element={<Redirect to={RoutesList.landing}/>}/>   
            <Route path={RoutesList.test} element={<Redirect to={RoutesList.test}/>}/>   
            <Route path={RoutesList.chatoperator} element={<Redirect to={RoutesList.chatoperator}/>}/>   
            <Route path={RoutesList.jobsteamleader} element={<Redirect to={RoutesList.jobsteamleader}/>}/>   
            <Route path={RoutesList.pp} element={<Redirect to={RoutesList.pp}/>}/>
            <Route path={RoutesList.terms} element={<Redirect to={RoutesList.terms}/>}/>
            <Route path={RoutesList.jobs} element={<Redirect to={RoutesList.jobs}/>} />
            <Route path={RoutesList.bloggerauth} element={<Redirect to={RoutesList.bloggerauth}/>} />
            <Route path={RoutesList.blogger} element={<Redirect to={RoutesList.blogger}/>} />
            <Route path={RoutesList.blog} element={<Redirect to={RoutesList.blog}/>} />
            <Route path={RoutesList.memberarea} element={<Redirect to={RoutesList.memberarea}/>} />
            <Route path={RoutesList.dashboard} element={<Redirect to={RoutesList.dashboard}/>} />
            <Route path={RoutesList.admin} element={<Redirect to={RoutesList.admin}/>} />
            <Route path={RoutesList.adminhome} element={<Redirect to={RoutesList.adminhome}/>} />
            <Route path={RoutesList.supervisor} element={<Redirect to={RoutesList.supervisor}/>} />
            <Route path={RoutesList.testsupervisor} element={<Redirect to={RoutesList.testsupervisor}/>} />
            <Route path={RoutesList.testteamleader} element={<Redirect to={RoutesList.testteamleader}/>}/>

            <Route path={RoutesList.home} element={<Home/>}/>
            <Route path={RoutesList.chatoperatorfinal} element={<ChatOperator/>}/> 
            <Route path={RoutesList.jobsteamleaderfinal} element={<TeamLeader/>}/> 
            <Route path={RoutesList.supervisorfinal} element={<Supervisor/>}/> 
            <Route path={RoutesList.ppfinal} element={<PrivacyPolicy/>}/>
            <Route path={RoutesList.termsfinal} element={<Terms/>}/>
            <Route path={RoutesList.jobsfinal} element={<Jobs/>} />
            <Route path={RoutesList.bloggerfinal} element={<ProtectedRoute to={<Blogger/>} authType={roles.blogger}/>} />
            <Route path={RoutesList.bloggerauthfinal} element={<ProtectedRoute to={<BloggerLogin/>} authType={roles.blogger} isAuthRoute={true}/>} />
            <Route path={RoutesList.blogfinal} element={<Blog/>} />
            <Route path={RoutesList.categoryfinal} element={<Category/>} />
            <Route path={RoutesList.authorfinal} element={<Author/>} />
            <Route path={RoutesList.article} element={<Article/>} />
            <Route path={RoutesList.memberareafinal} element={ <ProtectedRoute to={<MemberAuth/>} authType={roles.candidat} isAuthRoute={true}/>} />
            <Route path={RoutesList.dashboardfinal} element={<ProtectedRoute to={<Dashboard/>} authType={roles.candidat}/>} />
            <Route path={RoutesList.adminfinal} element={<ProtectedRoute to={<AdminLogin/>} authType={roles.admin} isAuthRoute={true}/>} />
            <Route path={RoutesList.adminhomefinal} element={<ProtectedRoute to={<AdminHome/>} authType={roles.admin}/>} />
        
            <Route path={RoutesList.teamleaderhome} element={<Redirect to={RoutesList.teamleaderhome}/>}/> 
            <Route path={RoutesList.teamleaderauth} element={<Redirect to={RoutesList.teamleaderauth}/>}/> 
            <Route path={RoutesList.teamleaderhomefinal} element={<ProtectedRoute to={<Team/>} authType={roles.teamleader}/>}/> 
            <Route path={RoutesList.teamleaderauthfinal} element={<ProtectedRoute to={<TeamLogin/>} authType={roles.teamleader} isAuthRoute={true}/>}/>

            <Route path={RoutesList.testfinal} element={<TestRoute to={<TestForm/>} redirectRoute={RoutesList.chatoperator}/>}/> 
            <Route path={RoutesList.testteamleaderfinal} element={<TestRoute to={<TestFormTL/>} redirectRoute={RoutesList.jobsteamleader}/>}/> 
            <Route path={RoutesList.testsupervisorfinal} element={<TestRoute to={<TestFormSup/>} redirectRoute={RoutesList.supervisor}/>}/> 
        
            <Route path={RoutesList.managerhome} element={<Redirect to={RoutesList.managerhome}/>}/> 
            <Route path={RoutesList.managerauth} element={<Redirect to={RoutesList.managerauth}/>}/> 
            <Route path={RoutesList.managerhomefinal} element={<ProtectedRoute to={<Manager/>} authType={roles.manager}/>}/> 
            <Route path={RoutesList.managerauthfinal} element={<ProtectedRoute to={<ManagerLogin/>} authType={roles.manager} isAuthRoute={true}/>}/>

        
        </Routes>       
        <Cookies/>
        { hf.footer }
      </>
    )
  }

  const App = () => {  
    return( 
      <BrowserRouter>
        <YourIntegStateProvider>
          <NotifStateProvider>
            <AuthStateProvider>
                <WithThemeProvider>
                  <AuthRedirectStateProvider>
                    <DashboardStateProvider><Routing/></DashboardStateProvider>  
                  </AuthRedirectStateProvider>
                </WithThemeProvider>
            </AuthStateProvider>
          </NotifStateProvider>
        </YourIntegStateProvider>
      </BrowserRouter>
    )
  }
  
  export default App