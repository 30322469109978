import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const BigContainerAccordion = ({summary, summaryBg='#fff', details, dataToDisplay}) =>{
    const [expanded, setExpanded] = useState(false)
    useEffect(()=>{ if (dataToDisplay) setExpanded( Object.keys(dataToDisplay).length != 0) }, [dataToDisplay])
    return(
        <Accordion variant="big-container" onChange={() => setExpanded(e => !e)} style={{boxShadow: 'none'}} expanded={expanded}>
            <AccordionSummary style={{background: summaryBg, width: 'max-content', minWidth: "22.5%", left: '50%', transform: 'translateX(-50%)', marginBottom: '20px'}} expandIcon={<ArrowDropDownIcon style={{color: '#fff'}}/>}>
                <Typography variant="white" width='100%' textAlign='center'>{ summary }</Typography>
            </AccordionSummary> 
            <AccordionDetails variant="big-container" style={{boxShadow: 'none'}}>
                { details }
            </AccordionDetails>
        </Accordion>
    )
}

export default BigContainerAccordion