import React  from "react";
import { Stack } from "@mui/material";
import { roles } from "../../helper/roles";
import UserTitle from "../tools/UserTitle";
import BlogPosts from "../Admin/BlogPosts6";

const Blogger = () =>{
    return(
        <Stack variant="primarybg" className="height-max-adjust">
            <UserTitle variant={roles.blogger} inverseDisplay/>
            <BlogPosts blogger/>
        </Stack>
    )
}

export default Blogger