const RoutesList = {
    landing: '/',
    test: '/test/chat-operator/',
    testteamleader: '/test/team-leader/',
    chatoperator: '/jobs/chat-operator/',
    auth: '/auth/',
    pp: '/privacy-policy/',
    terms: '/terms/',
    jobs: '/jobs/',
    blogger: "/blogger-home/",
    bloggerauth: "/blogger/",
    blog: "/blog/",
    category: "/category/",
    author: "/author/",
    memberarea: "/member/",
    dashboard: "/dashboard/",
    admin: '/admin/',
    adminhome: '/admin-home/',
    teamleaderauth: '/team/',
    teamleaderhome: '/team-home/',
    jobsteamleader: '/jobs/team-leader/',
    supervisor: '/jobs/supervisor/',
    testsupervisor: '/test/supervisor/',
    managerauth: '/manager/',
    managerhome: '/manager-home/',

    home: '/:lang',
    chatoperatorfinal: '/jobs/chat-operator/:lang',
    testfinal: '/test/chat-operator/:lang',
    testteamleaderfinal: '/test/team-leader/:lang',
    authfinal: '/auth/:lang',
    ppfinal: '/privacy-policy/:lang',
    termsfinal: '/terms/:lang',
    jobsfinal: '/jobs/:lang',
    bloggerfinal: "/blogger-home/:lang",
    bloggerauthfinal: "/blogger/:lang",
    blogfinal: "/blog/:lang",
    categoryfinal: "/category/:id/:lang",
    authorfinal: "/author/:id/:lang",
    article: '/:datepub/:id/:lang',
    memberareafinal: "/member/:lang",
    dashboardfinal: "/dashboard/:lang",
    adminfinal: '/admin/:lang',
    adminhomefinal: '/admin-home/:lang',
    teamleaderauthfinal: '/team/:lang',
    teamleaderhomefinal: '/team-home/:lang',
    jobsteamleaderfinal: '/jobs/team-leader/:lang',
    supervisorfinal: '/jobs/supervisor/:lang',
    testsupervisorfinal: '/test/supervisor/:lang',
    managerauthfinal: '/manager/:lang',
    managerhomefinal: '/manager-home/:lang',
}
export default RoutesList