import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import SMButton from "../../tools/SMButton";
import PointedButton from "../../tools/PointedButton";
import ImgInput from "../../tools/ImgInput";
import useLanguage from "../../hooks/useLanguage";
import { useForm } from 'react-hook-form'

const AuthLanding = (props) =>{
    const baseUrl = '/images/Member/'
    const { text } = useLanguage()
    const { control, handleSubmit, formState: {errors}, setValue } = useForm()
    const signUp = async data => props.onChangeSlide(2, data)    
    return(
        <Stack style={{visibility: props.visible ? 'visible' : 'hidden', width: "100%", transition: 'visibility .2s'}}>
            <Box className="wrap-content" alignItems="center" width="100%">
                <Stack spacing={3} width="50%" className="plr-resize">
                    <Typography variant="xlwhitetitle" style={{textShadow: '12px 0px 15px #FFF6'}} className="style-font">{text.welcomeback}!</Typography>
                    <Typography variant="white" textAlign="center">{text.signinint}</Typography>
                    <PointedButton onClick={()=> props.onChangeSlide(0, null)}>{text.signin}</PointedButton>
                </Stack>
                <Stack width="50%" spacing={4} className="plr-resize">
                    <Typography textAlign="center" variant="footertitle" fontWeight="bold">{text.createaccount}</Typography>
                    {/*<Stack display="flex" justifyContent="center" alignSelf="center" flexDirection="row" maxWidth="60%" gap="7px">
                        <SMButton network="facebook" inverse onClick={() =>{}}/>
                        <SMButton network="google" inverse onClick={() =>{}}/>
                        <SMButton network="linkedin" inverse onClick={() =>{}}/>
                        </Stack>
                    <Typography variant="white" textAlign="center">{text.oruseyourpersonnalmail}</Typography>*/}
                    <Stack spacing={2}>
                        <ImgInput range='1' name="firstname" type="text" error={errors.firstname} imgSrc={baseUrl+ "first_name.png"} placeholder={text.firstname} control={control}/>
                        <ImgInput range='2' name="lastname" type="text" error={errors.lastname} imgSrc={baseUrl+ "last_name.png"} placeholder={text.lastname} control={control}/>
                        <ImgInput range='3' name="mail" type="email" error={errors.mail} imgSrc={baseUrl+ "email_address.png"} placeholder={text.email2} control={control}/>
                        <ImgInput range='4' setValue={setValue} suggest name="password" type="password" error={errors.password} imgSrc={baseUrl+ "password.png"} placeholder={text.password} control={control}/>
                        <PointedButton red onClick={handleSubmit(signUp)}>{text.signup}</PointedButton>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    )
}

export default AuthLanding