import { Stack, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import AdminStaff from './AdminStaff1'
import BlogPosts from './BlogPosts6'
import CnMembers from "./CnMembers7";
import EmpIntegration from './EmpIntegration5'
import JobApplication from "./JobApplication3"
import JobPosts from './JobPosts2'
import NewEmpTraining from './NewEmpTraining4'
import NewsLetter from "./NewsLetter8";
import Sn from "./Sn9";
import PositionOverview from "./PositionOverview0";
import useLanguage from "../hooks/useLanguage";
import UserTitle from "../tools/UserTitle";
import { roles } from "../../helper/roles";

const AdminHome = () =>{
    const [state, setState] = useState(() => {
        const storedState = localStorage.getItem('adminHomeState');
        return storedState ? parseInt(storedState, 10) : 0;
    });
    const {text} = useLanguage()
    const currentComponent = () =>{
        switch (state){
            case 0: return <PositionOverview seeDetails={setState}/>
            case 1: return <AdminStaff/>
            case 2: return <JobPosts/>
            case 3: return <JobApplication/>
            case 4: return <NewEmpTraining/>
            case 5: return <EmpIntegration switchToTraining={()=> setState(4)}/>
            case 6: return <BlogPosts/>
            case 7: return <CnMembers/>
            case 8: return <NewsLetter/>
            case 9: return <Sn/>
            default: return <></>
        }
    }

    useEffect(() => {
        localStorage.setItem('adminHomeState', state.toString());
    }, [state]);
    
    return(
        <Stack variant="primarybg" className="height-max-adjust" spacing={3} paddingBottom='75px'>
            <UserTitle variant={roles.admin} inverseDisplay/>
            { currentComponent() }
            { 
                (state != 0) ?
                    <Stack padding='3px' className="card-button-resize" 
                    style={{backgroundColor: '#ba80ed', borderRadius: '20px', alignSelf: 'center'}}>
                        <Button variant="rose" onClick={()=>setState(0)}>{text.back}</Button> 
                    </Stack>
                : <></>
            }
        </Stack>
    )
}

export default AdminHome