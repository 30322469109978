const allGroups = [
    ["Leadership:", "Communication : ", "Gestion des performances :", "Innovation :", "Gestion d'équipe :", "Assurance de la qualité :", "Collaboration :"],
    ["Leadership:", "Communication:", "Performance Management:", "Innovation:", "Team Management:", "Quality Assurance:","Collaboration:" ],
    ["Leadership:", "Comunicazione:", "Gestione delle prestazioni:", "Innovazione:", "Gestione del team:", "Garanzia di qualità:", "Collaborazione:"],
    ["Leiderschap:", "Communicatie:", "Prestatiemanagement:", "Innovatie:", "Teammanagement:", "Kwaliteitsborging:","Samenwerking:"],
    ["Liderazgo", "Comunicación", "Gestión del rendimiento", "Innovación", "Gestión de equipos", "Garantía de calidad", "Colaboración"],
    ["Führung:", "Kommunikation:", "Leistungsmanagement:", "Innovation:", "Teammanagement:", "Qualitätssicherung:", "Zusammenarbeit:"],
]

const allTest = [
    [ // fr
       [
             
            "Philosophie de leadership : Comment décririez-vous votre philosophie de leadership ? Donnez un exemple de stratégie de leadership réussie que vous avez mise en œuvre dans une fonction précédente.",
            "Techniques de motivation : Comment motivez-vous les membres de votre équipe ? Racontez un cas où votre leadership a eu une influence positive sur la motivation et la productivité de l'équipe.",
            "Prise de décision : Décrivez une décision difficile que vous avez prise en tant que dirigeant. Comment vous êtes-vous assuré qu'elle était conforme aux objectifs de l'équipe et à la réussite globale ?"
        ], [
            
            "Communication efficace au sein d'une équipe : Comment assurez-vous une communication efficace au sein de votre équipe ? Donnez un exemple d'une situation où une communication claire a été cruciale pour le succès.",
            "Livraison du retour d'information : Comment donnez-vous un retour d'information constructif aux membres de votre équipe ? Donnez un exemple d'une situation où votre retour d'information a eu des résultats positifs.",
            "La communication transversale : Comment facilitez-vous la communication entre votre équipe et les autres départements pour assurer la collaboration et la synergie ?",

        ], [
            
            "Définir les mesures de performance : Comment définissez-vous et communiquez-vous les indicateurs de performance à votre équipe ? Comment vous assurez-vous qu'ils sont alignés sur les objectifs de l'organisation ?",
            "Stratégies d'amélioration des performances : Décrivez une situation dans laquelle vous avez identifié des problèmes de performance au sein de votre équipe. Comment les avez-vous abordés et comment avez-vous mis en œuvre des améliorations ?",

        ], [
            
            "Encourager l'innovation : Comment encouragez-vous une culture de l'innovation au sein de votre équipe ? Donnez un exemple d'une situation où la pensée innovante a eu un impact positif sur les résultats de l'équipe.",
            "Mettre en œuvre des stratégies innovantes : Partagez un cas où vous avez introduit une stratégie innovante pour améliorer l'efficacité ou les résultats de l'équipe.",
        ], [
            
            "Mentorat et coaching : Comment encadrez-vous et accompagnez-vous les membres de votre équipe dans leur développement professionnel ? Pouvez-vous nous faire part d'un exemple de réussite d'un membre de l'équipe qui s'est développé grâce à vos conseils ?",
            "Créer une culture d'équipe positive : Comment créez-vous et maintenez-vous une culture d'équipe positive qui favorise la collaboration, l'inclusion et le respect mutuel ?",

        ], [
            
            "Maintien des normes de qualité : Comment vous assurez-vous que votre équipe respecte systématiquement les normes de qualité ? Donnez un exemple de la manière dont vous appliquez les protocoles d'assurance qualité." ,
            "Utiliser des indicateurs pour améliorer la qualité : Comment utilisez-vous les indicateurs de performance pour évaluer et améliorer la qualité du travail de votre équipe ?",

        ], [
            
            "Collaboration interfonctionnelle : Décrivez un projet pour lequel la collaboration avec d'autres départements était cruciale. Comment avez-vous assuré une collaboration efficace et quel en a été le résultat ?",

        ]

    ], [// en
        [
            
            "Leadership Philosophy: How would you describe your leadership philosophy? Provide an example of a successful leadership strategy you implemented in a previous role.",
            "Motivational Techniques: How do you motivate your team members? Share an instance where your leadership positively influenced team motivation and productivity.",
            "Decision-Making: Describe a challenging decision you made as a leader. How did you ensure it aligned with team goals and overall success?"
        ], [
            
            "Effective Team Communication: How do you ensure effective communication within your team? Provide an example of a situation where clear communication was crucial to success.",
            "Feedback Delivery: How do you deliver constructive feedback to team members? Share an example of a situation where your feedback led to positive outcomes.",
            "Cross-Functional Communication: How do you facilitate communication between your team and other departments to ensure collaboration and synergy?"
        ], [
            
            "Setting Performance Metrics: How do you set and communicate performance metrics to your team? How do you ensure they align with organizational goals?",
            "Performance Improvement Strategies: Describe a situation where you identified performance issues within your team. How did you address them and implement improvements?",
        ], [
            
            "Encouraging Innovation: How do you foster a culture of innovation within your team? Provide an example of a time when innovative thinking positively impacted team outcomes.",
            "Implementing Innovative Strategies: Share an instance where you introduced an innovative strategy to improve team efficiency or outcomes.",
        ], [
            
            "Mentoring and Coaching: How do you mentor and coach team members for their professional development? Can you share a success story of a team member's growth under your guidance?",
            "Creating a Positive Team Culture: How do you create and maintain a positive team culture that fosters collaboration, inclusivity, and mutual respect?"
        ], [
            
            "Maintaining Quality Standards: How do you ensure your team consistently meets quality standards? Share an example of how you enforce quality assurance protocols.",
            "Using Metrics for Quality Improvement: How do you use performance metrics to evaluate and improve the quality of your team's work?"
        ], [
            
            "Cross-Functional Collaboration: Describe a project where collaboration with other departments was crucial. How did you ensure effective collaboration, and what was the outcome?"
        ]
      
    ], [// it
    [
        "Filosofia della leadership: Come descriverebbe la sua filosofia di leadership? Fornisca un esempio di una strategia di leadership di successo che ha attuato in un ruolo precedente.",
        "Tecniche motivazionali: Come motivate i membri del vostro team? Condividete un caso in cui la vostra leadership ha influenzato positivamente la motivazione e la produttività del team.",
        "Processo decisionale: Descrivete una decisione impegnativa che avete preso come leader. Come si è assicurato che fosse in linea con gli obiettivi del team e con il successo generale?",

    ], [
        "Comunicazione di squadra efficace: Come garantite una comunicazione efficace all'interno del vostro team? Fornite un esempio di una situazione in cui una comunicazione chiara è stata fondamentale per il successo.",
        "Consegna del feedback: Come fornite un feedback costruttivo ai membri del team? Condividete un esempio di una situazione in cui il vostro feedback ha portato a risultati positivi",
        "Comunicazione interfunzionale: In che modo facilitate la comunicazione tra il vostro team e gli altri reparti per garantire collaborazione e sinergia?",

    ], [
        "Impostazione delle metriche di prestazione: Come si stabiliscono e si comunicano le metriche di prestazione al team? Come vi assicurate che siano in linea con gli obiettivi organizzativi?",
        "Strategie di miglioramento delle prestazioni: Descrivete una situazione in cui avete identificato problemi di performance all'interno del vostro team. Come li avete affrontati e come avete implementato i miglioramenti?",

    ], [
        "Incoraggiare l'innovazione: Come promuovete una cultura dell'innovazione all'interno del vostro team? Fornite un esempio di un momento in cui il pensiero innovativo ha avuto un impatto positivo sui risultati del team",
        "Implementare strategie innovative: Condividete un caso in cui avete introdotto una strategia innovativa per migliorare l'efficienza o i risultati del team.",

    ],[
        "Mentoring e Coaching: Come fa a fare da mentore e coach ai membri del team per il loro sviluppo professionale? Può condividere una storia di successo di un membro del team cresciuto sotto la sua guida?",
        "Creare una cultura di squadra positiva: Come crea e mantiene una cultura di squadra positiva che favorisce la collaborazione, l'inclusività e il rispetto reciproco?",
    ], [
        "Mantenimento degli standard di qualità: Come fate a garantire che il vostro team rispetti costantemente gli standard di qualità? Condividete un esempio di come applicate i protocolli di garanzia della qualità.",
        "Utilizzo delle metriche per il miglioramento della qualità: Come utilizzate le metriche di performance per valutare e migliorare la qualità del lavoro del vostro team?",

    ], [
        "Collaborazione interfunzionale: Descrivete un progetto in cui la collaborazione con altri reparti è stata fondamentale. Come avete garantito una collaborazione efficace e qual è stato il risultato?",
        
    ]
    ], [// nl
    [
        "Filosofie leiderschap: Hoe zou je jouw leiderschapsfilosofie omschrijven? Geef een voorbeeld van een succesvolle leiderschapsstrategie die je in een vorige functie hebt toegepast.",
        "Motiverende technieken: Hoe motiveer jij je teamleden? Deel een voorbeeld waarbij jouw leiderschap de motivatie en productiviteit van het team positief beïnvloedde.",
        "Besluitvorming: Beschrijf een uitdagende beslissing die je als leider hebt genomen. Hoe zorgde je ervoor dat deze in lijn was met de teamdoelen en het algemene succes?",
    ], [
        "Effectieve teamcommunicatie: Hoe zorg je voor effectieve communicatie binnen je team? Geef een voorbeeld van een situatie waarin duidelijke communicatie cruciaal was voor succes.",
        "Feedbacklevering: Hoe geef je constructieve feedback aan teamleden? Geef een voorbeeld van een situatie waarin je feedback tot positieve resultaten leidde.",
        "Functieoverschrijdende communicatie: Hoe bevordert u de communicatie tussen uw team en andere afdelingen om samenwerking en synergie te garanderen?",

    ], [
        "Prestatiecijfers instellen: Hoe stel je prestatiemaatstaven op en hoe communiceer je die met je team? Hoe zorgt u ervoor dat ze in lijn liggen met de doelstellingen van de organisatie?",
        "Strategieën voor prestatieverbetering: Beschrijf een situatie waarin u prestatieproblemen binnen uw team hebt geïdentificeerd. Hoe heb je ze aangepakt en verbeteringen doorgevoerd?",

    ], [
        "Innovatie aanmoedigen: Hoe stimuleer je een cultuur van innovatie binnen je team? Geef een voorbeeld van een moment waarop innovatief denken de resultaten van het team positief beïnvloedde.",
        "Innovatieve strategieën implementeren: Deel een voorbeeld van een moment waarop je een innovatieve strategie introduceerde om de efficiëntie of resultaten van het team te verbeteren.",
    ],[
        "Mentorschap en coaching: Hoe mentor en coach je teamleden voor hun professionele ontwikkeling? Kun je een succesverhaal vertellen over de groei van een teamlid onder jouw begeleiding?",
        "Een positieve teamcultuur creëren: Hoe creëer en behoud je een positieve teamcultuur die samenwerking, inclusiviteit en wederzijds respect bevordert?",

    ], [
        "Kwaliteitsnormen handhaven: Hoe zorg je ervoor dat je team consequent voldoet aan de kwaliteitsnormen? Geef een voorbeeld van hoe je protocollen voor kwaliteitsborging handhaaft.",
        "Metriek gebruiken voor kwaliteitsverbetering: Hoe gebruik je prestatiecijfers om de kwaliteit van het werk van je team te evalueren en te verbeteren?",

    ], [
        "Functionele samenwerking: Beschrijf een project waarbij samenwerking met andere afdelingen cruciaal was. Hoe heb je gezorgd voor effectieve samenwerking en wat was het resultaat?",

    ]

    ], [// es
    [
        "Filosofía de liderazgo: ¿Cómo describiría su filosofía de liderazgo? Proporcione un ejemplo de una estrategia de liderazgo exitosa que haya implementado en un puesto anterior.",
        "Técnicas de motivación: ¿Cómo motiva a los miembros de su equipo? Comparta un ejemplo en el que su liderazgo haya influido positivamente en la motivación y productividad del equipo",
        "Toma de decisiones: Describa una decisión difícil que haya tomado como líder. ¿Cómo te aseguraste de que se ajustaba a los objetivos del equipo y al éxito general?",
    ], [
        "Comunicación eficaz en equipo: ¿Cómo garantiza una comunicación eficaz dentro de su equipo? Proporcione un ejemplo de una situación en la que una comunicación clara fue crucial para el éxito.",
        "Retroalimentación: ¿Cómo transmite comentarios constructivos a los miembros de su equipo? Comparta un ejemplo de una situación en la que sus comentarios condujeron a resultados positivos",
        "Comunicación interfuncional: ¿Cómo facilita la comunicación entre su equipo y otros departamentos para garantizar la colaboración y la sinergia?"
    ], [
        "Establecimiento de métricas de rendimiento: ¿Cómo establece y comunica las métricas de rendimiento a su equipo? ¿Cómo se asegura de que están en línea con los objetivos de la organización?",
        "Estrategias de mejora del rendimiento: Describa una situación en la que haya detectado problemas de rendimiento en su equipo. ¿Cómo los abordó y aplicó las mejoras?",
    ], [
        "Fomentar la innovación: ¿Cómo fomenta una cultura de innovación en su equipo? Proporcione un ejemplo de un momento en el que el pensamiento innovador haya repercutido positivamente en los resultados del equipo",
        "Aplicación de estrategias innovadoras: Comparta un caso en el que haya introducido una estrategia innovadora para mejorar la eficacia o los resultados del equipo",
    ], [
        "Tutoría y entrenamiento: ¿Cómo tutela y entrena a los miembros de su equipo para su desarrollo profesional? ¿Puede compartir una historia de éxito sobre el crecimiento de un miembro del equipo bajo su tutela?",
        "Creación de una cultura de equipo positiva: ¿Cómo crea y mantiene una cultura de equipo positiva que fomente la colaboración, la inclusión y el respeto mutuo?"
    ], [
        "Mantenimiento de los estándares de calidad: ¿Cómo se asegura de que su equipo cumple sistemáticamente las normas de calidad? Comparta un ejemplo de cómo hace cumplir los protocolos de garantía de calidad",
        "Uso de métricas para la mejora de la calidad: ¿Cómo utiliza las métricas de rendimiento para evaluar y mejorar la calidad del trabajo de su equipo?"
    ], [
        "Colaboración interfuncional: Describa un proyecto en el que la colaboración con otros departamentos haya sido crucial. Cómo garantizó una colaboración eficaz y cuál fue el resultado?"
    ]

    ], [// de
    [
        "Führungsphilosophie: Wie würden Sie Ihre Führungsphilosophie beschreiben? Nennen Sie ein Beispiel für eine erfolgreiche Führungsstrategie, die Sie in einer früheren Funktion umgesetzt haben.",
        "Motivationstechniken: Wie motivieren Sie Ihre Teammitglieder? Nennen Sie ein Beispiel, bei dem Ihre Führung die Motivation und Produktivität des Teams positiv beeinflusst hat.",
        "Entscheidungsfindung: Beschreiben Sie eine schwierige Entscheidung, die Sie als Führungskraft getroffen haben. Wie haben Sie sichergestellt, dass sie mit den Teamzielen und dem Gesamterfolg übereinstimmt?"
    ], [
        "Effektive Teamkommunikation: Wie stellen Sie eine effektive Kommunikation innerhalb Ihres Teams sicher? Nennen Sie ein Beispiel für eine Situation, in der klare Kommunikation entscheidend für den Erfolg war.",
        "Feedback geben: Wie geben Sie Ihren Teammitgliedern konstruktives Feedback? Nennen Sie ein Beispiel für eine Situation, in der Ihr Feedback zu positiven Ergebnissen geführt hat.",
        "Funktionsübergreifende Kommunikation: Wie erleichtern Sie die Kommunikation zwischen Ihrem Team und anderen Abteilungen, um Zusammenarbeit und Synergie zu gewährleisten?"
    ], [
        "Festlegen von Leistungsmetriken: Wie legen Sie Leistungskennzahlen fest und teilen sie Ihrem Team mit? Wie stellen Sie sicher, dass sie mit den Unternehmenszielen übereinstimmen?",
        "Strategien zur Leistungsverbesserung: Beschreiben Sie eine Situation, in der Sie Leistungsprobleme innerhalb Ihres Teams festgestellt haben. Wie haben Sie diese angegangen und Verbesserungen umgesetzt?",
    ], [
        "Ermutigung zur Innovation: Wie fördern Sie eine Kultur der Innovation in Ihrem Team? Nennen Sie ein Beispiel, bei dem sich innovatives Denken positiv auf die Teamergebnisse ausgewirkt hat.",
        "Innovative Strategien umsetzen: Nennen Sie einen Fall, in dem Sie eine innovative Strategie eingeführt haben, um die Effizienz oder die Ergebnisse des Teams zu verbessern",
    ], [
        "Mentoring and Coaching: How do you mentor and coach team members for their professional development? Can you share a success story of a team member's growth under your guidance?",
        "Creating a Positive Team Culture: How do you create and maintain a positive team culture that fosters collaboration, inclusivity, and mutual respect?"
        
    ], [
        "Aufrechterhaltung von Qualitätsstandards: Wie stellen Sie sicher, dass Ihr Team die Qualitätsstandards konsequent einhält? Nennen Sie ein Beispiel dafür, wie Sie Qualitätssicherungsprotokolle durchsetzen.",
        "Einsatz von Metriken zur Qualitätsverbesserung: Wie nutzen Sie Leistungskennzahlen zur Bewertung und Verbesserung der Qualität der Arbeit Ihres Teams?"
    ], [
        "Funktionsübergreifende Zusammenarbeit: Beschreiben Sie ein Projekt, bei dem die Zusammenarbeit mit anderen Abteilungen entscheidend war. Wie haben Sie eine effektive Zusammenarbeit sichergestellt, und was war das Ergebnis?"
    ]

    ]
];
const getKey = lang =>{
    switch(lang){
        case 'fr': return 0
        case 'it': return 2
        case 'nl': return 3
        case 'es': return 4
        case 'de': return 5
        default: return 1
    }
}

const order = testlist => {
    let i = 1
    let toReturn = []
    testlist.forEach(testgroup =>{
        let thisGroupVal = []
        testgroup.forEach(t => thisGroupVal.push(`${i++}. ${t}`))
        toReturn.push(thisGroupVal)
    })
    return toReturn
}

export const groupteamlead = lang => { return allGroups[getKey(lang)] }
export const testteamlead =  (lang, unOrdered=false) => {
    const list = allTest[getKey(lang)]
    return unOrdered ? list : order(list)
}