import  React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form"
import { Stack, Typography, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useLanguage from "../hooks/useLanguage";

const ImgInputLight = ({readonly=false, type, control, name, error, required=true, placeholder="", value="", setValue =(a,b)=>{}, imgSrc, prefix=''}) => {
    const { text } = useLanguage()
    const maildomain = undefined // exemple: 'gmail.com'
    const domain = (maildomain) ? maildomain.replace('.', '\\.'): undefined
    const isPassword = type === 'password'
    const [vis, setVis] = useState(!isPassword)
    const switchVis = () => setVis(vis => !vis)

    useEffect(()=> setValue(name, value), [value])

    return(
        <Stack>
            <Stack style={{flexDirection: 'row', alignItems:"center"}}>    
                <Stack className="imginput-img" borderRadius="50%" style={{ backgroundColor: '#eaea53', transform: "translateX(30%)" , border: '5px solid #4B4998'}}><img src={imgSrc} alt="" /></Stack>
                <Stack style={{backgroundColor:"#eaea53", flexDirection:"row", width:"100%", alignItems: "center"}}>
                    <Typography style={{paddingLeft: '25px'}}>{prefix}</Typography>
                    <Controller
                        control={control}
                        name={name}
                        defaultValue=""
                        rules={ (type === 'email') ? {
                            required: (required) ? "req" : false,
                            pattern: {
                                value: new RegExp(`^[A-Z0-9._%+-]+@${domain || `[A-Z0-9.-]+\\.[A-Z]{2,}`}$`, 'i'),
                                message: "emailsyn"
                            }}
                            : {required: (required) ? "req" : false}
                        }
                        render={({ field }) =>
                            <input readOnly={readonly} className="in-input-light" style={{ paddingRight: '30px', width:'calc(100% - 50px)', backgroundColor: 'transparent' }}
                                placeholder={placeholder} type={(vis) ? 'text' : 'password'} 
                                autoComplete="off"
                                {...field}
                            />
                               
                            }
                    />
                    <Stack className="in-input-light" width="50px">
                        {
                            isPassword ?
                            <IconButton style={{color: "#111",height: '100%', transform: 'translateX(-50%)'}} onClick={switchVis}>
                                { vis ? <VisibilityOff /> : <Visibility/> }
                            </IconButton>
                            : <></>
                        }
                    </Stack>
                </Stack>
                <Stack className="in-input-light-triangle" style={{  width: 0, height: 0, borderRight:'25px solid #4B4998', transform: "translateX(-100%)"}}></Stack>
            </Stack>
            {error && <Typography variant="error" textAlign='left' padding="0 30px">
                {
                    (error.message == "req") ? text.required : ((error.message == "emailsyn") ? text.invalidemail : text.error)
                }
                </Typography>}
        </Stack>
    )
}
export default ImgInputLight