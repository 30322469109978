import React, { useContext, useEffect, useState } from "react";
import { Typography, Stack, Divider, Button, TextField, CircularProgress, Alert } from "@mui/material";
import useLanguage from '../hooks/useLanguage'
import { situations } from "../../helper/situation";
import {AuthStateContext} from '../state/AuthStateContext'
import axiosInstance from "../../axios";
import { jobs } from "../../helper/jobs";
import { jwtDecode } from "jwt-decode";
import useData from "../hooks/useData";
import { useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";
import { DashboardStateContext } from "../state/DashboardStateContext";

const TestForm = () => {
    const { text, currentLanguage } = useLanguage()

    const [response, setResponse] = useState([])
    const [err, setErr] = useState(false)
    const [situationRank, setSituationRank] = useState(0)
    const [input, setInput] = useState(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [error, setError] = useState('')

    useEffect(()=> setInput(document.querySelector('#cotesttf')) , [situationRank])

    const { testLanguages } = useData()
    const [ userInfo, setUserInfo] = useState({})
    const [ lang, setLang ] = useState('en')
    const { candidateId } = useContext(AuthStateContext)
    const { id } = jwtDecode(candidateId)
    useEffect(()=>{
        const func = async () =>{
            try {
                const { status, data: {user_name, language} } = await axiosInstance.get(`/test_info/${id}/${jobs.chatoperator}`, { headers: { 'Authorization' : `Bearer ${candidateId}` } })
                if (status == 200) setUserInfo({user_name, language})
                if (language) setLang(language)
            } catch (error) {}
        }
        func()
    }, [])

    const back = () =>{
        setErr(false)
        if (situationRank != 1){
            const updatedResponse = [...response]
            updatedResponse.pop()
            setResponse(updatedResponse)
            input.value = ""
        }
        setSituationRank(current => current - 1)
    }

    const next = () =>{
        if (situationRank == 0) setSituationRank(1)
        else{
            if (input.value.length){
                setErr(false)
                setResponse([...response, input.value]);
                input.value = ""
                if (situationRank < situations(lang).length) setSituationRank(current => current + 1)
            }
            else setErr(true)
        }
    }
    const { setSelectedButton } = useContext(DashboardStateContext)
    const sendTestResp = async () =>{
        setLoading(true)
        try {
            const { status } = await axiosInstance.post(`/job/${jobs.chatoperator}/test/${id}/${currentLanguage}`, response, { headers: { 'Authorization' : `Bearer ${candidateId}` } })
            if (status == 200) {
                setSelectedButton(4)
                navigate(RoutesList.dashboard)
            }
        } catch (error) {setError(text.smthngwntwrng)}
        setLoading(false)
    }
    useEffect(()=>{ if (response.length == situations(lang).length) sendTestResp() }, [response])
    return(
        <Stack onLoad={() => window.scrollTo(0, 0)}>
            <Stack style={{position: "relative"}}>
                <Stack alignItems='center' style={{overflow: 'hidden'}}>
                    <img src={ "/images/test/Test Chat Operator.webp"} style={{width: '100%'}}/>
                </Stack>
                <Stack style={{ width:"70%", position: 'absolute', top: "40%", transform: "translateY(-50%)"}}>
                    <Typography className="text-resize" variant="whitetitle" fontWeight='bold'>{text.cotest}</Typography>
                </Stack>
            </Stack>
            <Stack className="waves-display" style={{transform: "translateY(-95%)"}} variant="wavestrans"><img src="/svg/testco.svg"/></Stack>
            <Stack alignItems='center' marginBottom="20px">
                <Stack className="test-form" spacing={2}>
                    <Stack variant="bordered" style={{overflow: 'hidden'}} spacing={3}>
                        <Divider variant="primarybold"/>
                        {
                            loading ? 
                            <Stack paddingBottom='15px' alignItems='center'>
                                <Typography variant="h2" width='95%' fontWeight="bold">{text.thanktest1} {userInfo.user_name ? ', ' + (userInfo.user_name)[0].toUpperCase() + (userInfo.user_name).slice(1) : ''}</Typography>
                            </Stack>
                            : <Stack spacing={3} alignItems="center">
                                <Stack spacing={1} width='95%'>
                                    <Typography style={{width: '95%'}} variant="h2" textAlign="left" fontWeight="bold">{text.cotesttitle}</Typography>
                                    <Stack>
                                        <Typography fontWeight='bold' alignSelf='left'>{text.username} : {userInfo.user_name ? (userInfo.user_name)[0].toUpperCase() + (userInfo.user_name).slice(1) : ''}</Typography>
                                        <Typography fontWeight='bold' alignSelf='left'>{text.language} : {userInfo.language ? testLanguages.find(l => l.value == userInfo.language).innerHTML : ''}</Typography>
                                    </Stack>
                                </Stack>
                                {
                                    (situationRank == 0) ?
                                    <>
                                        <Typography style={{width: '95%'}}>{text.cotestint1}</Typography>
                                        <Typography style={{width: '95%'}}>{text.cotestint2}</Typography>
                                        <Typography style={{width: '95%'}}>{text.goodluck}</Typography> 
                                    </>
                                    : <></>
                                }
                            </Stack>
                        }
                    </Stack>
                    {
                        ((situationRank== 0) || loading) ? <></>
                        : <Stack variant="bordered" style={{overflow: 'hidden'}}>
                        <Stack variant="primarybg" alignItems='center'>
                            <Typography width='95%' variant="primarybg" fontWeight='bold' padding='10px 0'>
                                {text.situation} {text.number}{situationRank}
                            </Typography>
                        </Stack>
                        <Stack alignItems='center' padding="20px 0">
                            <Typography width='95%' padding='10px 0'>
                                {situations(lang)[situationRank -1]}
                            </Typography>
                            <TextField id="cotesttf" style={{ width: '95%' }} maxRows={3} placeholder={text.youranswer} multiline/>
                            { err ? <Typography variant="error" textAlign="left" style={{ width: "95%" }}>{text.required}</Typography> : <></> }
                        </Stack>
                        </Stack>
                    }
                    {error && <Alert severity={(error== 'succ') ? "success" : "error"}>
                            { error }
                        </Alert>}
                    {
                        ((situationRank <= situations(lang).length) && (Boolean(userInfo.language)) ) ?
                        <Stack display='flex' flexDirection='row' gap='12px'>
                            {(situationRank > 0 ) ?<Button variant="testform" disabled={loading} onClick={back}>{text.back}</Button>: <></>}
                            <Button variant="testform" disabled={loading} onClick={next}>{ loading ? <CircularProgress color="inherit" size="24px"/> : ((situationRank < situations(lang).length ) ? text.next : text.finish)}</Button>
                        </Stack>
                        : <></>
                    }
                </Stack>
            </Stack>
        </Stack>

    )
} 

export default TestForm;