import { Typography } from "@mui/material";
import React from "react";
import useLanguage from "../hooks/useLanguage";

const DateDisplay = ({children, paddingLeft="0px"}) =>{
    const { text } = useLanguage()
    const transform = () =>{
        const parts = children.split('/')     
        return text.months[parts[1] - 1] + " " + parts[0] + ', ' + parts[2]
    }
    return(
        <Typography paddingLeft={paddingLeft}>{transform(children)}</Typography>
    )
}

export default DateDisplay