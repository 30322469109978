import React, { createContext, useEffect, useState } from 'react';

const DashboardStateContext = createContext(false);

const token = 'CREATIV-NETWORK-DASHBOARD6STATE'
const DashboardStateProvider = ({children}) => {
    const [selectedButton, setSelectedButton] = useState();
    const saveState = () => localStorage.setItem(token, selectedButton)
    
    useEffect(()=>{
        const content = localStorage.getItem(token)
        if ((Boolean(content)) && (content != null)){
            setSelectedButton(parseInt(content))
            localStorage.removeItem(token)
        }
    }, [])
    return (
        <DashboardStateContext.Provider value={{
            selectedButton, setSelectedButton, saveState
        }}>
            {children}
        </DashboardStateContext.Provider>
    )
}
export {DashboardStateContext, DashboardStateProvider}