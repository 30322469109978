import { Stack, Typography, Button, CircularProgress, Alert } from "@mui/material";
import React, {useEffect, useState, useContext} from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import axiosInstance from '../../axios'
import useData from "../hooks/useData";
import { useLocation } from "react-router-dom";
import RoutesList from "../../helper/routes";
import { AuthStateContext } from '../state/AuthStateContext'
import { format } from "date-fns";

const EmpIntegration = ({switchToTraining = () =>{}}) =>{
    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        jobTitle: '',
        language: 'all',
    })
    const { text } = useLanguage()
    const { filterLanguages, jobName } = useData()
    const { adminId, userId, managerId } = useContext(AuthStateContext)
    const location = useLocation()
    const getToken = () =>{
        switch (location.pathname.split('/')[1]){
            case RoutesList.teamleaderhome.split('/')[1]: return userId
            case RoutesList.adminhome.split('/')[1]: return adminId
            case RoutesList.managerhome.split('/')[1]: return managerId
            default: return ''
        }
    }
    const [ list, setList ] = useState([])
    useEffect(()=>{
        const func = async () =>{
            try {
                const { data } = await axiosInstance.get(`/employee_integration/all`, { headers: { 'Authorization' : `Bearer ${getToken()}` } })
                setList(data)
            } catch (error) {}
        }
        func()
    }, [])
    const getPaymentMethod = arr =>{
        if (!arr) return text.notprovided
        if (!arr[0]) return text.notprovided
        const cont = arr[0]
        if (cont.paypal?.length) return 'PayPal'
        if (cont.wise?.length) return 'Wise'
        if (cont.your_safe?.length) return 'Yoursafe'
        if (cont.iban?.length) return text.bank
        return text.notprovided
        /*const cont = arr[0]
        let resp = ''
        if (cont.paypal?.length) resp += 'PayPal$'
        if (cont.wise?.length) resp += 'Wise$'
        if (cont.your_safe?.length) resp += 'Yoursafe$'
        if (cont.iban?.length) resp += text.bank
        return resp.length ? resp.split('$').join(', ') : text.notprovided*/
    }
    const [loading, setLoading] = useState(-1)
    /*const sendConf = async test_id =>{
        setLoading(test_id)
        try {
            await axiosInstance.post(`/employee_integration/${test_id}`, {}, { headers: { 'Authorization' : `Bearer ${getToken()}` } })
            const newList = list.filter(l => l.test_id != test_id)
            setList(newList)
        } catch (error) {}
        setLoading(-1)
    }*/
    const [mailSentFb, setMailSentFb] = useState(-1)
    const sendMailIncompleteDocs = async test_id =>{
        setMailSentFb(-1)
        setLoading(test_id)
        try {
            await axiosInstance.post(`/employee_integration/${test_id}`, {}, { headers: { 'Authorization' : `Bearer ${getToken()}` } })
            setMailSentFb(test_id)
        } catch (error) {}
        setLoading(-1)
    }
    const validateIntegration = async test_id =>{
        setMailSentFb(-1)
        setLoading(test_id)
        try {
            await axiosInstance.post(`/employee_integration/pass_training/${test_id}`, {}, { headers: { 'Authorization' : `Bearer ${getToken()}` } })
            const newL = list.filter(l => l.test_id != test_id)
            setList(newL)
            switchToTraining()
        } catch (error) {}
        setLoading(-1)
    }
    return(
        <Stack variant="primarybg" spacing={3}>
            <Stack style={{display: 'flex', gap:'15px', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                <img className="card-img-resize-2" src='/images/Admin/employees_integration.png' alt=""/>
                <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#8c52ff' fontWeight='bold' 
                    style={{WebkitTextStroke: "1px #2b0080"}}
                >{text.empintegration.toUpperCase()  }</Typography>
            </Stack>
            <BigContainerAccordion 
                summaryBg="#fbc20b"
                summary={text.aei1}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker',  selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.jobtitle , comp: 'TextInput', onChange: e => setFilter({...filter, jobTitle: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter({...filter, language: l.target.value}) },
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 1,  text: text.date},
                                { xs: 1,  text: text.jobtitle},
                                { xs: 1,  text: text.agentfirstname},
                                { xs: 1,  text: text.agentlastname},
                                { xs: 2,  text: text.agentlogin},
                                { xs: 1,  text: text.agentid},
                                { xs: 1,  text: text.freelancecontract},
                                { xs: 1,  text: text.slfi},
                                { xs: 1,  text: text.proofofresidence},
                                { xs: 1,  text: text.paymentmethod},
                                { xs: 1,  text: text.actions},
                            ]}/>
                            {
                                list
                                .filter(a => 
                                    // Starting date
                                    ( (!filter.startingDate) || ( filter.startingDate <= new Date(a.date)) )
                                    // Closing date
                                    && ( (!filter.closingDate) || (new Date(a.date) <= filter.closingDate) )
                                    // Text match
                                    && ((!Boolean(jobName(a.title))) || (jobName(a.title)?.toLowerCase().includes(filter.jobTitle)))
                                    // Language
                                    && ( (filter.language == 'all') || (a.language == filter.language))  
                                )
                                .map( (a, i) =>
                                    <TableRow key={`number${i}`} columns={[
                                        { xs: 1,  text: a.date ? format(a.date, 'dd//MM/yyyy') : ''},
                                        { xs: 1,  text: jobName(a.title)},
                                        { xs: 1,  text: a.first_name},
                                        { xs: 1,  text: a.last_name},
                                        { xs: 2,  text: a.login},
                                        {   xs: 1,
                                            color: Boolean(a.proof_of_identity) ? '#0b0' : '#f00',
                                            text: Boolean(a.proof_of_identity) ? text.provided : text.notprovided
                                        },
                                        {   xs: 1,
                                            color: Boolean(a.contract) ? '#0b0' : '#f00',
                                            text: Boolean(a.contract) ? text.provided : text.notprovided
                                        },
                                        {   xs: 1,
                                            color: Boolean(a.selfie) ? '#0b0' : '#f00',
                                            text: Boolean(a.selfie) ? text.provided : text.notprovided
                                        },
                                        {   xs: 1,
                                            color: Boolean(a.proof_of_residence) ? '#0b0' : '#f00',
                                            text: Boolean(a.proof_of_residence) ? text.provided : text.notprovided
                                        },                          
                                        {   xs: 1,
                                            color: (getPaymentMethod(a.payment_method) == text.notprovided) ? '#f00' : '#5781ce',
                                            text: getPaymentMethod(a.payment_method)
                                        },
                                        { xs: 1,  customContent: true, content:
                                            (loading == a.test_id) ? <CircularProgress size='24px'/> :
                                            <Stack width='100%'>
                                                {
                                                    (Boolean(a.proof_of_identity) && Boolean(a.contract) && Boolean(a.selfie) && Boolean(a.proof_of_residence) && (getPaymentMethod(a.payment_method) != text.notprovided))
                                                    ? <Button onClick={()=> validateIntegration(a.test_id)} style={{textTransform: 'capitalize', color: '#0b0', width: '100%', height: '100%', fontWeight: 'bold',}}>{text.vldtinteg}</Button>
                                                    : <Button onClick={()=> sendMailIncompleteDocs(a.test_id)} style={{textTransform: 'capitalize', color: '#3c6dc6', width: '100%', height: '100%', fontWeight: 'bold',}}>{text.sndmaildocincomp}</Button>
                                                }
                                                {
                                                    (mailSentFb == a.test_id)
                                                    && <Alert severity="success">
                                                        <Typography>{text.success}</Typography>                              
                                                    </Alert>
                                                }
                                            </Stack>
                                        },
                                    ]
                                }/>                 
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default EmpIntegration