import React from "react";
import { Select, MenuItem, Stack, Typography } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const TimeSelect = ({type, onChange, selected=null}) =>{
    const {text} = useLanguage()
    const getYears = () =>{
        let years = []
        for (let y = new Date().getFullYear() + 1; y >= 2021; y--) years.push({value: y, innerHTML: y})
        return years
    }
    const getMonths = () =>{
        let months = []
        text.months.forEach((content, index) => months.push({ value: index + 1, innerHTML: content }))
        return months
    }
    const getContent = () =>{
        switch (type){
            case 'year': return {options: getYears(), label: text.year}
            case 'month': return {options: getMonths(), label: text.month}
            case 'date': return {label: text.date}
            default: return {options: [], label: ''}
        }
    }
    const { options, label } = getContent()

    return(
        <Stack flexDirection='row' gap='10px' style={{height: '50px'}}>
            <Typography variant="datefilter">{label}</Typography>
            {
                (type == 'date') ?
                <DatePicker dateFormat='dd-MM-yyyy' showYearDropdown scrollableYearDropdown yearDropdownItemNumber={100} maxDate={new Date()} className="datepicker datepicker-2" selected={selected} onChange={onChange}/>
                :
                <Select defaultValue={options[0].value} onChange={onChange}
                sx={{backgroundColor: '#ece3ff', width: '100px'}}
                MenuProps={{PaperProps: { style: { backgroundColor: 'transparent', boxShadow: 'none' } }}}
                >
                {
                    
                    options.map(o =>
                        <MenuItem key={o.value} value={o.value} style={{backgroundColor: '#ece3ff'}}>{o.innerHTML}</MenuItem>
                    )
                }
                </Select>
            }
        </Stack>
    )
}

export default TimeSelect