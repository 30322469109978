import {Button, MenuItem, Typography, Menu, Box, Stack,  Accordion, Badge, AccordionDetails, AccordionSummary, IconButton, Divider, Icon, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import Logout from "../tools/Logout";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { roles } from "../../helper/roles";
import { animateScroll } from 'react-scroll';
import { DashboardStateContext } from "../state/DashboardStateContext";
import { AuthStateContext } from "../state/AuthStateContext";
import { YourIntegStateContext } from "../state/YourIntegState";
import { NotifStateContext } from "../state/NotifState";
import axiosInstance from "../../axios";
import { format } from "date-fns";
import NotificationsIcon from '@mui/icons-material/Notifications';

const MemberOpt = () =>{
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [notifsAE, setNotifsAE] = React.useState(null)
    const open = Boolean(anchorEl)
    const notifsOpen = Boolean(notifsAE)
    const { text } = useLanguage()

    const { setSelectedButton } = useContext(DashboardStateContext)
    const { profilePicture, candidateId } = useContext(AuthStateContext)
    const { show } = useContext(YourIntegStateContext)
    const { notifs, setNotifs } = useContext(NotifStateContext)
    const [ notifsCount, setNotifsCount ] = useState(0) 
    const [loading, setLoading] = useState(false)

    const handleButtonClick = (buttonId) => {
        setAnchorEl(null)
        setSelectedButton(buttonId);
        animateScroll.scrollTo(0, {
            duration:500,
            spy:true,
            smooth:true,
            offset:-175
        })
    };
    useEffect(()=>{
        let count = 0
        notifs.forEach(n => {if (!n.isRead) count++})
        setNotifsCount(count)
    }, [notifs])

    const handleMarkReadNotif = async id => {
        setLoading(true)
        try {
            await axiosInstance.post(`/mark-read/notifications/${id}`, {}, { headers: {'Authorization': `Bearer ${candidateId}`}})
            const newNotifs = [...notifs]
            const i = newNotifs.findIndex(n => n.id == id)
            newNotifs[i].isRead = true
            setNotifs(newNotifs)
            
        } catch (error) {}
        setLoading(false)
    }

    const handleShowNotif = async e => {
        setNotifsAE(notifsAE ? null : e.currentTarget)
    }

    return(<>
        <Stack width='100%'>
            <Stack className="usericon" position='relative' height="45px" width="45px" >
                <IconButton style={{position: 'absolute', bottom: '0', right: '0', 
                transform:'translate(60%, 50%)' ,color: '#FDC309', zIndex: 2}}
                onClick={event =>  setAnchorEl(anchorEl ? null : event.currentTarget)}>
                    <ArrowDropDownIcon/>
                </IconButton>
                <IconButton 
                    onClick={event =>  setAnchorEl(anchorEl ? null : event.currentTarget)} 
                    sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
                >
                    <img className="profil"  src={profilePicture} alt=""  style={{borderRadius: '50%'}}/>
                </IconButton>
                              
            </Stack>
            <Menu
                anchorEl={notifsAE}
                open={notifsOpen}
                onClose={()=> setNotifsAE(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{paper: {style: { color: "#fff", backgroundColor: '#4B4998', boxShadow: 'none', 
                borderRadius: "10px", border: "5px solid #fff", maxWidth: '40vw', maxHeight:"50%", padding: '5px'}}}}
            >
                <Stack spacing={3} >
                    { notifs
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((n, i) => <Stack key={`notifs${i}`}  spacing={1}>
                        { !n.isRead && <Typography alignSelf='flex-end' style={{margin: '0 5px', padding: '2px 8px', backgroundColor: '#fdc309', width: 'fit-content', color: '#4b4998', fontWeight: 'bold'}}>{text.nw}</Typography> }
                        <Typography>{n.content}</Typography>
                        <Typography>{n.date ? format(n.date, 'dd/MM/yyyy HH:mm') : ''}</Typography>
                        {
                            n.isRead==false ?
                                
                                loading ? <Stack style={{alignSelf: 'end', width: "fit-content"}}><CircularProgress color="inherit" size="18px"/></Stack>
                                : <Typography style={{cursor: "pointer", textAlign: 'end'}} color="secondary" padding="1% 5%" borderRadius="10px" variant="white" onClick={()=>handleMarkReadNotif(n.id)}>{text.mrk}</Typography>      
                            : <></>

                        }
                        <Divider style={{border: '1px solid white'}}/>
                    </Stack>
                    )}
                </Stack>
            </Menu>
            <Menu
                className="menu-box"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                slotProps={{paper: {style: { color: "#fff", backgroundColor: '#4B4998', boxShadow: 'none', 
                borderRadius: "10px", border: "5px solid #fff"}}}}
            >
                <Accordion style={{width: "100%"}} variant="loginfo">    
                    <MenuItem  variant="white" >
                        <AccordionSummary style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} expandIcon={<ExpandMoreIcon style={{color: '#fff'}}/>}>
                            <Box display="flex" flexDirection="row" gap={2} sx={{alignItems: "center" }}>
                                <img className="menubox-img"  src="/images/HeaderImage/MemberOpt/56.png" alt=""/>
                                <Typography variant="tiny">{text.memberopt1}</Typography>
                            </Box>
                        </AccordionSummary>
                    </MenuItem>
                    <AccordionDetails >
                        <Stack gap={2} >
                            <Box display="flex" flexDirection="row" gap={2} >
                                <Stack><img height='20px' src="/images/Member/dashboard/2.png" alt=""/></Stack>
                                <Typography variant="white" onClick={() => handleButtonClick(1)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.information1}</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" gap={2} >
                                <Stack><img height='20px' src="/images/Member/dashboard/3.png" alt=""/></Stack>
                                <Typography variant="white" onClick={() => handleButtonClick(2)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.information2}</Typography>
                            </Box>
                        </Stack>
                        
                    </AccordionDetails>
                </Accordion>
                    
                <Accordion style={{width: "100%"}} variant="loginfo">    
                    <MenuItem  variant="white">
                        <AccordionSummary style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} expandIcon={<ExpandMoreIcon style={{color: '#fff'}}/>}>
                            <Box display="flex" flexDirection="row" gap={2} sx={{alignItems: "center"}}>
                                <img className="menubox-img"  src="/images/HeaderImage/MemberOpt/57.png" alt=""/>
                                <Typography variant="tiny">{text.memberopt2}</Typography>
                            </Box>
                        </AccordionSummary>
                    </MenuItem>
                    <AccordionDetails>
                        <Stack gap={2}>
                            <Box display="flex" flexDirection="row" gap={2} >
                                <Stack><img height='20px' src="/images/Member/dashboard/5.png" alt=""/></Stack>
                                <Typography variant="white" onClick={() => handleButtonClick(3)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.application1}</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" gap={2} >
                                <Stack><img height='20px' src="/images/Member/dashboard/6.png" alt=""/></Stack>
                                <Typography variant="white" onClick={() => handleButtonClick(4)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.application2}</Typography>
                            </Box>
                        </Stack>
                        
                    </AccordionDetails>
                </Accordion>
                { show &&
                <Accordion style={{width: "100%"}} variant="loginfo">    
                    <MenuItem  variant="white">
                        <AccordionSummary style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} expandIcon={<ExpandMoreIcon style={{color: '#fff'}}/>}>
                            <Box display="flex" flexDirection="row" gap={2} sx={{alignItems: "center"}}>
                                <img className="menubox-img"  src="/images/HeaderImage/MemberOpt/58.png" alt=""/>
                                <Typography variant="tiny">{text.memberopt3}</Typography>
                            </Box>
                        </AccordionSummary>
                    </MenuItem>
                    <AccordionDetails>
                        <Stack gap={2}>
                            <Box display="flex" flexDirection="row" gap={2} >
                                <Stack><img height='20px' src="/images/Member/dashboard/8.png" alt=""/></Stack>
                                <Typography variant="white" onClick={() => handleButtonClick(5)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.integration1}</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" gap={2} >
                                <Stack><img height='20px' src="/images/Member/dashboard/9.png" alt=""/></Stack>
                                <Typography variant="white" onClick={() => handleButtonClick(6)} style={{ cursor: 'pointer', alignSelf: "center" }}>{text.integration2}</Typography>
                            </Box>

                        </Stack>
                        
                    </AccordionDetails>
                </Accordion>
                }
                <Logout type={roles.candidat} transparent imgSrc="/images/HeaderImage/MemberOpt/59.png"/>
            </Menu>
        </Stack>
        <IconButton onClick={handleShowNotif} sx={{ transition: '.2s', '&:hover': { transform: 'scale(1.25)' }}}> 
        <Badge badgeContent={notifsCount} color="error" sx={{color: 'white'}}>
            <Icon style={{color: '#fdc309'}}><NotificationsIcon/></Icon>
        </Badge>
    </IconButton>  
    </>
    )
}

export default MemberOpt