import React, { useContext, useState, useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import ImgInputLight from "../../tools/ImgInputLight";
import { useForm } from 'react-hook-form'
import useLanguage from "../../hooks/useLanguage";
import PointedButton from "../../tools/PointedButton";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../axios";
import { roles } from "../../../helper/roles";
import { AuthStateContext } from "../../state/AuthStateContext";
import { AuthRedirectStateContext } from "../../state/AuthRedirectStateContext";
import Alert from '@mui/material/Alert';
import { format } from "date-fns";

const SignIn = (props) =>{
    const baseUrl = '/images/Member/'
    const { control, handleSubmit, formState: {errors} } = useForm()
    const [ error, setError ] = useState('')
    const { text } = useLanguage()
    const { setAuthenticated } = useContext(AuthStateContext)
    const { redirectRoute } = useContext(AuthRedirectStateContext)
    const [loading, setLoading] = useState(false)
    const [ datereactivate, setDateReactivate ] = useState('')
    const navigate = useNavigate()
    const signIn = async data =>{
        const { mailpseudo, password } = data
        setError('')
        setLoading(true)
        try {
            const resp = await axiosInstance.post('/login', { email: mailpseudo, user_name: mailpseudo, password: password, role: roles.candidat })
            if (resp.status == 200){
                await setAuthenticated(true, roles.candidat, resp.data.token)
                navigate(redirectRoute)
            }
        } catch (error) {
            if (error.response.status == 401){
                if (error.response.data.error =="id") setError('id')
                else setError('mdp')
            }
            else if (error.response.status == 403){
                if (error.response.data.message =='Votre compte a été suspendu pendant 15 jours vous ne pouvez pas accédez') 
                {
                    setDateReactivate (error.response.data.date ? format(new Date(error.response.data.date), 'dd/MM/yyyy') : '')
                    setError('sspnd')
                }
            else if (error.response.data.error=='Utilisateur banni') {
                    setError('bn')
                }
            }
            
            else setError('smth')
        }
        setLoading(false)
    }

    const getError = error =>{
        
        switch (error){
            case 'id': return <Typography variant= "error" >{text.iderr}</Typography>
            case 'mdp': return <Typography variant= "error" >{text.mdperr}</Typography>
            case 'bn': return <Typography variant= "error" >{text.bn}</Typography>
            case 'sspnd': return <Typography variant= "error" >{text.susp}{datereactivate} {text.reak}</Typography>
            default: return <Typography variant="error">{text.smthngwntwrng}</Typography>;
        }
    }

    useEffect(() => {
        if (error) {
          const timeout = setTimeout(() => {
            setError(null);
          }, 6000);
    
          return () => clearTimeout(timeout);
        }
      }, [error]);

    return(
        <Stack width="100%" alignItems="center" justifyContent="center" style={{transition: 'visibility .2s', visibility: props.visible ? 'visible' : 'hidden'}}>
            <Stack className="width-resize" spacing={3}>
                <ImgInputLight placeholder={text.yourmailpseudo} control={control} name="mailpseudo" type="text" error={errors.mailpseudo} imgSrc={baseUrl + 'signinuser.png'}/>
                <ImgInputLight placeholder={text.yourpassword} control={control} name="password" type="password" error={errors.password} imgSrc={baseUrl + 'signinpassword.png'}/>
                {error && <Alert style={{margin: "20px"}} severity="error">
                    {getError(error)}
                </Alert>}                
                <Link className="link4" onClick={()=> props.onPasswordForgot()} style={{textAlign: "center", textDecoration: "none"}}><Typography>{text.idorpasswordforgot}</Typography></Link>
                <Stack alignItems="center"><PointedButton loading={loading} onClick={handleSubmit(signIn)} width="calc(100% - 50px)">{text.signin}</PointedButton></Stack>
                <Button onClick={()=>props.onChangeSlide(1, null)}><Typography variant="white">{text.back}</Typography></Button>
            </Stack>
        </Stack>
    )
}
export default SignIn