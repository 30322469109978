import { Stack, Typography, Button, Checkbox, Box, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import useLanguage from "../hooks/useLanguage";
import { useForm } from 'react-hook-form'
import Input from "../tools/input";
import { Link, useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";
import NavItem from "../tools/NavItem";
import CustomDivider from "../tools/CustomDivider"
import SMButton from "../tools/SMButton"
import axiosInstance from "../../axios";
import Alert from '@mui/material/Alert';

const Footer = () =>{
    const {text, currentLanguage} = useLanguage()
    const { control, handleSubmit, formState: {errors} } = useForm()
    const [ consentsErrShow, setConsentsErrShow ] = useState(false)
    const [ termsAgree, setTermsAgree ] = useState(false)
    const navigate = useNavigate()
    const [snData, setSnData] = useState([])
    const getData = async() =>{
        try {
            const { data } = await axiosInstance.get(`/social_networks`)
            setSnData(data) 
        } catch (error) { console.error(error) }
    }

    useEffect(()=>{getData()}, [])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const subscribe = async data => {
        const {firstname, email} = data
        setConsentsErrShow(!termsAgree)
        if (termsAgree) {
            setLoading(true)
            setError('')
            try {
                const resp = await axiosInstance.post(`/subscribe/${currentLanguage}`, {email: email, firstname: firstname})
                if (resp.status == 201){
                    setError('s')
                }
            } catch (error) {
                console.log(error.response)
                if (error.response.status == 400) { if  (error.response.data.errors.mail == "Cet e-mail est déjà utilisé.") setError('subfailed') } else setError('smthngwntwrng')
                if (error.response.status == 403) setError('bn')
                else setError('smthngwntwrng')
            }
            setLoading(false)
        }    
    }
    useEffect(() => {
        if (error) {
          const timeout = setTimeout(() => {
            setError(null);
          }, 4000);
    
          return () => clearTimeout(timeout);
        }
      }, [error]);

    const getUrl = snName =>{
        const data = snData.filter(d => d.languages[0]?.name == currentLanguage && d.social_name.toLowerCase() == snName.toLowerCase())
        return data[0]?.link
    }

    return(
        <Stack variant="footer">
            <Stack margin='30px' spacing={10}>

                <Box className="wrap-content" justifyContent="space-between">
                    <Stack spacing={3} className="footer-first footer-s">
                        <NavItem to="home">
                            <img src="/images/HeaderImage/CNheader.png" style={{cursor: "pointer"}} alt="" width="100%"/>
                        </NavItem>
                        <Typography variant="footertext">{text.slog}</Typography>
                        <Typography variant="footertext">{text.foexpl}</Typography>
                    </Stack>

                    <Stack alignItems='flex-start' className="footer-second footer-s" spacing={2}>
                        <Typography className="title-typo" variant="footertitle" textAlign='center'>
                            { text.useful } <Typography variant="secondaryfootertitle" width='80%' className="title-typo-change">{text.links}</Typography>
                        </Typography>
                        <CustomDivider color="secondary" width='75%'/>
                        <Stack spacing={1}>
                        <Button variant='footernav' style={{justifyContent: "flex-start", padding: 0}}><NavItem to="missions">{text.ourmissions}</NavItem></Button>
                        <Button variant='footernav' style={{justifyContent: "flex-start", padding: 0}}><NavItem to="compvalues">{text.ourcompany}</NavItem></Button>
                        <Button variant='footernav' style={{justifyContent: "flex-start", padding: 0}}><NavItem to="advantages">{text.ouradvantages}</NavItem></Button>
                        <Button variant="footernav" style={{justifyContent: "flex-start", padding: 0}} onClick={()=>navigate(RoutesList.blog)}>  
                            <Typography variant="nav">{text.blog}</Typography>
                        </Button>
                        <Button onClick={() => navigate(RoutesList.jobs)} variant="footernav" style={{ justifyContent:'flex-start', padding: 0 }}>
                            <Typography variant="nav">{text.jobs}</Typography>
                        </Button>
                        </Stack>
                    </Stack>

                    <Stack className="footer-third footer-s" spacing={4}>
                        <Stack spacing={2}>
                            <Typography className="title-typo" variant="footertitle" width='80%' textAlign='left'>
                                { text.our } <Typography variant="secondaryfootertitle" width='80%' className="title-typo-change">{text.contact}</Typography>
                            </Typography>
                            <CustomDivider color="secondary" width='75%'/>
                            <Stack spacing={1}>
                                <Typography variant="footertext">{text.focontact1}</Typography>
                                <Typography variant="footertext">{text.focontact2}</Typography>
                                <Typography variant="footertext">{text.focontact3}</Typography>
                            </Stack>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography className="title-typo" variant="footertitle" width='80%' textAlign='left'>
                                <Typography variant="secondaryfootertitle" width='80%' className="title-typo-change">{text.follow}</Typography>{text.us}
                            </Typography>
                            <CustomDivider color="secondary" width='75%'/>
                            <Stack display="flex" flexDirection="row" justifyContent="space-evenly">
                                <SMButton network="facebook" url={getUrl("Facebook")}/>
                                <SMButton network="x" url={getUrl("Twitter")}/>
                                <SMButton network="youtube" url={getUrl("Youtube")}/>
                                <SMButton network="instagram" url={getUrl("Instagram")}/>
                                <SMButton network="pinterest" url={getUrl("Pinterest")}/>
                                <SMButton network="linkedin" url={getUrl("Linkedin")}/>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack className="footer-last footer-s" spacing={2}>
                        <Typography className="title-typo" variant="footertitle" width='80%' textAlign='left'>
                            { text.subscribetoour } <Typography variant="secondaryfootertitle" width='80%' className="title-typo-change"> {text.newsletter}</Typography>
                        </Typography>
                        <CustomDivider color="secondary" width='75%'/>
                        <Stack spacing={2}>
                            <Stack>
                                <Typography variant="footerlabel">{text.firstname}</Typography>
                                <Input type='text' label="" control={control} name='firstname' error={errors.firstname} white={true}/>
                            </Stack>
                            <Stack>
                                <Typography variant="footerlabel">{text.email}</Typography>
                                <Input type='email' label="" control={control} name='email' error={errors.email} white={true}/>
                            </Stack>
                            <Stack>
                                <Typography variant="footerlabel">{text.consents}</Typography>
                                <Stack display='flex' flexDirection='row' justifyContent='flex-start' gap='10px'>
                                    <Checkbox onChange={()=> setTermsAgree(!termsAgree)} variant="primarybg" size="small"/>
                                    <Typography variant="footertext">
                                        { text.termsAgree1 } <Link to={RoutesList.pp} className="link">{ text.termsAgreeLink1 }</Link> { text.termsAgree2 } <Link to={RoutesList.terms} className="link">{ text.termsAgreeLink2 }</Link>
                                    </Typography>
                                </Stack>
                                { consentsErrShow ?  <Typography variant="footertext" color='#F00' textAlign='left'>{text.consentsErr}</Typography> : <></>}
                            </Stack>
                            {error && <Alert severity= {error=='s' ? "success" : "error" } >
                                {
                                    (error=='s') ? <Typography variant= "success" >{text.subsuccess}</Typography>
                                    : ((error=='subfailed') ? <Typography variant= "error" >{text.subfailed}</Typography> 
                                    :  ((error=='bn') ? <Typography variant= "error" >{text.bn}</Typography> 
                                    :  <Typography variant= "error" >{text.smthngwntwrng}</Typography>))
                                }
                                
                            </Alert>}
                            <Button variant="footer" onClick={handleSubmit(subscribe)}>{loading ? <CircularProgress size="22px"/> : text.subscribe}</Button>
                        
                        </Stack>
                    </Stack>
                </Box>

                <Typography variant="footertext" margin='15px'>{text.copyright}</Typography>
            </Stack>
        </Stack>
    )
}

export default Footer