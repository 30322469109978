import { Stack, Typography, Button, CircularProgress } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import useLanguage from "../hooks/useLanguage";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import {useForm} from 'react-hook-form'
import FormRow from "../tools/FormRow";
import { AuthStateContext } from "../state/AuthStateContext";
import axiosInstance from "../../axios";
import { format, addDays } from "date-fns";
import Alert from '@mui/material/Alert';

const NewsLetter = () =>{
    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        language: 'all',
    })
    const [filter2, setFilter2] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        language: 'all',
        topic: '',
    })
    const { control, handleSubmit, formState: {errors}, setValue: setValue } = useForm()

    const { text } = useLanguage()
    const { testLanguages, subscribersList, newslettersList, languageName, filterLanguages } = useData()

    const [subscribers, setSubscribers] = useState([])
    const { adminId } = useContext(AuthStateContext)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        const subscriber = async () =>{try {
            const { data } = await axiosInstance.get(`/subscribes`, { headers: {'Authorization': `Bearer ${adminId} `} })
            console.log(data)
            setSubscribers(data)
            
        } catch (err){}}
        subscriber()  
    },[]) 

    const [newsletter, setNewsletter] = useState([])

    useEffect(()=>{
        const newsfunct =async() => {
            try{
                const{data} = await axiosInstance.get('/news_letter/all', { headers: {'Authorization' : `Bearer ${adminId}`}})
                setNewsletter(data)
            } catch(err){}
        }
        newsfunct()
    }, [])


    const [illustration, setIllustration] = useState(null)
    const newNewsletter = async data => {
        const { language, title, text, category } = data;
        setLoading(true)
        setError('')
        const formData = new FormData();
        formData.append('language', language);
        formData.append('title', title);
        formData.append('image', illustration);
        formData.append('text', text);
        formData.append('category', category);
        
        try {
            const { status } = await axiosInstance.post(`/news_letter/add`, formData, {
                headers: {
                    'Authorization': `Bearer ${adminId}`,
                    'Content-Type': 'multipart/form-data',
                },
                transformRequest: null
            });
    
            if (status === 201) {
                setError('crtd')
                window.location.reload()
            }
        } catch (error) {
            if (error.response.status === 403) {setError('accref')}
            else {setError('smth')}
        }
        setLoading(false)

    
    };

    const [loadingActions, setLoadingActions] = useState(0)

    const deleteSubscriber = async id =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.delete(`/subscriber/delete/${id}`, {headers: { 'Authorization': `Bearer ${adminId}`}})
            const newList = subscribers.filter(s => s.id !== id)
            setSubscribers(newList)
            if (status === 200) {
                setError('suppr')
            }
        } catch (error) {
            if (error.response.status === 401) {setError('dcnnctd')}
            if (error.response.status === 403) {setError('accref')}
            if (error.response.status === 404) {setError('sbntfnd')}
        }
        setLoadingActions(0)
    }

    const deleteNewsletter = async id =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.delete(`/news_letter/delete/${id}`, {headers: { 'Authorization': `Bearer ${adminId}`}})
            const newList = newsletter.filter(s => s.id !== id)
            setNewsletter(newList)
            if (status === 200) {
                setError('suppr')
            }
        } catch (error) {
            if (error.response.status === 401) {setError('dcnnctd')}
            if (error.response.status === 403) {setError('accref')}
            if (error.response.status === 404) {setError('sbntfnd')}
        }
        setLoadingActions(0)
    }

    const suspend = async id =>{
        setLoadingActions( id )
        try {
            const {status} = await axiosInstance.post(`/subSuspend/${id}`, {}, {headers: { 'Authorization': `Bearer ${adminId}`}})
            const newList = [...subscribers]
            const i = subscribers.findIndex(c => c.id == id)
            newList[i].suspend = addDays(new Date(), 15)
            console.log(newList)
            setSubscribers(newList)
            if (status === 200) {
                setError('susp')
            }
        } catch (error) {
            if (error.response.status === 403) {setError('accref')}
            if (error.response.status === 404) {setError('usrntfnd')}
        }
        setLoadingActions(0)
        
    }

    const getError = error =>{
        
        switch (error){
            case 'crtd': return <Typography variant= "success" >{text.crtd}</Typography>
            case 'suppr': return <Typography variant= "success" >{text.dltd}</Typography>
            case 'dcnnctd': return <Typography variant="error">{text.dcnnctd}</Typography>
            case 'accref': return <Typography variant="error">{text.accref}</Typography>
            case 'sbntfnd' : return <Typography variant="error">{text.sbntfnd}</Typography>
            case 'susp': return <Typography variant= "success" >{text.sspndd}</Typography>
            default: return <Typography variant="error">{text.smthngwntwrng}</Typography>;
        }
    }

    useEffect(() => {
        if (error) {
          const timeout = setTimeout(() => {
            setError(null);
          }, 4000);
    
          return () => clearTimeout(timeout);
        }
      }, [error]);


    return(
        <Stack variant="primarybg" spacing={3}>
            <Stack style={{display: 'flex', gap:'15px', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                <img className="card-img-resize-2" src='/images/Admin/newsletter.png' alt=""/>
                <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#fde6e7' fontWeight='bold' 
                    style={{WebkitTextStroke: "1px #936695"}}
                >{text.newsletter.toUpperCase()  }</Typography>
            </Stack>
            <BigContainerAccordion 
                summaryBg="#936695"
                summary={text.anl1}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter({...filter, language: l.target.value}) },
                                //{ label: text.newsubscribers , comp: 'TextInput', onChange: e => setFilter({...filter, newsubscribers: e.target.value}) },
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 1,  text: text.subscriptiondate},
                                { xs: 2,  text: text.memberfirstname},
                                { xs: 3,  text: text.email},
                                { xs: 2,  text: text.language},
                                { xs: 1,  text: text.emailaddressconfirmed},
                                { xs: 1,  text: text.sspndd},
                                { xs: 2,  text: text.actions},
                            ]}/>
                            {
                                subscribers
                                .filter(s => 
                                    // Starting date
                                    ( (!filter.startingDate) || ( filter.startingDate <= new Date(s.created_at)) )
                                    // Closing date
                                    && ( (!filter.closingDate) || (new Date(s.created_at) <= filter.closingDate) )
                                    // Language
                                    && ( (filter.language == 'all') || (s.language?.name == filter.language))  
                                )
                                .map( s =>
                                    <TableRow key={s.id} columns={[
                                        { xs: 1,  text: s.created_at ? format(new Date(s.created_at), 'dd/MM/yyyy') : ''},
                                        { xs: 2,  text: s.first_name},
                                        { xs: 3,  text: s.mail},
                                        { xs: 2,  text: languageName(s.language?.name)},
                                        {   xs: 1,
                                            color: s.mailconfirmed ? '#0b0' : '#f00',
                                            text: s.mailconfirmed ? text.yes : text.no
                                        },
                                        {   xs: 1,
                                            color: '#f00' ,
                                            text: (s.suspend ? (new Date() < new Date(s.suspend)) : "") ? text.sspndd : ""
                                        },
                                        { xs: 2,  customContent: true, content: 
                                            (loadingActions == s.id) ? <CircularProgress/>
                                            :
                                                ((s.suspend ? (new Date() < new Date(s.suspend)) : "") ?
                                                    <Stack width='100%'>
                                                        <Button onClick={() => deleteSubscriber(s.id)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                    </Stack>
                                                :
                                                    <Stack width='100%'>
                                                        <Button onClick={() => suspend(s.id)} style={{textTransform: 'capitalize', color: '#e37584', width: '100%'}}><Typography variant="boldcenter">{text.suspend}</Typography></Button>
                                                        <Button onClick={() => deleteSubscriber(s.id)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.fire}</Typography></Button>
                                                    </Stack>
                                                )
                                                
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error=='suppr' || error=='susp' || error =='crtd') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>}
                    </Stack>
                }
            />
            <BigContainerAccordion 
                summaryBg="#1e57be"
                summary={text.anl2}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                                date: d => setFilter2({...filter2, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter2.startingDate, onChange: d => setFilter2({...filter2, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter2.closingDate, onChange: d => setFilter2({...filter2, closingDate: d})},
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter2({...filter2, language: l.target.value}) },
                                { label: text.topic , comp: 'TextInput', onChange: e => setFilter2({...filter2, topic: e.target.value}) },
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 2,  text: text.releasedate},
                                { xs: 2,  text: text.releaselanguage},
                                { xs: 2,  text: text.topic},
                                { xs: 2,  text: text.linktothenewsletter},
                                { xs: 2,  text: text.sendtoallsubscribers},
                                { xs: 2,  text: text.actions},
                            ]}/>
                            {
                                newsletter
                                .filter(n => 
                                    // Starting date
                                    ( (!filter2.startingDate) || ( filter2.startingDate <= new Date(n.created_at)) )
                                    // Closing date
                                    && ( (!filter2.closingDate) || (new Date(n.created_at) <= filter2.closingDate) )
                                    // Text match
                                    && n.topic?.toLowerCase().includes(filter2.topic)
                                    // Language
                                    && ( (filter2.language == 'all') || (n.language == filter2.language))  
                                )
                                .map( n =>
                                    <TableRow key={n.id} columns={[
                                        { xs: 2,  text: n.created_at ? format(new Date(n.created_at), 'dd/MM/yyyy') : ''},
                                        { xs: 2,  text: languageName(n.language)},
                                        { xs: 2,  text: n.topic},
                                        { xs: 2,  text: n.link},
                                        {   xs: 2,
                                            color: n.sendtoallsubscribers ? '#0b0' : '#f00',
                                            text: n.status_send ? text.yes : text.no
                                        },
                                        { xs: 2,  customContent: true, content: 
                                            (loadingActions == n.id) ? <CircularProgress/>
                                            :
                                            <Stack width='100%'>
                                                {/*<Button style={{textTransform: 'capitalize', color: '#3c6dc6', width: '100%'}}><Typography variant="boldcenter">{text.edit}</Typography></Button>*/}
                                                <Button onClick={()=> deleteNewsletter(n.id)} style={{textTransform: 'capitalize', color: '#f00', width: '100%'}}><Typography variant="boldcenter">{text.delete}</Typography></Button>
                                            </Stack>
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error=='suppr') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>}
                    </Stack>
                }
            />
            <BigContainerAccordion 
                summaryBg="#fbc20b"
                summary={text.anl3}
                details={
                    <Stack spacing={2}>
                        {/*<FormRow label={text.newsletterid} comp="readOnly"/>
                        <FormRow label={text.adminid} comp="Select" control={control} name='adminid' error={errors.adminid}/>*/}
                        <FormRow label={text.language} comp="Select" options={testLanguages} control={control} name='language' error={errors.language}/>
                        <FormRow label={text.newslettercategory} control={control} name='category' error={errors.category}/>
                        <FormRow label={text.illustrationphoto} envImg={setIllustration} comp="Image" control={control} name='illustration' error={errors.illustration}/>
                        <FormRow label={text.newslettertitle} control={control} name='title' error={errors.title}/>
                        <FormRow label={text.newslettertext} comp="DocxAndText" setValue={setValue} control={control} name='text' error={errors.text}/>
                        {/*<FormRow label={text.allsubscribersemail} comp="readOnly"/>*/}
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit(newNewsletter)} style={{backgroundColor: '#01e1e9', color: '#fff', textTransform: 'capitalize', fontWeight: 'bold'}}>
                                {
                                    loading ? <CircularProgress color="inherit" size="24px"/>
                                    : <Typography>{text.publishnewnewsletter}</Typography>      
                                }
                            </Button>
                        </Stack>
                        {error && <Alert style={{width: "fit-content", alignSelf: "center"}} severity={(error== 'crtd') ? "success" : "error"}>
                            {getError(error)}
                        </Alert>}
                    </Stack>
                }
            />
        </Stack>
    )
}

export default NewsLetter