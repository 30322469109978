import { Controller } from "react-hook-form"
import { Stack, Select, MenuItem, Typography } from "@mui/material"
import useLanguage from "../hooks/useLanguage"

const CustomSelect = ({ control, name, label, error , options, required=true, white=false, className= "", max=false, defaultValueIndex=0, onExternalChange= ()=>{}}) => {

    const { text } = useLanguage()
    
    return(
        <Stack style={max ? {height: '100%', width: '100%'} : {}}>
            <Typography variant={white ? "primarybg" : ""}>{label}</Typography>
            <Controller 
                name= {name}
                control={control}
                rules={{required: required}}
                defaultValue={options.length ? options[defaultValueIndex].value : ''}
                render={({ field: { onChange, ...rest } }) => 
                    <Select {...rest} onChange={opt =>{ onChange(opt); onExternalChange(opt.target.value) }} className={className} sx={{boxShadow: 'none', height: max ? '100%' : undefined, background: max ? 'transparent' : undefined ,'.MuiOutlinedInput-notchedOutline': { border: 0 }, "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{border: 0}, }} style={(className == 'in-input-light')?{backgroundColor: 'transparent' ,margin: '0 20px', width: '90%'}: {}}>
                        {
                            options.map(opt =>  <MenuItem key={opt.value} value={opt.value}>{opt.innerHTML}</MenuItem>)
                        }
                    </Select>
                }
            />
            {error && <Typography variant="error" textAlign='left'>{text.required}</Typography>}
        </Stack>
    )
}
export default CustomSelect