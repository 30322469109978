import React from "react";
import { Typography, Stack } from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { useNavigate, useParams, Link } from "react-router-dom";
import RoutesList from "../../helper/routes";
import DateDisplay from "./DateDisplay";
import { format } from "date-fns";

const BlogCard = ({imgSrc,datepub, category, categoryId, texts, id}) =>{

    const { lang } = useParams()
    const navigate = useNavigate()

    return(
        <Stack variant="blogcard" spacing={4} style={{ minWidth: '250px', maxWidth: '250px' }} className="action-blogcard" >
            <Stack alignItems='center'>
                <Stack style={{transform: "translateY(-40px)" ,alignItems: "center", borderRadius: '20px', width: '110%'}} overflow="hidden">
                    <Link to={ "/" + format(datepub, 'yyyy-MM-dd') + "/" +  id + "/" + lang } style={{textDecoration: 'none'}} state={{name: texts[0]}}>
                        <img src={imgSrc} alt=""  style={{maxWidth: '110px', height: '110px'}}/>
                    </Link>
                </Stack>
            </Stack>
            <Stack variant='white' style={{opacity: "0.5"}}>
                <Stack display="flex" flexDirection="row" >
                    <Stack ><CalendarTodayIcon/></Stack>
                    <DateDisplay paddingLeft="10px">{format(datepub, 'dd/MM/yyyy')}</DateDisplay>
                </Stack>
                <Stack display="flex" flexDirection="row" >
                    <Stack><FolderOpenIcon/></Stack>
                    <Typography onClick={()=> navigate(RoutesList.category + categoryId + "/" + lang, { state: { name: category } })} paddingLeft="10px" style={{cursor: 'pointer'}}>{category}</Typography>
                </Stack>
            </Stack>
            <Stack variant="white" paddingBottom="35px" spacing={3} >
                <Typography onClick={() => navigate( "/" + format(datepub, 'yyyy-MM-dd') + "/" +  id + "/" + lang, { state: { name: texts[0] } })} state={{name: texts[0]}} variant="h3title" fontWeight="bold" style={{cursor: 'pointer'}}>{texts[0]}</Typography>
                <Typography style={{opacity: "0.5"}}>{texts[1]}</Typography>
            </Stack>            
        </Stack>
    )
}
export default BlogCard