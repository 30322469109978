import { Stack, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import useData from "../hooks/useData";
import CustomDivider from "../tools/CustomDivider";
import BlogCard from "../tools/BlogCard";
import axiosInstance from "../../axios";
import { baseURL } from "../../helper/conf";

const Blog = () => {
    const { text, currentLanguage } = useLanguage()

    const { dataBlog } = useData()
    const [blogPosts3, setBlogPosts3] = useState([])

    useEffect(()=>{
        const func = async () =>{try {
            const { data } = await axiosInstance.get(`/posts_all`)
            console.log(data)
            setBlogPosts3(data)
        } catch (err){}}
        func()  
    },[]) 

    {/*useEffect(() =>{
        const func = async() => {
            try {
                const { status, data } = await axiosInstance.get(`/posts/${currentLanguage}`)
                if (status == 200) setBlogPosts3(data)
            } catch (error) { console.log(error.response) }         
        }
        func()
    },[])*/}

    return(
        <Stack>
            <Stack>
                <Stack alignItems='center' style={{overflow: 'hidden', position: "relative"}}>
                    <img src={ "/images/Blog/Blog page CN-1.webp"} style={{width: '100%'}}/>
                    <Stack style={{position: 'absolute', top: "50%", left: "65%", transform: "translateY(-50%)", width: "33%"}} spacing={1}>
                        <Typography variant="whitetitle" textAlign='center' style={{fontWeight: 'bold'}}>{text.blog}</Typography>
                        <CustomDivider color="white" alignCenter width='13%'/>
                        <Typography variant="secondaryh2" textAlign='center' style={{fontWeight: 'bold'}}>{text.blog2}</Typography>
                    </Stack>
                </Stack>
                <Stack variant='waves1'><img src='/svg/wavesblog.svg'/></Stack>

                <Stack>
                    <Stack spacing={2}>
                        <Typography variant="primarytitle" textAlign='center'>{text.ourposts}</Typography>
                        <CustomDivider color="secondary" alignCenter width='12%'/>
                        <Typography variant="secondaryh2" textAlign='center' style={{fontWeight: 'bold'}}>{text.blog3}</Typography>
                    </Stack>
                </Stack>
                <Stack className="gap-change" style={{overflowX: 'scroll'}} maxWidth='85%' alignSelf='center' flexDirection='row' display='flex' justifyContent='space-between'>
                    {
                        blogPosts3.filter(b => b.post_language[0]?.name == currentLanguage).map(datas =>  <BlogCard key={`bp${datas.id}`} id={datas.id} imgSrc={`${baseURL}/images/posts/${datas.image_illustration}`} datepub={datas.created_at} category={datas.category.name} categoryId={datas.category.id} texts={[datas.title_Post, datas.content]} />)
                    }
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Blog