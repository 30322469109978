import { Typography, Stack, Box } from "@mui/material";
import React from "react";
import useLanguage from "../../hooks/useLanguage";
import IconTextCard from "../../tools/IconTextCard";
import AnimatedButton from "../../tools/AnimatedButton";
import CustomDivider from "../../tools/CustomDivider"
import { useParams } from "react-router-dom";

const Requirements = () =>{
    const iconBaseUrl = "/images/TeamLeader/icons/4_Requirements/"
    const { text } = useLanguage()
    const { lang } = useParams()
    return(
        <Stack alignItems='center' spacing={5} margin='35px 0'>
            <Typography className="title-typo text-resize-4" variant="title">
                {text.tlreint1} <Typography variant="primarytitle2" className="title-typo-change-light-2">{text.teamleader}</Typography> {text.tlreint2}
            </Typography>
            <CustomDivider color="secondary" alignCenter width='22%'/>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="strcontent" bgColor="#f1dbd0" iconSrc={iconBaseUrl+ "1_Proficiency_"+ lang + ".png"} label={text.cor11}>{text.tlre12}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#f1dbd0" iconSrc={iconBaseUrl+ "2_Age Limit.svg"} label={text.cor21}>{text.tlre22}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#f1dbd0" iconSrc={iconBaseUrl+ "3_Bank Account or Payment Account.svg"} label={text.cor31}>{text.cor32}</IconTextCard>
            </Box>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="strcontent" bgColor="#f1dbd0" iconSrc={iconBaseUrl+ "4_Acceptance of Privacy Statement and Contract Terms.svg"} label={text.cor41}>{text.tlre42}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#f1dbd0" iconSrc={iconBaseUrl+ "5_Equipment Responsibility.svg"} label={text.cor51}>{text.cor52}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#f1dbd0" iconSrc={iconBaseUrl+ "6_Reports.svg"} label={text.tlre61}>{text.tlre62}</IconTextCard>
            </Box>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="strcontent" bgColor="#f1dbd0" iconSrc={iconBaseUrl+ "7_Minimum weekly hours.svg"} label={text.cor71}>{text.tlre72}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#f1dbd0" iconSrc={iconBaseUrl+ "8_Tax.svg"} label={text.tlre81}>{text.tlre82}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#f1dbd0" iconSrc={iconBaseUrl+ "9_Adherence to Legal and Regulatory Guidelines.svg"} label={text.cor91}>{text.tlre92}</IconTextCard>
            </Box>
            <AnimatedButton to="apply-tl">{text.applynow}</AnimatedButton>
        </Stack>
    )
}

export default Requirements