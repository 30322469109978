import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Stack, Dialog } from '@mui/material';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfDisplayer = ({file, innerTableCell= false, openable=false}) =>{
    //const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [open, setOpen] = useState(false)
    /*useEffect(() => {
        const handleResize = () => {setWindowWidth(window.innerWidth)}
        window.addEventListener('resize', handleResize)
        return () => {window.removeEventListener('resize', handleResize)}
    }, [])*/

    return<><Stack display='flex' alignItems='center' justifyContent='center' onClick={()=>{ if (openable) setOpen(true) }} sx={{height: '100%', width: '100%'}} style={openable ? {cursor: 'pointer'} : {}}>
        <Document file={file}>
            <Page className='pdf-preview' pageNumber={1} renderMode="canvas" height={innerTableCell ? 100 : 400} renderTextLayer={false} renderAnnotationLayer={false}/>
        </Document>
    </Stack>
    <Dialog
        open={open}
        onClose={()=> setOpen(false)}
    >
        <Stack position="relative">
            <Document file={file}>
                <Page className='pdf-preview' pageNumber={1} renderMode="canvas" height={1000} renderTextLayer={false} renderAnnotationLayer={false}/>
            </Document>
        </Stack>
    </Dialog></>
}

export default PdfDisplayer