import { Stack, Typography, Box } from "@mui/material";
import React from "react";
import AdminCard from "../tools/AdminCard";
import useLanguage from "../hooks/useLanguage";

const PositionOverview = ({seeDetails}) =>{
    const { text } = useLanguage()
    return(
        <Stack spacing={2}>
            <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#ffc50f' fontWeight='bold' 
                style={{WebkitTextStroke: "1px #7f6002"}}
            >{text.positionoverview.toUpperCase()  }</Typography>
            <Box className="wrap-content" justifyContent='center' gap='50px'>
                <AdminCard label={text.adminstaff} imgFile="admin_staff.png" onDetails={()=>seeDetails(1)}/>              
                <AdminCard label={text.jobposts} imgFile="job_posts.png" onDetails={()=>seeDetails(2)}/>
                <AdminCard label={text.jobapplication} imgFile="job_application.png" onDetails={()=>seeDetails(3)}/>
            </Box>
            <Box className="wrap-content" justifyContent='center' gap='50px'>
                <AdminCard label={text.empintegration} imgFile="employees_integration.png" onDetails={()=>seeDetails(5)}/>
                <AdminCard label={text.newemptraining} imgFile="new_employee_training.png" onDetails={()=>seeDetails(4)}/>
                <AdminCard label={text.blogposts} imgFile="blog_posts.png" onDetails={()=>seeDetails(6)}/>
            </Box>
            <Box className="wrap-content" justifyContent='center' gap='50px'>
                <AdminCard label={text.cnmembers} imgFile="cnmembers.png" onDetails={()=>seeDetails(7)}/>
                <AdminCard label={text.newsletter} imgFile="newsletter.png" onDetails={()=>seeDetails(8)}/>
                <AdminCard label={text.sn} imgFile="sn.png" onDetails={()=>seeDetails(9)}/>
            </Box>
        </Stack>
    )
}

export default PositionOverview