import React, { useRef, useState } from 'react';
import { Box, Stack, Button, Typography } from "@mui/material";
import { freelanceContract, freelanceContractName } from '../../helper/conf';

const Camera = ({label, camera=false}) => {
  const videoRef = useRef(null);
  const [mediaStream, setMediaStream] = useState(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      setMediaStream( stream);
    } catch (error) {
      console.error('Erreur en accédant à la caméra :', error);
    }
  };

  const stopCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
  };

  const handleDownload = () => {
    fetch(freelanceContract)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
  
        a.download = freelanceContractName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Erreur du téléchargement :', error));
  };
  


  return (
    
    <Box className="wrap-content"  gap="25px" padding="0px 20px" >
        <Stack width="70%" alignSelf="center"><Typography variant="white">{label}</Typography></Stack>
        {
          camera ? 
          <Stack width="20%" alignSelf="center"><Button className="usericon" style={{display: "flex", alignItems:"center", justifyContent:"center"}} onClick={startCamera}><img src="/images/Member/dashboard/information/10.png" alt="" style={{ height:"50px", width:"50px"}}/></Button></Stack>
          :
          <Stack width="20%" alignSelf="center"><Button className="usericon"><img src='/images/Member/dashboard/integration/37.png' alt="" style={{width: "140px"}} onClick={handleDownload}/></Button></Stack>
        }
    </Box>

  );
};

export default Camera;