import React from "react";
import { Stack } from "@mui/material";
import Language from '../Language/Language'
import UserOptions from "./UserOptions";
import UserTitle from "../tools/UserTitle";
import NavItem from "../tools/NavItem";

const Header3 = ({variant=null}) => {
    return(
        <Stack className="head" variant='head' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' >
            <Stack style={{height: "75%"}}>
                <NavItem to="home">
                <img src="/images/HeaderImage/CNheader.png" style={{cursor: 'pointer'}} alt="" height='100%'/>
                </NavItem>
            </Stack>
            { variant ? <UserTitle variant={variant}/> : <></> }
            <Stack flexDirection='row'>
                { variant ? <UserOptions variant={variant}/> : <></> }
                <Language/>
            </Stack>
        </Stack>
    )
} 

export default Header3;