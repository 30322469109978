import React from "react";
import { Stack, Typography} from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import TitleWithBg from '../../tools/TitleWithBg'
import ImgInputLight from "../../tools/ImgInputLight";

const InfoChange = ({control, errors}) =>{
    const { text } = useLanguage()
    

    return(
        <Stack variant="rightcontent">
            <Stack display="flex" justifyContent="center" alignItems="center"><TitleWithBg iconSrc='/images/Member/dashboard/1.png' children={text.information} color="#f61c02" title/></Stack>
            <Stack style={{alignItems: "center", justifyContent: "center"}}><Typography variant="secondaryitalic">{text.information2}</Typography></Stack>
            <Stack className="password-input" spacing={3}  display="flex" justifyContent="center" alignSelf="center">
                <ImgInputLight placeholder={text.actualpassword} required={false} control={control} name="actualpassword" type="password" error={errors.actualpassword} imgSrc="/images/Member/dashboard/information/actual password.png"/>
                <ImgInputLight placeholder={text.newpassword} required={false} control={control} name="newpassword" type="password" error={errors.newpassword} imgSrc="/images/Member/signinpassword.png"/>
                <ImgInputLight placeholder={text.confirmnewpassword} required={false} control={control} name="confirmnewpassword" type="password" error={errors.confirmnewpassword} imgSrc="/images/Member/signinpassword.png"/>

            </Stack>
        </Stack>
    )
}

export default InfoChange