import { Stack, Box, Button } from "@mui/material";
import React from "react";
import useLanguage from "../hooks/useLanguage";
import CustomFormItem from "./CustomFormItem";
import TimeSelect from "./TimeSelect";

const SearchFilter = ({ selectedDate, onDateChange, items}) =>{
    const {text} = useLanguage()
    return(
        <Stack spacing={2}>
            <Box className="wrap-content" gap='20px' justifyContent='space-between'>
                <TimeSelect type='year' onChange={e => onDateChange.year(e.target.value)}/>
                <TimeSelect type='month' onChange={e => onDateChange.month(e.target.value)}/>
                <TimeSelect type='date' selected={selectedDate} onChange={onDateChange.date}/>
            </Box>
            <Box className="wrap-content" gap='10px'justifyContent='flex-start'>
                {
                    ['#7ed957', '#a5e2ff', '#ffde59', '#ff5757'].map((wbg, i) =>
                        <Button key={wbg} style={{backgroundColor: wbg, maxWidth: '50%', fontWeight: 'bold', textTransform: 'capitalize', color: '#000'}}>
                            {text.week + ' ' + (i+1) + ' -'}
                        </Button>    
                    )
                }
                <Button style={{backgroundColor: '#1e57be', fontWeight: 'bold', textTransform: 'capitalize', color: '#fff'}}>
                    {text.today}
                </Button>    
            </Box>
            <Stack className="width-resize">
                {
                    items.map(i =>
                        <CustomFormItem key={i.label} label={i.label} options={{comp: i.comp, selected: i.selected,
                            onChange: i.onChange, choices: i.choices}}/>
                    )
                }
            </Stack>
        </Stack>
    )
}

export default SearchFilter