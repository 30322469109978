import React from "react";
import { Typography, Stack, Box } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import IconTextCard from "../../tools/IconTextCard";
import AnimatedButton from "../../tools/AnimatedButton";
import CustomDivider from "../../tools/CustomDivider"

const Skills = () =>{
    const { text } = useLanguage()
    const iconBaseUrl = "/images/Supervisor/icons/3_QS job skills/"
    return(
        <Stack alignItems='center' spacing={5} padding='35px 0' variant="primarybg">
            <Typography className="title-typo width-resize-tlint-typo" variant="whitetitle" textAlign='center'>
                {text.sskint1} <Typography variant="secondarytitle" className="title-typo-change">{text.qualitysupervisor}</Typography> {text.sskint2}
            </Typography>
            <CustomDivider color="secondary" alignCenter width='22%'/>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard whitetext variant="strcontent" bgColor="#cf1d60" iconSrc={iconBaseUrl+ "1_Leadership.svg"} label={text.leadership}>{text.ssk1}</IconTextCard>
                <IconTextCard whitetext variant="strcontent" bgColor="#cf1d60" iconSrc={iconBaseUrl+ "2_Communication.svg"} label={text.communication}>{text.ssk2}</IconTextCard>
                <IconTextCard whitetext variant="strcontent" bgColor="#cf1d60" iconSrc={iconBaseUrl+ "3_Analytical Skills.svg"} label={text.analyticalskills}>{text.ssk3}</IconTextCard>
            </Box>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard whitetext variant="strcontent" bgColor="#cf1d60" iconSrc={iconBaseUrl+ "4_Problem-Solving.svg"} label={text.pbsolving}>{text.ssk4}</IconTextCard>
                <IconTextCard whitetext variant="strcontent" bgColor="#cf1d60" iconSrc={iconBaseUrl+ "5_Quality assurance-def.svg"} label={text.qualityassurance}>{text.ssk5}</IconTextCard>
                <IconTextCard whitetext variant="strcontent" bgColor="#cf1d60" iconSrc={iconBaseUrl+ "6_Training and Development.svg"} label={text.traininganddev}>{text.ssk6}</IconTextCard>
            </Box>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard whitetext variant="strcontent" bgColor="#cf1d60" iconSrc={iconBaseUrl+ "7_Innovation.svg"} label={text.innovation}>{text.ssk7}</IconTextCard>
                <IconTextCard whitetext variant="strcontent" bgColor="#cf1d60" iconSrc={iconBaseUrl+ "8_Collaboration.svg"} label={text.collab}>{text.ssk8}</IconTextCard>
                <IconTextCard whitetext variant="strcontent" bgColor="#cf1d60" iconSrc={iconBaseUrl+ "9_Adaptability.svg"} label={text.adaptability}>{text.ssk9}</IconTextCard>
            </Box>
            <AnimatedButton to="apply-s">{text.applynow}</AnimatedButton>
        </Stack>
    )
}

export default Skills