import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import AnimatedButton from "../../tools/AnimatedButton";
import useLanguage from "../../hooks/useLanguage";
import IconText from '../../tools/IconText'
import CustomDivider from "../../tools/CustomDivider"

const IdentityCheck = () =>{
    const { text } = useLanguage()
    const iconBaseUrl = "/images/Supervisor/icons/5_Quality Supervisor Age verification reason/"
    return(
        <Stack>
            <Stack variant="waves2" className="waves-display"><img src="/svg/waves2.svg"/></Stack>
            <Stack variant="curvy">
                <Box className="wrap-content" margin='15px 0'>
                    <Stack width="45%" display='flex' alignItems='center' justifyContent='flex-end'><img width='80%' src="/images/chat-operator/5- ID-Checking-CN.webp" alt=""/></Stack>
                    <Stack width="55%">
                        <Stack spacing={3}>
                            <Typography className="title-typo text-resize-4" variant="whitetitle" textAlign='left'>
                                {text.coicint1} <Typography variant="secondarytitle" className="title-typo-change">{text.cn}</Typography> {text.coicint2}
                            </Typography>
                            <CustomDivider color="secondary" width='14%'/>
                            <Stack gap="20px">
                                <IconText white iconSrc={iconBaseUrl + "1- Age 25 verification.svg"}>{text.sic1}</IconText>
                                <IconText white iconSrc={iconBaseUrl + "2- Security and Trust.svg"}>{text.sic2}</IconText>
                                <IconText white iconSrc={iconBaseUrl + "3- documents for verification.svg"}>{text.sic3}</IconText>
                            </Stack>
                            <AnimatedButton to="apply-s">{text.applynow}</AnimatedButton>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
            <Stack variant="waves1" className="waves-display"><img src="/svg/waves1.svg"/></Stack>
        </Stack>
    )
}

export default IdentityCheck