import React from "react";
import { Grid, Typography } from "@mui/material";
import SlidingTypography from "./SlidingTypography";

const TableRow = ({columns, header=false}) =>{
    const getContent = ({customContent, content, color, text}) =>{
        if (customContent) return content
        if (header) return <Typography variant="center" className="style-font-4"
            style={{color: color ? color : '#000'}}>{text}</Typography>
        return <SlidingTypography color={color ? color : '#000'} textAlign="center" fast>{text}</SlidingTypography>
    }
    return(
        <Grid container style={{minHeight: '50px'}}>
            {
                columns.map((cell, i) =>
                    <Grid key={'item' + i} style={{overflow: 'hidden'}} item xs={cell.xs} variant={header ? "table-header" : "table-body"}>
                        { getContent(cell) }
                    </Grid>
                )
            }
        </Grid>
    )
}

export default TableRow