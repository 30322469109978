import axios from 'axios';
import qs from 'qs'
import { apiURL } from './helper/conf';

const axiosInstance = axios.create({
  baseURL: apiURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*'
  },
  transformRequest: [data => qs.stringify(data)]
});

export default axiosInstance;