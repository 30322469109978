import React, { useState, useContext } from "react";
import { Stack } from "@mui/material";
import AuthLanding from "./AuthLanding";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import MailValidate from "./MailValidate";
import PasswordForgot from "./PasswordForgot";
import axiosInstance from "../../../axios";
import useLanguage from "../../hooks/useLanguage";
import { format } from 'date-fns';
import { roles } from "../../../helper/roles";
import { useNavigate } from "react-router-dom";
import useData from "../../hooks/useData";
import RoutesList from "../../../helper/routes";
import { AuthStateContext } from "../../state/AuthStateContext";
import countriesList from "../../../helper/countries";
import { AuthRedirectStateContext } from "../../state/AuthRedirectStateContext";

const MemberAuth = () =>{
    const {text, currentLanguage} = useLanguage()
    const [slide, setSlide] = useState(1)
    const [signUpData, setSignUpData] = useState(null)
    const [mailValidation, setMailValidation] = useState('')
    const [passwordForgot, setPasswordForgot] = useState(false)
    const [signUpLoading, setSignUpLoading] = useState(false)
    const [signUpError, setSignUpError] = useState('')
    const [password, setPassword] = useState('')
    const { errorsList } = useData()
    const navigate = useNavigate()
    const { setAuthenticated } = useContext(AuthStateContext)
    const { redirectRoute } = useContext(AuthRedirectStateContext)
    const [phonePrefix, setPhonePrefix] = useState(`+${countriesList[0].phone}`)

    const handleChange = (s, data) => {
        setSlide(s)
        setSignUpData(data)
        if (data) setPassword(data.password)
    }
 
    const verify = async data =>{
        const { firstname, lastname, username, skypeid, birthdate, gender, phone, mail, appartroad, citydep, pobox, country } = data
        try {
            const { status } = await axiosInstance.post(`/send_mail_code/${currentLanguage}`, {
                first_name: firstname,
                last_name: lastname,
                user_name: username,
                skype: skypeid,
                birth_date: format(birthdate, 'yyyy-MM-dd'),
                gender, country, pobox, password,
                phone: phonePrefix + phone,
                address: appartroad,
                email: mail,
                city: citydep,
                role: 'candidate'
            })
            if (status == 200){
                setMailValidation(data.mail)
                setSignUpData(data)
            }
        } catch (error) {
            if (error.response.status == 400){
                const errorName = Object.values(error.response.data.errors)[0]
                switch (errorName){
                    case errorsList.usernamePattern.message: setSignUpError(errorsList.usernamePattern.errorLabel); break
                    case errorsList.poboxPattern.message: setSignUpError(errorsList.poboxPattern.errorLabel); break
                    case errorsList.phonePattern.message: setSignUpError(errorsList.phonePattern.errorLabel); break
                    case errorsList.skypeIdPattern.message: setSignUpError(errorsList.skypeIdPattern.errorLabel); break
                    case errorsList.usernameLength.message: setSignUpError(errorsList.usernameLength.errorLabel); break
                    case errorsList.mailUnique.message: setSignUpError(errorsList.mailUnique.errorLabel); break
                    case errorsList.usernameUnique.message: setSignUpError(errorsList.usernameUnique.errorLabel); break
                    default: setSignUpError(text.smthngwntwrng)
                }
            }
            else if (error.response.status == 403 ) {
                    if (error.response.data.error=='Utilisateur banni') {setSignUpError(text.bn)}
                }
                else setSignUpError(text.smthngwntwrng)
        }
    }

    const signUp = async () =>{
        const { firstname, lastname, username, skypeid, birthdate, gender, phone, mail, appartroad, citydep, pobox, country } = signUpData
        setSignUpLoading(true)
        setSignUpError('')
        try {
            const resp = await axiosInstance.post(`/register/${currentLanguage}`, {
                first_name: firstname,
                last_name: lastname,
                user_name: username,
                skype: skypeid,
                birth_date: format(birthdate, 'yyyy-MM-dd'),
                gender, country, pobox, password,
                phone: phonePrefix + phone,
                address: appartroad,
                email: mail,
                city: citydep,
                role: 'candidate'
            }, {})
            if (resp.status == 201){
                await setAuthenticated(true, roles.candidat, resp.data.token)
                navigate(redirectRoute)
            }
        } catch (error) { console.log(error.response) }
        setSignUpLoading(false)
    }

    
    return(
        <Stack variant="primarybg" alignItems="center">
            <Stack className="width-resize" style={{ overflowX: 'hidden' }}>
                <Stack width="300%" height="100%" style={{display: 'flex', flexDirection: 'row',
                    transform:"translateX(calc("+ slide +"*-100%/3))", transition: '.3s'}}>
                    <SignIn onChangeSlide={handleChange} visible={slide == 0} onPasswordForgot={ ()=> setPasswordForgot(true) }/>
                    <AuthLanding onChangeSlide={handleChange} visible={slide == 1}/>
                    <SignUp onMailVerify={verify} onChangeSlide={handleChange} visible={slide == 2} mainData={signUpData} loading={signUpLoading} setLoading={setSignUpLoading} error={signUpError} setError={setSignUpError} phonePrefix={phonePrefix} setPhonePrefix={setPhonePrefix}/>
                </Stack>
            </Stack>
            <PasswordForgot visible={passwordForgot} onClose={() => setPasswordForgot(false) } />
            <MailValidate onValidated={signUp} mail={mailValidation} onClose={()=> setMailValidation("")}/>
        </Stack>
    )
}

export default MemberAuth