import React from "react";
import {Typography, Stack } from "@mui/material";
const TitleWithBg = ({iconSrc, children, title=false, color=""}) =>{
    return(
        <Stack style={{ position: 'relative', alignItems: 'center' }}>
            <img src={iconSrc} alt="Background" style={{ display: 'block', width: title ? '80%' : '90%'}}/>
            <Typography  variant={title ? "titlewithbg" : "black"}  
            style={{ position: 'absolute', zIndex: 2, top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
            textAlign: 'center', color: color,  WebkitTextStroke: ( title ) ? '2px' + color :'' } } 
            >
                {children}
            </Typography>
        </Stack>

    )
}
export default TitleWithBg