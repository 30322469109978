import React, { useState } from "react";
import { Stack, Typography, Box } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import IconTextCard from "../tools/IconTextCard";
import useData from "../hooks/useData";
import { Waypoint } from "react-waypoint";
import CustomDivider from "../tools/CustomDivider"

const Statistics = () =>{
    const { text } = useLanguage()
    const { dataStat } = useData()

    const [ teamMembers, setTeamMembers ] = useState(0)
    const [ projects, setProjects ] = useState(0)
    const [ netwokPartners, setNetwokPartners ] = useState(0)
    const [ years, setYears ] = useState(0)

    const refresh = (incrementFunction, lim) =>{
        const totaltime = 3000
        const [intervalTime, incValue] = (totaltime/lim<10) ? [10, parseInt((10 *lim)/totaltime)] : [(totaltime/lim), 1]
        const interval = setInterval(() =>{
            incrementFunction( current =>{
                if (current+incValue <lim) return current + incValue
                else {
                    clearInterval(interval)
                    return lim
                }
            })
        }, intervalTime)
    }

    const refreshDatas = () =>{
        refresh(setTeamMembers, dataStat[0].innerHTML)
        refresh(setProjects, dataStat[1].innerHTML)
        refresh(setNetwokPartners, dataStat[2].innerHTML)
        refresh(setYears, dataStat[3].innerHTML)
    }


    return(
        <Stack>
            <Stack variant="wavesstat"><img src="/svg/stat1.svg"/></Stack>
            <Stack variant='statistics' spacing={3} >
                <Stack className="title-typo"  ><Typography variant='primarytitle' className="title-typo-change">{text.stat}</Typography></Stack>
                <CustomDivider color="primary" alignCenter width='16%'/>
                <Stack textAlign='center' marginBottom='50px'>
                    <Typography >{text.at} <Typography variant="bold" >{text.cn}</Typography>{text.ourstat}</Typography>
                </Stack>
                <Box maxWidth="95%" className="wrap-content" style={{display: 'flex', alignItems: 'center', gap:"30px", marginTop:'50px'}}>
                    <IconTextCard variant="statcard" label={teamMembers + " +"} iconSrc={'/icones/4- Statistics/1- Team members.png'}>{ text.team }</IconTextCard>
                    <IconTextCard variant="statcard" label={projects + " +"} iconSrc={'/icones/4- Statistics/2- Projects.png'}>{ text.projects }</IconTextCard>
                    <IconTextCard variant="statcard" label={netwokPartners} iconSrc={'/icones/4- Statistics/3- Network partners.png'}>{ text.netpart }</IconTextCard>
                    <IconTextCard variant="statcard" label={years} iconSrc={'/icones/4- Statistics/4- Years in business.png'}>{ text.years }</IconTextCard>
                </Box>
                <Waypoint onEnter={refreshDatas}/>
            </Stack>
            <Stack variant="wavesstat"><img src="/svg/stat2.svg"/></Stack>
        </Stack>
    )
}
export default Statistics