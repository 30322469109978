import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import AnimatedButton from "../../tools/AnimatedButton";
import useLanguage from "../../hooks/useLanguage";
import IconText from '../../tools/IconText'
import CustomDivider from "../../tools/CustomDivider"

const IdentityCheck = () =>{
    const { text } = useLanguage()
    const iconBaseUrl = "/images/chat-operator/5- What does Creativ'Network need to check your identity/"
    return(
        <Stack>
            <Stack variant="waves2"><img src="/svg/waves2.svg"/></Stack>
            <Stack variant="curvy" >
                <Box className="wrap-content">
                    <Stack width="45%" display='flex' alignItems='center' justifyContent='flex-end'><img width='80%' src="/images/chat-operator/5- ID-Checking-CN.webp" alt=""/></Stack>
                    <Stack width="55%">
                        <Stack spacing={3}>
                            <Typography className="title-typo text-resize-4" variant="whitetitle" textAlign='left'>
                                {text.coicint1} <Typography variant="secondarytitle" className="title-typo-change">{text.cn}</Typography> {text.coicint2}
                            </Typography>
                            <CustomDivider color="secondary" width='14%'/>
                            <Stack gap="20px">
                                <IconText white range={1} iconSrc={iconBaseUrl + "1. Age verification.svg"}>{text.coic1}</IconText>
                                <IconText white range={2} iconSrc={iconBaseUrl + "2. security and trust.svg"}>{text.coic2}</IconText>
                                <IconText white range={3} iconSrc={iconBaseUrl + "3. Documents needed.svg"}>{text.coic3}</IconText>
                            </Stack>
                            <AnimatedButton to="apply-co">{text.applynow}</AnimatedButton>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
            <Stack variant="waves1"><img src="/svg/waves1.svg"/></Stack>
        </Stack>
    )
}

export default IdentityCheck