import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import CustomDivider from "../../tools/CustomDivider";
import AnimatedButton from "../../tools/AnimatedButton";
import useLanguage from "../../hooks/useLanguage";
import IconText from "../../tools/IconText";

const About = () =>{
    const {text} = useLanguage()
    const iconBaseUrl = "/images/TeamLeader/icons/2_About/"
    return(
        <Stack>
            <Box className="wrap-content" margin='50px 0' justifyContent='space-evenly' gap='15px'>
                <Stack justifyContent='center' alignItems='center' width='100%'>
                    <img width='90%' src="/images/TeamLeader/2- Team leader job.webp" alt=""/>
                </Stack>    
                <Stack spacing={3}>
                    <Typography className="title-typo" variant="title" textAlign='left'>
                        {text.tlabint1} <Typography variant="primarytitle" className="title-typo-change-light">{text.teamleader}</Typography> {text.tlabint2}
                    </Typography>
                    <CustomDivider color="secondary" width='14%'/>
                    <Stack spacing={1} paddingRight='35px'>
                        <IconText iconSrc={iconBaseUrl + "1_Leadership and Coordination.svg"}>{text.tlab1}</IconText>
                        <IconText iconSrc={iconBaseUrl + "2_ Performance Management.svg"}>{text.tlab2}</IconText>
                        <IconText iconSrc={iconBaseUrl + "3_Team Development.svg"}>{text.tlab3}</IconText>
                        <IconText iconSrc={iconBaseUrl + "4_Innovation in Chat Services.svg"}>{text.tlab4}</IconText>
                        <IconText iconSrc={iconBaseUrl + "5_Quality Assurance.svg"}>{text.tlab5}</IconText>
                        <IconText iconSrc={iconBaseUrl + "6_Communication Hub.svg"}>{text.tlab6}</IconText>
                        <IconText iconSrc={iconBaseUrl + "7_Strategic Planning.svg"}>{text.tlab7}</IconText>
                    </Stack>
                    <AnimatedButton to="apply-tl">{text.applynow}</AnimatedButton>
                </Stack>
            </Box>
        </Stack>
    )
}

export default About