import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import CustomDivider from "../../tools/CustomDivider";
import AnimatedButton from "../../tools/AnimatedButton";
import useLanguage from "../../hooks/useLanguage";
import IconText from "../../tools/IconText";

const About = () =>{
    const {text} = useLanguage()
    const iconBaseUrl = "/images/Supervisor/icons/2_QS job about/"
    return(
        <Stack>
            <Box className="wrap-content" margin='50px 0' justifyContent='space-evenly' gap='15px'>
                <Stack justifyContent='center' alignItems='center' width='100%'>
                    <img width='90%' src="/images/Supervisor/2-quality-supervisor-job DEF.webp" alt=""/>
                </Stack>    
                <Stack spacing={3}>
                    <Typography className="title-typo" variant="title" textAlign='left'>
                        {text.sabint1} <Typography variant="primarytitle" className="title-typo-change-light">{text.qualitysupervisor}</Typography> {text.sabint2}
                    </Typography>
                    <CustomDivider color="secondary" width='14%'/>
                    <Stack spacing={1} paddingRight='35px'>
                        <IconText iconSrc={iconBaseUrl + "1_Performance Monitoring.svg"}>{text.sab1}</IconText>
                        <IconText iconSrc={iconBaseUrl + "2_Feedback and Training.svg"}>{text.sab2}</IconText>
                        <IconText iconSrc={iconBaseUrl + "3_Issue Resolution.svg"}>{text.sab3}</IconText>
                        <IconText iconSrc={iconBaseUrl + "4_Quality Assurance Protocols.svg"}>{text.sab4}</IconText>
                        <IconText iconSrc={iconBaseUrl + "5_Innovation and Optimization.svg"}>{text.sab5}</IconText>
                        <IconText iconSrc={iconBaseUrl + "6_Team Collaboration.svg"}>{text.sab6}</IconText>
                    </Stack>
                    <AnimatedButton to="apply-s">{text.applynow}</AnimatedButton>
                </Stack>
            </Box>
        </Stack>
    )
}

export default About