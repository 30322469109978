const countriesList = [
    { value: 'AF', innerHTML: 'Afghanistan - افغانستان', phone: '93' },
    { value: 'AL', innerHTML: 'Albania - Shqipëri', phone: '355' },
    { value: 'DZ', innerHTML: 'Algeria - الجزائر', phone: '213' },
    { value: 'AD', innerHTML: 'Andorra - Andorra', phone: '376' },
    { value: 'AO', innerHTML: 'Angola - Angola', phone: '244' },
    { value: 'AG', innerHTML: 'Antigua and Barbuda - Antigua and Barbuda', phone: '1' },
    { value: 'AR', innerHTML: 'Argentina - Argentina', phone: '54' },
    { value: 'AM', innerHTML: 'Armenia - Հայաստան', phone: '374' },
    { value: 'AU', innerHTML: 'Australia - Australia', phone: '61' },
    { value: 'AT', innerHTML: 'Austria - Österreich', phone: '43' },
    { value: 'AZ', innerHTML: 'Azerbaijan - Azərbaycan', phone: '994' },
    { value: 'BS', innerHTML: 'Bahamas - Bahamas', phone: '1' },
    { value: 'BH', innerHTML: 'Bahrain - البحرين', phone: '973' },
    { value: 'BD', innerHTML: 'Bangladesh - বাংলাদেশ', phone: '880' },
    { value: 'BB', innerHTML: 'Barbados - Barbados', phone: '1' },
    { value: 'BY', innerHTML: 'Belarus - Беларусь', phone: '375' },
    { value: 'BE', innerHTML: 'Belgium - België', phone: '32' },
    { value: 'BZ', innerHTML: 'Belize - Belize', phone: '501' },
    { value: 'BJ', innerHTML: 'Benin - Bénin', phone: '229' },
    { value: 'BT', innerHTML: 'Bhutan - འབྲུག་ཡུལ', phone: '975' },
    { value: 'BO', innerHTML: 'Bolivia - Bolivia', phone: '591' },
    { value: 'BA', innerHTML: 'Bosnia and Herzegovina - Bosna i Hercegovina', phone: '387' },
    { value: 'BW', innerHTML: 'Botswana - Botswana', phone: '267' },
    { value: 'BR', innerHTML: 'Brazil - Brasil', phone: '55' },
    { value: 'BN', innerHTML: 'Brunei - Brunei', phone: '673' },
    { value: 'BG', innerHTML: 'Bulgaria - България', phone: '359' },
    { value: 'BF', innerHTML: 'Burkina Faso - Burkina Faso', phone: '226' },
    { value: 'BI', innerHTML: 'Burundi - Burundi', phone: '257' },
    { value: 'CV', innerHTML: 'Cabo Verde - Cabo Verde', phone: '238' },
    { value: 'KH', innerHTML: 'Cambodia - កម្ពុជា', phone: '855' },
    { value: 'CM', innerHTML: 'Cameroon - Cameroun', phone: '237' },
    { value: 'CA', innerHTML: 'Canada - Canada', phone: '1' },
    { value: 'CF', innerHTML: 'Central African Republic - République centrafricaine', phone: '236' },
    { value: 'TD', innerHTML: 'Chad - تشاد', phone: '235' },
    { value: 'CL', innerHTML: 'Chile - Chile', phone: '56' },
    { value: 'CN', innerHTML: 'China - 中国', phone: '86' },
    { value: 'CO', innerHTML: 'Colombia - Colombia', phone: '57' },
    { value: 'KM', innerHTML: 'Comoros - Comores', phone: '269' },
    { value: 'CG', innerHTML: 'Congo (Congo-Brazzaville) - Congo', phone: '242' },
    { value: 'CR', innerHTML: 'Costa Rica - Costa Rica', phone: '506' },
    { value: 'HR', innerHTML: 'Croatia - Hrvatska', phone: '385' },
    { value: 'CU', innerHTML: 'Cuba - Cuba', phone: '53' },
    { value: 'CY', innerHTML: 'Cyprus - Κύπρος', phone: '357' },
    { value: 'CZ', innerHTML: 'Czechia (Czech Republic) - Česko', phone: '420' },
    { value: 'DK', innerHTML: 'Denmark - Danmark', phone: '45' },
    { value: 'DJ', innerHTML: 'Djibouti - جيبوتي', phone: '253' },
    { value: 'DM', innerHTML: 'Dominica - Dominica', phone: '1' },
    { value: 'DO', innerHTML: 'Dominican Republic - República Dominicana', phone: '1' },
    { value: 'TL', innerHTML: 'East Timor - Timor-Leste', phone: '670' },
    { value: 'EC', innerHTML: 'Ecuador - Ecuador', phone: '593' },
    { value: 'EG', innerHTML: 'Egypt - مصر', phone: '20' },
    { value: 'SV', innerHTML: 'El Salvador - El Salvador', phone: '503' },
    { value: 'GQ', innerHTML: 'Equatorial Guinea - Guinea Ecuatorial', phone: '240' },
    { value: 'ER', innerHTML: 'Eritrea - ኤርትራ', phone: '291' },
    { value: 'EE', innerHTML: 'Estonia - Eesti', phone: '372' },
    { value: 'SZ', innerHTML: 'Eswatini (fmr. Swaziland) - Eswatini', phone: '268' },
    { value: 'ET', innerHTML: 'Ethiopia - ኢትዮጵያ', phone: '251' },
    { value: 'FJ', innerHTML: 'Fiji - Fiji', phone: '679' },
    { value: 'FI', innerHTML: 'Finland - Suomi', phone: '358' },
    { value: 'FR', innerHTML: 'France - France', phone: '33' },
    { value: 'GA', innerHTML: 'Gabon - Gabon', phone: '241' },
    { value: 'GM', innerHTML: 'Gambia - Gambia', phone: '220' },
    { value: 'GE', innerHTML: 'Georgia - საქართველო', phone: '995' },
    { value: 'DE', innerHTML: 'Germany - Deutschland', phone: '49' },
    { value: 'GH', innerHTML: 'Ghana - Ghana', phone: '233' },
    { value: 'GR', innerHTML: 'Greece - Ελλάδα', phone: '30' },
    { value: 'GD', innerHTML: 'Grenada - Grenada', phone: '1' },
    { value: 'GT', innerHTML: 'Guatemala - Guatemala', phone: '502' },
    { value: 'GN', innerHTML: 'Guinea - Guinée', phone: '224' },
    { value: 'GW', innerHTML: 'Guinea-Bissau - Guiné-Bissau', phone: '245' },
    { value: 'GY', innerHTML: 'Guyana - Guyana', phone: '592' },
    { value: 'HT', innerHTML: 'Haiti - Haïti', phone: '509' },
    { value: 'HN', innerHTML: 'Honduras - Honduras', phone: '504' },
    { value: 'HU', innerHTML: 'Hungary - Magyarország', phone: '36' },
    { value: 'IS', innerHTML: 'Iceland - Ísland', phone: '354' },
    { value: 'IN', innerHTML: 'India - भारत', phone: '91' },
    { value: 'ID', innerHTML: 'Indonesia - Indonesia', phone: '62' },
    { value: 'IR', innerHTML: 'Iran - ایران', phone: '98' },
    { value: 'IQ', innerHTML: 'Iraq - العراق', phone: '964' },
    { value: 'IE', innerHTML: 'Ireland - Éire', phone: '353' },
    { value: 'IL', innerHTML: 'Israel - ישראל', phone: '972' },
    { value: 'IT', innerHTML: 'Italy - Italia', phone: '39' },
    { value: 'CI', innerHTML: 'Ivory Coast - Côte d\'Ivoire', phone: '225' },
    { value: 'JM', innerHTML: 'Jamaica - Jamaica', phone: '1' },
    { value: 'JP', innerHTML: 'Japan - 日本', phone: '81' },
    { value: 'JO', innerHTML: 'Jordan - الأردن', phone: '962' },
    { value: 'KZ', innerHTML: 'Kazakhstan - Қазақстан', phone: '7' },
    { value: 'KE', innerHTML: 'Kenya - Kenya', phone: '254' },
    { value: 'KI', innerHTML: 'Kiribati - Kiribati', phone: '686' },
    { value: 'KP', innerHTML: 'Korea, North - 북한', phone: '850' },
    { value: 'KR', innerHTML: 'Korea, South - 대한민국', phone: '82' },
    { value: 'XK', innerHTML: 'Kosovo - Kosovë', phone: '383' },
    { value: 'KW', innerHTML: 'Kuwait - الكويت', phone: '965' },
    { value: 'KG', innerHTML: 'Kyrgyzstan - Кыргызстан', phone: '996' },
    { value: 'LA', innerHTML: 'Laos - ລາວ', phone: '856' },
    { value: 'LV', innerHTML: 'Latvia - Latvija', phone: '371' },
    { value: 'LB', innerHTML: 'Lebanon - لبنان', phone: '961' },
    { value: 'LS', innerHTML: 'Lesotho - Lesotho', phone: '266' },
    { value: 'LR', innerHTML: 'Liberia - Liberia', phone: '231' },
    { value: 'LY', innerHTML: 'Libya - ليبيا', phone: '218' },
    { value: 'LI', innerHTML: 'Liechtenstein - Liechtenstein', phone: '423' },
    { value: 'LT', innerHTML: 'Lithuania - Lietuva', phone: '370' },
    { value: 'LU', innerHTML: 'Luxembourg - Luxembourg', phone: '352' },
    { value: 'MG', innerHTML: 'Madagascar - Madagascar', phone: '261' },
    { value: 'MW', innerHTML: 'Malawi - Malawi', phone: '265' },
    { value: 'MY', innerHTML: 'Malaysia - Malaysia', phone: '60' },
    { value: 'MV', innerHTML: 'Maldives - ދިވެހިރާއްޖެ', phone: '960' },
    { value: 'ML', innerHTML: 'Mali - Mali', phone: '223' },
    { value: 'MT', innerHTML: 'Malta - Malta', phone: '356' },
    { value: 'MH', innerHTML: 'Marshall Islands - Marshall Islands', phone: '692' },
    { value: 'MR', innerHTML: 'Mauritania - موريتانيا', phone: '222' },
    { value: 'MU', innerHTML: 'Mauritius - Maurice', phone: '230' },
    { value: 'MX', innerHTML: 'Mexico - México', phone: '52' },
    { value: 'FM', innerHTML: 'Micronesia - Micronesia', phone: '691' },
    { value: 'MD', innerHTML: 'Moldova - Moldova', phone: '373' },
    { value: 'MC', innerHTML: 'Monaco - Monaco', phone: '377' },
    { value: 'MN', innerHTML: 'Mongolia - Монгол улс', phone: '976' },
    { value: 'ME', innerHTML: 'Montenegro - Crna Gora', phone: '382' },
    { value: 'MA', innerHTML: 'Morocco - المغرب', phone: '212' },
    { value: 'MZ', innerHTML: 'Mozambique - Moçambique', phone: '258' },
    { value: 'MM', innerHTML: 'Myanmar (formerly Burma) - မြန်မာ', phone: '95' },
    { value: 'NA', innerHTML: 'Namibia - Namibia', phone: '264' },
    { value: 'NR', innerHTML: 'Nauru - Nauru', phone: '674' },
    { value: 'NP', innerHTML: 'Nepal - नेपाल', phone: '977' },
    { value: 'NL', innerHTML: 'Netherlands - Nederland', phone: '31' },
    { value: 'NZ', innerHTML: 'New Zealand - Aotearoa', phone: '64' },
    { value: 'NI', innerHTML: 'Nicaragua - Nicaragua', phone: '505' },
    { value: 'NE', innerHTML: 'Niger - Niger', phone: '227' },
    { value: 'NG', innerHTML: 'Nigeria - Nigeria', phone: '234' },
    { value: 'MK', innerHTML: 'North Macedonia - Северна Македонија', phone: '389' },
    { value: 'NO', innerHTML: 'Norway - Norge', phone: '47' },
    { value: 'OM', innerHTML: 'Oman - عُمان', phone: '968' },
    { value: 'PK', innerHTML: 'Pakistan - پاکستان', phone: '92' },
    { value: 'PW', innerHTML: 'Palau - Palau', phone: '680' },
    { value: 'PS', innerHTML: 'Palestine - فلسطين', phone: '970' },
    { value: 'PA', innerHTML: 'Panama - Panamá', phone: '507' },
    { value: 'PG', innerHTML: 'Papua New Guinea - Papua Niugini', phone: '675' },
    { value: 'PY', innerHTML: 'Paraguay - Paraguay', phone: '595' },
    { value: 'PE', innerHTML: 'Peru - Perú', phone: '51' },
    { value: 'PH', innerHTML: 'Philippines - Pilipinas', phone: '63' },
    { value: 'PL', innerHTML: 'Poland - Polska', phone: '48' },
    { value: 'PT', innerHTML: 'Portugal - Portugal', phone: '351' },
    { value: 'QA', innerHTML: 'Qatar - قطر', phone: '974' },
    { value: 'RO', innerHTML: 'Romania - România', phone: '40' },
    { value: 'RU', innerHTML: 'Russia - Россия', phone: '7' },
    { value: 'RW', innerHTML: 'Rwanda - Rwanda', phone: '250' },
    { value: 'KN', innerHTML: 'Saint Kitts and Nevis - Saint Kitts and Nevis', phone: '1869' },
    { value: 'LC', innerHTML: 'Saint Lucia - Saint Lucia', phone: '1758' },
    { value: 'VC', innerHTML: 'Saint Vincent and the Grenadines - Saint Vincent and the Grenadines', phone: '1784' },
    { value: 'WS', innerHTML: 'Samoa - Samoa', phone: '685' },
    { value: 'SM', innerHTML: 'San Marino - San Marino', phone: '378' },
    { value: 'ST', innerHTML: 'Sao Tome and Principe - São Tomé e Príncipe', phone: '239' },
    { value: 'SA', innerHTML: 'Saudi Arabia - السعودية', phone: '966' },
    { value: 'SN', innerHTML: 'Senegal - Sénégal', phone: '221' },
    { value: 'RS', innerHTML: 'Serbia - Србија', phone: '381' },
    { value: 'SC', innerHTML: 'Seychelles - Sesel', phone: '248' },
    { value: 'SL', innerHTML: 'Sierra Leone - Sierra Leone', phone: '232' },
    { value: 'SG', innerHTML: 'Singapore - Singapore', phone: '65' },
    { value: 'SK', innerHTML: 'Slovakia - Slovensko', phone: '421' },
    { value: 'SI', innerHTML: 'Slovenia - Slovenija', phone: '386' },
    { value: 'SB', innerHTML: 'Solomon Islands - Solomon Islands', phone: '677' },
    { value: 'SO', innerHTML: 'Somalia - Soomaaliya', phone: '252' },
    { value: 'ZA', innerHTML: 'South Africa - South Africa', phone: '27' },
    { value: 'SS', innerHTML: 'South Sudan - South Sudan', phone: '211' },
    { value: 'ES', innerHTML: 'Spain - España', phone: '34' },
    { value: 'LK', innerHTML: 'Sri Lanka - ශ්‍රී ලංකාව', phone: '94' },
    { value: 'SD', innerHTML: 'Sudan - السودان', phone: '249' },
    { value: 'SR', innerHTML: 'Suriname - Suriname', phone: '597' },
    { value: 'SE', innerHTML: 'Sweden - Sverige', phone: '46' },
    { value: 'CH', innerHTML: 'Switzerland - Schweiz', phone: '41' },
    { value: 'SY', innerHTML: 'Syria - سوريا', phone: '963' },
    { value: 'TW', innerHTML: 'Taiwan - 台灣', phone: '886' },
    { value: 'TJ', innerHTML: 'Tajikistan - Тоҷикистон', phone: '992' },
    { value: 'TZ', innerHTML: 'Tanzania - Tanzania', phone: '255' },
    { value: 'TH', innerHTML: 'Thailand - ประเทศไทย', phone: '66' },
    { value: 'TG', innerHTML: 'Togo - Togo', phone: '228' },
    { value: 'TO', innerHTML: 'Tonga - Tonga', phone: '676' },
    { value: 'TT', innerHTML: 'Trinidad and Tobago - Trinidad and Tobago', phone: '1868' },
    { value: 'TN', innerHTML: 'Tunisia - تونس', phone: '216' },
    { value: 'TR', innerHTML: 'Turkey - Türkiye', phone: '90' },
    { value: 'TM', innerHTML: 'Turkmenistan - Türkmenistan', phone: '993' },
    { value: 'TV', innerHTML: 'Tuvalu - Tuvalu', phone: '688' },
    { value: 'UG', innerHTML: 'Uganda - Uganda', phone: '256' },
    { value: 'UA', innerHTML: 'Ukraine - Україна', phone: '380' },
    { value: 'AE', innerHTML: 'United Arab Emirates - الإمارات العربية المتحدة', phone: '971' },
    { value: 'GB', innerHTML: 'United Kingdom - United Kingdom', phone: '44' },
    { value: 'US', innerHTML: 'United States of America - United States of America', phone: '1' },
    { value: 'UY', innerHTML: 'Uruguay - Uruguay', phone: '598' },
    { value: 'UZ', innerHTML: 'Uzbekistan - Oʻzbekiston', phone: '998' },
    { value: 'VU', innerHTML: 'Vanuatu - Vanuatu', phone: '678' },
    { value: 'VA', innerHTML: 'Vatican City (Holy See) - Città del Vaticano', phone: '379' },
    { value: 'VE', innerHTML: 'Venezuela - Venezuela', phone: '58' },
    { value: 'VN', innerHTML: 'Vietnam - Việt Nam', phone: '84' },
    { value: 'YE', innerHTML: 'Yemen - اليمن', phone: '967' },
    { value: 'ZM', innerHTML: 'Zambia - Zambia', phone: '260' },
    { value: 'ZW', innerHTML: 'Zimbabwe - Zimbabwe', phone: '263' },
]
export default countriesList