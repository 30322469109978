import React, {useEffect} from "react";
import { Stack, Typography, Box } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import TitleWithBg from '../../tools/TitleWithBg'
import ImgInputLight from "../../tools/ImgInputLight";
import "react-datepicker/dist/react-datepicker.css";
import CustomSelect from "../../tools/select";
import useData from "../../hooks/useData";
import countriesList from "../../../helper/countries";
import FileInput from "../../tools/FileInput";
import Camera from "../../tools/Camera";
import { baseURL } from "../../../helper/conf";

const InfoManage = ({control, errors, defaultValues, setValue, phonePrefix, setPhonePrefix, file, setFile}) =>{
    const { text } = useLanguage()
    const { genders } = useData()

    useEffect(()=>{
        if (defaultValues.country){
            setValue('gender', genders.find(g => g.value == defaultValues.gender).value)
            setValue('country', countriesList.find(c => c.value == defaultValues.country).value)
            setValue('birthdate', new Date(defaultValues.birthdate))
        }
    }, [defaultValues])

    return(
        <Stack variant="rightcontent">
            <Stack display="flex" justifyContent="center" alignItems="center"><TitleWithBg iconSrc='/images/Member/dashboard/1.png' children={text.information} color="#f61c02" title/></Stack>
            <Stack style={{alignItems: "center", justifyContent: "center"}}><Typography variant="secondaryitalic" >{text.information1}</Typography></Stack>
            <Stack spacing='10px'>
                <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                    <Stack width="50%"><ImgInputLight type="text" control={control} required={false} name="lastname" value={defaultValues.lastname} setValue={setValue} error={errors.lastname} placeholder={text.yourlastname} imgSrc="/images/Member/user.png"/></Stack>
                    <Stack width="50%"><ImgInputLight type="text" control={control} required={false} name="firstname" value={defaultValues.firstname} setValue={setValue} error={errors.firstname} placeholder={text.yourfirstname} imgSrc="/images/Member/user.png" /></Stack>
                </Box>
                <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                    <Stack width="50%"><ImgInputLight readonly type="text" control={control} required={false} name="username" value={defaultValues.username} setValue={setValue} error={errors.username} placeholder={text.yourusername} imgSrc="/images/Member/user.png" /></Stack>
                    <Stack width="50%"><ImgInputLight readonly type="email" control={control} required={false} name="mail" value={defaultValues.mail} setValue={setValue} error={errors.mail} placeholder={text.yourmailaddress} imgSrc="/images/Member/mail.png"/></Stack>
                </Box>

                <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                    <Stack width="50%"><ImgInputLight type="text" readonly control={control} required={false} name="countr" value={defaultValues.country ? countriesList.find(c => c.value == defaultValues.country).innerHTML : ''} setValue={setValue} error={errors.countr} placeholder={text.yourcountry} imgSrc="/images/Member/country.png" /></Stack>
                    <Stack width="50%"><ImgInputLight type="text" control={control} required={false} name="phone" prefix={phonePrefix} value={ defaultValues.phone ? defaultValues.phone.slice(phonePrefix.length) : ''} setValue={setValue} error={errors.phone} placeholder={text.yourphonenumber} imgSrc="/images/Member/phone.png"/></Stack>
                    
                </Box>
                <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                    <Stack width="50%"><ImgInputLight readonly type="text" control={control} required={false} name="gender" value={defaultValues.gender} setValue={setValue} error={errors.gender} placeholder={text.gender} imgSrc="/images/Member/gender.png"/></Stack>
                    <Stack width="50%"><ImgInputLight type="text" control={control} required={false} name="appart" value={defaultValues.address} setValue={setValue} error={errors.appart} placeholder={text.appartroad} imgSrc="/images/Member/home.png"/></Stack>
                </Box>
                <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                    <Stack width="50%"><ImgInputLight type="text" control={control} required={false} name="city" value={defaultValues.city} setValue={setValue} error={errors.city} placeholder={text.yourcitydep} imgSrc="/images/Member/home.png"/></Stack>
                    <Stack width="50%"><ImgInputLight type="text" control={control} required={false} name="pobox" value={defaultValues.pobox} setValue={setValue} error={errors.pobox} placeholder={text.yourpobox} imgSrc="/images/Member/postalcode.png" /></Stack>
                </Box>
            </Stack>
            <Stack style={{alignItems: "center", justifyContent: "center"}}><Typography variant="secondaryitalic">{text.info11}</Typography></Stack>
            <Stack style={{padding: '20px'}} gap={2}>
                <FileInput profil label={text.info12} visiblePartVariant='Browser' required={false} onExportFile={files =>{setFile(files)}}
                    accept="image/*" control={control} name="profilephoto" error={errors.profilephoto}/>        
                <Camera label={text.info14} camera/>
                
            </Stack>
             <Stack display="flex" justifyContent="center" alignItems="center">
                { file.name ? 
                    <Box component='img' sx={{height: '200px', width: '200px'}} src={URL.createObjectURL(file)}/>
                    : 
                    (
                        defaultValues.profilephoto ?
                        <Box component='img' 
                            sx={{height: '200px', width: '200px'}} 
                            src={`${baseURL}/images/users/${defaultValues.profilephoto}`}                
                        /> 
                        :
                        <img src="/images/Member/dashboard/information/profil.png" alt="" />
                    )
                }
            </Stack>
            
        </Stack>
    )
}

export default InfoManage