import  React from "react";
import { Stack } from "@mui/material";
import SlidingTypography from './SlidingTypography'

const TextWithBg = ({ imgSrc, children}) => {

    return(
        <Stack style={{flexDirection: 'row', alignItems:"center"}}>    
            <Stack className="img-bg" borderRadius="50%" style={{ backgroundColor: '#eaea53', transform: "translateX(30%)" , border: '5px solid #4B4998'}}><img src={imgSrc} alt="" /></Stack>
            <Stack className="text-bg" style={{backgroundColor:"#eaea53", flexDirection:"row", alignItems: "center", padding: '0 30px'}}>
                <SlidingTypography>{children}</SlidingTypography>
            </Stack>
            <Stack style={{  width: 0, height: 0, borderTop: '42px solid transparent', borderRight:'25px solid #4B4998', borderBottom: '42px solid transparent', transform: "translateX(-100%)"}}></Stack>
        </Stack>
    )
}
export default TextWithBg