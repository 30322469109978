import React from "react";
import { Stack, IconButton } from "@mui/material";
import NavItem from "../tools/NavItem";
import SearchIcon from '@mui/icons-material/Search';
import Language from '../Language/Language'
import MemberOpt from "./MemberOpt";
import HelloUser from "./HelloUser";

const Header2 = () => {

    return(
        <Stack className="head" variant='head' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' >
            <Stack style={{height: "75%"}}>
                <NavItem to="home">
                    <img src="/images/HeaderImage/CNheader.png" style={{cursor: 'pointer'}} alt="" height='100%'/>
                </NavItem>
            </Stack>  
            
            <div className="cond-rendering">
                <HelloUser />
             </div>
            <Stack className="header-opt" flexDirection='row' >
                <MemberOpt/>
                {/*<IconButton variant="nav" style={{height: '50px'}}><SearchIcon /></IconButton>*/}
                <Language/>
            </Stack>      
        </Stack>
    )
} 

export default Header2;