import React, { useState } from "react";
import { Stack, Button, Typography, CircularProgress } from "@mui/material";

const PointedButton = ({children, red=false, onClick, width="100%", loading=false, disabled=false}) =>{
    const [ hover, setHover ] = useState(false)
    const bgColors = red ? ["#f82626", "#d32222"] : [ "#31bd31", "#29aa29" ]
    return(
        <Stack position="relative" style={{width: width}}>
            <Button onMouseOver={()=> setHover(true)} onMouseLeave={()=> setHover(false)}
                onClick={onClick} disabled={disabled}
                style={{backgroundColor: hover ? bgColors[1] : bgColors[0], borderRadius: "20px",
                color: "white"}}>
                    {
                        loading ? <CircularProgress color="inherit" size="24px"/>
                        : <Typography fontWeight= "bold">{children}</Typography>      
                    }
                </Button>    
            <img className={hover ? "slide-hand hand-pointer-img" : "hand-pointer-img"} src={"/images/Member/handpointer"+ ( red ? "red" : "green" ) + ".png"} 
            style={{position: "absolute", top: '0'}}/>    
        </Stack>
    )
}

export default PointedButton