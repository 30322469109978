import React from "react";
import { Grid, Typography, Button, Stack, CircularProgress } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import useData from "../hooks/useData";
import Input from "./input";
import axiosInstance from "../../axios";
import { baseURL } from "../../helper/conf";

const SnTable = ({sn, data={}, loading=-1, onEdit= ()=>{}, onDelete, editing= -1, control, errors, onSubmit= ()=>{}}) =>{
    const {text} = useLanguage()
    const { websites } = useData()
    const getIndex = () =>{
        switch (sn){
            case 'Facebook': return 0
            case 'Twitter': return 1
            case 'Youtube': return 2
            case 'Instagram': return 3
            case 'Pinterest': return 4
            case 'Linkedin': return 5

            default: return 0
        }
    }

    const breakURL = (url) => {
        if (!url) return null
        return url.replace(/([^\s]{15})(?=[^\s])/g, '$1\u200B');
    };
    return(
            <Grid container style={{display: 'flex', alignItems: 'center', justifyContent:"space-between" }} >
                <Grid item xs={2} style={{ minHeight: '80px', backgroundColor: "#ffebcd",display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img className="img-bg" src={ (data.name == null)? websites[getIndex()].iconSrc : `${baseURL}/icon/social/${data.name}`} alt=""  /> 
                </Grid>
                <Grid item xs={3.5} style={{ minHeight: '80px', backgroundColor: "#fff",display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {
                        (editing == data.id)
                        ? <Input control={control} name="link" error={errors.link} max/>
                        : <Typography textAlign="center" style={{overflow: "clip" }}>
                            {breakURL(data?.link)}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={3} style={{ minHeight: '80px', backgroundColor: "#fff",display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {
                        (editing == data.id)
                        ? <Input control={control} name="login" error={errors.login} max/>
                        : <Typography textAlign="center" style={{overflow: "clip" }}>
                        {breakURL(data?.login)}
                    </Typography>
                    }                   
                </Grid>
                <Grid item xs={2} style={{ minHeight: '80px', backgroundColor: "#fff",display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {
                        (editing == data.id)
                        ? <Input control={control} name="password" error={errors.password} max/>
                        : <Typography textAlign="center" style={{overflow: "clip" }}>
                        {breakURL(data?.password)}
                    </Typography>
                    }          
                </Grid>
                <Grid item xs={1} style={{ minHeight: '80px', backgroundColor: "#fff",display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {
                        (loading == data.id)?
                        <CircularProgress color="inherit" size="24px"/>
                        : (editing == data.id) ?
                        <Stack width='100%' height='100%'>
                            <Button onClick={onSubmit} style={{textTransform: 'capitalize', width: '100%', height: '100%'}}>{text.save}</Button>
                            <Button onClick={()=> onEdit({}) } style={{textTransform: 'capitalize', width: '100%', height: '100%'}}>{text.back}</Button>
                        </Stack>
                        : <Stack width='100%' height='100%'>
                            <Button onClick={()=> onEdit(data) } style={{textTransform: 'capitalize', width: '100%', height: '100%'}}>{text.edit}</Button>
                            <Button onClick={()=> onDelete(data.id) } style={{textTransform: 'capitalize', width: '100%', height: '100%', color: '#f00'}}>{text.delete}</Button>
                        </Stack> 
                    }
                </Grid>
            </Grid>
    )
}

export default SnTable