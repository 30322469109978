import { Stack, Box, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArticleComment from "./ArticleComment";
import ArticleItem from "./ArticleItem";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import useLanguage from "../hooks/useLanguage";
import { format } from "date-fns";

const Article = () =>{

    const { id } = useParams()
    const { currentLanguage } = useLanguage()
    const [article, setArticle] = useState({})
    useEffect(()=>{
        const func = async () =>{
            try {
                const { data } = await axiosInstance.get(`/post/${id}`)
                setArticle(data)
                console.log(data)
            } catch (error) {
                console.log(error.response)
            }
        }
        func()
    }, [])
    return(
        <Stack onLoad={() => window.scrollTo(0, 0)}>
            <Box className="wrap-content">
                <Stack width="75%" padding="40px 0">
                    
                            <ArticleItem categoryId={article.post?.category?.id} 
                                imgSrc={article.post?.image_illustration} title={article.post?.title_Post} date={article.post?.created_at}
                                author={article.post?.author?.user_name} authorId={article.post?.author?.id} 
                                comments={article.comment?.comments} category={article.post?.category?.name}
                                content={article.post?.content}
                            />
                    <ArticleComment postid={id} comments={article.comment?.comments}/>
                </Stack>
                <Stack width="25%"></Stack>
            </Box>
        </Stack>
    )
}

export default Article