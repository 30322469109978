import React, { useContext, useEffect, useState } from "react";
import { Stack, Button, IconButton, Box, Typography } from "@mui/material";
import useLanguage from '../hooks/useLanguage';
import {  useNavigate } from "react-router-dom";
import Language from '../Language/Language'
import RoutesList from "../../helper/routes";
import PersonIcon from '@mui/icons-material/Person';
import DropDownHeader from "./DropDownHeader";
import NavItem from "../tools/NavItem";
import { AuthRedirectStateContext } from "../state/AuthRedirectStateContext";
import { AuthStateContext } from "../state/AuthStateContext";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../../axios";
import { baseURL } from "../../helper/conf";

const Header = () => {
    const { text, currentLanguage } = useLanguage()
    const navigate = useNavigate()
    const { setRedirectRoute } = useContext(AuthRedirectStateContext)
    const { candidateId, setProfilePicture: setUserPP, profilePicture } = useContext(AuthStateContext)
    
    
    useEffect(()=>{
        const userInfos = async () =>{try {
                if (candidateId){
                    const { id } = jwtDecode(candidateId)
                    const { data } = await axiosInstance.get(`/infos/${id}`, { headers: {'Authorization': `Bearer ${candidateId}`} })
                    const pp = data.profilephoto
                    if (pp) setUserPP(`${baseURL}/images/users/${pp}`)
                }
            } catch (err){}}
            userInfos()
          
    },[])

    const getFileName = () => {
        const prefix = "Creativ'Network_"
        switch (currentLanguage){
            case 'fr': return prefix + "Manuel_d'utilisation"
            case 'it': return prefix + "Manuale_dell'utente"
            case 'nl': return prefix + "Gebruikershandleiding"
            case 'es': return prefix + "Manual_del_usuario"
            case 'de': return prefix + "Benutzerhandbuch"
            default: return prefix + "User_Manual"
        }
    }

    const downloadUserManual = async () => {
        const filename = getFileName()
        const path = `/usermanual/${currentLanguage}.pdf`
        try {
            const res = await fetch(path)
            const blobRes = await res.blob()
            const url = window.URL.createObjectURL(new Blob([blobRes]))
            const a = document.createElement('a')
            a.href = url
            a.download = `${filename}.pdf`
            a.click()
            document.removeChild(a)
        } catch (error) { console.error(error) }
    }

    return(
        <Stack className="head" variant='head' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' >
            <DropDownHeader/>
            <Stack style={{height: "75%"}}>
                <NavItem to="home">
                    <img src="/images/HeaderImage/CNheader.png" style={{cursor: 'pointer'}} alt="" height='100%'/>
                </NavItem>
            </Stack>
            
            <Box className="displayed-menu" flexDirection='row' gap="25px" justifyContent='space-between' alignItems='center'>
                <Button style={{fontWeight: "bold"}}><NavItem to="home">{text.home}</NavItem></Button>
                <Button style={{fontWeight: "bold"}}><NavItem to="missions">{text.ourmissions}</NavItem></Button>
                <Button style={{fontWeight: "bold"}}><NavItem to="compvalues">{text.ourcompany}</NavItem></Button>
                <Button style={{fontWeight: "bold"}}><NavItem to="advantages">{text.ouradvantages}</NavItem></Button>
                <Button style={{fontWeight: "bold"}} onClick={()=>navigate(RoutesList.blog)}>
                    <Typography variant="nav">{text.blog}</Typography>
                </Button>
                <Button style={{fontWeight: "bold"}} onClick={() => navigate(RoutesList.jobs)}>
                    <Typography variant="nav">{text.jobs}</Typography>
                </Button>
                <Button style={{fontWeight: "bold"}} onClick={ downloadUserManual }>
                    <Typography variant="nav">{text.usermanual}</Typography>
                </Button>
            </Box>
            <Stack flexDirection='row'>
            <>
                {
                    (candidateId!==null) ? 
                        <Stack className="usericon" position='relative' height="45px" width="45px" >
                            <IconButton 
                                onClick={() =>{ setRedirectRoute(RoutesList.dashboard); navigate(RoutesList.memberarea)}} 
                                sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
                            >
                                <img  className="profil"  src={profilePicture} alt=""  style={{borderRadius: '50%'}}/>
                            </IconButton>
                        </Stack>
                    :<IconButton variant="nav" onClick={() =>{ setRedirectRoute(RoutesList.dashboard); navigate(RoutesList.memberarea)}}><PersonIcon /></IconButton>
                }
            </>
                <Language/>
            </Stack>
        </Stack>
    )
} 

export default Header;