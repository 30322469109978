import { MenuItem, Typography, Menu, Box, Stack, Icon } from "@mui/material";
import React, {useContext, useState} from "react";
import useLanguage from "../hooks/useLanguage";
import Logout from "../tools/Logout";
import { roles } from "../../helper/roles";
import { AuthStateContext } from "../state/AuthStateContext";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { jwtDecode } from "jwt-decode";

const UserOptions = ({variant}) =>{
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const { text } = useLanguage()
    const { bloggerId, userId, adminId } = useContext(AuthStateContext)
    const [hover, setHover] = useState(false)

    const getFirstItemData = () => {
        switch (variant){
            case roles.admin: return [text.ida, adminId, 'Admin']
            case roles.blogger: return [text.idb, bloggerId, 'Blogger']
            case roles.teamleader: return [text.idtl, userId, 'TeamLeader']
            case roles.manager: return [text.idm, userId, 'Manager']
            default: return ['', '', '']
        }
    }
    const [ idIntro, token, imgFolder ] = getFirstItemData()
    const { id } = token ? jwtDecode(token) : ''
    return(
        <Box>
            <Stack height="45px" width="45px" position='relative'>
                {/*<img src="/images/Admin/bell.png" style={{height: '30px', width: '30px', position: 'absolute', transform: 'translate(-25px, -2px)'}} alt=""/>*/}
                {
                    hover ?
                    <Icon style={{position: 'absolute', bottom: '0', right: '0', 
                    transform:'translate(25%, 25%)' ,color: '#fff'}}>
                        <ArrowDropDownIcon/>
                    </Icon>
                    : <></>
                }
                <img onMouseEnter={()=> setHover(true)} onMouseLeave={()=> setHover(false)} style={{cursor: 'pointer', height: '100%', width: '100%'}} 
                src={'/images/' + imgFolder + '/person.png'} alt="" 
                onClick={event =>  setAnchorEl(anchorEl ? null : event.currentTarget)}/>
            </Stack>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                slotProps={{paper: {style: { backgroundColor: '#4B4998', boxShadow: 'none'}}}}
            >
                <MenuItem style={{backgroundColor: '#de5b6d', color: '#fff', justifyContent: 'center'}}><Typography>{idIntro}: {id}</Typography></MenuItem>
                <MenuItem style={{backgroundColor: '#01e1e9', margin: '7px 0', color: '#fff', justifyContent:'center'}}><Typography>{text.notifs}</Typography></MenuItem>
                <Logout type={variant}/>
            </Menu>
        </Box>
    )
}

export default UserOptions