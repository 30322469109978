import React from "react";
import { Divider , Stack } from "@mui/material";

const CustomDivider = ({color, alignCenter=false, width="100%"}) =>{
    return(
        <Stack style={alignCenter ? { alignItems: "center", width: "100%" } : { alignItems: "flex-start", width: "100%" }}>
            <Stack  style={{display: "flex", flexDirection: "row", gap: "5px", width: width}}>
                <Divider variant={"threedots" + color} />
                <Divider variant={color}/>
            </Stack>
        </Stack>
    )
}

export default CustomDivider