import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import SlidingTypography from "./SlidingTypography";

const AdminCard = ({label, imgFile, onDetails}) =>{
    const baseUrl = '/images/Admin/'
    const { text } = useLanguage()
    const isLabelOneWord = () => {
        const words = label.split(' ')
        return words.length <=1
    }
    return(
        <Stack className="card-resize" style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
            <img src={baseUrl + "cadre.png"} style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}/>
            <Stack alignItems='center' width='85%'>
            {
                    isLabelOneWord() ?
                    <SlidingTypography variant="admin-card-header" color="#fef8ee" textAlign="center" fast>{label.toUpperCase()}</SlidingTypography>
                    : <Typography variant="admin-card-header" style={{color: '#fef8ee'}}>{label.toUpperCase()}</Typography>
                }
                <img src={baseUrl + imgFile} className="card-img-resize"/>
            </Stack>
            <Stack padding='3px' className="card-button-resize" 
            style={{backgroundColor: '#ba80ed', borderRadius: '20px'}}>
                <Button onClick={onDetails} variant="rose">
                    {text.details.toUpperCase()}
                </Button>
            </Stack>
        </Stack>
    )
}

export default AdminCard