import React, { useEffect, useState } from "react";
import { Stack, Typography, IconButton, Button, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useLanguage from "../hooks/useLanguage";
import RoutesList from "../../helper/routes";
import { Link } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from '@mui/material/Switch';

const Cookies = () =>{
    const cookieName = 'cn-accept'
    const content = 'yes'

    const [visible, setVisible] = useState(false)
    const [prefVisible, setPrefVisible] = useState(false)

    useEffect(() =>{ setVisible(!getCookie()) }, [])

    const setCookie = () =>{
        const expirationDate = new Date()
        expirationDate.setDate(expirationDate.getDate() + 7)
        document.cookie = `${cookieName}=${content}; expires=${expirationDate.toUTCString()}; path=/`
    }

    const getCookie = () =>{
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            const [name, value] = cookie.split('=')
            if (name === cookieName) return value;    
        }
        return null
    }

    const accept = () =>{
        setVisible(false)   
        setCookie()   
    }

    const { text } = useLanguage()
    return(
            <Stack className={visible ? "cookie popup" : "close-popup popup"} spacing={4} style={{transform: "translateX(-50%)"}}>
                <Stack display="flex" alignItems="center" flexDirection="row" justifyContent="space-between">
                    <Stack/><Stack/>
                    <Typography maxWidth="100%" display="flex" justifyContent="flex-end">{text.cookiestitle}</Typography>
                    <img width="15%" src="/images/cookie/cookies_CN.png"/>
                    <IconButton style={{fontWeight: "bold"}} onClick={() => setVisible(false)}><CloseIcon/></IconButton>             
                </Stack>
                {
                    prefVisible ?
                    <Stack>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Stack style={{ width: '100%', display: 'flex', flexDirection:"row", alignItems: "center", justifyContent: 'space-between' }}>
                                    <Typography variant="tiny">{text.cookiessm1i}</Typography>
                                    <Typography variant="tiny" style={{color: "#0C0"}}>{text.alwyasactive}</Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails><Typography variant="tiny">{text.cookiessm1c}</Typography></AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Stack style={{ width: '100%', display: 'flex', flexDirection:"row", alignItems: "center", justifyContent: 'space-between' }}>
                                    <Typography variant="tiny">{text.cookiessm2i}</Typography>
                                    <Switch defaultChecked onClick={e =>{ e.stopPropagation() }}/>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails><Typography variant="tiny">{text.cookiessm2c}</Typography></AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Stack style={{ width: '100%', display: 'flex', flexDirection:"row", alignItems: "center", justifyContent: 'space-between' }}>
                                    <Typography variant="tiny">{text.cookiessm3i}</Typography>
                                    <Switch defaultChecked onClick={e =>{ e.stopPropagation() }}/>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails><Typography variant="tiny">{text.cookiessm3c}</Typography></AccordionDetails>
                        </Accordion>
                    </Stack>
                    : <Typography variant="tiny">{text.cookiescontent}</Typography>
                }
                <Stack display="flex" flexDirection="row">
                    <Button variant="hiring" style={{width: "50%", textTransform: "capitalize"}} onClick={accept}>{text.accept}</Button>
                    <Button variant="outlined2" onClick={()=>setPrefVisible(v => !v)}>{ prefVisible ? text.savepref : text.pref}</Button>
                </Stack>
                <Stack alignItems="center">
                    <Link to={RoutesList.pp} className="link3" variant="tiny">{ text.termsAgreeLink1 }</Link>
                </Stack>
            </Stack>
    )
}

export default Cookies