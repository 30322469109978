import React, { useContext, useEffect } from "react";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import Introduction from "./ChatOperator/Introduction1";
import About from "./ChatOperator/About2";
import SalaryCompute from "./ChatOperator/SalaryCompute3";
import Requirements from "./ChatOperator/Requirements4";
import IdentityCheck from "./ChatOperator/IdentityCheck5";
import ApplySteps from "./ChatOperator/ApplySteps6";
import ApplyForm from "./ChatOperator/ApplyForm7";
import { AuthStateContext } from "../state/AuthStateContext";

const ChatOperator = () =>{
    const {lang} = useParams()
    const { candidateId } = useContext(AuthStateContext)
    if (candidateId){
        const dest = document.getElementById('apply-co')
        if (dest) dest.scrollIntoView()
    }    
    return(
        <Stack>
            <Introduction/>
            <About/>
            <SalaryCompute/>
            <Requirements/>
            <IdentityCheck/>
            <ApplySteps/>
            <ApplyForm/>
        </Stack>
    )
}

export default ChatOperator