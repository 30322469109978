import  React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form"
import { Stack, Typography, IconButton, MenuItem } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useLanguage from "../hooks/useLanguage";
import { mdpminlength } from "../../helper/conf";

const ImgInput = ({type, control, name, error, required=true, placeholder="", value="", setValue =(a,b)=>{}, imgSrc, range, green, suggest=false, readonly=false }) => {
    const { text } = useLanguage()
    const maildomain = undefined // exemple: 'gmail.com'
    const domain = (maildomain) ? maildomain.replace('.', '\\.'): undefined
    const isPassword = type === 'password'
    const [vis, setVis] = useState(!isPassword)
    const switchVis = () => setVis(vis => !vis)
    const [ hover, setHover ] = useState(false)
    const [showSuggest, setShowSuggest] = useState(false)
    const handleFocus = () => {document.querySelector("#img-input-" + range).focus(); if (suggest) setShowSuggest(true) }
    const [suggestion, setSuggestion] = useState('')
    useEffect(()=> setValue(name, value), [value])
    useEffect(()=> {
        let sug = ''
        const choices = 'ZQSDFGHJKWXCVBNzqdfghjkwxcvbn123456789$'
        for (let i = 1; i <= mdpminlength; i ++) sug += choices.charAt(Math.floor(Math.random() * choices.length))
        setSuggestion(sug)
    }, [])
    const takeSuggestion = () =>{ setValue(name, suggestion); setShowSuggest(false) }

    return(
        <Stack>
            <Stack onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}
            onClick={handleFocus}
            
            style={{ position: 'relative', cursor:"text", backgroundColor: green ? '#7bd667' : '#bf8919', transform: hover ? "translate(-2px, -2px)" : "none", border: hover ? ( green ? "2px solid #137c08" : "2px solid #FDC309" ) : "none",
            padding:"2px", borderRadius: "25px", display: "flex", flexDirection:'row', 
            justifyContent:"space-between"}}
            >
                <Stack style={{flexDirection: 'row', width:"calc(100% - 50px)"}}>    
                    <Stack borderRadius="50%" justifyContent="center"><img className="imginput-img" src={imgSrc} alt=""/></Stack>
                    <Controller
                        control={control}
                        name={name}
                        defaultValue=""
                        rules={ (type === 'email') ? {
                            required: text.required,
                            pattern: {
                                value: new RegExp(`^[A-Z0-9._%+-]+@${domain || `[A-Z0-9.-]+\\.[A-Z]{2,}`}$`, 'i'),
                                message: text.invalidemail
                            }}
                            : (
                                isPassword ? { required: text.required, minLength: {value: mdpminlength, message: text.passwordlength} }
                                : {required: (required) ? text.required : false}
                            )
                        }
                        render={({ field: {onBlur, value, ...rest} }) =>
                            <input id={"img-input-" + range} readOnly={readonly}
                                 className="in-input in-input-white" placeholder={placeholder} type={(vis) ? 'text' : 'password'} 
                                 {...rest} value={value} autoComplete="off" onBlur={()=>{ if ((suggest)&&(value.length >= mdpminlength)) setShowSuggest(false) }}
                            />          
                            }
                    />
                </Stack>
                <Stack alignItems="center" justifyContent="center" marginRight="10px">
                    {
                        isPassword ?
                        <IconButton className="in-input-icon" style={{color: "#EEE"}} onClick={switchVis}>
                            { vis ? <VisibilityOff /> : <Visibility/> }
                        </IconButton>
                        : <></>
                    }
                </Stack>
            </Stack>
            {
                showSuggest ?
                <MenuItem onClick={() => takeSuggestion()} style={{backgroundColor: '#fff7', borderRadius: '5px'}}>{suggestion}</MenuItem>
                : <></>
            }
            {error && <Typography variant="error" textAlign='left'>
                { error.message }
            </Typography>}
        </Stack>
    )
}
export default ImgInput