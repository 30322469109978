import { Stack, Typography, Divider } from "@mui/material";
import React from "react";
import useLanguage from "../hooks/useLanguage";
import IconText from "../tools/IconText";
import CustomDivider from "../tools/CustomDivider";

const PrivacyPolicy = () => {
    const { text } = useLanguage()
    const baseUrl = "/images/PP/"
    return(
        <Stack>
            <Stack className="pp-stack" style={{ zIndex: -2 }} variant="primarybg">
                <Stack alignItems='center' style={{ overflow: 'hidden', zIndex: -1}}>
                    <img src={ baseUrl + "Privacy Policy.webp"} style={{width: '100%'}}/>
                </Stack>
                <Stack className="text-replace-3" style={{position: 'absolute', left: '0%', width: "50%", textAlign: 'center'}} >
                    { text.termsAgreeLink1.split(' ').map((t, i) => <Typography key={"n"+i} variant="whitetitle" fontWeight="bold" textTransform="uppercase">{t}</Typography>)}
                </Stack>
            </Stack>
            <Stack style={{transform: "translateY(-100%)"}} variant="wavestrans" className="waves-display" ><img src="/svg/pp.svg"/></Stack>
            <Stack alignItems='center' spacing={3} marginBottom='20px'>
                <Typography variant="primarytitle" style={{textTransform: 'capitalize'}}>{text.termsAgreeLink1}</Typography>
                <CustomDivider color="secondary" alignCenter width='22%'/>
                <Stack spacing={2} style={{width: '85%'}}>
                    {
                        text.ppint.map( (t, i) =>
                            <Typography key={"n"+i} textAlign='center'>{t}</Typography>
                        )
                    }
                </Stack>
                <Stack style={{width: '85%'}} variant="card" spacing={2}>
                    <IconText center iconSrc={ baseUrl + "1- Consent.svg" }>
                        <Typography>{text.pp1t[0]}</Typography>
                        { text.pp1t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "2- Information we collect.svg" }>
                        <Typography>{text.pp2t[0]}</Typography>
                        { text.pp2t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "3- How we use your information.svg" }>
                        <Typography>{text.pp3t[0]}</Typography>
                        { text.pp3t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "4- Log Files.svg" }>
                        <Typography>{text.pp4t[0]}</Typography>
                        { text.pp4t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "5- Cookies and Web Beacons.svg" }>
                        <Typography>{text.pp5t[0]}</Typography>
                        { text.pp5t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "6- Advertising Partners Privacy Policies.svg" }>
                        <Typography>{text.pp6t[0]}</Typography>
                        { text.pp6t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "7- Third party.svg" }>
                        <Typography>{text.pp7t[0]}</Typography>
                        { text.pp7t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "8- CCPA Privacy Policy.svg" }>
                        <Typography>{text.pp8t[0]}</Typography>
                        { text.pp8t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "9- GDPR Privacy Policy.svg" }>
                        <Typography>{text.pp9t[0]}</Typography>
                        { text.pp9t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "10- Children's Information.svg" }>
                        <Typography>{text.pp10t[0]}</Typography>
                        { text.pp10t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default PrivacyPolicy