
import React, { useEffect } from 'react';
import { Stack, Typography, Box } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import Missions from "./Missions";
import CompValues from "./CompValues";
import Advantages from "./Advantages";
import AnimatedButton from "../tools/AnimatedButton";
import Testimonial from "./Testimonial";
import Statistics from "./Statistics";
import CustomDivider from '../tools/CustomDivider';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutesList from '../../helper/routes';
import { scroller } from "react-scroll"

const Home = () =>{
    const { text } = useLanguage()
    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(()=>{ if (state) scroller.scrollTo(state.link, {duration: 500, smooth: true, offset: -175}) }, [])
    
    return(
        <Stack id="home">
            <Stack  variant='home'>
                <Box className="wrap-content" display='flex'>
                    <Stack>
                        <Stack spacing={5} padding='0 17px'>

                            <Stack className="title-typo" display='flex'  alignItems='center' >
                                <Typography variant='whitetitle' fontWeight="bold">{text.wantto} <Typography variant='secondarytitle' className="title-typo-change">{text.cn}  </Typography> <Typography variant='whitetitle'>?</Typography> </Typography>
                            </Stack>
                            <CustomDivider color="white" alignCenter width='17%'/>
                            <Stack><Typography variant='texthome'>{text.areyou}</Typography></Stack>
                            <Stack alignItems='center'><AnimatedButton onClick={()=> navigate(RoutesList.jobs)}>{text.check}</AnimatedButton></Stack>
                        </Stack>
                        
                    </Stack>
                    <Stack display='flex' justifyContent='center' alignItems='center'><img alt="" src='/images/HomeImage/1-fille-avec-ordi-1re-ligne DEF.webp' width='90%'/></Stack>
                </Box>
                
            </Stack>
            <Stack variant='waves1'><img src='/svg/waves1.svg' alt=""/></Stack>
            <Missions />
            <CompValues/>
            <Advantages/>
            <Testimonial/>
            <Statistics/>
        </Stack>
    )
}
export default Home