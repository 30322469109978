import React from "react";
import { Typography, Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";

const Status = ({children, success=false, testRoute=null}) =>{
    const { text } = useLanguage()
    const iconSrc = `/images/Member/dashboard/application/${success ? 'true' : 'false'}.png`
    return(
        <Box gap={1} flexDirection='row' display='flex'>
            <img src={iconSrc} alt="" style={{width: "25px", height: "25px", alignSelf: 'center'}}/>
            <Stack>
                <Typography variant="white" style={{alignSelf: "center"}}>{children}</Typography>
                { ((!success) && (testRoute)) ? <Link to={testRoute} className="link">{ text.tktst }</Link> : null }
            </Stack>
        </Box>
    )
}
export default Status