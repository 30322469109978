    import React from "react";
    import { Grid, Typography, Stack, OutlinedInput, Select, MenuItem } from "@mui/material";
    import DatePicker from 'react-datepicker'
    import "react-datepicker/dist/react-datepicker.css";
    import SlidingTypography from "./SlidingTypography";

    const CustomFormItem = ({label, options:{comp, selected, onChange, choices}}) =>{
        const getComponent = () => {
            switch (comp){
                case 'DatePicker': return <DatePicker dateFormat='dd-MM-yyyy'showYearDropdown
                    scrollableYearDropdown yearDropdownItemNumber={100}
                    className="datepicker datepicker-3" selected={selected} onChange={onChange}/>
                case 'TextInput': return <OutlinedInput type="text" style={{backgroundColor: 'transparent'}} 
                    onChange={onChange} sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{border: 0} }}/>
                case 'Select': return <Select defaultValue={choices[0]?.value} onChange={onChange}sx={{backgroundColor: 'transparent',
                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, 
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{border: 0}}}>
                    {
                        
                        choices.map(c =>
                            <MenuItem key={c.value} value={c.value}>{c.innerHTML}</MenuItem>
                        )
                    }
                    </Select>    
                default: return <></>
            }
        }
        return(
            <Grid container style={{border: '1px solid #ffd697'}}>
                <Grid item xs={4} style={{border: '1px solid #ffd697', backgroundColor: '#ffe3e3', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <SlidingTypography variant="bold" textAlign="center" fast>
                        {label}
                    </SlidingTypography>
                </Grid>
                <Grid item xs={8} style={{border: '1px solid #ffd697', backgroundColor: '#8fa9b1'}}>
                    <Stack width='100%'>
                        { getComponent() }
                    </Stack>
                </Grid>
            </Grid>
        )
    }
    export default CustomFormItem