import getText from "../../helper/texts"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"

const cnlangtoken = 'creativnetworklang'
const useLanguage = () =>{
    const navigate = useNavigate()
    const location = useLocation()

    const getLang = () => { return localStorage.getItem(cnlangtoken) || 'en' }
    const currentLanguage = getLang()

    const getCurrentLocation = () => {
        const parts = location.pathname.split('/')
        return parts.slice(0, parts.length -1).join('/')
    }

    const setLanguage = (lang, nav=true) =>{
        localStorage.setItem(cnlangtoken, lang)
        if (nav) navigate(getCurrentLocation()+ "/" + lang, { state: location.state })
    }
    const text = getText(currentLanguage)
    return { text, currentLanguage, setLanguage }
}

export default useLanguage