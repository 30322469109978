import React, { createContext, useState } from 'react'

const NotifStateContext = createContext(false)

const NotifStateProvider = ({children}) => {
    const [notifs, setNotifs] = useState([])
    return (
        <NotifStateContext.Provider value={{
            notifs, setNotifs
        }}>
            {children}
        </NotifStateContext.Provider>
    )
}
export {NotifStateContext, NotifStateProvider}