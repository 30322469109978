import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import IconTextCard from "../../tools/IconTextCard";
import AnimatedButton from "../../tools/AnimatedButton";
import CustomDivider from "../../tools/CustomDivider"

const ApplySteps = () =>{
    const { text } = useLanguage()
    const iconBaseUrl = "/images/TeamLeader/icons/6_ApplySteps/"
    return(
        <Stack alignItems='center' spacing={3} marginBottom='50px'>
            <Typography className="title-typo" variant="title" width="85%">
                {text.coasint1} <Typography variant="primarytitle2" className="title-typo-change-light-2">{text.cn}</Typography> {text.coasint2}
            </Typography>
            <CustomDivider color="secondary" alignCenter width='23%'/>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
            <IconTextCard variant="tabcontent" bgColor="#daf9df" label={text.coas11 + ":"} iconSrc={iconBaseUrl + "1_Easy application and online tests.svg"}>{text.tlas1.split('|')}</IconTextCard>
                <IconTextCard variant="tabcontent" bgColor="#daf9df" label={text.coas21 + ":"} iconSrc={iconBaseUrl + "2_ID verification.svg"}>{text.tlas2.split('|')}</IconTextCard>
                <IconTextCard variant="tabcontent" bgColor="#daf9df" label={text.coas31 + ":"} iconSrc={iconBaseUrl + "3_Online training.svg"}>{text.tlas3.split('|')}</IconTextCard>
            </Box>
            <AnimatedButton to="apply-tl">{text.applynow}</AnimatedButton>
        </Stack>
    )
}

export default ApplySteps