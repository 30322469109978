import React, { useContext } from "react";
import { Stack } from "@mui/material";
import useLanguage from '../hooks/useLanguage';
import ArcText from "../tools/ArcText";
import { AuthStateContext } from "../state/AuthStateContext";

const HelloUser = () => {
    const { text, currentLanguage } = useLanguage()
    const { firstName } = useContext(AuthStateContext)
    
    return(
        
        <Stack style={{ position: 'relative', alignItems: 'center' }}>
            <img  src="/images/Member/hello user.png" alt="Background" style={{ display: 'block', width: '90%'}}/>
            <Stack style={{ position: 'absolute', zIndex: 2, top: '80%', left: '50%', transform: 'translate(-50%, -50%)',
                display: 'flex', justifyContent: "center", alignItems: "center" } } 
            >
                <ArcText children={`${text.hello} ${firstName}${(currentLanguage == 'fr') ? ' !' : '!'}` } />
            </Stack>
        </Stack>
            
    )
} 

export default HelloUser;