import React, { useEffect, useState } from "react";
import { Stack, Typography, Box, Select, MenuItem} from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import TitleWithBg from '../../tools/TitleWithBg'
import ImgInputLight from "../../tools/ImgInputLight";
import ImgInput from "../../tools/ImgInput";
import useData from "../../hooks/useData";

const IntegUploadPay = ({control, errors, defaultValues, setValue}) =>{
    const { text } = useLanguage()
    const {payment} = useData()
    const [df, setDf] = useState({})
    useEffect(()=>{ 
        if (defaultValues.payements) {
            const data = defaultValues.payements[0]
            if (data){
                const filteredEntries = Object.entries(data).filter(([key, value]) => value !== null)
                const filteredObject = Object.fromEntries(filteredEntries)
                setDf(filteredObject)
            }
        } 
    }, [defaultValues])

    const [selectedPayment, setSelectedPayment] = useState('');

    const handleSelect = (event) => {
      setSelectedPayment(event.target.value);
    };

    return(
        <Stack variant="rightcontent" >
            <Stack display="flex" justifyContent="center" alignItems="center" ><TitleWithBg iconSrc='/images/Member/dashboard/7.png' children={text.integration} color="#cb6ce5" title/></Stack>
            <Stack style={{alignItems: "center", justifyContent: "center"}}><Typography variant="secondaryitalic">{text.integration2}</Typography></Stack>
            { df.paypal?.length ? 
                <Stack padding="30px 0 0 0" width="65%" display="flex" justifyContent="center" alignSelf="center">
                    <ImgInputLight readonly type="email" required={false} control={control} name="paypalemail" setValue={setValue} value={df.paypal} error={errors.paypalemail} placeholder={text.paypalaccount} imgSrc="/images/Member/dashboard/integration/paypal.png"/>
                    <Typography variant="white" alignSelf= "center">{text.yourpaypal}</Typography>
                    <Typography variant="white" alignSelf= "center">{text.chngpay}</Typography>
                </Stack>
            : df.wise?.length ?
                <Stack padding="30px 0 0 0" width="65%" display="flex" justifyContent="center" alignSelf="center">
                    <ImgInputLight readonly type="email" required={false} control={control} name="wiseemail" setValue={setValue} value={df.wise} error={errors.wiseemail} placeholder={text.wiseaccount} imgSrc="/images/Member/dashboard/integration/wise.png"/>
                    <Typography variant="white" alignSelf= "center">{text.yourwise}</Typography>
                    <Typography variant="white" alignSelf= "center">{text.chngpay}</Typography>
                </Stack>
            : df.your_safe?.length ?
                <Stack padding="30px 0 0 0" width="65%" display="flex" justifyContent="center" alignSelf="center">
                    <ImgInputLight readonly type="email" required={false} control={control} name="bitesafeemail" setValue={setValue} value={df.your_safe} error={errors.bitesafeemail} placeholder={text.bitesafeaccount} imgSrc="/images/Member/dashboard/integration/bitesafe.png"/>
                    <Typography variant="white" alignSelf= "center">{text.yourbitesafe}</Typography>
                    <Typography variant="white" alignSelf= "center">{text.chngpay}</Typography>
                </Stack>
            : df.holder_bank_name?.length ?
                <Stack gap={5} padding="30px 0 0 0">
                    <Stack width="65%" display="flex" justifyContent="center" alignSelf="center"><ImgInputLight readonly type="text" required={false} control={control} name="bankaccount" error={errors.bankaccount} placeholder={text.bankaccount} imgSrc="/images/Member/dashboard/integration/bank.png"/></Stack>
                    <Stack gap={3} padding="0px 20px">
                        <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                            <Stack width="50%"><ImgInput readonly range='5' required={false} green type="text" control={control} name="holdrename" setValue={setValue} value={df.holder_name}  error={errors.holdername} placeholder={text.accountholder} imgSrc="/images/Member/dashboard/integration/h-name.png"/></Stack>
                            <Stack width="50%"><ImgInput readonly range='6' required={false} green type="text" control={control} name="holderaddress" setValue={setValue} value={df.holder_address} error={errors.holderaddress} placeholder={text.holderaddress} imgSrc="/images/Member/dashboard/integration/h-address.png" /></Stack>
                        </Box>
                        <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                            <Stack width="50%"><ImgInput readonly range='7' required={false} green type="text" control={control} name="bankholder" setValue={setValue} value={df.holder_bank_name} error={errors.bankholder} placeholder={text.banksholder} imgSrc="/images/Member/dashboard/integration/h-bank-name.png"/></Stack>
                            <Stack width="50%"><ImgInput readonly range='8' required={false} green type="text" control={control} name="bankaddress" setValue={setValue} value={df.holder_bank_address} error={errors.bankaddress} placeholder={text.bankaddress} imgSrc="/images/Member/dashboard/integration/h-bank-address.png" /></Stack>
                        </Box>
                        <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                            <Stack width="50%"><ImgInput readonly range='9' required={false} green type="text" control={control} name="iban" setValue={setValue} value={df.iban} error={errors.iban} placeholder={text.iban} imgSrc="/images/Member/dashboard/integration/iban.png" /></Stack>
                            <Stack width="50%"><ImgInput readonly range='10' required={false} green type="text" control={control} name="swiftcode" setValue={setValue} value={df.bic} error={errors.swiftcode} placeholder={text.swiftcode} imgSrc="/images/Member/dashboard/integration/swift.png"/></Stack>
                        </Box>
                    </Stack>
                    <Typography variant="white" alignSelf= "center">{text.chngpay}</Typography>
                </Stack>
            : 
                <>
                    <Stack gap={1} padding="10px">
                        <Typography variant="white">{text.cypm}</Typography>
                        <Select value={selectedPayment} onChange={handleSelect}>
                            {
                                payment.map(opt =>  <MenuItem key={opt.value} value={opt.value}>{opt.innerHTML}</MenuItem>)
                            }
                        </Select>
                    </Stack>

                    <Stack padding="30px 0 0 0">
                        {selectedPayment === 'paypal' && 
                            <Stack width="65%" display="flex" justifyContent="center" alignSelf="center">
                                <ImgInputLight type="email" required={false} control={control} name="paypalemail" setValue={setValue} value={df.paypal} error={errors.paypalemail} placeholder={text.paypalaccount} imgSrc="/images/Member/dashboard/integration/paypal.png"/>
                                <Typography variant="white" alignSelf= "center">{text.yourpaypal}</Typography>
                            </Stack>
                        }
                        {selectedPayment === 'wise' && 
                            <Stack width="65%" display="flex" justifyContent="center" alignSelf="center">
                                <ImgInputLight type="email" required={false} control={control} name="wiseemail" setValue={setValue} value={df.wise} error={errors.wiseemail} placeholder={text.wiseaccount} imgSrc="/images/Member/dashboard/integration/wise.png"/>
                                <Typography variant="white" alignSelf= "center">{text.yourwise}</Typography>
                            </Stack>
                        }
                        {selectedPayment === 'yoursafe' && 
                            <Stack width="65%" display="flex" justifyContent="center" alignSelf="center">
                                <ImgInputLight type="email" required={false} control={control} name="bitesafeemail" setValue={setValue} value={df.your_safe} error={errors.bitesafeemail} placeholder={text.bitesafeaccount} imgSrc="/images/Member/dashboard/integration/bitesafe.png"/>
                                <Typography variant="white" alignSelf= "center">{text.yourbitesafe}</Typography>
                            </Stack>
                        }
                        {selectedPayment === 'bank' && 
                            <Stack gap={5}>
                                <Stack width="65%" display="flex" justifyContent="center" alignSelf="center"><ImgInputLight readonly type="text" required={false} control={control} name="bankaccount" error={errors.bankaccount} placeholder={text.bankaccount} imgSrc="/images/Member/dashboard/integration/bank.png"/></Stack>
                                <Stack gap={3} padding="0px 20px">
                                    <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                                        <Stack width="50%"><ImgInput range='5' required={false} green type="text" control={control} name="holdrename" setValue={setValue} value={df.holder_name}  error={errors.holdername} placeholder={text.accountholder} imgSrc="/images/Member/dashboard/integration/h-name.png"/></Stack>
                                        <Stack width="50%"><ImgInput range='6' required={false} green type="text" control={control} name="holderaddress" setValue={setValue} value={df.holder_address} error={errors.holderaddress} placeholder={text.holderaddress} imgSrc="/images/Member/dashboard/integration/h-address.png" /></Stack>
                                    </Box>
                                    <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                                        <Stack width="50%"><ImgInput range='7' required={false} green type="text" control={control} name="bankholder" setValue={setValue} value={df.holder_bank_name} error={errors.bankholder} placeholder={text.banksholder} imgSrc="/images/Member/dashboard/integration/h-bank-name.png"/></Stack>
                                        <Stack width="50%"><ImgInput range='8' required={false} green type="text" control={control} name="bankaddress" setValue={setValue} value={df.holder_bank_address} error={errors.bankaddress} placeholder={text.bankaddress} imgSrc="/images/Member/dashboard/integration/h-bank-address.png" /></Stack>
                                    </Box>
                                    <Box className="wrap-content" justifyContent="space-evenly" gap="25px">
                                        <Stack width="50%"><ImgInput range='9' required={false} green type="text" control={control} name="iban" setValue={setValue} value={df.iban} error={errors.iban} placeholder={text.iban} imgSrc="/images/Member/dashboard/integration/iban.png" /></Stack>
                                        <Stack width="50%"><ImgInput range='10' required={false} green type="text" control={control} name="swiftcode" setValue={setValue} value={df.bic} error={errors.swiftcode} placeholder={text.swiftcode} imgSrc="/images/Member/dashboard/integration/swift.png"/></Stack>
                                    </Box>
                                </Stack>
                            </Stack>
                        }
                    </Stack>
                </>
            }
            
            
        </Stack>
    )
}

export default IntegUploadPay