import React from "react";
import { Typography, Stack, Box } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import IconTextCard from "../../tools/IconTextCard";
import AnimatedButton from "../../tools/AnimatedButton";
import CustomDivider from "../../tools/CustomDivider"

const LeadershipSkills = () =>{
    const { text } = useLanguage()
    const iconBaseUrl = "/images/TeamLeader/icons/3_LeadershipSkills/"
    return(
        <Stack alignItems='center' spacing={5} padding='35px 0' variant="primarybg">
            <Typography className="title-typo width-resize-tlint-typo" variant="whitetitle" textAlign='center'>
                {text.tllsint1} <Typography variant="secondarytitle" className="title-typo-change">{text.teamleader}</Typography> {text.tllsint2}
            </Typography>
            <CustomDivider color="secondary" alignCenter width='22%'/>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="strcontent" whitetext bgColor="#356ab5" iconSrc={iconBaseUrl+ "1_Leadership Experience.svg"} label={text.tlls11}>{text.tlls12}</IconTextCard>
                <IconTextCard variant="strcontent" whitetext bgColor="#356ab5" iconSrc={iconBaseUrl+ "2_Communication Skills.svg"} label={text.tlls21}>{text.tlls22}</IconTextCard>
                <IconTextCard variant="strcontent" whitetext bgColor="#356ab5" iconSrc={iconBaseUrl+ "3_Industry Knowledge.svg"} label={text.tlls31}>{text.tlls32}</IconTextCard>
            </Box>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="strcontent" whitetext bgColor="#356ab5" iconSrc={iconBaseUrl+ "4_Problem-Solving Abilities.svg"} label={text.tlls41}>{text.tlls42}</IconTextCard>
                <IconTextCard variant="strcontent" whitetext bgColor="#356ab5" iconSrc={iconBaseUrl+ "5_Performance Management.svg"} label={text.tlls51}>{text.tlls52}</IconTextCard>
                <IconTextCard variant="strcontent" whitetext bgColor="#356ab5" iconSrc={iconBaseUrl+ "6_Adaptability and Innovation.svg"} label={text.tlls61}>{text.tlls62}</IconTextCard>
            </Box>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="strcontent" whitetext bgColor="#356ab5" iconSrc={iconBaseUrl+ "7_Team Development Skills.svg"} label={text.tlls71}>{text.tlls72}</IconTextCard>
                <IconTextCard variant="strcontent" whitetext bgColor="#356ab5" iconSrc={iconBaseUrl+ "8_Quality Assurance Focus.svg"} label={text.tlls81}>{text.tlls82}</IconTextCard>
                <IconTextCard variant="strcontent" whitetext bgColor="#356ab5" iconSrc={iconBaseUrl+ "9_Collaboration and Coordination.svg"} label={text.tlls91}>{text.tlls92}</IconTextCard>
            </Box>
            <AnimatedButton to="apply-tl">{text.applynow}</AnimatedButton>
        </Stack>
    )
}

export default LeadershipSkills