import { Stack } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";

const Author = () =>{
    const { id } = useParams()
    return(
        <Stack>
        </Stack>
    )
}

export default Author