import { Typography, Stack, Box } from "@mui/material";
import React from "react";
import useLanguage from "../../hooks/useLanguage";
import IconTextCard from "../../tools/IconTextCard";
import AnimatedButton from "../../tools/AnimatedButton";
import CustomDivider from "../../tools/CustomDivider"
import { useParams } from "react-router-dom";

const Requirements = () =>{
    const iconBaseUrl = "/images/TeamLeader/icons/4_Requirements/"
    const { text } = useLanguage()
    const { lang } = useParams()
    return(
        <Stack alignItems='center' spacing={5} margin='35px 0'>
            <Typography className="title-typo text-resize-4" variant="title">
                {text.tlreint1} <Typography variant="primarytitle2" className="title-typo-change-light-2">{text.qualitysupervisor}</Typography> {text.tlreint2}
            </Typography>
            <CustomDivider color="secondary" alignCenter width='22%'/>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="strcontent" bgColor="#25dcaa" iconSrc={iconBaseUrl+ "1_Proficiency_"+ lang + ".png"} label={text.langproficiency}>{text.sre1}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#25dcaa" iconSrc={iconBaseUrl+ "2_Age Limit.svg"} label={text.agelimit}>{text.sre2}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#25dcaa" iconSrc={iconBaseUrl+ "3_Bank Account or Payment Account.svg"} label={text.bankaccountpaymentaccount}>{text.sre3}</IconTextCard>
            </Box>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="strcontent" bgColor="#25dcaa" iconSrc={iconBaseUrl+ "4_Acceptance of Privacy Statement and Contract Terms.svg"} label={text.acceptanceppterms}>{text.sre4}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#25dcaa" iconSrc={iconBaseUrl+ "5_Equipment Responsibility.svg"} label={text.equipmentresponsibility}>{text.sre5}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#25dcaa" iconSrc={iconBaseUrl+ "6_Reports.svg"} label={text.reports}>{text.sre6}</IconTextCard>
            </Box>
            <Box className="wrap-content" justifyContent='space-evenly' gap="30px" width='90%'>
                <IconTextCard variant="strcontent" bgColor="#25dcaa" iconSrc={iconBaseUrl+ "7_Minimum weekly hours.svg"} label={text.minweekly}>{text.sre7}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#25dcaa" iconSrc={iconBaseUrl+ "8_Tax.svg"} label={text.tax}>{text.sre8}</IconTextCard>
                <IconTextCard variant="strcontent" bgColor="#25dcaa" iconSrc={iconBaseUrl+ "9_Adherence to Legal and Regulatory Guidelines.svg"} label={text.adhlegalregguidelines}>{text.sre9}</IconTextCard>
            </Box>
            <AnimatedButton to="apply-s">{text.applynow}</AnimatedButton>
        </Stack>
    )
}

export default Requirements