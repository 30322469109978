import React, { useContext } from "react";
import { Stack, Typography } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import useLanguage from "../hooks/useLanguage";
import AnimatedButton from "./AnimatedButton";
import CustomDivider from "./CustomDivider";
import { useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";
import { AuthRedirectStateContext } from "../state/AuthRedirectStateContext";

const ApplyFormSignup = ({chatoperator=false, supervisor=false, teamleader=false}) => {

    const { text } = useLanguage()
    const navigate = useNavigate()
    const get = () =>{
        if (chatoperator) return RoutesList.chatoperator
        if (supervisor) return RoutesList.supervisor
        return RoutesList.jobsteamleader
    }
    const route = get()
    const { setRedirectRoute } = useContext(AuthRedirectStateContext)
    return(
        <Stack id={chatoperator ? "apply-co" : (supervisor ? "apply-s" : "apply-tl")} style={{paddingTop: "100px"}}>
            <Stack variant="applyform" padding='2px'> 
                <Stack variant= "applyform" spacing={4} style={{padding: "75px 0 "}}>
                    <Typography className="title-typo" variant="whitetitle" textAlign='center'>
                        {text.appformfor} <Typography variant="secondarytitle" className="title-typo-change">{chatoperator ? text.chatoperator : (supervisor ? text.supervisor : text.teamleader)}</Typography>
                    </Typography>
                    <CustomDivider color="secondary" alignCenter width='14%'/>
                    <Typography marginBottom='50px' textAlign='center' variant="h3title" color='#fff'>{text.tofilltheapplicationform}</Typography>
                    <AnimatedButton onClick={() =>{ setRedirectRoute(route); navigate(RoutesList.memberarea)}}>{text.signup}</AnimatedButton>
                </Stack>
            </Stack>
        </Stack>
    )
    
}

export default ApplyFormSignup;