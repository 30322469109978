import { Stack, Typography, Divider } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import Footer from "./Footer";
import IconText from "../tools/IconText";
import useLanguage from "../hooks/useLanguage";
import useData from "../hooks/useData";
import CustomDivider from "../tools/CustomDivider";

const Terms = () => {
    const { text } = useLanguage()
    const { lastUpdateTermsAndConditions } = useData()
    const baseUrl = "/images/TC/"
    return(
        <Stack>
            <Stack>
                <Stack alignItems='center' style={{width: '100%', overflow: 'hidden'}}>
                    <img src={ baseUrl + "Terms and Conditions.webp"} style={{width: '100%'}}/>
                </Stack>
                <Stack ><img src="/svg/waves1.svg"/></Stack>
                <Typography className="text-replace-2" variant="bigprimarytitle" style={{position: 'absolute', width:'65%', textAlign:'center', maxHeight: "100%"}}>{text.termsAgreeLink2real}</Typography>
            </Stack>
            <Stack alignItems='center' spacing={3} marginBottom='20px'>
                <Typography variant="primarytitle" style={{textTransform: 'capitalize'}}>{text.termsAgreeLink2real}</Typography>
                <CustomDivider color="secondary" alignCenter width='20%'/>
                <Typography>{text.lastupdated}: {lastUpdateTermsAndConditions}</Typography>
                <Stack spacing={2} style={{width: '85%'}}>
                    {
                        text.tcint.map( (t, i) =>
                            <Typography key={"n"+i} textAlign='center'>{t}</Typography>
                        )
                    }
                </Stack>
                <Stack style={{width: '85%'}} variant="card" spacing={2}>
                    <IconText center iconSrc={ baseUrl + "1- Acceptance of Terms.svg" }>
                        <Typography>{text.tc1t[0]}</Typography>
                        { text.tc1t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "2- User Eligibility.svg" }>
                        <Typography>{text.tc2t[0]}</Typography>
                        { text.tc2t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "3- Website Use.svg" }>
                        <Typography>{text.tc3t[0]}</Typography>
                        { text.tc3t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "4- Privacy and Data Handling.svg" }>
                        <Typography>{text.tc4t[0]}</Typography>
                        { text.tc4t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "5- Intellectual Property.svg" }>
                        <Typography>{text.tc5t[0]}</Typography>
                        { text.tc5t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "6- User-Generated Content.svg" }>
                        <Typography>{text.tc6t[0]}</Typography>
                        { text.tc6t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "7- Changes to the Terms.svg" }>
                        <Typography>{text.tc7t[0]}</Typography>
                        { text.tc7t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "8- Disclaimers and Limitations of Liability.svg" }>
                        <Typography>{text.tc8t[0]}</Typography>
                        { text.tc8t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "9- Governing Law and Jurisdiction.svg" }>
                        <Typography>{text.tc9t[0]}</Typography>
                        { text.tc9t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                    <Divider variant="dotted"/>
                    <IconText center iconSrc={ baseUrl + "10- Contact Information.svg" }>
                        <Typography>{text.tc10t[0]}</Typography>
                        { text.tc10t[1].map((t, i) => <Typography key={"n"+i}>{t}</Typography>)}
                    </IconText>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Terms