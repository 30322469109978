import React, { createContext, useState } from 'react'

const YourIntegStateContext = createContext(false)

const YourIntegStateProvider = ({children}) => {
    const [show, setShow] = useState(false)
    return (
        <YourIntegStateContext.Provider value={{
            show, setShow
        }}>
            {children}
        </YourIntegStateContext.Provider>
    )
}
export {YourIntegStateContext, YourIntegStateProvider}