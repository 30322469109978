import { Stack, Typography, Button,CircularProgress, IconButton } from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import countriesList from "../../helper/countries"
import FormRow from "../tools/FormRow";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import RoutesList from "../../helper/routes";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { jobs } from "../../helper/jobs";
import { situations } from "../../helper/situation";
import { testsup } from "../../helper/testsupervisor";
import { testteamlead } from "../../helper/testteamlead";

const JobApplication = () =>{
    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        jobTitle: '',
        language: 'all',
    })
    const [filter2, setFilter2] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        jobTitle: '',
        language: 'all',
        country: "all",
        trainingprocess: "both",
        integrationprocess: "both",
    })
    const { adminId, userId, managerId } = useContext(AuthStateContext)
    const location = useLocation()
    const getToken = () =>{
        switch (location.pathname.split('/')[1]){
            case RoutesList.teamleaderhome.split('/')[1]: return userId
            case RoutesList.adminhome.split('/')[1]: return adminId
            case RoutesList.managerhome.split('/')[1]: return managerId
            default: return ''
        }
    }
    const [ jobApplications, setJobApplications ] = useState([])
    const [ successfulApplications, setSuccessfulApplications ] = useState([])
    const getDatas = async () =>{
        try {     
            const { data } = await axiosInstance.get('/all_candidate', { headers: { 'Authorization': `Bearer ${getToken()}` } })
            if (data) setJobApplications(data)
        } catch (error) {}
        try {
            const { data } = await axiosInstance.get('/hired/all', { headers: { 'Authorization': `Bearer ${getToken()}` }})
            if (data) setSuccessfulApplications(data)
        } catch (error) {}
    }
    useEffect(()=>{ getDatas() },[])

    const { text } = useLanguage()
    const { filterCountries, jobName, languageName, filterLanguages } = useData()
    const [details, setDetails] = useState({})
    const questions = useMemo(()=>{
        switch (details.title){
            case jobs.teamleader:
                var final = []
                var t = testteamlead(details.language, true)
                t.forEach(tt => tt.forEach(ttt => final.push(ttt)))
                return final
            case jobs.supervisor:
                var final = []
                var t = testsup(details.language, true)
                t.forEach(tt => tt.forEach(ttt => final.push(ttt)))
                return final
            case jobs.chatoperator: return situations(details.language)
            default: return []
        }
    }, [details.title])
    const onDetails = async jobapp =>{
        console.log(jobapp)
        setDetails(jobapp)
        try { await axiosInstance.post(`/check_app/${jobapp.user_id}/${jobapp.title}/${jobapp.language}`, {}, {headers: {'Authorization': `Bearer ${getToken()}`}})
        } catch (error) {}
    }
    const [ loading, setLoading ] = useState(false)
    const appDecision = async decision =>{
        const { title, Job_id, user_id, language } = details
        if (title){
            //console.log(value)
            setLoading(true)
            setDetails({})
            try {
                const { status } = await axiosInstance.post(`/decision/${user_id}/${title}/${language}`, { decision }, { headers: { "Authorization" : `Bearer ${getToken()}` } })    
                const newList = jobApplications.filter(j => ((j.Job_id != Job_id) || (j.user_id != user_id)))
                setJobApplications(newList)
                setLoading(false)
                await getDatas()
            } catch (error) { }
        }
    }
    return(
        <Stack variant="primarybg" spacing={3}>
            <Stack style={{display: 'flex', gap:'15px', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                <img className="card-img-resize-2" src='/images/Admin/job_application.png' alt=""/>
                <Typography className="style-font-2" variant="whitetitle" alignSelf='center' color='#d314fe' fontWeight='bold' 
                    style={{WebkitTextStroke: "1px #66007b"}}
                >{text.jobapplication.toUpperCase()  }</Typography>
            </Stack>
            <BigContainerAccordion 
                summaryBg="#0cc0df"
                summary={text.aja1}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.jobtitle , comp: 'TextInput', onChange: e => setFilter({...filter, jobTitle: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter({...filter, language: l.target.value}) },
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 1, text: text.date },
                                { xs: 2, text: text.jobtitle },
                                { xs: 2, text: text.jobdescription },
                                { xs: 1, text: text.language },
                                { xs: 2, text: text.applicantfirstname },
                                { xs: 2, text: text.applicantlastname },
                                { xs: 1, text: text.applicantcountry },
                                { xs: 1, text: text.application },
                            ]} />
                            {
                                jobApplications
                                    .filter(j => 
                                        // Starting date
                                        ( (!filter.startingDate) || ( filter.startingDate <= new Date(j.date)) )
                                        // Closing date
                                        && ( (!filter.closingDate) || (new Date(j.date) <= filter.closingDate) )
                                        // Text match
                                        && ((!Boolean(jobName(j.title))) || (jobName(j.title)?.toLowerCase().includes(filter.jobTitle)))
                                        // Language
                                        && ( (filter.language == 'all') || (j.language == filter.language))  
                                    )
                                    .sort((a, b) => {
                                        if (a.date && b.date) {
                                          return new Date(a.date) - new Date(b.date);
                                        } else if (a.date && !b.date) {
                                          return new Date(a.date) - new Date(b.date_form);
                                        } else if (!a.date && b.date) {
                                          return new Date(a.date_form) - new Date(b.date);
                                        } else {
                                          return new Date(a.date_form) - new Date(b.date_form);
                                        }
                                    })
                                    .map((j, i) =>
                                    <TableRow key={`jobapp${i}`} columns={[
                                        { xs: 1, text: j.date ? format(j.date, 'dd/MM/yyyy') : (j.date_form ? format(j.date_form, 'dd/MM/yyyy') : '') },
                                        { xs: 2, text: jobName(j.title) },
                                        { xs: 2, text: j.description },
                                        { xs: 1, text: languageName(j.language) },
                                        { xs: 2, text: j.firstname },
                                        { xs: 2, text: j.lastname },
                                        { xs: 1, text: countriesList.find(c => c.value == j.country).innerHTML },
                                        { xs: 1, customContent: true, content:
                                            <Stack width='100%'>
                                                <Button onClick={()=> onDetails(j)} style={{ textTransform: 'capitalize', color: '#3c6dc6', width: '100%' }}><Typography variant="boldcenter">{text.checkthapplication}</Typography></Button>
                                            </Stack>
                                        },
                                    ]} />
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                dataToDisplay={details}
                summaryBg="#d315fe"
                summary={text.aja2}
                details={
                    <Stack spacing={2}>
                        <FormRow label={text.jobtitle} comp="readOnly" value={jobName(details.title)}/>
                        <FormRow label={text.firstname} comp="readOnly" value={details.firstname}/>
                        <FormRow label={text.lastname} comp="readOnly" value={details.lastname}/>
                        <FormRow label={text.email} comp="readOnly" value={details.mail}/>
                        <FormRow label={text.birthday} comp="readOnly" value={details.birth_date ? format(details.birth_date, 'dd/MM/yyyy') : ''}/>
                        <FormRow label={text.telephonenumber} comp="readOnly" value={details.phone_number}/>
                        <FormRow label={text.skypeid} comp="readOnly" value={details.skype_id}/>
                        <FormRow label={text.language} comp="readOnly" value={languageName(details.language)}/>
                        <FormRow label={text.haveexperience} comp="readOnly" value={[true, false].includes(details.experience) ? ( details.experience ? text.yes : text.no ) : ''}/>
                        <FormRow label={text.howtheyfindus} comp="readOnly" value={details.heard_about}/>
                        <FormRow label={text.motivation} comp="readOnly" value={details.motivation}/>
                        <FormRow label={text.rsm} comp="readOnlyPDF" value={details.cv}/>
                        <FormRow label={text.testresult} comp="readOnly" value={
                            <Stack spacing={2}>{
                                (details.test_result?.length >0) && questions.map((q, i) => <Stack key={`testresult${i}`} spacing={1}>
                                    <Typography fontWeight="bold">{`${i+1} - ${q}`}</Typography>
                                    <Typography>{details.test_result ? details.test_result[i] : ''}</Typography>
                                </Stack>)
                            }</Stack>
                        }/>
                        
                        {(details.test_result?.length >0) && <Stack display="flex" flexDirection="row" alignItems='center' justifyContent='space-evenly'>
                            {
                                loading ? <CircularProgress style={{color: '#fff'}} size="24px"/>
                                : <>
                                    <Typography style={{padding: '10px 15px', color:'#fff', background: '#000', fontWeight: 'bold'}}>{text.decision}:</Typography>      
                                    <Stack flexDirection='row' gap='10px' alignItems='center'>
                                        <IconButton onClick={()=>{appDecision('Hired')}} style={{height: '17px', width: '17px', border: '3px solid white'}} />
                                        <Typography style={{padding: '10px 15px', color:'#fff', background: '#1cb743', fontWeight: 'bold'}}>{text.hired2}</Typography>     
                                    </Stack>
                                    <Stack flexDirection='row' gap='10px' alignItems='center'>
                                        <IconButton onClick={()=>{appDecision('Rejected')}} style={{height: '17px', width: '17px', border: '3px solid white'}} />
                                        <Typography style={{padding: '10px 15px', color:'#fff', background: '#ff1723', fontWeight: 'bold'}}>{text.rejected}</Typography>     
                                    </Stack>
                                </>
                            }
                        </Stack>}
                    </Stack>
                }
            />
            <BigContainerAccordion 
                summaryBg="#fbc20b"
                summary={text.aja3}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                                date: d => setFilter2({...filter2, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter2.startingDate, onChange: d => setFilter2({...filter2, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter2.closingDate, onChange: d => setFilter2({...filter2, closingDate: d})},
                                { label: text.jobtitle , comp: 'TextInput', onChange: e => setFilter2({...filter2, jobTitle: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: filterLanguages ,onChange: l => setFilter2({...filter2, language: l.target.value}) },
                                { label: text.country , comp: 'Select', choices: filterCountries ,onChange: c => setFilter2({...filter2, country: c.target.value}) },
                                { label: text.trainingprocess , comp: 'Select', choices: [{value: 'both', innerHTML: text.all},{value: 'done', innerHTML: text.done},{value: 'inprogress', innerHTML: text.inprogress}], onChange: p => setFilter2({...filter2, trainingprocess: p.target.value}) },
                                { label: text.integrationprocess , comp: 'Select', choices: [{value: 'both', innerHTML: text.all},{value: 'done', innerHTML: text.done},{value: 'inprogress', innerHTML: text.inprogress}], onChange: p => setFilter2({...filter2, integrationprocess: p.target.value}) },
                            ]}
                        />
                        <Stack>
                            <TableRow header columns={[
                                { xs: 1, text: text.date },
                                { xs: 2, text: text.firstname },
                                { xs: 2, text: text.lastname },
                                { xs: 1, text: text.jobposition },
                                { xs: 1, text: text.language },
                                { xs: 3, text: text.country },
                                { xs: 1, text: text.integrationn },
                                { xs: 1, text: text.training },
                            ]} />
                            {
                                successfulApplications
                                .filter(a =>
                                    // Starting date
                                    ( (!filter2.startingDate) || ( filter2.startingDate <= new Date(a.date_test)) )
                                    // Closing date
                                    && ( (!filter2.closingDate) || (new Date(a.date_test) <= filter2.closingDate) )
                                    // Text match
                                    && ((!Boolean(jobName(a.jobTitle))) || (jobName(a.jobTitle)?.toLowerCase().includes(filter2.jobTitle)))
                                    // Language
                                    && ( (filter2.language == 'all') || (a.language == filter2.language))  
                                    // Country
                                    && ( (filter2.country == 'all') || (a.country == filter2.country))  
                                    // Done / In progress training
                                    && (
                                        (filter2.trainingprocess == 'both')
                                        || ( (filter2.trainingprocess == 'done') && (a.date_schedule) && (new Date() > new Date(a.date_schedule) ))
                                        || ((filter2.trainingprocess == 'inprogress') && ((!a.date_schedule) || (new Date() < new Date(a.date_schedule))))
                                    )
                                    // Done / In progress integration
                                    && (
                                        (filter2.integrationprocess == 'both')
                                        || ( (filter2.integrationprocess == 'done') && (a.integration_date) && (new Date() > new Date(a.integration_date) ))
                                        || ((filter2.integrationprocess == 'inprogress') && ((!a.integration_date) || (new Date() < new Date(a.integration_date))))
                                    )
                                    )
                                .map((a, i) =>
                                    <TableRow key={`succapp${i}`} columns={[
                                        { xs: 1, text: a.date_test ? format(a.date_test, 'dd/MM/yyyy') : '' },
                                        { xs: 2, text: a.firstName },
                                        { xs: 2, text: a.lastName },
                                        { xs: 1, text: jobName(a.jobTitle) },
                                        { xs: 1, text: languageName(a.language) },
                                        { xs: 3, text: countriesList.find(c => c.value == a.country)?.innerHTML },
                                        {   xs: 1,
                                            color: a.integration_date ? '#0b0' : '#3c6dc6',
                                            text: a.integration_date ? text.done + ' (' + format(a.integration_date, 'dd/MM/yyyy') + ')' : text.inprogress
                                        },
                                        {   xs: 1,
                                            color: (a.date_schedule && (new Date() > new Date(a.date_schedule) )) ? '#0b0' : '#3c6dc6',
                                            text: (a.date_schedule && (new Date() > new Date(a.date_schedule) )) ? text.done + ' (' + format(a.date_schedule, 'dd/MM/yyyy') + ')' : text.inprogress
                                        },
                                    ]} />
                                )
                            }
                        </Stack>

                    </Stack>
                }
            />
        </Stack>
    )
}

export default JobApplication