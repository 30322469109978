import React, { useEffect, useState } from "react";
import { Stack, Box, Typography, Checkbox, Grid, Button, Alert } from "@mui/material";
import ImgInputLight from "../../tools/ImgInputLight";
import useLanguage from "../../hooks/useLanguage";
import { useForm, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import RoutesList from "../../../helper/routes";
import { Link } from "react-router-dom";
import PointedButton from "../../tools/PointedButton";
import CustomSelect from "../../tools/select";
import useData from "../../hooks/useData";
import countriesList from '../../../helper/countries'

const SignUp = ({mainData, onMailVerify, visible, onChangeSlide, loading, setLoading, error, setError, phonePrefix, setPhonePrefix}) =>{
    const baseUrl = '/images/Member/'
    const {text} = useLanguage()
    const [ ppAgree, setPpAgree ] = useState(false)
    const { control, handleSubmit, formState: {errors}, setValue } = useForm()
    const { genders } = useData()
    
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [mail, setMail] = useState('')
    useEffect(()=>{
        const data = mainData
        if (data){
            setFirstname(data.firstname)
            setLastname(data.lastname)
            setMail(data.mail)
        }
    },[mainData])

    const createAccount = async data =>{
        setError(ppAgree ? '' : text.consentsErr)
        if (ppAgree){
            setLoading(true)
            await onMailVerify(data)
            setLoading(false)
        }
    }

    return(
        <Stack spacing={3} width="100%" style={{transition: 'visibility .2s', visibility: visible ? 'visible' : 'hidden'}}>
           <Typography variant="style-font-title" className="style-font-2">{text.settingupaccount.toUpperCase()}</Typography>
           <Typography variant="white">{text.settingupaccountint}</Typography>
            <Stack>
                <Box className="wrap-content" justifyContent="space-evenly">
                    <Stack width="50%">
                        <ImgInputLight readonly type="text" setValue={setValue} value={firstname} control={control} name="firstname" error={errors.firstname} placeholder={text.yourfirstname} imgSrc={baseUrl + 'user.png'}/>
                    </Stack>
                    <Stack width="50%">
                        <ImgInputLight readonly type="text" setValue={setValue} value={lastname} control={control} name="lastname" error={errors.lastname} placeholder={text.yourlastname} imgSrc={baseUrl + 'user.png'}/>
                    </Stack>
                </Box>
                <Box className="wrap-content" justifyContent="space-evenly">
                    <Stack width="50%"><ImgInputLight type="text" control={control} name="username" error={errors.username} placeholder={text.yourusername} imgSrc={baseUrl + 'username.png'}/></Stack>
                    <Stack width="50%"><ImgInputLight type="text" control={control} name="skypeid" error={errors.skypeid} placeholder={text.yourskypeid} imgSrc={baseUrl + 'skypeid.png'}/></Stack>
                </Box>
                <Box className="wrap-content" justifyContent="space-evenly">
                    <Stack width="50%">
                        <Stack style={{flexDirection: 'row', alignItems:"center"}}>    
                            <Stack className="imginput-img" borderRadius="50%" style={{ backgroundColor: '#eaea53', transform: "translateX(30%)" , border: '5px solid #4B4998'}}><img src={baseUrl + 'birth.png'} alt="" /></Stack>
                            <Stack style={{backgroundColor:"#eaea53", flexDirection:"row", width:"100%", alignItems: "center"}}>
                                <Controller 
                                    name="birthdate" 
                                    control={control}
                                    rules={{required: text.required}}
                                    render={({ field }) => (
                                        <DatePicker 
                                            placeholderText={text.yourbirthdate} 
                                            dateFormat='dd-MM-yyyy' 
                                            showYearDropdown 
                                            scrollableYearDropdown 
                                            yearDropdownItemNumber={100} 
                                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                                            className="in-datepicker" 
                                            selected={field.value} 
                                            onChange={field.onChange}
                                        />    
                                    )}
                                />
                            </Stack>
                            <Stack style={{  width: 0, height: 0, borderTop: '25px solid transparent', borderRight:'25px solid #4B4998', borderBottom: '25px solid transparent', transform: "translateX(-100%)"}}></Stack>
                        </Stack>
                        {!!errors.birthdate && <Typography variant="error" textAlign="left">{errors.birthdate.message}</Typography>}
                    </Stack>
                    {}
                    <Stack width="50%">
                        <Stack style={{flexDirection: 'row', alignItems:"center"}}>    
                            <Stack className="imginput-img" borderRadius="50%" style={{ backgroundColor: '#eaea53', transform: "translateX(30%)" , border: '5px solid #4B4998'}}><img src={baseUrl + 'gender.png'} alt="" /></Stack>
                            <Stack style={{backgroundColor:"#eaea53", flexDirection:"row", width:"100%", alignItems: "center"}}>
                                <CustomSelect name="gender" control={control}
                                    options={genders} className="in-input-light"
                                />
                            </Stack>
                            <Stack style={{  width: 0, height: 0, borderTop: '25px solid transparent', borderRight:'25px solid #4B4998', borderBottom: '25px solid transparent', transform: "translateX(-100%)"}}></Stack>
                        </Stack>
                    </Stack>
                    {}
                </Box>
                <Box className="wrap-content" justifyContent="space-evenly">
                    <Stack width="50%">
                        <Stack style={{flexDirection: 'row', alignItems:"center"}}>    
                            <Stack className="imginput-img" borderRadius="50%" style={{ backgroundColor: '#eaea53', transform: "translateX(30%)" , border: '5px solid #4B4998'}}><img src={baseUrl + 'country.png'} alt="" /></Stack>
                            <Stack style={{backgroundColor:"#eaea53", flexDirection:"row", width:"100%", alignItems: "center"}}>
                                <CustomSelect name="country" control={control} onExternalChange={val => setPhonePrefix(`+${countriesList.find(c => c.value == val).phone}`)}
                                    options={countriesList} className="in-input-light"
                                />
                            </Stack>
                            <Stack style={{  width: 0, height: 0, borderTop: '25px solid transparent', borderRight:'25px solid #4B4998', borderBottom: '25px solid transparent', transform: "translateX(-100%)"}}></Stack>
                        </Stack>
                    </Stack>
                    <Stack width="50%"><ImgInputLight type="text" control={control} prefix={phonePrefix} name="phone" error={errors.phone} placeholder={text.yourphonenumber} imgSrc={baseUrl + 'phone.png'}/></Stack>
                </Box>
                <Box className="wrap-content" justifyContent="space-evenly">
                    <Stack width="50%"><ImgInputLight readonly type="email" setValue={setValue} value={mail} control={control} name="mail" error={errors.mail} placeholder={text.yourmailaddress} imgSrc={baseUrl + 'mail.png'}/></Stack>
                    <Stack width="50%"><ImgInputLight type="text" control={control} name="appartroad" error={errors.appartroad} placeholder={text.appartroad} imgSrc={baseUrl + 'home.png'}/></Stack>
                </Box>
                <Box className="wrap-content" justifyContent="space-evenly">
                    <Stack width="50%"><ImgInputLight type="text" control={control} name="citydep" error={errors.citydep} placeholder={text.yourcitydep} imgSrc={baseUrl + 'home.png'}/></Stack>
                    <Stack width="50%"><ImgInputLight type="text" control={control} name="pobox" error={errors.pobox} placeholder={text.yourpobox} imgSrc={baseUrl + 'postalcode.png'}/></Stack>                   
                </Box>
            </Stack>
            <Stack spacing={1}>
                <Stack display='flex' flexDirection='row' justifyContent='flex-start' gap='10px'>
                    <Checkbox onChange={()=> setPpAgree(!ppAgree)} variant="primarybg" size="small"/>
                    <Typography variant="footertext">
                        { text.agreepp1 } <Link to={RoutesList.pp} className="link">{ text.termsAgreeLink1 }</Link> { text.agreepp2 }
                    </Typography>
                </Stack>
            </Stack>

            {error && <Alert style={{margin: "20px"}} severity="error">
                <Typography variant="error">{error}</Typography>
            </Alert>}
            <Stack width="50%" alignSelf="center" alignItems="center">
                <PointedButton disabled={loading} loading={loading} onClick={handleSubmit(createAccount)}>{text.createmyaccount}</PointedButton>
                <Button onClick={()=>onChangeSlide(1, null)}><Typography variant="white">{text.back}</Typography></Button>
            </Stack>
        </Stack>
    )
}
export default SignUp